import React, { useState, useRef, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useIntl } from 'react-intl'
import { Popup, IconButton, AuroraPanel } from 'components'
import { Spacer, PopupTitle, PopupSubTitle, DynamicImage } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { getAccessibilityManager } from '../../main'

/* interface PopupTimerProps {
  timeoutManger: TimeoutManager
  popupTimeoutDuration?: number
  timeoutDuration?: number
  isShowTimeoutPopup?: boolean
  onBtnClicked: (answer: string) => void
  yesMsg?: string
  noMsg?: string
  textMsg?: string
  subTextMsg?: string
  buttonGap?: number
  bgColor?: string
  buttonYesBgColor?: string
  buttonNoBgColor?: string
} */

const PopupTimer = (props) => {
  const intl = useIntl()
  const {
    timeoutManger,
    popupTimeoutDuration,
    timeoutDuration,
    isShowTimeoutPopup,
    onBtnClicked,
    yesMsg,
    noMsg,
    textMsg,
    subTextMsg,
    buttonGap,
    bgColor,
    buttonYesBgColor,
    buttonNoBgColor
  } = props
  const isLandscape = useCheckLandscape()
  const timeoutTimer = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const themeContext = useContext(ThemeContext)

  const accMgr = getAccessibilityManager()
  const screenMessage = `${textMsg}. ${subTextMsg}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'exitLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'cancelNo', text: intl.formatMessage(messages.buttonQuit), buttonId: 'cancelNo' },
        { id: 'cancelYes', text: intl.formatMessage(messages.buttonContinue), buttonId: 'cancelYes' }
      ]
    }
  }

  /* const accMgr = getAccessibilityManager()
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        {
          id: 'popupTimerContent',
          text: `${textMsg}. ${subTextMsg}`
        },
        { id: 'yesNeedTimeBtn', text: intl.formatMessage(messages.buttonYes), buttonId: 'yesNeedTimeBtn' },
        { id: 'noNeedTimeBtn', text: intl.formatMessage(messages.buttonNo), buttonId: 'noNeedTimeBtn' }
      ]
    }
  } */

  useEffect(() => {
    const handleTimeout = () => {
      console.log('handleTimeout from PopupTimer')
      console.log('from popupTimer => IsShowTimeoutPopup:', isShowTimeoutPopup)
      if (isShowTimeoutPopup) {
        timeoutManger.stopTimer()
        setIsVisible(true)
        clearPopupTimer()
        startPopupTimer()
        accMgr.buildPopupGroup(true, accDef) // accessibility
      } else {
        if (onBtnClicked) {
          onBtnClicked('NO')
        }
      }
    }
    timeoutManger.setTimeoutCallback(handleTimeout)
  }, [isShowTimeoutPopup])

  const startPopupTimer = () => {
    timeoutTimer.current = setTimeout(onPopupTimeout, popupTimeoutDuration ? popupTimeoutDuration * 1000 : 5000)
    console.log(
      'Timer startPopupTimer() ... START (POPUP) ... ',
      timeoutTimer,
      ' ... duration ... ',
      popupTimeoutDuration
    )
  }

  const clearPopupTimer = () => {
    if (timeoutTimer.current) {
      clearTimeout(timeoutTimer.current)
      timeoutTimer.current = null
    }
  }

  const onYesBtnClicked = () => {
    clearPopupTimer()
    // reset page timer
    timeoutManger.resetTimer(timeoutDuration)
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, { action: 'QUIT' }) // accessibility
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true }) // accessibility
    if (onBtnClicked) {
      onBtnClicked('YES')
    }
  }

  const onNoBtnClicked = () => {
    clearPopupTimer()
    timeoutManger.stopTimer()
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, {}) // accessibility
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true }) // accessibility
    if (onBtnClicked) {
      onBtnClicked('NO')
    }
  }

  const onPopupTimeout = () => {
    clearPopupTimer()
    timeoutManger.stopTimer()
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, {}) // accessibility
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true }) // accessibility
    if (onBtnClicked) {
      onBtnClicked('NO')
    }
  }

  return (
    <Popup visible={isVisible} cssDialogName="popup-dialog">
      <div className="popup-body">
        <AuroraPanel
          width="100%"
          height="100%"
          panelBgColor={themeContext.PopupBgColor}
          showTopBorder={false}
          cssName={'aurora-panel'}
          justifyContent={'space-between'}
        >
          <div id="exitLabel" className="popup-content" tabIndex="0">
            {isLandscape ? <Spacer height={'50px'} /> : <Spacer height={'10px'} />}
            <DynamicImage
              imageName={'question-mark-icon.svg'}
              cssName={'emb_animation_drawbox'}
              width={200}
              height={200}
            />
            <div className="popup-text-wrap">
              <PopupTitle color={themeContext.PrimaryFontColor}>{textMsg}</PopupTitle>
              <PopupSubTitle color={themeContext.PrimaryFontColor}>{subTextMsg}</PopupSubTitle>
            </div>
            {isLandscape ? <Spacer height={'50px'} /> : <Spacer height={'10px'} />}
          </div>
          <div className="popup-buttons">
            <IconButton
              id={'cancelNo'}
              cssName={'cancel-btn'}
              color={themeContext.CancelButton.color}
              bgColor={themeContext.CancelButton.bgColor}
              width={themeContext.CancelButton.width}
              height={themeContext.CancelButton.height}
              iconLocation={'LEFT'}
              isLeftBtn={true}
              //disable={props.disableAction}
              onClick={onNoBtnClicked}
              fontSize={20}
              text={intl.formatMessage(messages.buttonQuit)}
              icon={'cancel-icon.svg'}
              iconColor={themeContext.CancelButton.iconColor}
            />
            <Spacer width={buttonGap} />
            <IconButton
              id={'cancelYes'}
              cssName={'confirm-btn'}
              color={themeContext.ConfirmButton.color}
              bgColor={themeContext.ConfirmButton.bgColor}
              width={themeContext.ConfirmButton.width}
              height={themeContext.ConfirmButton.height}
              iconLocation={'RIGHT'}
              isRightBtn={true}
              //disable={props.disableAction}
              onClick={onYesBtnClicked}
              fontSize={20}
              text={intl.formatMessage(messages.buttonContinue)}
              icon={'continue-icon.svg'}
              iconColor={themeContext.ConfirmButton.iconColor}
            />
          </div>
        </AuroraPanel>
      </div>
    </Popup>
  )
}

PopupTimer.defaultProps = {
  popupTimeoutDuration: 5000, // default to 60 seconds
  isShowTimeoutPopup: true, // if false, do not show timeout popup
  yesMsg: 'Yes',
  noMsg: 'No',
  textMsg: 'Do you need more time?',
  buttonGap: 40,
  bgColor: 'white'
}

export default PopupTimer
