import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import useUIBase from 'hooks/ui/useUIBase'
import { selectPaxFlight } from 'actions/etsTransactions/selectPaxFlight'
import { Button, CheckboxStatus, Checkbox, EMBTable } from 'components'
import { PageContent, PageTitle, PageSubTitle, PageSubContent, Spacer } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { Footer } from '../footer'
import { playSound, getAccessibilityManager, getBuildAccessibility } from 'main'
import { clone, populateItineraryInfo } from 'utils/helper'

const getData = (passengers, mainPassengerOrdinal, formatMessage) => {
  let selectableCheckboxStatus = CheckboxStatus.UNCHECKED
  let alreadyCheckIn = false
  let data = []
  let canSelectCount = 0
  console.log('getData...')
  if (passengers && passengers.length > 0) {
    passengers.map((paxInfo) => {
      let additionalText = ''
      let additionalText1 = ''
      let additionalText2 = ''
      // additionalText = ''
      alreadyCheckIn = false
      selectableCheckboxStatus = CheckboxStatus.UNCHECKED
      if (paxInfo.passenger.ordinal === mainPassengerOrdinal) {
        selectableCheckboxStatus = CheckboxStatus.CHECKED_DISABLED
        //setIsDisableConfirmButton(false)
      } else {
        canSelectCount++
      }

      /*if (paxInfo.passenger.paxTxnStatus.selectable) {
        // checked single passenger by default
        if (passengers.length === 1) {
          selectableCheckboxStatus = CheckboxStatus.CHECKED
          //this.disableConfirm = false
        } else {
          selectableCheckboxStatus = CheckboxStatus.UNCHECKED
        }
        canSelectCount++
      } else {
        selectableCheckboxStatus = CheckboxStatus.UNCHECKED_DISABLED
        canSelectCount--
      }*/

      /*if (
        pax.flightSpecific[0].checkinStatus === CHECK_IN_STATUS.CHECKED_IN_NOT_PRINTED ||
        pax.flightSpecific[0].checkinStatus === CHECK_IN_STATUS.CHECKED_IN_PRINTED
      ) {
        alreadyCheckIn = true
        alreadyCheckInCount++
        /!*if (this.props.enableFastPath === 'false') {
          selectableCheckboxStatus = CheckboxStatus.UNCHECKED_DISABLED
          selectableCount--
        }*!/
      } else {
        alreadyCheckIn = false
      }*/

      /*if (paxInfo.passenger.withInfant) {
        additionalText += ' +' + formatMessage(messages.Infant) + ' '
      }*/
      /*if (pax.showDOB) {
        dob = getDOB(pax.ordinal)
        additionalText += dob ? ' (' + formatDOB(dob) + ') ' : ''
      }
      if (pax.alreadyCheckIn) {
        additionalText1 = formatMessage(messages.AlreadyCheckedin)
      }
      if (pax.inhibited) {
        additionalText2 = formatMessage(messages.InhibitPassenger)
      }*/
      //this.allAlreadyCheckIn = alreadyCheckInCount === passengers.length

      if (paxInfo.passenger.withInfant) {
        additionalText += ` +${formatMessage(messages.Infant)} `
      }
      data.push({
        ordinal: paxInfo.passenger.ordinal,
        id: 'pax_' + paxInfo.passenger.ordinal.toString(),
        text: paxInfo.passenger.firstName + ' ' + paxInfo.passenger.lastName,
        passengerType: paxInfo.passenger.passengerType,
        status: selectableCheckboxStatus,
        dateOfBirth: paxInfo.passenger.dateOfBirth,
        alreadyCheckIn: alreadyCheckIn,
        withInfant: paxInfo.passenger.withInfant,
        additionalText: additionalText,
        additionalText1: additionalText1,
        additionalText2: additionalText2
      })
    })
  }
  return data
}

const PassengerSelection = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(false)
  const [itineraryData, setItineraryData] = useState(null)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const isQuitRequired = true
  const isLandscape = useCheckLandscape()

  const columns = isLandscape
    ? [
        {
          header: '',
          width: '500px'
        },
        {
          header: '',
          width: '500px'
        }
      ]
    : [
        {
          header: '',
          width: '600px'
        }
      ]

  const handleSelection = (updatedData) => {
    playSound.beepOK()
    console.log('onCheckboxClick id=' + updatedData[0].id + ', status=' + updatedData[0].status)
    let hasChecked = false
    data.map((item) => {
      if (item.id === updatedData[0].id) {
        item.status = updatedData[0].status
      }
      if (item.status === CheckboxStatus.CHECKED || CheckboxStatus.CHECKED_DISABLED) {
        hasChecked = true
      }
    })
    if (hasChecked) {
      setIsDisableConfirmButton(false)
    } else {
      setIsDisableConfirmButton(true)
    }
    setData([...data])
  }
  const buildRows = (data) => {
    let canSelectCount = 0
    let rows = []
    let count = 0
    if (!data) {
      return rows
    }
    if (isLandscape) {
      data.forEach((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        const checkbox = (
          <Checkbox
            key={'key-' + index}
            id={cell.id}
            data={[checkboxData]}
            onClick={handleSelection}
            additionalText={cell.additionalText}
            additionalText1={cell.additionalText1}
            additionalText2={cell.additionalText2}
            tabIndex={cell.tabIndex}
          />
        )
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        if (index % 2 === 0) {
          rows.push({ data: [checkbox] })
        } else {
          rows[count].data.push(checkbox)
          count++
        }
      })
    } else {
      data.map((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        rows.push({
          data: [
            <Checkbox
              key={'key-' + index}
              id={cell.id}
              data={[checkboxData]}
              onClick={handleSelection}
              additionalText={cell.additionalText}
              additionalText1={cell.additionalText1}
              additionalText2={cell.additionalText2}
              tabIndex={cell.tabIndex}
            />
          ]
        })
      })
    }
    return rows
  }

  const getCheckAllButton = (data) => {
    let checkAllButton = data ? (
      <PageContent flexFlow="row" justifyContent="flex-start" style={{ width: isLandscape ? '90%' : '' }}>
        <Button
          id={'selectAllPassengers'}
          cssName={'large-blue-button-up'}
          color={themeContext.OutlineButton.color}
          bgColor={themeContext.OutlineButton.bgColor}
          border={themeContext.OutlineButton.border}
          width={themeContext.OutlineButton.width}
          height={themeContext.OutlineButton.height}
          fontSize={20}
          justifyContent={'center'}
          borderRadius={themeContext.OutlineButton.borderRadius}
          onClick={() => {
            playSound.beepOK()
            data.forEach((item) => {
              if (item.status === CheckboxStatus.UNCHECKED) {
                item.status = CheckboxStatus.CHECKED
              }
            })
            setData([...data])
          }}
        >
          {formatMessage(messages.buttonSelectAll)}
        </Button>
      </PageContent>
    ) : (
      ''
    )
    return checkAllButton
  }

  useEffect(() => {
    const mainPassengerOrdinal =
      itineraryInfo && itineraryInfo.scanSequence.length > 0 ? itineraryInfo.scanSequence[0] : 0
    const passengers = itineraryInfo.passengerBagtagDetails
    const initData = getData(passengers, mainPassengerOrdinal, intl.formatMessage)
    setItineraryData(populateItineraryInfo(itineraryInfo))
    setData(initData)
  }, [])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const accMgr = getAccessibilityManager()

  useEffect(() => {
    if (data) {
      handleAccessibility()
    }
  }, [data, locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PassengerSelection',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: true,
      sequenceDef: {
        sequence: getSequence(),
        nextKeyNavId: 'confirmBtn'
      }
    }
    buildAccessibility(accDef)
  }

  const getSequence = () => {
    const screenTitle = `${intl.formatMessage(messages.PassengerSelectionTitle)}. ${intl.formatMessage(
      messages.PassengerSelectionSubTitle
    )}.`
    const language = accMgr.getLanguageDef(locale).language
    const sequence = [{ id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [screenTitle] }]
    if (data.length > 1) {
      sequence.push({
        id: 'selectAllPassengers',
        textId: 'SelectAllPassengers',
        buttonId: 'selectAllPassengers'
      })
    }
    data.map((pax) => {
      // console.log('pax: ', pax)
      let parameters = []
      if (pax.status === CheckboxStatus.CHECKED) {
        parameters = [pax.text, language['Selected'], language['UnSelected']]
      } else {
        parameters = [pax.text, language['UnSelected'], language['Selected']]
      }
      if (pax.status === CheckboxStatus.CHECKED_DISABLED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'SelectedAndDisabledPassenger',
          textParameters: [pax.text],
          buttonId: 'pax_' + pax.ordinal
        })
      } else if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.UNCHECKED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'NavigateSelectPassenger',
          textParameters: parameters,
          buttonId: 'pax_' + pax.ordinal
        })
      }
    })
    sequence.push({ id: 'confirmBtn', textId: 'NavNext', buttonId: 'confirmBtn' })

    return sequence
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // return (
  //   <PageContent>
  //     <PageContent padding="0 20px">
  //       <PageContent
  //         borderRadius={'25px'}
  //         boxShadow={themeContext.MainContentBoxShadow}
  //         background={themeContext.MainContentBackground}
  //       >
  //         <PageContent>
  //           <EMBTable
  //             columns={columns}
  //             rows={buildRows(data)}
  //             headerHeight={50}
  //             rowHeight={isLandscape ? 90 : 80}
  //             rowFontSize={30}
  //             headFontSize={30}
  //             maxDisplayRows={isLandscape ? 4 : 6}
  //           />
  //           {getCheckAllButton(data)}
  //         </PageContent>
  //         <PageSubContent id="a_title" tabIndex={0}>
  //           <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
  //             {intl.formatMessage(messages.PassengerSelectionTitle)}
  //           </PageTitle>
  //           <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
  //             {intl.formatMessage(messages.PassengerSelectionSubTitle)}
  //           </PageSubTitle>
  //         </PageSubContent>
  //       </PageContent>
  //     </PageContent>
  //     <Footer
  //       isQuitRequired={!appMode}
  //       quitBtnText={formatMessage(messages.buttonQuit)}
  //       isBackRequired={false}
  //       isSkipRequired={false}
  //       isConfirmRequired={true}
  //       confirmAction={() => {
  //         let selectedPassengers = []
  //         data.forEach((pax) => {
  //           if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.CHECKED_DISABLED) {
  //             selectedPassengers.push({
  //               paxOrdinal: pax.ordinal,
  //               selected: true
  //             })
  //           } else {
  //             selectedPassengers.push({
  //               paxOrdinal: pax.ordinal,
  //               selected: false
  //             })
  //           }
  //         })
  //         dispatch(selectPaxFlight(selectedPassengers))
  //       }}
  //       confirmBtnText={formatMessage(messages.buttonNext)}
  //       disableConfirmAction={isDisableConfirmButton}
  //     />
  //   </PageContent>
  // )
  const animationSection = (
    <>
      <EMBTable
        columns={columns}
        rows={buildRows(data)}
        headerHeight={50}
        rowHeight={80}
        rowFontSize={30}
        headFontSize={30}
        maxDisplayRows={isLandscape ? 4 : 6}
      />
      {getCheckAllButton(data)}
    </>
  )

  const textSection = config.disableConfirmPassenger ? (
    ''
  ) : (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.PassengerSelectionTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.PassengerSelectionSubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={!appMode}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={() => {
        let selectedPassengers = []
        data.forEach((pax) => {
          if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.CHECKED_DISABLED) {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: true
            })
          } else {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: false
            })
          }
        })
        dispatch(selectPaxFlight(selectedPassengers))
      }}
      confirmBtnText={formatMessage(messages.buttonContinue)}
      disableConfirmAction={isDisableConfirmButton}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default PassengerSelection
