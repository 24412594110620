import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { store, playSound, history, getBuildAccessibility, getAcuantClient, getSummaryStore } from 'main'
import { TestButtons } from 'components'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { appLog, logEvent } from 'utils/Logger'
import { navigate } from 'utils/helper'
import { Footer } from '../footer'
import { GeneratePP, GenerateChipPP, testData } from 'utils/passportGenerator'
import { checkTravelDoc } from 'actions/etsTransactions/checkTravelDoc'
import { updateLocalData } from 'actions/localActions'
import usePassportScanner from 'hooks/usePassportScanner'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import {
  PageTitle,
  PageSubTitle,
  PageHeader,
  PageContent,
  PageSubContent,
  DynamicImage
} from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'

const ScanDocuments = (props) => {
  const intl = useIntl()
  const summaryStore = getSummaryStore()
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const buildAccessibility = getBuildAccessibility()
  const { formatMessage } = intl
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const scannedCallback = (scannedValue, image) => {
    //console.log('what is the scanned value??????', scannedValue, image)
    logEvent('DOC CHECK Scan: document scanned')
    logEvent('DOC CHECK Scan: document type: ' + 'passport')
    // no accuant , no update data
    // summaryStore.updateDocScan(
    //   responseObj.documentType,
    //   responseObj.issuingCountry,
    //   responseObj.issuingState,
    //   responseObj.authenticationResult === AcuantStatus.PASSED
    // )

    // summaryStore.endDocScan(true)
    dispatch(updateLocalData('updateTravelDocImage', image))
    dispatch(checkTravelDoc(scannedValue))
  }
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const isLandscape = useCheckLandscape()
  const [enable, disable, passportReaderCallback, popupContainer] = usePassportScanner(scannedCallback, sbdModel)

  // ***** TEST DATA *****
  // const currentPassenger = { ordinal: 0 }
  // const validatePaxResult = [
  //   {
  //     ordinal: 0,
  //     docCheckOk: true,
  //     faceMatch: false,
  //     privacyAgreement: true
  //   }
  // ]

  useEffect(() => {
    handleAccessibility()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js)  did mount,call scan passport enable function...')
    enable()

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) UNMOUNTED')
      //turnLights('scanPassport', false)
      appLog(TraceLevels.LOG_TRACE, '(ScanDocuments.js) disable passport reader.')
      disable()
    }
  }, [])

  const getNextPassenger = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === false
    })
    if (remains.length > 0) {
      return remains[0]
    } else {
      return validatePaxResult[0]
    }
  }
  const getScanSequence = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === true
    })
    return remains.length + 1
  }

  /** For Testing **/
  const handleClick = (e) => {
    let passportData = null
    let testPassenger = getNextPassenger(validatePaxResult)
    const photo1 = config.photoData_1
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) : handleClick() ... ' + e.currentTarget.id)
    switch (e.currentTarget.id) {
      case 'buttonScanDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GenerateChipPP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        // passportData = testData
        //appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        /* passportReaderCallback({
          key: 'passportRemoved',
          value: null,
        }) */
        break
      case 'buttonSwipeDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        passportData = GeneratePP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        //appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        break
      case 'buttonScanDocsRemove':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        //appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        setTimeout(() => disable(), 7000)
        break
      case 'buttonNotReadable':
        //appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged ')
        return passportReaderCallback({
          key: 'passportDamaged',
          value: ''
        })
      case 'buttonInvalidPassport':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP('FirstName', 'LastName')
        //appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'push: /')
        navigate('Welcome')
    }
  }

  const testButtons = [
    {
      id: 'buttonScanDocs',
      text: 'Scan',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonSwipeDocs',
      text: 'Swipe',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonScanDocsRemove',
      text: 'Remove',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonInvalidPassport',
      text: 'Invalid',
      handler: handleClick,
      group: 0
    },
    {
      id: 'buttonNotReadable',
      text: 'Not Readable',
      handler: handleClick,
      group: 0
    }
  ]

  const animationSize = useMedia(null, [420, 350, 420], 420)
  // const animationSection = (
  //   <DynamicImage
  //     imageName={`${themeContext.AnimationPath}/${sbdModel}/PassportScan.gif`}
  //     width={animationSize}
  //     height={animationSize}
  //   />
  // )
  const animationSection = (
    <>
      <PageSubContent flexFlow={'row'} padding={'0'}>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage imageName={'scan-card.png'} width={isLandscape ? 447 : 335} height={isLandscape ? 320 : 240} />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'20px'} color={'white'}>
              {formatMessage(messages.ScanCard_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage
            imageName={'scan-passport.png'}
            width={isLandscape ? 447 : 335}
            height={isLandscape ? 320 : 240}
          />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'20px'} color={'white'}>
              {formatMessage(messages.ScanPassport_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
      </PageSubContent>
    </>
  )

  // Test
  //let message = formatMessage(messages.ScanDocs_Body, { passengerSequence: 0 })
  let message = formatMessage(messages.ScanDocs_Body, { passengerSequence: getScanSequence(validatePaxResult) })
  const paxName = validatePaxResult ? validatePaxResult.firstName + validatePaxResult.lastName : null
  const textSection = (
    <>
      <PageTitle minHeight={'30px'} fontSize={'25px'}>
        {paxName ? paxName : message}
      </PageTitle>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.ScanDocs_Title)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.ScanDocs_Subtitle)}
      </PageSubTitle>
    </>
  )

  /* let message = docRetryStatus
    ? formatMessage(messages.ScanDocs_Retry, {
        passengerSequence: currentPassenger
          ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
          : getScanSequence(validatePaxResult)
      })
    : formatMessage(messages.ScanDocs_Body, {
        passengerSequence: currentPassenger
          ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
          : getScanSequence(validatePaxResult)
      })

  const textSection = (
    <PageSubContent width={'100%'} justifyContent={'center'}>
      <PageTitle>{message}</PageTitle>
    </PageSubContent>
  ) */

  const header = null //<PageHeader alignItems="center">{formatMessage(messages.ScanDocs_Header)}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testButtons}
      />
    </>
  )

  const popupSection = (
    <>
      {popupContainer.removePassport}
      {popupContainer.retryMediaAccess}
    </>
  )

  const handleAccessibility = () => {
    const textParameters = [intl.formatMessage(messages.ScanDocs_Header), message]
    const accDef = {
      pathName: 'ScanDocuments',
      startIndex: 0,
      isLangRequired: true,
      isQuitRequired: true,
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters }]
      }
    }
    buildAccessibility(accDef)
  }

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ScanDocuments
