class MrzGenerator {
  constructor(firstName, lastName, nationCode = 'CAN') {
    this.type = 'P'
    this.sexCode = 'M'
    this.countryCode = nationCode
    this.firstName = firstName
    this.middleName = ''
    this.lastName = lastName
    this.name = ''
    this.expiryDate = '250101'
    this.dateOfBirth = '610503'
    this.passportNo = Math.floor(100000000 + Math.random() * 900000000).toString() //  9 digital
    this.nationalityCode = nationCode
    this.issuingStateCode = nationCode
    this.extraInfo = null
  }

  getType() {
    return this.type
  }

  setType(type) {
    this.type = type
  }

  getSexCode() {
    return this.sexCode
  }

  setSexCode(sexCode) {
    this.sexCode = sexCode
  }

  getCountryCode() {
    return this.countryCode
  }

  setCountryCode(countryCode) {
    this.countryCode = countryCode
  }

  getFirstName() {
    return this.firstName
  }

  setFirstName(firstName) {
    this.firstName = firstName
  }

  getMiddleName() {
    return this.middleName
  }

  setMiddleName(middleName) {
    this.middleName = middleName
  }

  getLastName() {
    return this.lastName
  }

  setLastName(lastName) {
    this.lastName = lastName
  }

  getName() {
    const firstName = this.firstName
    const middleName = this.middleName
    const lastName = this.lastName
    if (firstName != null && lastName != null) {
      const master = `${lastName}  ${firstName}`
      return master
    }
    return this.name
  }

  setName(name) {
    this.name = name
  }

  getExpiryDate() {
    return this.expiryDate
  }

  setExpiryDate(expiryDate) {
    this.expiryDate = expiryDate
  }

  getDateOfBirth() {
    return this.dateOfBirth
  }

  setDateOfBirth(dateOfBirth) {
    this.dateOfBirth = dateOfBirth
  }

  getPassportNo() {
    return this.passportNo
  }

  setPassportNo(passportNo) {
    this.passportNo = passportNo
  }

  getNationalityCode() {
    return this.nationalityCode
  }

  setNationalityCode(nationalityCode) {
    this.nationalityCode = nationalityCode
  }

  getIssuingStateCode() {
    return this.issuingStateCode
  }

  setIssuingStateCode(issuingStateCode) {
    this.issuingStateCode = issuingStateCode
  }

  getExtraInfo() {
    if (
      this.extraInfo == null ||
      ((o1, o2) => {
        if (o1 && o1.equals) {
          return o1.equals(o2)
        } else {
          return o1 === o2
        }
      })(this.extraInfo, '')
    ) {
      this.setExtraInfo('              ')
    }
    return this.extraInfo
  }

  setExtraInfo(extraInfo) {
    this.extraInfo = extraInfo
  }

  /*private*/ getPassportNumChecksum() {
    return this.checksumGenerator(this.getPassportNo(), 9)
  }

  /*private*/ getDateBirthChecksum() {
    return this.checksumGenerator(this.getDateOfBirth(), 6)
  }

  /*private*/ getDateExpiryChecksum() {
    return this.checksumGenerator(this.getExpiryDate(), 6)
  }

  /*private*/ generate(text) {
    for (let i = text.length; i <= 43; i++) {
      text += ' '
    }
    return text
      .split(' ')
      .join('<')
      .toUpperCase()
  }

  /*private*/ zeroGenerator(text) {
    let zero = ''
    for (let i = 0; i < 43 - text.length; i++) {
      zero += 0
    }
    return zero
  }

  /*private*/ checksumGenerator(text, length) {
    const weights = [
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1,
      7,
      3,
      1
    ]
    let sum = 0
    for (let i = 0; i < length; i++) {
      if (
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) >= 48 &&
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) < 58
      ) {
        sum += weights[i] * ((c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) - 48)
      } else if (
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) >= 'A'.charCodeAt(0) &&
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) <= 'Z'.charCodeAt(0)
      ) {
        sum += weights[i] * ((c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) - 55)
      } else if (
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) >= 'a'.charCodeAt(0) &&
        (c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) <= 'z'.charCodeAt(0)
      ) {
        sum += weights[i] * ((c => (c.charCodeAt == null ? c : c.charCodeAt(0)))(text.charAt(i)) - 32 - 55)
      }
    }
    return `${sum % 10}`
  }

  /*private*/ getPersonalNumberChecksum() {
    const full = this.getExtraInfo()
    return this.checksumGenerator(full, 14)
  }

  /*private*/ lastChecksumDigit() {
    const zeroForPersonalNumber = '00000000000000'
    if (
      ((o1, o2) => {
        if (o1 && o1.equals) {
          return o1.equals(o2)
        } else {
          return o1 === o2
        }
      })(this.getExtraInfo(), '              ')
    ) {
      this.setExtraInfo(zeroForPersonalNumber)
    }
    const all =
      this.getPassportNo() +
      this.getPassportNumChecksum() +
      this.getDateOfBirth() +
      this.getDateBirthChecksum() +
      this.getExpiryDate() +
      this.getDateExpiryChecksum() +
      this.getExtraInfo()
    return this.checksumGenerator(all, 37)
  }

  getFirstLine() {
    const all = `${this.getType()} ${this.getIssuingStateCode()}${this.getName()} `
    return this.generate(all)
  }

  getSecondLine() {
    const str = ''
    str.concat()
    const _this = this
    const stringBuffer = {
      str: '',
      toString() {
        return this.str
      }
    }
    /* append */ ;(sb => {
      sb.str = sb.str.concat(_this.lastChecksumDigit())
      return sb
    })(
      /* append */ (sb => {
        sb.str = sb.str.concat(_this.getPersonalNumberChecksum())
        return sb
      })(
        /* append */ (sb => {
          sb.str = sb.str.concat(_this.getExtraInfo())
          return sb
        })(
          /* append */ (sb => {
            sb.str = sb.str.concat(_this.getDateExpiryChecksum())
            return sb
          })(
            /* append */ (sb => {
              sb.str = sb.str.concat(_this.getExpiryDate())
              return sb
            })(
              /* append */ (sb => {
                sb.str = sb.str.concat(_this.getSexCode())
                return sb
              })(
                /* append */ (sb => {
                  sb.str = sb.str.concat(_this.getDateBirthChecksum())
                  return sb
                })(
                  /* append */ (sb => {
                    sb.str = sb.str.concat(_this.getDateOfBirth())
                    return sb
                  })(
                    /* append */ (sb => {
                      sb.str = sb.str.concat(_this.getNationalityCode())
                      return sb
                    })(
                      /* append */ (sb => {
                        sb.str = sb.str.concat(_this.getPassportNumChecksum())
                        return sb
                      })(
                        /* append */ (sb => {
                          sb.str = sb.str.concat(_this.getPassportNo())
                          return sb
                        })(stringBuffer)
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
    return this.generate(stringBuffer.str)
  }
}

export const GeneratePP = (firstName, lastName, nationCode = 'CAN', dg2) => {
  const gen = new MrzGenerator(firstName, lastName, nationCode)
  return gen.getFirstLine() + '_@' + gen.getSecondLine() + '_@,' + dg2
}
export const GenerateChipPP = (firstName, lastName, nationCode = 'CAN', dg2) => {
  const gen = new MrzGenerator(firstName, lastName, nationCode)
  const part = gen.getFirstLine() + '_@' + gen.getSecondLine() + '_@,'
  return (
    part +
    part +
    dg2 +
    ',' +
    '[PPT, Passport, ' +
    lastName +
    ', ' +
    firstName +
    ', , ' +
    nationCode +
    ' , F, 1965, 12, 25 , , C090909, P, CAN, 2026, 1, 1, 3, 0, 0, 4, 3, 51], ' +
    'RFCHIP=Y, YBVfAQQwMTA3XzYGMDQwMDAwXANhdW8=, d4IISzCCCEc, dg1file, dg2file'
  )
}
