import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { TraceLevels } from 'embross-device-manager'
import { getDeviceManager, getSBDAppMan, playSound, history, getBuildAccessibility } from 'main'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { ErrCodes } from 'constants/Errors'
import { TestButtons } from 'components'
import { PageTitle, PageHeader, Spacer, DynamicImage, PageSubTitle } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { replacer, populateItineraryInfo, formatBagTagNumber, getTestBP, generateBP } from 'utils/helper'
import { appLog } from 'utils/Logger'

const ErrorBPMismatch = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const errorName = useSelector((state) => state.localData.errorName)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const barcodeScanAttempts = useSelector((state) => state.localData.errorBarcodeAttempts)
  const bagtag = useSelector((state) => state.localData.bagtag)
  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )
  const scannedCallback = (scannedValue) => {
    let location = {
      state: {
        from: 'ErrorBPMismatch',
        statistics: {
          paxOrdinal: null,
          paxName: null
        }
      }
    }
    appLog(TraceLevels.LOG_TRACE, 'Event:  barcodeReadInternal ' + this.props.etsTxnStatus)
    dispatch(updateLocalData('updateMismatchBPError', true)) // set mismatch BP error (intrusion or bagtag match can clear it)
    dispatch(getItinerarySBD({ barcodeData: scannedValue }, location))
  }

  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(ErrorBPMismatch.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()
    /// TEST CODE
    if (e.currentTarget.id === 'buttonScanBarcodeTEST' || e.currentTarget.id === 'buttonScanBarcodeTESTERROR') {
      let updatedBarcodeScanAttempts = barcodeScanAttempts + 1
      dispatch(updateLocalData('updateBarcodeAttempts', updatedBarcodeScanAttempts))
      appLog(TraceLevels.LOG_EXT_TRACE, 'barcodeReaderOnEvent() barcodeScanAttempts: ' + updatedBarcodeScanAttempts)
      if (e.currentTarget.id === 'buttonScanBarcodeTEST') {
        dispatch(getItinerarySBD({ barcodeData: getTestBP(config.testPNR) }, location))
      } else {
        dispatch(getItinerarySBD({ barcodeData: getTestBP(config.testPNR, '9') }, location)) // S2 - skip 2
      }
      return
    }
    // end OF TEST CODE

    switch (e.currentTarget.id) {
      case 'buttonWeight':
        let testPassager = testPassengers[1]
        let barcode = generateBP('G8SRL9', testPassager.firstName, testPassager.lastName, 'YYZ', 'YVR', 'WS', '1001')
        //barcode = 'M1SMITH/MARK          E111111 YYZLGANK 0123 036Y014K2101 90F>50B1OO8303BWS 0E8380000000000 ^460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk9BGGk1XDkPdJrfzAiBLSzIjq3EHP6CZOxFacKwJ41G2RqsJLvRRREGt4sTFrA==1580928289,1580928311'
        //barcode ='M1DTWTOCLT/PAXTWO     EG8SRL9 AUHCLTDL 0508 354Y025D0001 155>318  W     WS              2900621403998520                           WS3J8LX7ZES25D'
        // barcode =
        //   'M1SMITH/MARK          EDOM123 YYZYULNK 0123 036Y014K2101 90F>50B1OO8303BWS 0E8380000000000 ^460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk9BGGk1XDkPdJrfzAiBLSzIjq3EHP6CZOxFacKwJ41G2RqsJLvRRREGt4sTFrA==1580928289,1580928311'
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: barcode
        })
      default:
    }
  }

  useEffect(() => {
    dispatch(updateLocalData('updateBagWeight', '0'))
    handleAccessibility()
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(ErrorBPMismatch.js) call barcode enable function')
      enable()
    }
    return () => {
      disable()
    }
  }, [])

  let testButtons = [
    {
      id: 'buttonWeight',
      text: 'Testing',
      handler: handleActions,
      cssName: 'test-button-up'
    }
  ]

  const animationSize = useMedia(null, [500, 500, 420], 420)
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/BoardingPassScan.gif`}
      cssName={'emb_animation_drawbox'}
      width={animationSize}
      height={animationSize}
    />
  )

  let heading = intl.formatMessage(messages.ErrorMismatch4_hdr, { name: errorName }) //TAG_SCANNED_BP_NOT_SCANNED
  let instMsg = intl.formatMessage(messages.ErrorMismatch4_inst, { name: errorName })
  let scannedBagtagMsg =
    bagtag && bagtag.length > 10
      ? intl.formatMessage(messages.ScannedBagTag2, { bagtag: formatBagTagNumber(bagtag) })
      : intl.formatMessage(messages.ScannedBagTag, { bagtag: formatBagTagNumber(bagtag) })

  const textSection = (
    // <PageSubContent width={'100%'} justifyContent={'flex-start'} textAlign="left">
    //   <ErrorTitle>{heading}</ErrorTitle>
    //   <ErrorSubTitle alignItems="flex-start">{instMsg}</ErrorSubTitle>
    //   <ErrorDetail alignItems="flex-start">{scannedBagtagMsg}</ErrorDetail>
    // </PageSubContent>
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {heading}
      </PageTitle>
      <PageSubTitle justifyPageSubTitle="center" color={themeContext.PrimaryFontColor}>
        {instMsg}
        {scannedBagtagMsg}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.RepositionBagTitle)}
    </PageHeader>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={false}
      />
      <TestButtons data={testButtons} />
    </>
  )

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : { displayName: '' }
    const errorMsg = [heading, instMsg, scannedBagtagMsg]
    const accDef = {
      pathName: 'BagProcessing',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [itinerary.displayName, errorMsg.join('. ')]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ErrorBPMismatch
