import React from 'react'
import { injectIntl } from 'react-intl'
const ItineraryInfo = props => {
  const { formatMessage } = props.intl
  const { itineraryData } = props
  let displayName = ''
  let flightInfo = ''
  let allowance = ''
  if (config.showItinerary && itineraryData) {
    displayName =
      itineraryData.groupSize > 1
        ? formatMessage(messages.itineraryInfoName, {
            name: itineraryData.displayName,
            groupSize: itineraryData.groupSize
          })
        : itineraryData.displayName

    switch (config.showFlightInfo) {
      case 1:
        flightInfo = formatMessage(messages.itineraryInfo1, { flightCode: itineraryData.flightCode })
        break
      case 2:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo2, {
              flightCode: itineraryData.flightCode,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo1, { flightCode: itineraryData.flightCode })
        break
      case 3:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo3, {
              flightCode: itineraryData.flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo4, {
              flightCode: itineraryData.flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity
            })
        break
      case 4:
        flightInfo = formatMessage(messages.itineraryInfo4, {
          flightCode: itineraryData.flightCode,
          departureCity: itineraryData.departureCity,
          arrivalCity: itineraryData.arrivalCity
        })
        break
    }
    if (config.showAllowance && itineraryData.allowance && itineraryData.allowance.unit) {
      if (itineraryData.allowance.unit == 'PC') {
        if (itineraryData.allowance.value == 1) {
          allowance = formatMessage(messages.allowanceInfo1)
        } else {
          allowance = formatMessage(messages.allowanceInfo2, { count: itineraryData.allowance.value })
        }
      } else {
        allowance = formatMessage(messages.allowanceInfo3, { weight: itineraryData.allowance.value })
      }
    }
  }
  return (
    <div className={'itinerary-info'} id={'itineraryInfo'} tabIndex={0}>
      <div className="page_title_paxName">{displayName}</div>
      <div className="page_title_itinerary">{flightInfo}</div>
      <div className="page_title_itinerary">{allowance}</div>
    </div>
  )
}
export default injectIntl(ItineraryInfo)
