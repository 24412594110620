/**
 @class
 *  This is the base ApplicationManagerListener object for handling callbacks. To override individual
 *  functions, use e.g.:
 <br/>
 <code>var listener = new ApplicationManagerListener()
 <br/>listener.notificationText = function(notifyString) {...}
 <br/>applicationManager.setListener(listener)
 </code>
 */
import Listener from 'listeners/listener'
import { getKeyByValue } from 'utils/helper'
import { PromiseCodes, ApplicationState, TraceLevels, AppStateEventType } from 'constants/Constants'

/** Application manager listener.
 *  @extends {Listener}
 */
export default class ApplicationManagerListener extends Listener {
  /**
   *  Default notifyText callback.  Called when there is custom notification string during ACTIVE transition
   *  @param {String} notifyString The custom notification string
   */
  notificationText(notifyString) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'notificationText() called ' + notifyString)
    if (notifyString.indexOf('ACCESSIBLE=TRUE') >= 0) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Start in accessible mode.')
      //this.device.enableHeadset()
    }
    if (notifyString.indexOf('SINGLEAPP MODE') < 0) {
      this.device.appInUse = true
    }
    this.setEvent('notificationText', notifyString)
  }

  /**
   *  Default getCurrentState callback.
   *  @param {number} response The current application state one of {@link src/constants/Constants.js~ApplicationState}.
   */
  getCurrentState(response) {
    let stateCode = parseInt(response, 10)
    this.logMessage(
      TraceLevels.LOG_EXT_TRACE,
      'getCurrentState() result ' + response + ' ' + getKeyByValue(ApplicationState, stateCode)
    )
    this.setEvent('getCurrentState', stateCode)
    this.device.currentState = stateCode
    if (this.device.prom) {
      clearTimeout(this.device.pTimer)
      this.device.prom[PromiseCodes.RESOLVE](response)
      this.device.prom = null
    }
  }

  /**
   *  Default sysmgr command callback.  called when there is custom command sent from kiosk management
   *  @param {String} commandString The custom command string
   */
  sysmgrCommand(commandString) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'sysmgrCommand() called ' + commandString)
    this.setEvent('sysmgrCommand', commandString)
  }

  /**
   *  Default notifyActive callback.  Called when the application becomes active (ACTIVE transition)
   *  @param {int} activationType The type of activation
   */
  notifyActive(activationType) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'notifyActive() called ' + activationType)
    if (this.device.dm.TSDMan) {
      this.device.dm.TSDMan.addAppStateEvent(AppStateEventType.APP_ACTIVATED)
    }
    this.device.Active = true //will call appManager.onActive
  }

  /**
   *  Default notifyAvailable callback.  Called when the application transitions to available
   *  (AVAILABLE transition)
   */
  notifyAvailable() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'notifyAvailable() called')
    this.device.Active = false
    this.setEvent('notifyAvailable', null)
  }

  /**
   *  Default notifyUnavailable callback.  Called when the application transitions to unavailable
   *  (UNAVAILABLE transition)
   */
  notifyUnavailable() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'notifyUnavailable() called')
    this.device.Active = false
    this.setEvent('notifyUnavailable', null)
  }

  /**
   *  Default notifyRestart callback.  Called when the application is restarted by the platform
   */
  restart() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'restart() called')
    this.device.Active = false
    this.setEvent('restart', null)
  }

  /**
   *  Default outOfTime callback.  Called when the application reaches the CUSS KILL_TIMEOUT
   */
  outOfTime() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'outOfTime() called')
    this.setEvent('outOfTime', null)
  }

  /**
   *  Default almostOutOutTime callback.  Called when the application reaches the CUSS SESSION_TIMEOUT
   */
  almostOutOfTime() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'almostOutOfTime() called')
    if (this.device.dm.TSDMan) {
      this.device.dm.TSDMan.addAppStateEvent(AppStateEventType.PLT_SESSION_TIMEOUT)
    }
    this.setEvent('almostOutOfTime', null)
  }

  /**
   *  Default getKioskId command callback.
   *  @param {String} response kiosk id
   */
  getKioskId(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getKioskID() result: ' + response)
    this.device.KioskId = response == null ? '' : response
  }

  /**
   *  Default getActualCUSSKioskId command callback.
   *  @param {String} response CUSS kiosk id
   */
  getActualCUSSKioskId(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getActualCUSSKioskId() result: ' + response)
    this.device.CussKioskId = response == null ? '' : response
  }

  /**
   *  Default getSystemManagerKioskId command callback.
   *  @param {String} response SM kiosk id
   */
  getSystemManagerKioskId(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getSystemManagerKioskId() result: ' + response)
    this.device.SmKioskId = response == null ? '' : response
  }

  /**
   *  Default getLocation command callback.
   *  @param {String} response location code
   */
  getLocation(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getLocation() result: ' + response)
    this.device.Location = response == null ? '' : response
  }

  /**
   *  Default getAirportCode command callback.
   *  @param {String} response airport code
   */
  getAirportCode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getAirportCode() result: ' + response)
    this.device.AirportCode = response == null ? '' : response
  }

  /**
   *  Default callback on websocket opened.
   *  @param {boolean} restarting true when websocket is restarting false otherwise(initial start)
   */
  socketOpened(restarting) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'socketOpened() called: restarting: ' + restarting)
    if (!restarting) {
      //      this.device.initialize()  moved to CUSSAppLink
      this.device.getKioskId()
      this.device.getSystemManagerKioskId()
      this.device.getActualCUSSKioskId()
      this.device.getAirportCode()
      this.device.getCurrentState()
      this.device.getLocation()
      this.device.getCompanyCode()
      this.device.getAppName()
      this.device.getVendor()
      if (this.device.onSocketOpened) this.device.onSocketOpened(restarting)
    }
  }

  /**
   *  Default accessibleModeStart callback.
   *  <br/>sends back confirmAccessibleMode()
   *  <br/>generates event: <code>setEvent('accessibleMode', true)</code>
   */
  accessibleModeStart() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.accessibleModeStart() called')
    this.device.confirmAccessibleMode()
    this.setEvent('accessibleMode', true)
  }

  /**
   *  Default accessibleModeEnd callback.
   *  <br/>sends back completeAccessibleMode()
   *  <br/>generates event: <code>setEvent('accessibleMode', false)</code>
   */
  accessibleModeEnd() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.accessibleModeEnd() called')
    /*this.device.disableKeypad()
    this.device.disableTTS()
    this.device.enableHeadset()*/
    this.device.completeAccessibleMode()
    this.setEvent('accessibleMode', false)
  }

  /**
   *  Default getCompanyCode command callback.
   *  @param {String} response company code
   */
  getCompanyCode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getCompanyCode() result: ' + response)
    this.device.CompanyCode = response == null ? '' : response
  }

  /**
   *  Default getVendor command callback.
   *  @param {String} response vendor
   */
  getVendor(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVendor() result: ' + response)
    this.device.Vendor = response == null ? '' : response
  }

  /**
   *  Default getAppName command callback.
   *  @param {String} response application name
   */
  getAppName(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getAppName() result: ' + response)
    this.device.AppName = response == null ? '' : response
  }

  /**
   *  Default getConfig command callback.
   *  @param {String} response array [key, value]
   */
  getConfig(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getConfig() result: ' + response)
    let res = response.split(',')
    if (res.length === 2) {
      console.log('getConfig from applicationManagerListener.js: ', JSON.stringify(res))
      /* if (res[0] === this.device.kioskTypeId) {
        this.device.kioskType = res[1] == null ? '' : res[1]
      } else if (res[0] === 'SMGR_STATION_IN_ID') {
        this.device.smgrStationInId = res[1] == null ? '' : res[1]
      } */
      this.device.Config = { key: res[0], value: res[1] }
      this.setEvent(res[0], res[1])
    } else {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getConfig() incorrect Response! ' + response)
    }

    //this.setEvent('getConfig', response)
  }

  /**
   *  Default isInAccessibleMode command callback.
   *  @param {String} response true or false
   */
  isInAccessibleMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'isInAccessibleMode() result: ' + response)
    this.setEvent('isInAccessibleMode', response)
  }

  /**
   *  Default isPendingAccessibleMode command callback.
   *  @param {String} response true or false
   */
  isPendingAccessibleMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'isPendingAccessibleMode() result: ' + response)
    this.setEvent('isPendingAccessibleMode', response)
  }
}
