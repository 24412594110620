import React, { useState } from 'react'
import { Footer } from '../footer'
import { getAccessibilityDevice, history, getSBDAppMan, playSound } from 'main'
import { appLog } from 'utils/Logger'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useUIBase from 'hooks/ui/useUIBase'
import { TraceLevels } from 'embross-device-manager'
import {
  PageHeader,
  PageContent,
  PageSubContent,
  PageFooter,
  PageText,
  PageTitle,
  PageSubTitle,
  OverweightPaymentText,
  PaymentCardSwipeTitle,
  Spacer
} from 'components/styledComponents'
import { formatCurrency } from 'utils/helper'

const DisplayPaymentReceipt = (props) => {
  const intl = useIntl()
  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  let SBDAppManager = getSBDAppMan()
  const [results, setResults] = useState(paymentInfo ? paymentInfo : { totalAmount: 0 })

  const handleActions = (e) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(DisplayPaymentReceipt.js) handleClick() ... target: ' + e.currentTarget.id)

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        console.log('handleConfirmAction')
        history.push('GetContactEmail')
        break
      case 'skipBtn':
        SBDAppManager.setAppFlow(2)
        if (baggageLoadedStatus) {
          history.push('BagProcessing')
        } else {
          history.push('PutBagOnBelt')
        }
        break
      default:
        break
    }
  }

  const header = (
    <>
      <PageHeader>Your payment was successful</PageHeader>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={true}
        isSkipInCenter={false}
        confirmBtnText={intl.formatMessage(messages.buttonContinue)}
        skipAction={handleActions}
        isConfirmRequired={true}
        confirmAction={handleActions}
      />
    </>
  )

  const receiptSection = (
    <>
      <PageHeader justifyContent="center">
        {intl.formatMessage(messages.AmountPaidText, { amount: formatCurrency(results.totalAmount) })}
      </PageHeader>
      <Spacer height="200px" />
      <PageText justifyContent="center">{intl.formatMessage(messages.DisplayReceiptHeader)}</PageText>
      <Spacer height="50px" />
      <PageText justifyContent="center">
        {intl.formatMessage(messages.DisplayReceiptSubHeader, {
          b: (chunks) => <b>{chunks}</b>
        })}
      </PageText>
      <Spacer height="140px" />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: receiptSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default DisplayPaymentReceipt
