import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { ItineraryTitle, PageContent, PageSubContent, PageText } from 'components/styledComponents'
import PropTypes from 'prop-types'
import './ItineraryInfo.scss'
import { populateItineraryInfo } from 'utils/helper'

const ItineraryInfo = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const { formatMessage } = intl
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [itineraryData, setItineraryData] = useState(null)

  useEffect(() => {
    setItineraryData(itineraryInfo ? populateItineraryInfo(itineraryInfo) : null)
  }, [])

  const bagAllowance = (
    <div className="bag-info-wrap">
      <div className="bag-info-number">0</div>
      <div className="bag-info-number">KG</div>
    </div>
  )

  //Manually compare current bag weight to unit limit weight
  const showRedText = () => {
    if (bagWeight !== '0' && bagWeightUnit === 'KG') {
      if(+bagWeight.slice(0, -2) > 23 ) {
        return 'red'
      } else {
        return ''
      }
    } else if (bagWeight !== '0' && bagWeightUnit === 'LB') {
      if(+bagWeight.slice(0, -2) > 50.7 ) {
        return 'red'
      } else {
        return ''
      }
    }
  }

  let displayName = ''
  let flightInfo = ''
  let allowance = ''
  let flightCode = ''
  if (itineraryData) {
    displayName =
      itineraryData.groupSize > 1
        ? formatMessage(messages.itineraryInfoName, {
            name: itineraryData.displayName,
            groupSize: itineraryData.groupSize
          })
        : itineraryData.displayName
    flightCode = itineraryData.airlineCode + itineraryData.flightNumber
    switch (config.showFlightInfo) {
      case 1:
        flightInfo = formatMessage(messages.itineraryInfo1, {
          flightCode: flightCode
        })
        break
      case 2:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo2, {
              flightCode: flightCode,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo1, { flightCode: flightCode })
        break
      case 3:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo3, {
              flightCode: flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo4, {
              flightCode: flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity
            })
        break
      case 4:
        flightInfo = formatMessage(messages.itineraryInfo4, {
          flightCode: flightCode,
          departureCity: itineraryData.departureCity,
          arrivalCity: itineraryData.arrivalCity
        })
        break
      default:
        flightInfo = flightCode
        break
    }
    if (config.showAllowance && itineraryData.allowance && itineraryData.allowance.unit) {
      if (itineraryData.allowance.unit == 'PC') {
        if (itineraryData.allowance.value == 1) {
          allowance = formatMessage(messages.allowanceInfo1)
        } else {
          allowance = formatMessage(messages.allowanceInfo2, { count: itineraryData.allowance.value })
        }
      } else {
        allowance = formatMessage(messages.allowanceInfo3, { weight: itineraryData.allowance.value })
      }
    }

    if (config.showFlightInfo > 4) {
      let boxAttribute = {}
      if (props.boxHeight) {
        boxAttribute['style'] = { height: props.boxHeight }
      }
      let horizontalAlignStyle = {
        justifyContent: props.hAlign
      }
      return (
        <PageContent flexFlow={'row'} justifyContent="flex-start" padding={isLandscape ? '' : '0 50px'}>
          <PageSubContent padding="0" style={{ minWidth: config.showPaxName || config.showFlight ? '200px' : '0' }}>
            {config.showPaxName ? (
              <PageText justifyContent={'flex-start'} fontSize={'20px'} minHeight={'20px'}>
                {displayName}
              </PageText>
            ) : (
              <div />
            )}
            {config.showFlight ? (
              <PageText justifyContent={'flex-start'} fontSize={'20px'} minHeight={'20px'}>
                {itineraryData.departureCityCode} - {itineraryData.arrivalCityCode}
              </PageText>
            ) : (
              <div />
            )}
          </PageSubContent>
          <PageSubContent
            padding={!config.showPaxName && !config.showFlight ? '0' : '0 20px'}
            flexFlow={!config.showPaxName && !config.showFlight ? 'row-reverse' : 'column'}
            width={!config.showPaxName && !config.showFlight ? '40%' : ''}
          >
            <PageText
              fontSize={!config.showPaxName && !config.showFlight ? '20px' : '35px'}
              minHeight={'20px'}
              color={themeContext.SecondaryColor ? themeContext.SecondaryColor : ''}
            >
              <span style={{color : showRedText()}}>{bagWeight === '0' ? bagWeight + bagWeightUnit : bagWeight}</span>
            </PageText>
            {themeContext.showBagWeightTitle ? (
              <PageText
                fontSize={!config.showPaxName && !config.showFlight ? '20px' : '15px'}
                minHeight={'20px'}
                color={themeContext.SecondaryColor ? themeContext.SecondaryColor : ''}
              >
                {formatMessage(messages.BagWeight)}
              </PageText>
            ) : (
              ''
            )}
          </PageSubContent>
          {config.showAllowance ? (
            <PageSubContent
              padding={'0 20px'}
              style={{
                borderLeft: `1px ${themeContext.PrimaryFontColor} solid`
              }}
            >
              <PageText fontSize={'35px'} minHeight={'20px'}>
                {allowance ? allowance : '--'}
              </PageText>
              <PageText fontSize={'15px'} minHeight={'20px'}>
                {formatMessage(messages.BagAllowance)}
              </PageText>
            </PageSubContent>
          ) : (
            <div />
          )}
        </PageContent>
      )
    } else {
      return (
        <ItineraryTitle id={'itineraryInfo'} tabIndex={0}>
          <div className="page_title_paxName">{displayName}</div>
          <div className="page_title_itinerary">{flightInfo}</div>
          <div className="page_title_itinerary">{allowance}</div>
        </ItineraryTitle>
      )
    }
  } else {
    return ''
  }
}
ItineraryInfo.propTypes = {
  iconSize: PropTypes.number,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
  showCityName: PropTypes.bool,
  hAlign: PropTypes.oneOf(['center', 'flex-start', 'flex-end'])
}
ItineraryInfo.defaultProps = {
  iconSize: 64,
  showCityName: false,
  hAlign: 'center'
}
export default ItineraryInfo
