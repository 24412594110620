/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { startCUSSTransaction } from 'utils/appTransactions'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import {
  devMgr,
  getDeviceManager,
  playSound,
  store,
  getSBDAppMan,
  getVersion,
  history,
  getAccessibilityManager,
  getBuildAccessibility,
  getDevice
} from 'main'
import { generateBP } from 'utils/helper'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { Footer } from '../footer'
import { startEtsTransaction, initialStore } from 'actions/etsTransactions/sessionAct'
import { TYPE_KIOSK, ETS_TXN_STATUS } from 'constants/Constants'
import useFaceTracking from 'hooks/useFaceTracking'
import { PageTitle, PageSubTitle, DynamicImage, Spacer } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { ThemeContext } from 'styled-components'
import { ErrCodes } from 'constants/Errors'
import { goToFetchError } from 'utils/FetchWithTimeout'
import { goToLocalError, goToLocalGenericError } from 'utils/helper'

import { updateResponses } from 'actions/responseActions'

const Welcome = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const animationSize = useMedia(null, [420, 350, 420], 420)
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const locale = useSelector((state) => state.localData.locale)
  const dispatch = useDispatch()
  const etsTxnStatus = useSelector((state) => state.sessions.ETSTxnStatus)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ActiveStage = useSelector((state) => state.kioskInfo.onActive)
  const buildAccessibility = getBuildAccessibility()
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  console.log('kioskType:', JSON.stringify(kioskType), ' sbdModel:', sbdModel)
  const scannedCallback = (scannedValue) => {
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      startEtsTransaction(store, TYPE_KIOSK, getVersion(), { barcodeData: scannedValue })
    } else {
      dispatch(getItinerarySBD({ barcodeData: scannedValue }))
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  const sendResultCallback = (value) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTracking send result callback is called, value: ' + value)
    //sendPhotoMatch(event.value.split(',')[1])
    const photoImage = value.split(',')[1].trim()
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      startEtsTransaction(store, TYPE_KIOSK, getVersion(), { uid: photoImage })
    } else {
      dispatch(getItinerarySBD({ uid: value }))
    }
  }

  const [
    faceTrackingEnable,
    faceTrackingDisable,
    faceTrackingShow,
    faceTrackingHide,
    faceTrackingCallback,
    liveStreamUrl
  ] = useFaceTracking(sendResultCallback)

  // face tracking functions
  //todo:
  const handleAccessibility = (locale) => {
    const languageDef = getAccessibilityManager().getLanguageDef(locale)
    const language = languageDef.language
    let textId = ''
    let textParameters = []
    const type = 'bp'
    switch (type) {
      case 'all':
        textId = 'WelcomeScreenAllPrompt'
        textParameters = [
          language['ScanBoardingPass'],
          language['PlaceBagOnBelt'],
          language[config.barcodeScanFace.toUpperCase()]
        ]
        break
      case 'bp':
        textId = 'WelcomeScreenBPPrompt'
        textParameters = [language[config.barcodeScanFace.toUpperCase()]]
        break
      case 'bt':
        textId = 'WelcomeScreenBTPrompt'
        break
    }

    let accDef = {
      pathName: 'Welcome',
      startIndex: 0,
      screenDescription: 'WelcomeScreenDescription',
      sequenceDef: {
        sequence: [
          { id: 'WelcomeScreen', textId, textParameters },
          { id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' }
        ],
        help: ['Barcode_Reader']
      }
    }
    //this.accMgr.buildAccessibilityGroup(accDef, this.accessibility)
  }

  useEffect(() => {
    handleAccessibility(locale)
    return () => {}
  }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      if (ActiveStage) {
        if (config.isBagtagLookupEnable) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (Welcome.js) componentDidMount() - send SQ')
          getSBDAppMan().setSQStart(-1)
          aeaBagdrop.sendAEA('SQ', -10000)
        }
        appLog(TraceLevels.LOG_TRACE, '(Welcome.js) call barcode enable function')
        if (config.isBagtagLookupEnable) {
          enable()
        }
        if (config.isFaceRecognitionEnable) {
          faceTrackingEnable(config.bestShotTimeWelcome)
        }
      }
    }

    return () => {
      if (config.isCUSSRequired) {
        if (ActiveStage) {
          if (config.isBoardingpassLookupEnable) {
            disable()
          }
          if (config.isFaceRecognitionEnable) {
            faceTrackingHide()
            faceTrackingDisable()
          }
        }
      }
    }
  }, [ActiveStage])

  const langAction = (event) => {
    console.log(`working: ${event}`)
    switch (event) {
      case 'langBtnClick':
        // popup show
        faceTrackingHide()
        faceTrackingDisable()
        break
      case 'langMoreLanguage':
      case 'langClosePopup':
        faceTrackingEnable()
        break
      default:
    }
  }

  const handleActions = (e) => {
    let barcode = ''
    const { pnr, firstName, lastName, from, to, airLine, flightNumber, bagtag } = config.testData
    const SBDAppManager = getSBDAppMan()
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          // errMsg = ErrCodes.BAG_LONG
          // msgCode = 'Error_BagLong'
          // goToLocalError(
          //   'commandCompleted appFlow 4',
          //   ErrCodes.SEE_AGENT_GENERAL,
          //   'Error_Default',
          //   'PutBagOnBelt',
          //   null,
          //   'ErrorMismatch'
          // )
          goToLocalGenericError('commandCompleted appFlow 0', ErrCodes.BAG_LONG, 'Error_BagLong', 'END_TXN', null)

          break
        case 'buttonScanBarcodeERROR':
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: ''
          })
          break
        case 'buttonFaceMatchTEST':
          faceTrackingCallback({
            key: 'faceDescriptor',
            value: config.photoData
          })

          break
        case 'myTest':
          SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCBQOK#LP=' + bagtag[0] + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
          })
          /* barcode = {
            pathname: 'pleaseWait',
            state: {
              messageId: 'PleaseWaitFindReservation'
            }
          }
          history.push(barcode) */
          break
        case 'myTest2':
          let json = {
            etsResponse: {
              sessionInfo: {
                etsSessionID: 'testkiosk1499959991546',
                emhaSessionID: null
              },
              currentServiceName: 'PROCESS_BAG',
              jsonExtend: '100KG',
              currentServerTime: 0,
              asyncAccepted: null,
              recordLocator: 'VRUASY',
              outboundFlights: [
                {
                  ordinal: 0,
                  departureFlight: true,
                  outbound: false,
                  returnFlight: false,
                  disrupted: false,
                  freeSeating: false,
                  seatChangeEnabled: false,
                  opFlightNo: '301',
                  mktFlightNo: '301',
                  aircraftType: null,
                  mktCarrierCode: null,
                  opCarrierCode: 'EMB',
                  arrivalSchedule: {
                    schedule: {
                      scheduledTime: '00010101-00:00:00',
                      estimatedTime: '00010101-00:00:00',
                      airport: {
                        code: 'YYZ',
                        name: 'TORONTO',
                        timeZone: null,
                        countryCode2: null
                      }
                    },
                    terminal: null
                  },
                  departureSchedule: {
                    boardTime: '20170712-11:30:00',
                    gateNumber: 'A12',
                    schedule: {
                      scheduledTime: '20170712-12:15:00',
                      estimatedTime: '20170712-12:15:00',
                      airport: {
                        code: 'LAS',
                        name: 'LAS VEGAS',
                        timeZone: null,
                        countryCode2: null
                      }
                    }
                  },
                  flightLegs: [],
                  jsonExtend: null,
                  flightStatus: null
                }
              ],
              passengerBagtagDetails: [
                {
                  passenger: {
                    ordinal: 0,
                    firstName: 'RENE',
                    lastName: 'BROOKS',
                    gender: null,
                    paxKey: null,
                    title: null,
                    farePaxType: null,
                    selectee: false,
                    staff: false,
                    passengerType: null,
                    hostBagRef: null,
                    hostPassengerRef: null,
                    withInfant: false,
                    associatedPassenger: null,
                    dateOfBirth: null,
                    jsonExtend: null
                  },
                  bagTags: [
                    {
                      issuingCarrierNo: null,
                      issuingCarrierCode: null,
                      baseTagNo: '845193',
                      securityNo: null,
                      finalDestination: null,
                      forExcessBag: false,
                      status: 'ADDED',
                      bagIdentifier: null,
                      jsonExtend: null
                    },
                    {
                      issuingCarrierNo: null,
                      issuingCarrierCode: null,
                      baseTagNo: '845194',
                      securityNo: null,
                      finalDestination: null,
                      forExcessBag: false,
                      status: 'ADDED',
                      bagIdentifier: null,
                      jsonExtend: null
                    }
                  ]
                }
              ]
            },
            transition: 'SBD_PROCESS'
          }
          let itineraryInfo = {
            recordLocator: json.etsResponse.recordLocator,
            outboundFlights: json.etsResponse.outboundFlights,
            passengerBagtagDetails: json.etsResponse.passengerBagtagDetails
          }
          dispatch(updateResponses('updateItinerary', itineraryInfo))

          history.push('BagProcessing')

          break
        default:
      }
    }
  }
  const testData = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonScanBarcodeTEST',
      text: config.isBagtagLookupEnable ? 'Scan bagtag' : 'Scan barcode',
      group: 0,
      handler: handleActions
    },
    {
      id: 'buttonFaceMatchTEST',
      text: 'Face match',
      group: 0,
      handler: handleActions
    },
    {
      id: 'myTest',
      text: 'bagtag lookup',
      group: 0,
      handler: handleActions
    },
    {
      id: 'myTest2',
      text: 'test',
      group: 0,
      handler: handleActions
    }
  ]
  // const welcomeMsg = config.isBagtagLookupEnable ? 'WelcomePutBagOnBelt' : 'WelcomeScanBoardingPass'
  // const imageLocation = config.isBagtagLookupEnable
  //   ? `${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`
  //   : `${themeContext.AnimationPath}/${sbdModel}/BoardingPassScan.gif`
  // const animationSection = <DynamicImage imageName={imageLocation} width={animationSize} height={animationSize} />

  // delta change
  const welcomeMsg = 'WelcomeFaceMatch'
  const animationSection = liveStreamUrl ? (
    <div>
      <img src={liveStreamUrl} alt="face" className="live-stream" />
    </div>
  ) : (
    <div width="50%">
      <DynamicImage imageName={'liveViewHolder.png'} width={300} height={600} />
    </div>
  )
  // '<PreviewCentered>False</PreviewCentered><PreviewHeight>315</PreviewHeight><PreviewWidth>560</PreviewWidth><PreviewXPos>87</PreviewXPos><PreviewYPos>438</PreviewYPos>'
  // '<PreviewCentered>False</PreviewCentered><PreviewHeight>416</PreviewHeight><PreviewWidth>494</PreviewWidth><PreviewXPos>137</PreviewXPos><PreviewYPos>365</PreviewYPos>'

  const textSection = (
    <>
      <DynamicImage imageName={'TSA_Precheck_logo.png'} width={235} height={85} />
      <Spacer height={26} />
      <PageTitle alignItems="center" justifyContent={textAlign}>
        {intl.formatMessage(messages.WelcomeTitle)}
      </PageTitle>
      <Spacer height={63} />
      <PageSubTitle justifyContent={textAlign}>{intl.formatMessage(messages.WelcomeFaceMatch)}</PageSubTitle>
    </>
  )

  const header = null //<PageHeader alignItems="center">{intl.formatMessage(messages.BagdropTitle)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={false}
      quitBtnStyle={'btn-red-up'}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={false}
      isLangRequired={true}
      langAction={langAction}
      langBtnStyle={'btn-green-up'}
      testData={testData}
    />
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }
  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth, displayItinerary: false }
  )

  return <>{UIDisplay}</>
}

export default Welcome
