import { TraceLevels } from '../embross-device-manager'
import { appLog } from 'utils/Logger'
import { ResponseActions } from 'constants/Constants'

export function updateResponses(type, obj) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'updateResponses ... ' + type)
  switch (type) {
    case 'updateItinerary':
      return {
        type: ResponseActions.UPDATE_ITINERARY,
        itineraryInfo: obj,
      }

    case 'updateBagtagData':
      return {
        type: ResponseActions.UPDATE_BAGTAGDATA,
        passengerBagtagDetails: obj,
      }

    case 'updateRemarks':
      return {
        type: ResponseActions.UPDATE_REMARKS,
        remarks: obj,
      }
    case 'updateErrorDetails':
      return {
        type: ResponseActions.RESPONSE_ERROR,
        errorDetails: obj,
      }
    case 'initialResponses':
      return {
        type: ResponseActions.INITIAL_RESPONSE_DATA,
      }
    case 'clearResponses':
      return {
        type: ResponseActions.CLEAR_RESPONSE_DATA,
      }

    // with biometrics
    case 'updateTravelers':
      return {
        type: ResponseActions.UPDATE_TRAVELERS,
        travelers: obj,
      }

    // updateTravelerDocument.js
    case 'updateTravelerPhotoRequired':
      return {
        type: ResponseActions.UPDATE_TRAVELER_PHOTO_REQUIRED,
        travelerPhotoRequired: obj,
      }

    // updateTravelData.js
    case 'updateTravelerPhoto':
      return {
        type: ResponseActions.UPDATE_TRAVELER_PHOTO,
        travelerPhoto: obj,
      }
    case 'updateBagtagResources':
      return {
        type: ResponseActions.UPDATE_BAGTAG_RESOURCES,
        bagtagResources: obj,
      }
    case 'updatePassengerDocuments':
      return {
        type: ResponseActions.UPDATE_PASSENGER_DOCUMENTS,
        paxDocuments: obj,
      }
    case 'updateKacPrintstream':
      return {
        type: ResponseActions.UPDATE_KAC_PRINT_STREAM,
        kacPrintstream: obj,
      }
    case 'updateKacResources':
      return {
        type: ResponseActions.UPDATE_KAC_RESOURCES,
        kacResources: obj,
      }
    case 'updateOverweightPayment':
      return {
        type: ResponseActions.UPDATE_OVERWEIGHT_PAYMENT,
        overweightPayment: obj,
      }
    default:
  }
}
