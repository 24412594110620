import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { PageTitle, PageSubTitle, DynamicImage } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility } from 'main'
import { Footer } from '../footer'

const GenericPleaseWait = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const [displayMessage, setDisplayMessage] = useState('')
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.messageId) {
      setDisplayMessage(intl.formatMessage(messages[location.state.messageId]))
    } else if (props.pleaseWaitTextId) {
      setDisplayMessage(intl.formatMessage(messages[pleaseWaitTextId]))
    }
  }, [])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    if (location?.state?.messageId || props.pleaseWaitTextId) {
      if (displayMessage !== '') {
        handleAccessibility()
      }
    } else {
      handleAccessibility()
    }
  }, [displayMessage])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'pleaseWait',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [intl.formatMessage(messages.PleaseWaitTitle), displayMessage]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <DynamicImage imageName={`${themeContext.AnimationPath}/pleaseWait_spinner.gif`} width={300} height={300} />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.PleaseWaitTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {displayMessage}
      </PageSubTitle>
    </>
  )

  const header = null

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={false}
        displayItinerary={false}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}
export default GenericPleaseWait
