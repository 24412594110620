import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconButton } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
const BackAct = (props) => {
  const intl = useIntl()

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <IconButton
        id={'backBtn'}
        cssName={props.backBtnStyle}
        color={themeContext.BackButton.color}
        bgColor={themeContext.BackButton.bgColor}
        width={themeContext.BackButton.width}
        height={themeContext.BackButton.height}
        disable={props.disableAction}
        onClick={props.backAction}
        fontSize={20}
        buttonPosition={themeContext.BackButton.buttonPosition}
        iconLocation={themeContext.BackButton.buttonPosition}
        text={props.backBtnText}
        icon={themeContext.BackButton.icon}
        iconColor={themeContext.BackButton.iconColor}
      />
    </>
  )
}
BackAct.propTypes = {
  backBtnStyle: PropTypes.string,
  backAction: PropTypes.func
}

BackAct.defaultProps = {
  backAction: null
}
export default BackAct
