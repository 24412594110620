import { appLog } from 'utils/Logger'

export function getBags(itineraryInfo) {
  let bags = []
  if (itineraryInfo) {
    let itInfo = itineraryInfo
    if (itInfo.passengerBagtagDetails) {
      for (let i = 0; i < itInfo.passengerBagtagDetails.length; i++) {
        for (let j = 0; j < itInfo.passengerBagtagDetails[i].bagTags.length; j++) {
          let bag = itInfo.passengerBagtagDetails[i].bagTags[j]
          bag.paxOrdinal = itInfo.passengerBagtagDetails[i].passenger.ordinal
          bag.paxName =
            itInfo.passengerBagtagDetails[i].passenger.firstName +
            ' ' +
            itInfo.passengerBagtagDetails[i].passenger.lastName
          bags.push(bag)
        }
      }
    }
  }
  return bags
}
