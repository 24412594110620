import React, { useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { TraceLevels } from 'embross-device-manager'
import { ThemeContext } from 'styled-components'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, Spacer } from 'components/styledComponents'
import { PageTitle, PageSubTitle, PageHeader } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from 'layouts/footer'
import { getDeviceManager, getSBDAppMan, playSound, getBuildAccessibility } from 'main'
import { populateItineraryInfo, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'

const Intrusion = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const appManager = getDeviceManager().getAppManager()
  const auroraHeight = useMedia(null, [730, 730, 0], 1050)
  const isLandscape = useCheckLandscape()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const mismatchBPError = useSelector((state) => state.localData.mismatchBPError)
  const locale = useSelector((state) => state.localData.locale)
  const itineraryData = itineraryInfo ? populateItineraryInfo(itineraryInfo) : null
  const themeContext = useContext(ThemeContext)
  const location = useLocation()

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(Intrusion.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    let url = '/'
    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        if (location.state && location.state.welcomeFlag) {
          SBDAppManager.setAppFlow(0)
          url = 'Welcome'
        } else {
          SBDAppManager.setAppFlow(2)
          SBDAppManager.setSQStart(-1)
          url = 'PutBagOnBelt'
        }
        break
      case 'buttonQuit':
        appManager.available()
        break
      default:
        url = '/'
    }

    appLog(TraceLevels.LOG_EXT_TRACE, '(Intrusion.js) handleActions() - url = "' + url + '"')
    navigate(url)
  }

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const intrusionMsg = [
      intl.formatMessage(messages.Error_ConveyorIntrusion_Title),
      intl.formatMessage(messages.Error_ConveyorIntrusion_SubTitle)
    ]
    const accDef = {
      pathName: 'Intrusion',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [intrusionMsg.join('. ')] },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    dispatch(updateLocalData('updateBagWeight', '0'))
    if (mismatchBPError) {
      dispatch(updateLocalData('updateMismatchBPError', false))
    }
  }, [mismatchBPError])

  useEffect(() => {
    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(Intrusion.js) componentWillUnmount() - UNMOUNTED ...')
      SBDAppManager.resetIntrusionDuringDispatch()
    }
  }, [])

  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )

  const animationSize = useMedia(null, [400, 350, 400], 400)
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/bagProgress_movement_new.gif`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.Error_ConveyorIntrusion_Title)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.Error_ConveyorIntrusion_SubTitle)}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.ErrorTitle)}
    </PageHeader>
  )

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      isLangRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonOk)}
      confirmAction={handleActions}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>

  // return (
  //   <div className="page-container">
  //     <AuroraPanel height={auroraHeight} width={'100%'}>
  //       <PageContent flexFlow={'column'}>
  //         <PageTitle alignItems="center" flexFlow="row">
  //           {errIcon}
  //           {intl.formatMessage(messages.ErrorTitle)}
  //         </PageTitle>
  //         <ItineraryInfo itineraryData={itineraryData} />
  //         <PageContent flexFlow={isLandscape ? 'row' : 'column'}>
  //           <PageSubContent flex={1} width={contentWidth}>
  //             {isLandscape ? animationSection : textSection}
  //           </PageSubContent>
  //           <PageSubContent minHeight={'500px'} flex={2} width={contentWidth}>
  //             {isLandscape ? textSection : animationSection}
  //           </PageSubContent>
  //         </PageContent>
  //       </PageContent>
  //     </AuroraPanel>
  //     <PageFooter>
  //       <Footer
  //         isQuitRequired={false}
  //         isBackRequired={false}
  //         isSkipRequired={false}
  //         isConfirmRequired={true}
  //         langBtnStyle={'btn-green-up'}
  //         isLangRequired={true}
  //         confirmBtnText={intl.formatMessage(messages.buttonOk)}
  //         confirmBtnStyle={'btn-green-up'}
  //         confirmAction={handleActions}
  //       />
  //     </PageFooter>
  //   </div>
  // )
}

export default Intrusion
