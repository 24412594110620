import {deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import BarcodeReaderListener from 'listeners/barcodeReaderListener'

/**
 *  Device representing a Barcode Reader
 *  @extends {Device}
 */
export default class BarcodeReader extends device
{
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Barcode Reader'
    /**
     * Device id - deviceIds.BARCODE_READER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.BARCODE_READER
    this.setListener(new BarcodeReaderListener())
  }
  /**
   *  Asynch call to read data
   */
  read()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'read().')
      this.socket.sendRequest(this.deviceId, 'read')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'read() exception: ' + e)
    }
  }
  /**
   *  Asynch call to eject
   */
  eject()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'eject().')
      this.socket.sendRequest(this.deviceId, 'eject')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'eject() exception: ' + e)
    }
  }
}
