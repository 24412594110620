import React, { useRef, useContext } from 'react'
import { Button, AuroraPanel, Popup, IconButton } from 'components'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { PopupTitle, PopupSubTitle, Spacer, PageSubContent, DynamicImage } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'

const QuitTxn = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const popupTimer = useRef(null)
  const { visible, popupCallback, buttonGap } = props
  const isLandscape = useCheckLandscape()

  const onYesBtnClicked = () => {
    console.log('QuitTxn onYesBtnClicked() ... cancel transaction')
    if (popupCallback) {
      popupCallback('YES')
    }
  }

  const onNoBtnClicked = () => {
    console.log('QuitTxn onNoBtnClicked() ... back to page')
    if (popupCallback) {
      popupCallback('NO')
    }
  }
  return (
    <Popup visible={visible} cssDialogName="popup-dialog">
      <Spacer height={'50px'} />
      <PageSubContent>
        <DynamicImage imageName={`question-mark-icon.svg`} width={200} height={200} />
      </PageSubContent>
      <div id="exitLabel" tabIndex="0" style={{ paddingLeft: '30px' }}>
        <PageSubContent flexFlow={'column'} textAlign="left" padding="10px">
          <PopupTitle color={themeContext.PrimaryFontColor}>{formatMessage(messages.CancelTxnTitle)}</PopupTitle>
          <PopupSubTitle color={themeContext.PrimaryFontColor}>
            {formatMessage(messages.CancelTxnSubTitle)}
          </PopupSubTitle>
        </PageSubContent>
      </div>
      <Spacer height={'50px'} />
      <PageSubContent flexFlow={'row'}>
        <IconButton
          id={'cancelYes'}
          color={themeContext.CancelButton.color}
          bgColor={themeContext.CancelButton.bgColor}
          width={themeContext.CancelButton.width}
          height={themeContext.CancelButton.height}
          //disable={props.disableAction}
          buttonPosition={themeContext.CancelButton.buttonPosition}
          iconLocation={themeContext.CancelButton.buttonPosition}
          onClick={onYesBtnClicked}
          fontSize={20}
          text={formatMessage(messages.buttonQuit)}
          icon={themeContext.CancelButton.icon}
          iconColor={themeContext.CancelButton.iconColor}
          borderRadius={themeContext.CancelButton.borderRadius}
          border={themeContext.CancelButton.border ? themeContext.CancelButton.border : ''}
        />
        <Spacer width={buttonGap} />
        <IconButton
          id={'cancelNo'}
          color={themeContext.ConfirmButton.color}
          bgColor={themeContext.ConfirmButton.bgColor}
          width={themeContext.ConfirmButton.width}
          height={themeContext.ConfirmButton.height}
          //disable={props.disableAction}
          buttonPosition={themeContext.ConfirmButton.buttonPosition}
          iconLocation={themeContext.ConfirmButton.buttonPosition}
          onClick={onNoBtnClicked}
          fontSize={20}
          text={formatMessage(messages.buttonContinue)}
          icon={themeContext.ConfirmButton.icon}
          iconColor={themeContext.ConfirmButton.iconColor}
          borderRadius={themeContext.ConfirmButton.borderRadius}
          border={themeContext.ConfirmButton.border ? themeContext.ConfirmButton.border : ''}
        />
      </PageSubContent>
    </Popup>
  )
}

export default QuitTxn
