import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from 'components'
import { playSound } from 'main'
import { ThemeContext } from 'styled-components'

const PhotoAct = (props) => {
  const themeContext = useContext(ThemeContext)
  const handlePhotoAction = (e) => {
    playSound.beepOK()
    console.log('handlePhotoAction() ... event id : ' + e.currentTarget.id)
    props.photoAction(e)
  }
  const { photoBtnText } = props
  return (
    <IconButton
      id={'PhotoBtn'}
      cssName={props.photoBtnStyle}
      color={themeContext.PhotoButton.color}
      bgColor={themeContext.PhotoButton.bgColor}
      width={themeContext.PhotoButton.width}
      height={themeContext.PhotoButton.height}
      disabled={props.disableAction}
      onClick={handlePhotoAction}
      fontSize={20}
      buttonPosition={themeContext.PhotoButton.buttonPosition}
      text={photoBtnText}
      icon={themeContext.PhotoButton.icon}
      iconWidth={'25px'}
      iconHeight={'25px'}
      iconColor={themeContext.PhotoButton.iconColor}
      borderRadius={themeContext.PhotoButton.borderRadius}
      border={themeContext.PhotoButton.border ? themeContext.PhotoButton.border : ''}
    />
  )
}

PhotoAct.propTypes = {
  photoAction: PropTypes.func,
  disableAction: PropTypes.bool,
  photoBtnText: PropTypes.string,
  photoBtnStyle: PropTypes.string,
  textSpanStyle: PropTypes.string,
  textContainerStyle: PropTypes.string,
  iconStyle: PropTypes.string
}

PhotoAct.defaultProps = {
  photoBtnText: 'photo',
  photoBtnStyle: 'btn-nav-photo-up',
  textSpanStyle: 'btn-nav-right-padding',
  textContainerStyle: 'row-container-space-around',
  iconStyle: 'font-icon'
}
export default PhotoAct
