import React from 'react'
import { ThemeProvider } from 'styled-components'
import CustomBrowserRouter from 'routes/customRouter'
import { history } from 'main'
import CoreLayout from 'layouts/coreLayout'
import { ErrorBoundary } from 'react-error-boundary'
import { IntlProvider } from 'react-intl'
import { useSelector, Provider } from 'react-redux'
//import { Theme } from 'custom/resources/theme'
function ErrorFallback() {
  return (
    <div role="alert">
      <p>Something went wrong:</p>

      <button
        onClick={() => {
          console.log('nothing to reset')
        }}
      >
        Try again
      </button>
    </div>
  )
}

const myErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log('this is unhandled error')
}

const Root = (props) => {
  const locale = useSelector((state) => state.localData.locale)
  const getLanguageTranslation = locale => {
    let msg = {}
    let translations = {}
    switch (locale) {
      case 'es':
        translations = messages_es
        break
      case 'zh':
        translations = messages_zh
        break
      case 'fr':
        translations = messages_fr
        break
      case 'ar':
        translations = messages_ar
        break
      default:
        Object.keys(messages).map(key => {
          msg = {}
          msg[key] = messages[key].defaultMessage
          Object.assign(translations, msg)
        })
        break
    }
    return translations
  }
  //const locale = config.defaultLanguage
  let lang = getLanguageTranslation(locale)
  //const defaultTheme = Object.keys(Theme)[0]
  //const theme = config.theme && Theme[config.theme] ? Theme[config.theme] : Theme[defaultTheme]
  return (
    <ThemeProvider theme={Theme}>
      <CustomBrowserRouter history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
          <IntlProvider locale={locale} messages={lang}>
            <CoreLayout routes={props.routes}/>
          </IntlProvider>
        </ErrorBoundary>
      </CustomBrowserRouter>
    </ThemeProvider>
  )
}

export default Root
