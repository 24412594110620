import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { TraceLevels } from 'embross-device-manager'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { PageTitle, PageSubTitle, PageHeader, DynamicImage, Spacer } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { getDeviceManager, getSBDAppMan, playSound, getBuildAccessibility } from 'main'
import { navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'

const ErrorPrint = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const SBDAppManager = getSBDAppMan()
  const dispatch = useDispatch()
  const errorDetails = useSelector((state) => state.errorDetails)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)

  useEffect(() => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '>> (ErrorMismatch.js) componentDidMount() - this.props.appFlow = "' +
        appFlow +
        '" error: ' +
        JSON.stringify(errorDetails)
    )
    appLog(TraceLevels.LOG_EXT_TRACE, '==> CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
    getDeviceManager()
      .getAppManager()
      .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    return () => {
      dispatch(updateError(null))
    }
  }, [])

  // const handleActions = (e) => {
  //   appLog(
  //     TraceLevels.LOG_EXT_TRACE,
  //     '(ErrorPrint.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
  //   )

  //   playSound.beepOK()

  //   switch (e.currentTarget.id) {
  //     case 'confirmBtn':
  //       if (errorDetails.source === 'PrintReceipt') {
  //         navigate('ClosingRemarks')
  //       } else {
  //         if (baggageLoadedStatus) {
  //           navigate('BagProcessing', 2)
  //         } else {
  //           navigate('PutBagOnBelt', 2)
  //         }
  //       }
  //       break
  //     default:
  //   }
  // }

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(ErrorPrint.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        if (errorDetails.source === 'PrintReceipt') {
          navigate('ClosingRemarks')
        } else {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        }
        break
      default:
    }
  }

  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )

  const animationSize = useMedia(null, [400, 350, 400], 400)
  const animationSection = (
    <DynamicImage imageName={`printError.png`} cssName={'animation'} width={animationSize} height={animationSize} />
  )

  let heading = null
  let instMsg = null
  if (errorDetails.source === 'PrintReceipt') {
    heading = intl.formatMessage(messages.PrintReceiptErrorTitle)
    instMsg = intl.formatMessage(messages.PrintReceiptError)
  } else if (errorDetails.source === 'PrintBagtag') {
    heading = intl.formatMessage(messages.PrintBagtagsErrorTitle)
    instMsg = intl.formatMessage(messages.PrintBagtagsError, {
      buttonContinue: intl.formatMessage(messages.buttonContinue)
    })
  } else if (errorDetails.source === 'PrintHeavyTag') {
    heading = intl.formatMessage(messages.PrintBagtagsErrorTitle)
    instMsg = intl.formatMessage(messages.PrintBagtagsError, {
      buttonContinue: intl.formatMessage(messages.buttonContinue)
    })
  }

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {heading}
      </PageTitle>
      <PageSubTitle justifyPageSubTitle="center" color={themeContext.PrimaryFontColor}>
        {instMsg}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.RepositionBagTitle)}
    </PageHeader>
  )

  const footer = (
    <Footer
      isQuitRequired={!appMode}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleActions}
      confirmBtnText={intl.formatMessage(messages.buttonContinue)}
    />
  )

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'ErrorPrint',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicTextWithDownKey',
            textParameters: [[heading, instMsg].join('. ')]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonContinue), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ErrorPrint
