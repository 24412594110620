/*
 * Use keyPressed to simulate EZ-Access Keypad
 * */
import { KBKeys, KeyboardEventCode } from './Constants'

export default class KeypadSimulator {
  constructor() {
    this.name = 'KeypadSimulator'
    this.deviceId = null
    // callback
    this.onDeviceEvent = null
    // hook up callback
    this.deviceEvent = null
    this.deviceStatus = null
    this.isDeviceOk = false
    this.isRequired = false
    this.enabled = false
    this.synth = window.speechSynthesis
    this.cancelDelay = null
    this.createDeviceEvent = this.createDeviceEvent.bind(this)
    this.locale = 'en'
    this.languageStr = ''
    this.voiceType = 'winVoice'
  }

  createDeviceEvent() {
    document.body.onkeydown = (e) => {
      console.log('KeypadSimulator ... Key Code ... ', e.code)
      let event = undefined
      e = e || window.event
      switch (e.code) {
        case KeyboardEventCode.LEFT:
          event = { key: 'keyPressed', value: KBKeys.LEFT }
          break
        case KeyboardEventCode.UP:
          event = { key: 'keyPressed', value: KBKeys.UP }
          break
        case KeyboardEventCode.RIGHT:
          event = { key: 'keyPressed', value: KBKeys.RIGHT }
          break
        case KeyboardEventCode.DOWN:
          event = { key: 'keyPressed', value: KBKeys.DOWN }
          break
        case KeyboardEventCode.HELP:
          event = { key: 'keyPressed', value: KBKeys.HELP }
          break
        case KeyboardEventCode.ENTER:
          event = { key: 'keyPressed', value: KBKeys.ENTER }
          e.preventDefault()
          e.stopPropagation()
          break
        case KeyboardEventCode.ESC:
          event = { key: 'keyPressed', value: KBKeys.ESC }
          if (this.enabled) {
            this.DeviceEvent = { key: 'enabled', value: false }
          } else {
            this.DeviceEvent = { key: 'enabled', value: true }
          }
          break
        default:
      }

      if (event) {
        if (event.value !== KBKeys.ESC) {
          if (this.enabled) {
            this.DeviceEvent = event
          }
        }
      }
    }
  }

  /*
   * event = {key:eventName, value: value/null}
   * i.e. {key:'barcodeInserted', null}
   * */
  set DeviceEvent(event) {
    this.deviceEvent = event
    if (this.onDeviceEvent && typeof this.onDeviceEvent === 'function') this.onDeviceEvent(event)
  }

  set OnDeviceEvent(callback) {
    this.onDeviceEvent = callback
  }

  set IsRequired(isRequired) {
    this.isRequired = isRequired
  }

  setWindowsVoice(lang, voices) {
    /** By default Windows allows only 3 languages. In order to unlock this limitation, we should modify the registry **/
    // const winVoice = voices.filter((voice) => voice.lang === lang) // google tts
    /** ONLY Microsoft SSL understand XML and read properly **/
    // const winVoice = voices.filter(
    //   (voice) => voice.name.includes('Microsoft') && voice.lang.split('-')[0] === lang.split('-')[0]
    // )
    // console.log('voices', voices)
    const winVoice = voices.filter((voice) => voice.name.includes('Microsoft') && voice.lang === lang)
    const googleVoice = voices.filter((voice) => voice.name.includes('Google') && voice.lang === lang)

    console.log('win voice:', winVoice)
    console.log('google voice:', googleVoice)
    if (this.voiceType === 'googleVoice') {
      return googleVoice.length > 0 ? googleVoice[0] : voices[0]
    } else {
      return winVoice.length > 0 ? winVoice[0] : voices[0]
    }
  }

  setTTSLanguage(language) {
    if (this.languageStr) {
      return this.languageStr
    }
    let lang = ''
    let locale = language ? language : this.locale
    switch (locale) {
      case 'en':
        lang = 'en-US'
        break
      case 'ar':
        lang = 'hi-IN'
        break
      case 'es':
        lang = 'es-MX'
        break
      case 'zh':
        lang = 'zh-CN'
        break
      default:
        lang = locale.toLowerCase() + '-' + locale.toUpperCase()
    }
    return lang
  }

  play(data) {
    let voices = this.synth.getVoices()
    // SpeechSyn is currently speaking, cancel the current utterance(s)
    if (this.synth.speaking) {
      speechSynthesis.cancel()
      // Make sure we don't create more than one timeout...
      if (this.cancelDelay !== null) {
        clearTimeout(this.cancelDelay)
      }
      this.cancelDelay = setTimeout(() => {
        this.play(data)
      }, 250)
    } else {
      let utterThis = new SpeechSynthesisUtterance(data)
      utterThis.lang = this.setTTSLanguage()
      utterThis.voice = this.setWindowsVoice(utterThis.lang, voices)
      this.synth.speak(utterThis)
    }
  }

  playBilingual(data) {
    let voices = this.synth.getVoices()
    if (this.synth.speaking) {
      speechSynthesis.cancel()
      if (this.cancelDelay !== null) {
        clearTimeout(this.cancelDelay)
      }
      this.cancelDelay = setTimeout(() => {
        this.playBilingual(data)
      }, 250)
    } else {
      let firstLanguageStr = ''
      data.forEach((speech, index) => {
        if (index === 0) {
          firstLanguageStr = speech.languageStr
        }
        let utterThis = new SpeechSynthesisUtterance(speech.text)
        this.languageStr = speech.languageStr
        utterThis.lang = this.setTTSLanguage()
        utterThis.voice = this.setWindowsVoice(utterThis.lang, voices)
        this.synth.speak(utterThis)
      })
      this.languageStr = firstLanguageStr
    }
  }
}
