/**
 * turn on and off predefined zones and patters as defined in the appConfig.config
 * cameraDevice: [['AREA_APP_ZONE1','APP_PATTERN2'], ['AREA_APP_ZONE2','APP_PATTERN2']],
 */
import { getDeviceManager, history } from 'main'
import { TraceLevels } from '../embross-device-manager'
import { appLog } from 'utils/Logger'

// id is the lights definition in the config
// on boolean - true (on) false (off)
export function turnLights(id, on) {
  if (!config.lightsAvailable) return
  appLog(TraceLevels.LOG_TRACE, '>> (lights.js) turnLights: ' + id + ' ' + on)
  // check if the id is defined in appConfig.config
  if (config.hasOwnProperty(id)) {
    try {
      let zonePatternArray = config[id]
      for (let item of zonePatternArray) {
        if (item[0] === 'ALERT') {
          if (on) getDeviceManager().getAppManager().kioskAlert(item[1], true)
          else getDeviceManager().getAppManager().kioskAlert(item[1], false)
        } else {
          if (on) getDeviceManager().getAppManager().appLighting(item[0], item[1])
          else getDeviceManager().getAppManager().appLighting(item[0], 'CLEAR')
        }
      }
    } catch (error) {
      appLog(TraceLevels.LOG_ALERT, '(lights.js) turnLights: ' + error.message)
    }
  } else {
    appLog(TraceLevels.LOG_TRACE, '>> (lights.js) turnLights: ' + id + ' not found in config.js')
  }
}
