import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { DynamicImage } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import './scroll.scss'
import { ThemeContext } from 'styled-components'

/* interface CustomScrollBoxProps {
  children?: JSX.Element[] | JSX.Element
  className?: string
  height: number
  isDraggable: boolean
  buttonUpIcon: string
  buttonDownIcon: string
}
 */
const CustomScrollBox = ({ children, className, ...props }) => {
  const contentRef = useRef(null)
  const scrollTrackRef = useRef(null)
  const scrollThumbRef = useRef(null)
  const observer = useRef(null)
  const [showScrollbar, setShowScrollbar] = useState(false)
  const [thumbHeight, setThumbHeight] = useState(20)
  const [scrollStartPosition, setScrollStartPosition] = useState(null)
  const [initialScrollTop, setInitialScrollTop] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const isLandscape = useCheckLandscape()
  const themeContext = useContext(ThemeContext)
  console.log('CustomScrollBox:', props.height)

  const scrollbarStyle = {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplate: 'auto 1fr auto / 1fr',
    placeItems: 'center',
    height: `${props.height}px`
  }

  const contentStyle = {
    height: `${props.height}px`
  }
  function handleResize(ref, trackSize) {
    const { clientHeight, scrollHeight } = ref
    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20))
  }

  function handleScrollButton(direction) {
    const { current } = contentRef
    const { height } = props
    if (current) {
      const scrollAmount = direction === 'down' ? height : -height
      //current.scrollBy({ top: scrollAmount })
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  const handleTrackClick = useCallback(
    (e) => {
      if (props.isDraggable) {
        e.preventDefault()
        e.stopPropagation()
        const { current: trackCurrent } = scrollTrackRef
        const { current: contentCurrent } = contentRef
        if (trackCurrent && contentCurrent) {
          const { clientY } = e
          const target = e.target
          const rect = target.getBoundingClientRect()
          const trackTop = rect.top
          const thumbOffset = -(thumbHeight / 2)
          const clickRatio = (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight
          const scrollAmount = Math.floor(clickRatio * contentCurrent.scrollHeight)
          contentCurrent.scrollTo({
            top: scrollAmount,
            behavior: 'smooth'
          })
        }
      }
    },
    [thumbHeight]
  )

  const handleThumbPosition = useCallback(() => {
    if (!contentRef.current || !scrollTrackRef.current || !scrollThumbRef.current) {
      return
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight } = contentRef.current
    const { clientHeight: trackHeight } = scrollTrackRef.current
    let newTop = (+contentTop / +contentHeight) * trackHeight
    newTop = Math.min(newTop, trackHeight - thumbHeight)
    const thumb = scrollThumbRef.current
    thumb.style.top = `${newTop}px`
  }, [])

  const handleThumbMousedown = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setScrollStartPosition(e.clientY)
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop)
    setIsDragging(true)
  }, [])

  const handleThumbMouseup = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isDragging) {
        setIsDragging(false)
      }
    },
    [isDragging]
  )

  const handleThumbMousemove = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isDragging && contentRef.current && scrollStartPosition) {
        const { scrollHeight: contentScrollHeight, offsetHeight: contentOffsetHeight } = contentRef.current

        const deltaY = (e.clientY - scrollStartPosition) * (contentOffsetHeight / thumbHeight)
        const newScrollTop = Math.min(initialScrollTop + deltaY, contentScrollHeight - contentOffsetHeight)

        contentRef.current.scrollTop = newScrollTop
      }
    },
    [isDragging, scrollStartPosition, thumbHeight]
  )
  useEffect(()=> {
    if (contentRef.current) {
      const { scrollHeight: contentScrollHeight, offsetHeight: contentOffsetHeight } = contentRef.current
      if (contentScrollHeight > contentOffsetHeight) {
        //show scrollbar
        setShowScrollbar(true)

      } else {
        //hide scrollbar
        setShowScrollbar(false)
      }
    }
  }, [children])  
  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current
      const { clientHeight: trackSize } = scrollTrackRef.current
      observer.current = new ResizeObserver(() => {
        handleResize(ref, (props.height - 2* 70))
      })
      observer.current.observe(ref)
      ref.addEventListener('scroll', handleThumbPosition)
      return () => {
        observer.current?.unobserve(ref)
        ref.removeEventListener('scroll', handleThumbPosition)
      }
    }
  }, [])

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove)
    document.addEventListener('mouseup', handleThumbMouseup)
    document.addEventListener('mouseleave', handleThumbMouseup)
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove)
      document.removeEventListener('mouseup', handleThumbMouseup)
      document.removeEventListener('mouseleave', handleThumbMouseup)
    }
  }, [handleThumbMousemove, handleThumbMouseup])

  return (
    <div className="custom-scrollbars-container">
      <div style={contentStyle} className="custom-scrollbars-content" ref={contentRef} >
        {children}
      </div>
      <div className="custom-scrollbars-scrollbar" style={{display: isLandscape ? 'none' : showScrollbar ? 'grid': 'none', height: props.height + 'px'}}>
        <button className="custom-scrollbars-button-up" onClick={() => handleScrollButton('up')}>
          {/* ⇑ */}
          <DynamicImage imageName={props.buttonUpIcon} height="70" width="70" />
        </button>
        <div className="custom-scrollbars-track-and-thumb">
          <div
            className="custom-scrollbars-track"
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{ cursor: isDragging ? 'grabbing' : 'none' }}
          ></div>
          <div
            className="custom-scrollbars-thumb"
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            style={{
              height: `${thumbHeight}px`,
              cursor: isDragging ? 'grabbing' : 'grab',
              backgroundColor: themeContext.SecondaryColor
            }}
          ></div>
        </div>
        <button className="custom-scrollbars-button-down" onClick={() => handleScrollButton('down')}>
          {/* ⇓ */}
          <DynamicImage imageName={props.buttonDownIcon} height="70" width="70" />
        </button>
      </div>
    </div>
  )
}

export default CustomScrollBox
