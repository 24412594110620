/**
 *
 * Dispatch Transition: support ETS to rearrange service sequences.
 *
 */

import { store, history, getSBDAppMan } from 'main'
import { TransitionCodes, FunctionCodes, StatusCodes, OOS, ServiceNames } from 'constants/Constants'
import { updateError } from 'actions/commonActions'
import { ErrCodes } from 'constants/Errors'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'
import { goToLocalGenericError, navigate } from 'utils/helper'

export function transitions(transition, response, location = {}) {
  appLog(TraceLevels.LOG_TRACE, 'transitions() :: transition = ' + transition + ' response = ' + response)
  switch (transition) {
    // case TransitionCodes.BARCODE_RETRY:
    //   return dispatch => {
    //     let url = Object.assign({}, location, { pathname: 'error' })
    //     // check if the max attempts count has been reached localData.errorBarcodeAttempts
    //     let barcodeScanAttempts = store.getState().localData.errorBarcodeAttempts
    //     appLog(TraceLevels.LOG_TRACE, 'transitions() :: barcodeScanAttempts: ' + barcodeScanAttempts)
    //     let maxBarcodeScanAttempts = config.mediaAccessRetryCount // default
    //     if (
    //       store.getState().sessions.configElements &&
    //       store.getState().sessions.configElements['mediaAccessRetryCount']
    //     ) {
    //       maxBarcodeScanAttempts = store.getState().sessions.configElements['mediaAccessRetryCount']
    //     }
    //     if (barcodeScanAttempts > maxBarcodeScanAttempts) {
    //       appLog(
    //         TraceLevels.LOG_TRACE,
    //         'transitions() :: maxBarcodeScanAttempts: ' + maxBarcodeScanAttempts + ' BARCODE_RETRIES_EXCEEDED'
    //       )
    //       dispatch(updateError(ErrCodes.BARCODE_RETRIES_EXCEEDED, '', 'InterpretBarcode'))
    //     }
    //     history.push(url)
    //   }

    case TransitionCodes.PUT_BAG_ON_BELT:
      appLog(TraceLevels.LOG_EXT_TRACE, 'transitions() :: display "PutBagOnBelt" page')
      navigate('PutBagOnBelt', 2)

    // case TransitionCodes.PRINT_RECEIPTS:
    // case TransitionCodes.FINISH:
    //   return dispatch => {
    //     appLog(TraceLevels.LOG_EXT_TRACE, 'transitions() :: display "layouts/finish/ClosingRemarks" page')
    //     let url = Object.assign({}, location, { pathname: 'ClosingRemarks' })
    //     history.push(url)
    //   }

    case TransitionCodes.OOS_ERROR:
      return (dispatch) => {
        dispatch(updateError(ErrCodes.DEVICE_ERROR, '', 'OOSError', OOS))
        let loc = {
          state: {
            from: 'OOSError',
            statistics: {
              paxOrdinal: null,
              paxName: null,
            },
          },
        }
        appLog(TraceLevels.LOG_ALERT, 'transitions() :: display "errors" page - OOS error')
        let url = Object.assign({}, loc, { pathname: 'error' })
        navigate(url)
      }
    case TransitionCodes.END_TXN:
      getSBDAppMan().doQuit(store.getState().localData.quitReason, store.getState().localData.quitDetail)
      return null

    case TransitionCodes.TAKE_PHOTO:
      return (dispatch) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'transitions() :: display "TakePhoto" page')
        let url = Object.assign({}, location, { pathname: 'TakePhoto' })
        navigate(url, 21)
      }

    // case TransitionCodes.NEXT_TRAVELER_PHOTO:
    //   return dispatch => {
    //     // if last traveler then goto 'updateTravelerPhoto' else goto 'TakePhoto' or 'PhotoNotRequired'
    //     // called from ConfirmPhoto or 'ErrorPage'(max photo attempts)
    //     let url = null
    //     let travelerPhotoRequired = store.getState().responses.travelerPhotoRequired
    //     let photoRequiredFlag = false

    //     let nextPaxOrdinal = location.state.paxOrdinal == null ? 0 : location.state.paxOrdinal
    //     let loopFlag = false
    //     if (nextPaxOrdinal + 1 < store.getState().responses.travelers.length) {
    //       // MORE PASSENGERS TO TAKE PHOTOS ...
    //       nextPaxOrdinal++
    //       loopFlag = true
    //     }
    //     if (loopFlag) {
    //       let travelerName = ''
    //       for (var i = 0; i < travelerPhotoRequired.length; i++) {
    //         if (travelerPhotoRequired[i].traveler.travelerOrdinal === nextPaxOrdinal) {
    //           photoRequiredFlag = travelerPhotoRequired[i].photoRequired
    //           travelerName =
    //             '' +
    //             (travelerPhotoRequired[i].traveler.firstName == null
    //               ? ''
    //               : travelerPhotoRequired[i].traveler.firstName + ' ') +
    //             (travelerPhotoRequired[i].traveler.lastName == null ? '' : travelerPhotoRequired[i].traveler.lastName)
    //         }
    //       }
    //       if (!config.retryCountByTransaction) {
    //         dispatch(updateLocalData('updatePhotoAttempts', 0))
    //       }
    //       let loc = {
    //         state: {
    //           from: location.state.from,
    //           paxOrdinal: nextPaxOrdinal,
    //           statistics: {
    //             paxOrdinal: nextPaxOrdinal,
    //             paxName: travelerName
    //           }
    //         }
    //       }
    //       dispatch(updateLocalData('appFlow', 22))
    //       if (photoRequiredFlag) {
    //         appLog(TraceLevels.LOG_TRACE, 'transitions() :: display "TakePhoto" page pax: ' + nextPaxOrdinal)
    //         url = Object.assign({}, loc, { pathname: 'TakePhoto' })
    //       } else {
    //         appLog(TraceLevels.LOG_TRACE, 'transitions() :: display "PhotoNotRequired" page pax: ' + nextPaxOrdinal)
    //         url = Object.assign({}, loc, { pathname: 'PhotoNotRequired' })
    //       }
    //       navigate(url, 21)
    //     } else {
    //       // all photos taken includes (not required and technical difficulties)
    //       let loc = {
    //         state: {
    //           from: location.state.from,
    //           statistics: {
    //             paxOrdinal: null,
    //             paxName: null
    //           }
    //         }
    //       }
    //       dispatch(updateLocalData('appFlow', 22))
    //       // dispatch(updateTravelerPhoto(true, StatusCodes.COMPLETE, loc))
    //       dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_PAX_VALIDATION))
    //     }
    //   }

    default:
      appLog(TraceLevels.LOG_TRACE, 'transitions() :: ERROR - unknown transition: ' + transition)
      return (dispatch) => {
        goToLocalGenericError('transitions', ErrCodes.APP_ERROR, 'Error_Default', 'END_TXN', null)
        // dispatch(updateError(ErrCodes.APP_ERROR, 'unknown transition: ' + transition, 'transitions'))
        let url = Object.assign({}, location, { pathname: 'error' })
        history.push(url)
      }
  }
}
