import { v4 as uuidv4 } from 'uuid'
import { store, getDeviceManager, getSBDAppMan, history, getSummaryStore } from 'main'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError, goToFetchError } from 'utils/FetchWithTimeout'
import { delay, isEmpty, isNotEmpty, format1107msg, goToLocalError, navigate, replacer } from 'utils/helper'
import { updateError } from 'actions/commonActions'
import { updateSessions } from 'actions/sessionActions'
import { updateLocalData, setBagProcessStatus } from 'actions/localActions'
import { updateResponses } from 'actions/responseActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { TYPE_KIOSK, BAG_TXN_NOMORE, SBDUpdateAction, TransitionCodes, PRINT_DOC_TYPE } from '../../constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { appLog } from 'utils/Logger'
import { getBagDetails } from 'actions/etsTransactions/getBagDetails'

let latestSequenceNumber = ''

function buildBagUpdateSBD(bagTagObject, status, measurements, overrideCode) {
  let bagStatus = status
  let withBagData = true

  switch (status) {
    case SBDUpdateAction.VERIFY:
    case SBDUpdateAction.ACCEPTED:
    case SBDUpdateAction.INGESTED:
      break
    case BAG_TXN_NOMORE:
      bagStatus = SBDUpdateAction.NO_MORE_BAG
      withBagData = false
      break
    case ErrCodes.USER_QUIT:
    case ErrCodes.SCREEN_TIMEOUT:
      bagStatus = SBDUpdateAction.ABANDONED
      withBagData = false
      break
    default:
      bagStatus = SBDUpdateAction.CRITICAL
      withBagData = false
      break
  }

  // remove temp data paxOrdinal and paxName
  let bagTagObject2 = Object.assign({}, bagTagObject)
  if (withBagData) {
    delete bagTagObject2.paxOrdinal
    delete bagTagObject2.paxName
  }

  let vals = ''
  let measurementsArray = []
  let weightUnit = ''
  let dimUnit = ''
  for (let i = 0; i < measurements.length; i++) {
    vals += measurements[i] + ' '
    measurementsArray[i] = parseFloat(measurements[i])
    if (i == 0 && (measurements[0].endsWith('KG') || measurements[0].endsWith('LB'))) {
      weightUnit = measurements[0].substr(measurements[0].length - 2)
    }
    if (i == 1 && (measurements[1].endsWith('CM') || measurements[1].endsWith('IN'))) {
      // dim
      dimUnit = measurements[1].substr(measurements[1].length - 2)
    }
  }
  if (measurements.length < 4) {
    for (let i = measurements.length; i < 4; i++) {
      measurementsArray[i] = 0
    }
  }
  let bagtag = bagTagObject2.baseTagNo
  appLog(TraceLevels.LOG_EXT_TRACE, 'callBagUpdate: ' + bagtag + ' ' + bagStatus + ' ' + vals)
  if (withBagData && (bagtag === undefined || bagtag === '')) return null

  let sbdMeasurements = Object.assign(
    {},
    {
      bagWeight: config.weightWholeNumber ? Math.round(measurementsArray[0]) : measurementsArray[0],
      bagWeightUnit: weightUnit,
      bagDimension: {
        height: measurementsArray[1],
        length: measurementsArray[2],
        width: measurementsArray[3],
        dimensionUnit: dimUnit,
      },
    }
  )

  const sequenceNumber = uuidv4()
  latestSequenceNumber = sequenceNumber

  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      jsonExtend: null,
      carrierCode: null,
      currentClientTime: 0,
      action: bagStatus,
      passengerOrdinal: withBagData ? bagTagObject.paxOrdinal : 0,
      bagTagData: withBagData ? bagTagObject2 : {},
      bagMeasurementDetail: withBagData ? sbdMeasurements : null,
      overrideCode: overrideCode ? overrideCode : '',
      sequence: sequenceNumber,
    }
  )
}

export function callBagUpdate(bagTagObject, status, measurements, overrideCode = '') {
  const summaryStore = getSummaryStore()
  let funcName = '(bagUpdateSBD.js)'
  appLog(TraceLevels.LOG_EXT_TRACE, funcName + ' ETS request begins ... ' + status + ' ' + measurements)
  // for some reason bagTagObject, measurements, overrideCode are not accessible in the then. function
  // also in the .then funcName is accessible but not other variables
  //  in getItinerary it works!
  // added bagObj as a work around
  let bagObj = Object.assign({}, { bagTagObject, measurements, overrideCode })
  store.dispatch(updateLocalData('updateBagObj', bagObj))
  let request = buildBagUpdateSBD(bagTagObject, status, measurements, overrideCode)
  appLog(TraceLevels.LOG_EXT_TRACE, '==> ' + funcName + ' ETS request: ' + JSON.stringify(request, replacer))
  let action = 'SBDUpdate/' //boardingPassScan
  appLog(TraceLevels.LOG_EXT_TRACE, funcName + ' ETS action: ' + action)
  if (request === null)
    // call error page
    return
  //  return () => {
  //    appLog(TraceLevels.LOG_EXT_TRACE,'ETS bagUpdateSBD before fetch 2')
  //store.dispatch(updateLocalData('appFlow', 1))
  store.dispatch(updateSessions('updateETSRequest', true))
  return fetchWithTimeout(action, request)
    .then((json) => {
      if (
        isNotEmpty(json.etsResponse) &&
        latestSequenceNumber !== '' &&
        latestSequenceNumber !== json.etsResponse.sequence
      ) {
        appLog(TraceLevels.LOG_EXT_TRACE, `Sequence number not match with latest`)
        appLog(TraceLevels.LOG_EXT_TRACE, `==> json: ${JSON.stringify(json, replacer)}`)
        return
      }
      latestSequenceNumber = ''
      let appFlow = store.getState().localData.appFlow
      appLog(TraceLevels.LOG_EXT_TRACE, '==> ' + funcName + ' ETS response ... appFlow: ' + appFlow)
      appLog(TraceLevels.LOG_EXT_TRACE, '==> json: ' + JSON.stringify(json, replacer))
      store.dispatch(updateSessions('updateETSRequest', false))
      if (store.getState().sessions.OOS) {
        return store.dispatch(transitions(TransitionCodes.OOS_ERROR, null))
      }
      // If transitionCode = Heavy tag, go to call get print stream and print out the heavy tag.
      if (json.transition === TransitionCodes.HEAVY_TAG) {
        store.dispatch(getPrintStream(PRINT_DOC_TYPE.HEAVY_BAG_TAG))
      } else {
        if (isNotEmpty(json.etsResponse)) {
          getSBDAppMan().hostResponse()
          if (json.etsResponse.jsonExtend === 'ADDPAX') {
            appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatchBP.')
            goToLocalError(
              'commandCompleted appFlow 5',
              ErrCodes.TAG_SCANNED_BP_NOT_SCANNED,
              'ErrorMismatch4',
              'PutBagOnBelt',
              null,
              'ErrorBPMismatch'
            )
            return
          }
          let printData = ''
          let nextTransition = ''
          let unexpectedState = false
          if ((appFlow >= 5 && appFlow <= 7) || appFlow == 9 || appFlow == 10) {
            // response to BagUpdate
            let bagStatusOK = false
            // TODO: (MN - 2016-06-16) This should be updated to check for errors and status of the bags (at least one not active)
            if (isNotEmpty(json.etsResponse.bagTagData)) {
              let paxIx = -1
              let updatedBagData = []
              let itineraryInfo = store.getState().responses.itineraryInfo
              if (json.etsResponse.passengerOrdinal != null) {
                if (itineraryInfo.passengerBagtagDetails) {
                  for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
                    if (
                      itineraryInfo.passengerBagtagDetails[i].passenger.ordinal === json.etsResponse.passengerOrdinal
                    ) {
                      //

                      itineraryInfo.passengerBagtagDetails[i].bagTags.forEach((bagtag) => {
                        if (bagtag.baseTagNo === json.etsResponse.bagTagData[0].baseTagNo) {
                          updatedBagData.push(json.etsResponse.bagTagData[0])
                        } else {
                          updatedBagData.push(bagtag)
                        }
                      })

                      //

                      paxIx = i
                      break
                    }
                  }
                }
              }
              if (paxIx >= 0) {
                // store.dispatch(updateResponses('updateBagtagData', { bagtagData: json.etsResponse.bagTagData, paxIx: paxIx }))
                store.dispatch(updateResponses('updateBagtagData', { bagtagData: updatedBagData, paxIx: paxIx }))
              } else {
                appLog(TraceLevels.LOG_TRACE, 'unknown paxOrdinal: ' + json.etsResponse.passengerOrdinal)
              }
            }
            switch (appFlow) {
              case 5:
                bagStatusOK = true
                store.dispatch(setBagProcessStatus(4, true))
                appLog(TraceLevels.LOG_EXT_TRACE, funcName + ' call dispatchBag..................')
                delay(config.delaySbdCall).then(() => {
                  dispatchBag()
                })
                break
              case 6:
                // summary log update
                summaryStore.updateBagData(summaryStore.currentBagtag, 'inducted', true)
                summaryStore.CurrentBagtag = null
                summaryStore.updateBagDispath()

                bagStatusOK = true
                store.dispatch(updateLocalData('updateBagWeight', '0'))
                store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
                store.dispatch(updateLocalData('updateBqCommand', ''))
                store.dispatch(setBagProcessStatus(5, true))
                // send message to SBDTRACK - bag activated
                let bagtag = store.getState().localData.bagtag
                let itineraryInfo = store.getState().responses.itineraryInfo
                let bagData = store.getState().localData.bagObj
                let msg = format1107msg(bagtag, itineraryInfo, bagData)
                store.dispatch(updateLocalData('BagsDetail', bagData))
                store.dispatch(updateLocalData('transactionDetail', msg))
                appLog(TraceLevels.LOG_EXT_TRACE, '==> CDS_APPLOG,1107,' + msg)
                getDeviceManager()
                  .getAppManager()
                  .sendApplicationLog(100, 'CDS_APPLOG,1107,' + msg)
                updateActivatedBags(bagtag)
                updateLocalData('overrideCode', {}) // reset override code
                getSBDAppMan().resetRepositionCount() // bag reposition count per bag - comment it out if per transaction
                const bagsCount = store.getState().localData.numberOfProcessedBags
                store.dispatch(updateLocalData('numberOfProcessedBags', bagsCount + 1))

                if (json.transition === TransitionCodes.GET_BAGGAGE_DETAILS) {
                  store.dispatch(updateLocalData('appFlow', 8))
                  store.dispatch(getBagDetails())
                } else if (json.transition === TransitionCodes.QUESTION_FOR_NEXT_BAG) {
                  appLog(
                    TraceLevels.LOG_EXT_TRACE,
                    '(bagUpdateSBD.js) transition: QUESTION_FOR_NEXT_BAG, ask passenger is next bag require'
                  )
                  nextTransition = 'NEXT_BAG_QUESTION'
                  navigate('NextBagQuestion', 8)
                } else if (json.transition === TransitionCodes.GET_SBD_RECEIPT) {
                  appLog(TraceLevels.LOG_EXT_TRACE, '(bagUpdateSBD.js) transition: GET_SBD_RECEIPT')
                  store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
                } else if (printData != '') {
                  nextTransition = 'HANDLE_BAG_INGESTION'
                } else if (config.asynchBagProcessing === 'F') {
                  if (getSBDAppMan().getNumberOfNotActivatedBags() == 0) {
                    appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Closing Remarks')
                    delay(config.delayBagActivatedCheckmark).then(() => {
                      store.dispatch(updateLocalData('appFlow', 8))
                      if (config.enablePrintReceipt) {
                        store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
                      } else {
                        nextTransition = 'CLOSING_REMARKS'
                        navigate('ClosingRemarks', 8)
                      }
                    })
                  } else {
                    appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Put Bag on Belt')

                    delay(config.delayBagActivatedCheckmark).then(() => {
                      nextTransition = 'PROCESS_BAG'
                      navigate('PutBagOnBelt', 2)
                    })
                  }
                } else {
                  //handleBagIngestion.delay(config.delaySbdCall);
                  store.dispatch(updateLocalData('appFlow', 7))
                  getDeviceManager().getDevice(deviceIds.AEA_BAGDROP).status()
                }
                store.dispatch(setBagProcessStatus(0, true))
                break
              case 7:
                bagStatusOK = true
                if (getSBDAppMan().getNumberOfNotActivatedBags() == 0) {
                  appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Closing Remarks')
                  nextTransition = 'CLOSING_REMARKS'
                  navigate('ClosingRemarks', 8)
                } else {
                  appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Put Bag on Belt')
                  nextTransition = 'PROCESS_BAG'
                  navigate('PutBagOnBelt', 2)
                }
                break

              case 10:
                // BAGUPDATE : QUIT, TIMEOUT, ERROR, NOMOREBAGS
                bagStatusOK = true //not checked
                nextTransition = json.transition
                // expected transitions: PRINT_DOCUMENT or CLOSING_REMARKS
                // when config.sendBagUpdateExitCases = "N" this code will not be executed
                if (config.enablePrintReceipt && store.getState().localData.numberOfProcessedBags > 0) {
                  store.dispatch(updateLocalData('appFlow', 8))
                  printData = 'Y'
                }
                break
              case 9:
                //if actionPerformend is VERIFY then go back to 2
                if (json.etsResponse.actionPerformend === SBDUpdateAction.VERIFY) {
                  appLog(TraceLevels.LOG_EXT_TRACE, funcName + 'intrusion in VERIFY goto --> Put Bag on Belt')
                  navigate('PutBagOnBelt', 2)
                }
                bagStatusOK = true //not checked - just to skip error processing
                break
            } //end switch
            if (!bagStatusOK) {
              unexpectedState = true // returned bag status not OK - ETS should respond with Error response in this case
              appLog(TraceLevels.LOG_EXT_TRACE, funcName + ' on success: bag status not OK.')
            }
          } else {
            unexpectedState = true
          }

          if (printData != '') {
            appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Print Receipt')
            store.dispatch(updateLocalData('printTransition', nextTransition))
            store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
          } else if (unexpectedState) {
            appLog(TraceLevels.LOG_EXT_TRACE, funcName + ' onSuccess Unexpected state ' + appFlow)
            store.dispatch(updateError(ErrCodes.ETS_MESSAGE_ERROR, '', 'ETS callBagUpdate unexpectedState'))
            navigate('error')
          } else {
            if (appFlow != 10) {
              appLog(TraceLevels.LOG_EXT_TRACE, 'host response nextTransition: ' + nextTransition)
              //json.transition = nextTransition;
              // do transition here - currently done individually for each case above - should be fixed to handle printdata in any response
            } // continue handling quit but check if the previous bag is on the airport belt before continuing
            else {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                funcName +
                  ' numberOfProcessedBags: ' +
                  store.getState().localData.numberOfProcessedBags +
                  ' inIngestLoop: ' +
                  getSBDAppMan().getInIngestLoop()
              )
              if (store.getState().localData.numberOfProcessedBags > 0) {
                if (config.waitForIngested && getSBDAppMan().getInIngestLoop()) {
                  navigate('PleaseWait')
                  return
                }
              }
              delay(0.1).then(() => {
                getSBDAppMan().handleQuitProcess()
              })
            }
          }
        } else if (json.error) {
          // switch (json.transition) {
          //   case TransitionCodes.OVERWEIGHT_BAGGAGE:
          //     goToFetchError('callBagUpdate', json, store.dispatch, 'AgentOverride')
          //     break
          //   default:
          //     goToFetchGenericError('callBagUpdate', json, store.dispatch)
          //     break
          // }
          // if (config.overrideBagErrors.includes(json.error.errorCode)) {
          //   goToFetchError('callBagUpdate', json, store.dispatch, 'AgentOverride')
          // } else {
          // }
          goToFetchGenericError(
            'callBagUpdate',
            json,
            store.dispatch,
            bagTagObject.baseTagNo ? { tagNumber: bagTagObject.baseTagNo } : null
          )
        }
      }
    })
    .catch((err) => {
      handleFetchErrors(err, 'callBagUpdate()', 'error')
      // navigate to the Error page - set the error code and message before
    })
}

export function callBagUpdateAsynch(bagTagObject, status, measurements) {
  let appFlow = store.getState().localData.appFlow
  appLog(
    TraceLevels.LOG_EXT_TRACE,
    'ETS bagUpdateSBDAsynch request begins appFlow: ' + appFlow + ' status: ' + status + ' ' + measurements
  )
  appLog(TraceLevels.LOG_EXT_TRACE, 'ETS bagUpdateSBDAsynch bagTagObject: ' + JSON.stringify(bagTagObject, replacer))
  let request = buildBagUpdateSBD(bagTagObject, status, measurements)
  appLog(TraceLevels.LOG_EXT_TRACE, 'ETS bagUpdateSBDAsynch ETS request: ' + JSON.stringify(request, replacer))
  let action = 'SBDUpdate/'
  appLog(TraceLevels.LOG_EXT_TRACE, 'ETS bagUpdateSBDAsynch action: ' + action)
  if (request == null)
    // call error page
    return
  appLog(TraceLevels.LOG_EXT_TRACE, 'ETS bagUpdateSBDAsynch before fetch ')

  return fetchWithTimeout(action, request)
    .then((json) => {
      // no print data expected
      //  		state.set("bagIngested", "1");
      appFlow = store.getState().localData.appFlow
      let bagTagObject = store.getState().localData.prevBagtagObject
      let bagtag = bagTagObject.baseTagNo
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'asynchBagIngested finished successfully - appFlow: ' + appFlow + ' for bag: ' + bagtag
      )

      store.dispatch(updateLocalData('prevBagtagObject', ''))
      store.dispatch(updateLocalData('prevBagMeasurements', {}))
      if (appFlow == 10 && store.getState().localData.numberOfProcessedBags > 0 && config.waitForIngested) {
        delay(0.1).then(() => {
          getSBDAppMan().handleQuitProcess()
        })
      }
    })
    .catch((err) => {
      appLog(TraceLevels.LOG_EXT_TRACE, 'bagUpdateSBDAsynch Catch:' + err)
      store.dispatch(updateError(err.errCode, err.message, 'bagUpdateSBDAsynch'))
      navigate('error')
    })
}

function dispatchBag() {
  // ALL OK - dispatch the bag
  appLog(TraceLevels.LOG_EXT_TRACE, 'dispatchBag - call CC#RA')
  if (!isEmpty(store.getState().errorDetails)) {
    // handling outofservice
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'dispatchBag appFlow: ' +
        store.getState().localData.appFlow +
        'errorDeatails: ' +
        JSON.stringify(store.getState().errorDetails)
    )
    return
  }
  store.dispatch(updateLocalData('appFlow', 6))
  //	state.set("bagIngested", "0");
  //	state.set("IntrusionDuringDispatch", false);
  let dispatchTimeout = -20000
  if (!isNaN(config.dispatchTimeout)) dispatchTimeout = config.dispatchTimeout
  //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CC#RA', dispatchTimeout)
  getSBDAppMan().dispatchBHSXTimer = 0
  getDeviceManager().getDevice(deviceIds.AEA_BAGDROP).sendAEA('CC#RA', dispatchTimeout)
}

function updateActivatedBags(bagtag) {
  try {
    const activatedBagtags = store.getState().localData.activatedBagtags //state.getValueAsString("activatedBagtags");
    const totalBagsWeight = store.getState().localData.totalWeight //state.getValueAsNumber("totalBagsWeight");
    const currBagMeasurements = store.getState().localData.bagMeasurements //state.getArray("bagMeasurements");
    if (activatedBagtags) store.dispatch(updateLocalData('activatedBagtags', activatedBagtags + ',' + bagtag))
    else store.dispatch(updateLocalData('activatedBagtags', bagtag))
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'updateActivatedBags activatedBagtags ' + store.getState().localData.activatedBagtags
    )
    if (currBagMeasurements.length > 0) {
      const weight = parseFloat(currBagMeasurements[0])
      appLog(TraceLevels.LOG_EXT_TRACE, 'updateActivatedBags totalBagsWeight: ' + totalBagsWeight)
      store.dispatch(updateLocalData('totalWeight', totalBagsWeight + weight))
      appLog(TraceLevels.LOG_EXT_TRACE, 'updateActivatedBags: ' + bagtag + ' ' + weight)
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'updateActivatedBags error: bagMeasurements not found')
    }
  } catch (e) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'updateActivatedBags error: ' + e)
  }
}
