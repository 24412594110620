/**
 * Device ids
 */
export const deviceIds = {
  WEBSOCKET_MANAGER: 100,
  CARD_READER: 0,
  ATB_PRINTER: 1,
  PASSPORT_READER: 2,
  BARCODE_READER: 3,
  BAGTAG_PRINTER: 4,
  APPLICATION_MANAGER: 5,
  SCREEN_MANAGER: 6,
  RECEIPT_PRINTER: 7,
  DISPENSER: 8,
  PIN_PAD: 9,
  LASER_PRINTER: 10,
  UPS: 11,
  IO_CONTROLLER: 12,
  GENERIC: 13,
  PAYMENT: 14,
  BAG_CONVEYOR: 15,
  FACE_CAMERA: 16,
  AEA_BAGDROP: 17,
  FINGERPRINT_SCANNER: 18,
  CUSS_ACCESSIBILITY: 19,
  FACE_TRACKING: 20,
  LED: 21,
  IRIS_CAMERA: 22,
  EGATE: 23,
  VBD_MODE: 24,
  ICCARD_READER: 25,
  BAGGAGE_SCALE: 26,
  LOGGER: 99,
}
/**
 * Methods
 */
export const methods = {
  status: 1,
  isOK: 2,
  enable: 3,
  disable: 4,
  available: 5,
  initialize: 6,
  unavailable: 7,
  setState: 8,
  getKioskId: 10,
  getActualCUSSKioskId: 11,
  getSystemManagerKioskId: 12,
  getLocation: 13,
  getAirportCode: 14,
  getCurrentState: 15,
  sendApplicationLog: 20,
  sendApplicationStatusReasonIndicator: 21,
  sendApplicationStatusTransactionIndicator: 22,
  applicationTransfer: 23,
  sendAEA: 30,
  setupAEA: 31,
  read: 40,
  eject: 41,
  isDIP: 42,
  isSwipe: 43,
  isMotorized: 44,
  isMagneticStripe: 45,
  isCardInReader: 46,
  isCardPartiallyInserted: 47,
  capture: 48,
  isCardDispenser: 49,
  areAllBinsEmpty: 50,
  isTicketInHopper: 51,
  isTicketInPrinter: 52,
  isPECTABLoadedInPrinter: 53,
  loadPECTAB: 54,
  loadPECTABArray: 55,
  print: 56,
  printArray: 57,
  offer: 58,
  getBinsStatus: 59,
  isPassportInReader: 60,
  isPassportPartiallyInserted: 61,
  getStatusTTS: 62,
  getStatusKeypad: 63,
  getStatusHeadset: 64,
  blankDisplay: 65,
  showDisplay: 66,
  disableHeadset: 67,
  enableHeadset: 68,
  pause: 69,
  resume: 70,
  play: 71,
  stop: 72,
  resumeTouchScreen: 73,
  suppressTouchScreen: 74,
  isAccessibilitySupported: 75,
  isHeadsetAvailable: 76,
  isHeadsetInserted: 77,
  isKeypadAvailable: 78,
  isTTSAvailable: 79,
  getAvailableLanguages: 80,
  getDeviceHelpLanguages: 81,
  getKeypadEvents: 82,
  getKeypadType: 83,
  getKioskDeviceHelp: 84,
  getScreenDiagonal: 85,
  confirmAccessibleMode: 86,
  completeAccessibleMode: 87,
  disableKeypad: 88,
  enableKeypad: 89,
  disableTTS: 90,
  enableTTS: 91,
  isInAccessibleMode: 92,
  isPendingAccessibleMode: 93,
  setActiveWhenActive: 94,
  getHeadsetDeviceHelp: 95,
  getKeypadDeviceHelp: 96,
  printFileByteStream: 97,
  printFileByteStreamBase64: 97,
  printPhotoStream: 98,
  printPDFStream: 99,
  statusIsOK: 100,
  show: 101,
  hide: 102,
  analyze: 103,
  clearSharedData: 104,
  write: 105,
  reset: 106,
  setup: 107,
  getCompanyCode: 108,
  getAppName: 109,
  toggleView: 110,
  switchView: 111,
  selectCamera: 112,
  setPaxId: 113,
  focusApp: 114,
  getVersion: 115,
  getVendor: 116,
  getStreamingURL: 117,
  getEESFaceQualityAssessmentResult: 118,
  setDeviceLanguage: 120,
  getDeviceLanguage: 121,
  irisCameraCapture: 122,
  irisCameraEnroll: 123,
  irisCameraRecognize: 124,
  irisCameraDeleteDB: 125,
  openGate: 130,
  closeGate: 131,
  openGateForPax: 132,
  isGateInManualBoarding: 133,
  queryGateMode: 134,
  enableEmergencyMode: 135,
  disableEmergencyMode: 136,
  startWarningAlarm: 137,
  startStandardAlarm: 138,
  clearAlarm: 139,
  isGateInAlarm: 140,
  isPassengerInLaneAfterBarriersOpen: 141,
  isGateInVandalismState: 142,
  allowPaxToLeaveMantrapThroughEntry: 143,
  setWeightUnit: 160,
  getMaxWeight: 161,
  set: 165,
  getTransactionResult: 166,
  getPaymentState: 167,
  startTransaction: 168,
  cancelTransaction: 169,
  clearTransactionData: 170,
  setDefaultLanguage: 180,
  getDefaultLanguage: 181,
  getConfig: 198,
  sendVersion: 199,
  setLogger: 200,
  log: 201,
  parse: 300,
  click: 301,
}
/**
 * Application events
 */
export const AppEvents = {
  socketOpened: 0,
  notifyActive: 1,
  notificationText: 2,
}

/**
 * Activation Types
 */
export const ActivationTypes = {
  /** @desc Activation Type App Touch
   *  <br/>Value: 0
   *  @type {number} @static @final*/
  APP_TOUCH: 0,
  /** Activation Type App Card Swipe
   *  <br/>Value: 1
   *  @type {number} @static @final*/
  CARD_SWIPE: 1,
  /** Activation Type App Passport Swipe
   *  <br/>Value: 2
   *  @type {number} @static @final*/
  PASSPORT_SWIPE: 2,
  /** Activation Type App Ticket Insert
   *  <br/>Value: 3
   *  @type {number} @static @final*/
  TICKET_INSERT: 3,
  /** Unknown Activation Type
   *  <br/>Value: -100
   *  @type {number} @static @final*/
  UNKNOWN_ACTIVATION: -100,
}

/**
 * Trace levels
 */
export const TraceLevels = {
  LOG_SYSTEM: 0,
  /** error message */
  LOG_ALERT: 1,
  LOG_LEVEL2: 2,
  /** trace */
  LOG_TRACE: 3,
  LOG_LEVEL4: 4,
  /** extended trace */
  LOG_EXT_TRACE: 5,
  LOG_LEVEL6: 6,
  LOG_SECURE: 7,
}

/**
 * Application states
 */
export const ApplicationState = {
  /**
   * This is a defined application state. The initializing state is used to indicate the
   * application doing its start up routine. An application can only be in initialization
   * state ONCE.  Once it leaves the initialization state it cannot return to it without
   * restarting the application.
   * In a CUSS environment, this signifies the application is in the initializing state
   * and InitReq() as already been executed. To exit the initialization state, use notifyState()
   * to change to UNAVAILABLE or AVAILABLE state.
   * @type {number} @static @final value 1
   */
  INITIALIZE: 1,
  /**
   * This is a defined application state. The unavailable state is used to indicate
   * the application is unavailable. The application or platform can set the application
   * in an UNAVAILABLE state.
   *
   * These application state are sent with the event CHANGE_STATE or can be set using
   * notifyState() method.
   *
   * In a CUSS implementation, the UNAVAILABLE state is combination of several of the
   * CUSS states in one. The state that are hidden from the application are the UNAVAILALBE,
   * STOPPED, SUSPENDED and DISABLED states.
   *
   * @type {number} @static @final
   */
  UNAVAILABLE: 2,
  /**
   * This is a defined application state. The available state is used to indicate the
   * application is in service and available for use. The application or platform can set the
   * application in an AVAILABLE state.
   *
   * These application state are sent with the event CHANGE_STATE or can be set using notifyState() method.
   *
   * In a CUSS implementation, the AVAILABLE state is mapped to the AVAILABLE state in CUSS state.
   *
   * @type {number} @static @final
   */
  AVAILABLE: 3,
  /**
   * This is a defined application state. The active state is used to indicate the application
   * has focus and is currently in use. The application cannot set itself to be active state.
   * Only the platform can make an application ACTIVE. When a transaction has been completed,
   * it is expected that the application change states to either AVAILABLE or UNAVAILABLE using the
   * notifyState() method.
   *
   * The state change to active will be sent in the event CHANGE_STATE.
   *
   * In a CUSS implementation, the ACTIVE state is mapped to the ACTIVE state in CUSS state.
   * @type {number} @static @final
   */
  ACTIVE: 4,
}

/**
 * Card reader enable types
 */
export const CardReaderEnableType = {
  NO_SETUP: 0,
  PAYMENT_ISO: 1,
  DISCRETIONARY_ISO: 2,
  FOID_ISO: 3,
  PAYMENT_JIS2: 4,
  DISCRETIONARY_JIS2: 5,
  FOID_JIS2: 6,
}

/**
 * Promise codes
 */
export const PromiseCodes = {
  RESOLVE: 0,
  REJECT: 1,
  TIMEOUT: 'Promise timeout',
  UNEXPECTED: 'Promise unexpected',
}

/**
 * Event types (for statistics) as numbers
 */
export const TSDTypes = {
  SCREEN_INTERACTION: 0,
  SCREEN_FLOW: 1,
  DEVICE_INTERACTION: 2,
  APPLICATION_STATE: 3,
  HOST_INTERFACE: 4,
  BUSINESS_FLOW: 5,
}

/**
 * Event types (for statistics) as text
 */
export const transactionStateEvent = {
  0: 'screenInteractionEvent',
  1: 'screenFlowEvent',
  2: 'deviceInteractionEvent',
  3: 'appStateEvent',
  4: 'hostInterfaceEvent',
  5: 'businessFlowEvent',
}

/**
 * Screen interaction event types
 */
export const ScreenInteractionEventType = {
  ACTIVE_BUTTON_PRESSED: 'ACTIVE_BUTTON_PRESSED',
  BUTTON_ACTIVATED: 'BUTTON_ACTIVATED',
  NONINTERACTIVE_AREA_TOUCHED: 'NONINTERACTIVE_AREA_TOUCHED',
  INACTIVE_BUTTON_PRESSED: 'INACTIVE_BUTTON_PRESSED',
}

/**
 * Device interaction event types
 */
export const DeviceEventType = {
  OFFLINE: 'OFFLINE',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  MEDIA_INSERTED: 'MEDIA_INSERTED',
  MEDIA_REMOVED: 'MEDIA_REMOVED',
  MEDIA_DAMAGED: 'MEDIA_DAMAGED',
  DATA_READ: 'DATA_READ',
  JAMMED: 'JAMMED',
  OUT: 'OUT',
  NAVIGATION_KEY_PRESSED: 'NAVIGATION_KEY_PRESSED',
  INTRUSION: 'INTRUSION',
  HELP_LEVEL_INVOKED: 'HELP_LEVEL_INVOKED',
  HELP_LEVEL_PLAYED: 'HELP_LEVEL_PLAYED',
  HEADSET_INSERTED: 'HEADSET_INSERTED',
  HEADSET_REMOVED: 'HEADSET_REMOVED',
  PICTURE_TAKEN: 'PICTURE_TAKEN',
  PRINT_STARTED: 'PRINT_STARTED',
  PRINT_ENDED: 'PRINT_ENDED',
  EJECTED: 'EJECTED',
  LOADED: 'LOADED',
  PRESENT: 'PRESENT',
  PRINTED: 'PRINTED',
  STATUS_CHANGED: 'STATUS_CHANGED',
  DATA_ERROR: 'DATA_ERROR',
  FAILED: 'FAILED',
  INSERTED_INCOMPLETE: 'INSERTED_INCOMPLETE',
  ACCESSING_RF_CHIP: 'ACCESSING_RF_CHIP',
  FACE_DESCRIPTOR: 'FACE_DESCRIPTOR',
}

/**
 * Screen flow event types
 */
export const ScreenFlowEventType = {
  LOCAL_TRANSITION: 'LOCAL_TRANSITION',
  CONTROL_TRANSITION: 'CONTROL_TRANSITION',
  POPUP_START: 'POPUP_START',
  POPUP_END: 'POPUP_END',
  TIMEOUT: 'TIMEOUT',
}

/**
 * Application state event types
 */
export const AppStateEventType = {
  TXN_START: 'TXN_START',
  TXN_END: 'TXN_END',
  PLT_SESSION_TIMEOUT: 'PLT_SESSION_TIMEOUT',
  PLT_ACTIVE_STOPPED: 'PLT_ACTIVE_STOPPED',
  APP_AVAILABLE: 'APP_AVAILABLE',
  APP_UNAVAILABLE: 'APP_UNAVAILABLE',
  APP_ACTIVATED: 'APP_ACTIVATED',
  APP_DEACTIVATED: 'APP_DEACTIVATED',
}
/**
 * Host interface event types
 */
export const HostInterfaceEventType = {
  REQUEST: 'REQUEST',
  RESPONSE: 'RESPONSE',
  EXCEPTION: 'EXCEPTION',
}

/**
 * Environment info event types
 */
export const EnvInfoEventType = {
  OS_INFO: 'OS_INFO',
  CUSS_INFO: 'CUSS_INFO',
  APP_INFO: 'APP_INFO',
  CDS_INFO: 'CDS_INFO',
}

/**
 * Session info event types
 */
export const SessionInfoEventType = {
  SESSION_START: 'SESSION_START',
  SESSION_END: 'SESSION_END',
  CHKIN_SUMMARY: 'CHKIN_SUMMARY',
  SBD_SUMMARY: 'SBD_SUMMARY',
}

/**
 * Fingerprint scanner errors
 */
export const FingerPrintScannerErrors = {
  DATA_ERROR_FINGER_DO_NOT_FIT: 31,
  DATA_ERROR_NO_FINGERS_FOUND: 32,
  DATA_ERROR_WRONG_HAND: 33,
  DATA_ERROR_MISSING_FINGERS: 34,
  DATA_ERROR_NOTHING: 35,
  DATA_ERROR_UNIQNESS: 36,
  DATA_ERROR_SEQUENCE: 37,
  DATA_ERROR_QUALITY_CHECK: 38,
  DATA_ERROR_ROLL_WIDTH_RATIO: 39,
  DATA_ERROR_DISPLAY_GENERATION: 40,
  DATA_ERROR_IMAGE_GENERATION: 41,
  DATA_ERROR_API_CORE: 42,
  DATA_ERROR_API_PLUGIN: 43,
  DATA_ERROR_PROCESSING: 44,
  DATA_ERROR_TIMEOUT: 45,
  DATA_ERROR_ERROR_UNKNOWN: 46,
}

/**
 * Iris Camera errors
 */
export const IrisCameraErrors = {}

/**
 * Iris Camera languages
 */
export const IrisCameraLanguages = {
  OTHER: 0,
  ENGLISH: 1,
  KOREAN: 2,
  CHINESE: 3,
  SPANISH: 4,
  ARABIC: 5,
  PORTUGUESE: 6,
  RUSSIAN: 7,
  GERMAN: 8,
  FRENCH: 9,
  TURKISH: 10,
  JAPANESE: 11,
  HINDI: 12,
}

/**
 * VBD mode status
 */
export const VBDModeStatus = {
  VBD_MODE_AUTOMATIC: 0,
  VBD_MODE_MANUAL: 1,
  VBD_MODE_ERROR: 2,
}

/** Device manager log name */
export const DM_LOG = 'DevMan'
/** Default kiosk id */
export const KIOSK_ID_DEFAULT = 'KIOSKID'
/** Default kiosk location */
export const LOCATION_DEFAULT = 'LOC'
/** Default for return promise */
export const RETURN_PROMISE = true

/** EventDetailName */
export const EventTypeName = {
  ENVIRONMENT_DETAILS: 'ENVIRONMENT_DETAILS',
  SESSION_SUMMARY_DETAILS: 'SESSION_SUMMARY_DETAILS',
  SCREEN_INTERACTION_EVENT_DETAILS: 'SCREEN_INTERACTION_EVENT_DETAILS',
  SCREEN_FLOW_EVENT_DETAILS: 'SCREEN_FLOW_EVENT_DETAILS',
  DEVICE_INTERACTION_EVENT_DETAILS: 'DEVICE_INTERACTION_EVENT_DETAILS',
  APP_STATE_EVENT_DETAILS: 'APP_STATE_EVENT_DETAILS',
  HOST_INTERFACE_EVENT_DETAILS: 'HOST_INTERFACE_EVENT_DETAILS',
  BUSINESS_FLOW_EVENT_DETAILS: 'BUSINESS_FLOW_EVENT_DETAILS',
}
/** authentication steps */
export const AUTHENTICATION_STEPS = {
  START: 'START',
  CONFIRM: 'CONFIRM',
}
export const EGATE_MODE = {
  DEFAULT: 'DEFAULT',
  LOCKDOWN: 'LOCKDOWN',
  EMERGENCY: 'EMERGENCY',
  MANUAL_BOARDING: 'MANUAL_BOARDING',
  MAINTENANCE: 'MAINTENANCE',
  FREE_PASS: 'FREE_PASS',
}

export const PAYMENT_STATE = {
  UNAVAILABLE: 'STATE_UNAVAILABLE',
  INITIALIZING: 'STATE_INITIALIZING',
  WAITING: 'STATE_WAITING',
  READY: 'STATE_READY',
  PENDING: 'STATE_PENDING',
  SUCCESS: 'STATE_SUCCESS',
  FAILURE: 'STATE_FAILURE',
}

export const FaceQualityDecision = {
  //low quality because mask detected
  LOWQUALITY_WAREING_MASK_DETECTED: 'LOWQUALITY_WAREING_MASK_DETECTED',
  //low quality because bad face orientation
  LOWQUALITY_NON_FRONTAL_FACE_ORIENTATION: 'LOWQUALITY_NON_FRONTAL_FACE_ORIENTATION',
  //low quality because mouth is opened
  LOWQUALITY_OPEN_MOUTH_DETECTED: 'LOWQUALITY_OPEN_MOUTH_DETECTED',
  //low quality because dark glasses
  LOWQUALITY_DARK_GLASSES_DETECTED: 'LOWQUALITY_DARK_GLASSES_DETECTED',
  //low quality because image cropped
  LOWQUALITY_CROPPED: 'LOWQUALITY_CROPPED',
}

export const FaceQualityWarning = {
  //no warning
  NOWARNING: 'NOWARNING',
  //too small image
  TOO_SMALL_IMAGE: 'TOO_SMALL_IMAGE',
  //too large image
  TOO_LARGE_IMAGE: 'TOO_LARGE_IMAGE',
  //low quality because of distance between eyes is less the threshold
  TOO_FAR_CAPTURE: 'TOO_FAR_CAPTURE',
}

export const SlapQualityDecision = {
  //slap quality check could not be processed
  SLAP_UNDETERMINED_CHECK: 0,
  //record has at least on bad quality finger or the slap quality is under threshold
  SLAP_LOW_QUALITY_FINGER: 1,
  //slap quality check is OK
  SLAP_CHECK_OK: 2,
}
