import styled from 'styled-components'

const screenSize = {
  landscape1280: `(min-width: 1280px)`,
  landscape1024: `(min-width: 1024px) and (max-width: 1279px)`,
  portrait: `(max-width: 1023px)`
}

const flexbox = (props) => {
  let flexFlow = 'rows'
  let justifyContent = 'center'
  let alignItems = 'center'
  if (props) {
    if (props.flexFlow) {
      flexFlow = props.flexFlow
    }
    if (props.justifyContent) {
      justifyContent = props.justifyContent
    }
    if (props.alignItems) {
      alignItems = props.alignItems
    }
  }
  return `
    display: flex;
    flex-flow: ${flexFlow};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `
}
export const Page = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'flex-start', alignItems: 'center' })}
  text-align: center;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  color: ${(props) => props.theme.PrimaryFontColor || '#ddeff0'};
  background-color: ${(props) => props.theme.BackgroundColor || '#ddeff0'};
  ${(props) =>
    props.theme.BackgroundImage &&
    `
    background: url(${props.theme.BackgroundImage}) no-repeat center center fixed;
    background-size: cover;
  `}
`

export const MainHeader = styled.div`
  ${flexbox({ flexFlow: 'row', justifyContent: 'center', alignItems: 'center' })}
  box-sizing: border-box;
  width: 100%;
  padding: 20px 25px;
  color: ${(props) => props.theme.Header.color};
  background-color: ${(props) => props.theme.Header.bgColor};
  ${(props) => {
    return props.theme.Header.bgImage ? `background: url(${props.theme.Header.bgImage});` : ''
  }}
  background-repeat: no-repeat;
  background-size: cover;
  @media ${screenSize.landscape1280} {
    height: ${(props) => (props.theme.HeaderHeight.landscape ? props.theme.HeaderHeight.landscape : '')};
  }
  @media ${screenSize.landscape1024} {
    height: 80px;
  }
  @media ${screenSize.portrait} {
    ${flexbox({ flexFlow: 'row', justifyContent: 'center', alignItems: 'flex-start' })}
    height: ${(props) => (props.theme.HeaderHeight.landscape ? '' : props.theme.HeaderHeight.portrait)};
  }
`

export const AppTitle = styled.div`
  ${flexbox({ justifyContent: 'flex-end', alignItems: 'center' })}
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.appTitleColor};
  @media ${screenSize.landscape1280} {
    font-size: 58px;
    padding-right: 60px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 50px;
    padding-right: 50px;
  }
  @media ${screenSize.portrait} {
    font-size: 47px;
  }
`

export const PageContent = styled.div`
  ${flexbox({ justifyContent: 'center', alignItems: 'center' })}
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-flow: ${(props) => props.flexFlow};
  flex: 1;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  box-sizing: border-box;
  padding: ${(props) => props.padding};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.background};
`
PageContent.defaultProps = {
  flexFlow: 'column'
}
export const PageSubContent = styled.div`
  ${flexbox({ flexFlow: 'column' })}
  flex-flow: ${(props) => props.flexFlow};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  flex: ${(props) => (props.flex ? props.flex : 'none')};
  text-align: ${(props) => props.textAlign};
  box-sizing: border-box;
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
`
PageSubContent.defaultProps = {
  justifyContent: 'center',
  alignItem: 'center',
  textAlign: 'center',
  padding: '20px'
}

export const PageHeader = styled.div`
  ${flexbox({ flexFlow: `${(props) => props.flexFlow}` })}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-weight: 600;
  letter-spacing: 1.2px;
  color: ${(props) => props.theme.PrimaryFontColor};
  box-sizing: border-box;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  @media ${screenSize.landscape1280} {
    min-height: 90px;
    font-size: 50px;
    top: 0;
  }
  @media ${screenSize.landscape1024} {
    min-height: 80px;
    font-size: 90px;
    top: 0;
  }
  @media ${screenSize.portrait} {
    min-height: 90px;
    font-size: 40px;
    top: 80px;
  }
`

PageHeader.defaultProps = {
  color: '#1A3F70'
}

export const PageTitle = styled.div`
  ${flexbox({ flexFlow: `${(props) => props.flexFlow}` })}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-weight: ${(props) => props.fontWeight};
  white-space: pre-wrap;
  letter-spacing: 1.2px;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryFontColor)};
  align-items: ${(props) => props.alignItems};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.theme.TextJustifyContent};
  text-align: ${(props) => props.theme.TextAlign};
  text-transform: ${(props) => props.theme.TitleCase ? 'capitalize' : ''};
  @media ${screenSize.landscape1280} {
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.TitleFontSize)};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.landscape1024} {
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.TitleFontSize)};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.portrait} {
    min-height: ${(props) => props.minHeight};
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.TitleFontSize)};
  }
`
PageTitle.defaultProps = {
  //fontSize: '40px',
  flexFlow: 'column',
  minHeight: '80px',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600
}

export const PageSubTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend Regular';
  font-weight: 400;
  white-space: pre-wrap;
  letter-spacing: 0.8px;
  line-height: 40px;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryFontColor)};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.theme.TextJustifyContent};
  text-align: ${(props) => props.theme.TextAlign};
  @media ${screenSize.landscape1280} {
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.SubTitleFontSize)};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.landscape1024} {
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.SubTitleFontSize)};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.portrait} {
    min-height: ${(props) => props.minHeight};
    font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.SubTitleFontSize)};
    width: 100%;
  }
`
PageSubTitle.defaultProps = {
  //fontSize: '28px',
  minHeight: '80px',
  justifyContent: 'center',
  alignItems: 'flex-start'
}

export const PageText = styled.div`
  ${flexbox()}
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight};
  white-space: pre-wrap;
  letter-spacing: 0.8px;
  line-height: 40px;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryFontColor)};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  @media ${screenSize.landscape1280} {
    font-size: ${(props) => props.fontSize};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.landscape1024} {
    font-size: ${(props) => props.fontSize};
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.portrait} {
    min-height: ${(props) => props.minHeight};
    font-size: ${(props) => props.fontSize};
  }
`
PageText.defaultProps = {
  width: '100%',
  fontSize: '36px',
  minHeight: '80px'
}

export const DynamicImage = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'center', alignItems: 'center' })}
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  background-image: url(images/${(props) => props.imageName});
  background-position: ${(props) => props.backgroundPosition};
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${(props) => props.background};
`

DynamicImage.defaultProps = {
  backgroundPosition: 'center center'
}

export const Spacer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`
Spacer.defaultProps = {
  width: '1px',
  height: '1px'
}
export const ItineraryTitle = styled.div`
  ${flexbox({ flexFlow: 'column' })}
  width: 100%;
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    height: 100px;
  }
  @media ${screenSize.landscape1024} {
    height: 100px;
  }
  @media ${screenSize.portrait} {
    height: 60px;
  }
`

export const BagProcessingInnerSpan = styled.span`
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  @media ${screenSize.landscape1280} {
    font-size: 23px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 20px;
  }
  @media ${screenSize.portrait} {
    font-size: 3.1vw;
  }
`

export const PageFooter = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'flex-end', alignItems: 'center' })}
  width: 100%;
  background-color: ${(props) => props.theme.Footer.bgColor};
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  ${(props) => {
    return props.theme.Footer.bgImage ? `background: url(${props.theme.Header.bgImage});` : ''
  }}
  @media ${screenSize.landscape1280} {
    height: 240px;
  }
  @media ${screenSize.landscape1024} {
    height: 200px;
  }
  @media ${screenSize.portrait} {
    height: 320px;
  }
`

export const ErrorTitle = styled.div`
  ${flexbox({
    flexFlow: 'column',
    justifyContent: 'center'
  })}
  width: 90%;
  font-weight: bold;
  color: ${(props) => props.theme.PageTitleColor};
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    font-size: 40px;
    align-items: flex-start;
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.landscape1024} {
    font-size: 35px;
    align-items: flex-start;
    min-height: ${(props) => props.minHeight};
  }
  @media ${screenSize.portrait} {
    font-size: 36px;
    margin-top: 50px;
    align-items: center;
  }
`
ErrorTitle.defaultProps = {
  minHeight: '200px',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center'
}

export const ErrorSubTitle = styled.div`
  ${flexbox({
    flexFlow: 'column',
    justifyContent: 'center'
  })}
  width: 90%;
  color: ${(props) => props.theme.PageSubTitleColor};
  white-space: pre-wrap;
  min-height: ${(props) => props.minHeight};
  @media ${screenSize.landscape1280} {
    font-size: 36px;
    align-items: flex-start;
  }
  @media ${screenSize.landscape1024} {
    font-size: 30px;
    align-items: flex-start;
  }
  @media ${screenSize.portrait} {
    font-size: 36px;
    height: 140px;
    align-items: center;
  }
`
ErrorSubTitle.defaultProps = {
  alignItems: 'center',
  minHeight: '100px'
}

export const ErrorDetail = styled.div`
  ${flexbox({ flexFlow: 'column', alignItems: `${(props) => props.alignItems}` })}
  width: 90%;
  color: ${(props) => props.theme.PageSubTitleColor};
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    font-size: 24px;
    min-height: 100px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 24px;
    min-height: 80px;
  }
  @media ${screenSize.portrait} {
    font-size: 26px;
    text-align: center;
    min-height: 100px;
  }
`
ErrorDetail.defaultProps = {
  alignItems: 'center'
}

export const LanguageButton = styled.button`
  width: 298px;
  height: 64px;
  font-size: 26px;
  font-weight: normal;
  color: white;
  background-color: navy;
  margin: 0;
  border: none;
  background-image: url(/images/btn_Language_up.png);
`

export const ButtonBox = styled.div`
  ${flexbox({ flexFlow: 'row' })}
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  flex: ${(props) => (props.flex ? props.flex : 'auto')};
  text-align: ${(props) => props.textAlign};
  padding: 20px;
`

export const PopupTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-weight: 500;
  white-space: pre-wrap;
  letter-spacing: 0.8px;
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  @media ${screenSize.landscape1280} {
    font-size: 40px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 40px;
  }
  @media ${screenSize.portrait} {
    font-size: 40px;
  }
`
PopupTitle.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 0 20px 0'
}

export const PopupSubTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend Light';
  font-weight: 400;
  white-space: pre-wrap;
  letter-spacing: 0.6px;
  color: ${(props) => props.color};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  @media ${screenSize.landscape1280} {
    font-size: 30px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 30px;
  }
  @media ${screenSize.portrait} {
    font-size: 30px;
  }
`
PopupSubTitle.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center'
}
