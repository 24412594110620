/**
 * ETS Request and Response - checkin
 */

import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { ServiceNames, TransitionCodes, FunctionCodes } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { isNotEmpty, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer, clone } from 'utils/helper'
import { genericCommand } from './genericCommand'

function buildCheckin(checkinPax, checkinBags, fastPath) {
  return {
    sessionInfo: {
      etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
      emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
    },
    //    'transitionToEcho': null,
    jsonExtend: null,
    currentClientTime: 0,
    asyncRequested: false,
    checkinPassenger: checkinPax,
    checkinBaggage: checkinBags,
    fastPath: fastPath,
    checkinContractReturn: fastPath,
  }
}

export function checkin(checkinPax, checkinBags, fastPath) {
  // appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() request begins ... ')
  let request = buildCheckin(checkinPax, checkinBags, fastPath)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait')
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('/checkin/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))
          // dispatch(updateSessions('updateTransitionOption', json.etsResponse.checkinConfigOptions))
          // save itinerary info
          if (isNotEmpty(json.etsResponse.checkinContract)) {
            let itineraryInfo = store.getState().responses.itineraryInfo
            let updatedBagtagDetails = clone(itineraryInfo.passengerBagtagDetails, true)

            updatedBagtagDetails = updatedBagtagDetails.map((paxDetail) => {
              let matchContract = json.etsResponse.checkinContract.passengers.filter(
                (pax) => pax.ordinal === paxDetail.passenger.ordinal
              )
              let newBagTagDetail = []
              console.log('???', matchContract)
              if (matchContract.length > 0) {
                const contractBagTagList = matchContract[0].bagTagData
                const paxBagTagList = paxDetail.bagTags
                if (contractBagTagList.length > 0 && paxBagTagList.length > 0) {
                  let paxBagTagNoList = paxBagTagList.map((paxBagTag) => paxBagTag.baseTagNo)
                  let contractBagTagNoList = contractBagTagList.map((contractBagTag) => contractBagTag.baseTagNo)
                  let updateList = paxBagTagList.filter((paxBagTag) => {
                    const result = contractBagTagNoList.includes(paxBagTag.baseTagNo)
                    return !result
                  })
                  let remainList = contractBagTagList
                  // let undatedList = contractBagTagList.filter(contractBagTag => {
                  //   const result = paxBagTagNoList.includes(contractBagTag.baseTagNo)
                  //   return result
                  // })
                  // let remainList = paxBagTagList.filter(paxBagTag => {
                  //   const result = contractBagTagNoList.includes(paxBagTag.baseTagNo)
                  //   console.log('CHECKING', contractBagTagNoList, paxBagTag, result)
                  //   return !result
                  // })
                  console.log('??????', updateList, remainList)
                  newBagTagDetail = [...updateList, ...remainList]
                } else if (contractBagTagList.length > 0) {
                  newBagTagDetail = contractBagTagList
                } else if (paxBagTagList.length > 0) {
                  newBagTagDetail = paxBagTagList
                }
              } else {
                newBagTagDetail = paxDetail.bagTags
              }

              // if (matchContract.length > 0 && paxDetail.bagTags.length > 0) {
              //   paxDetail.bagTags.forEach(bagTag => {
              //     const matchBagtag = matchContract[0].bagTagData.filter(newBagTag => bagTag.baseTagNo === newBagTag.baseTagNo)
              //     if (matchBagtag.length > 0) {
              //       const newBagTagInfo = {...bagTag, ...matchBagtag[0]}
              //       newBagTagDetail.push(newBagTagInfo)
              //     } else {
              //       newBagTagDetail.push(bagTag)
              //     }
              //   })
              // } else if (matchContract.length > 0) {
              //   newBagTagDetail = matchContract[0].bagTagData
              // } else {
              //   newBagTagDetail = paxDetail.bagTags
              // }
              paxDetail.bagTags = newBagTagDetail
              return paxDetail
            })

            itineraryInfo.passengerBagtagDetails = updatedBagtagDetails
            dispatch(updateResponses('updateItinerary', itineraryInfo))
          }

          switch (json.transition) {
            case TransitionCodes.PRINT_BAGTAG:
              appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() receive response then dispatch print bagtag page. ')
              dispatch(getPrintStream('BAG_TAG'))
              break
            case TransitionCodes.GET_BAGTAG_PRINTSTREAM:
              appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() receive response then dispatch print bagtag page. ')
              dispatch(getPrintStream('BAG_TAG'))
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'checkin() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else {
          goToFetchGenericError('checkin', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'checkin()', 'error')
      })
  }
}
