import { deviceIds, TraceLevels, DM_LOG, KIOSK_ID_DEFAULT, LOCATION_DEFAULT } from 'constants/Constants'
import AppManager from 'devices/appManager'
import LinkSocket from 'linkSocket/linkSocket'
import BarcodeReader from 'devices/barcodeReader'
import CardReader from 'devices/cardReader'
import PassportReader from 'devices/passportReader'
import ATBPrinter from 'devices/ATBPrinter'
import BagtagPrinter from 'devices/BagtagPrinter'
import Accessibility from 'devices/accessibility'
import AEABagdrop from 'devices/AEABagdrop'
import FaceTracking from 'devices/faceTracking'
import LaserPrinter from 'devices/laserPrinter'
import FingerPrintScanner from 'devices/fingerPrintScanner'
import IrisCamera from 'devices/irisCamera'
import EGate from 'devices/eGate'
import VBDMode from 'devices/vbdMode'
import BaggageScale from 'devices/baggageScale'
import Payment from 'devices/payment'
import { getTime, isInteger } from 'utils/helper'

/**
 * The DeviceManager class allows the application to interact with hardware devices and CUSS platform.
 */
export default class DeviceManager {
  /**
   * @param {String} url to websocket server (CUSSAppLink runs the websocket server).
   * @param {number} port of the websocket server
   * @param {Boolean} noWebsocket - used for testing when no connection to the CUSS platform is required
   * @param {String} [defaultLocation] - only used in no CUSS environment
   */
  constructor(url, port, noWebsocket, defaultLocation = '') {
    /**
     * reference to CUSS application manager device {@link AppManager}. Use {@link getAppManager} function instead.
     * @type {AppManager}
     */
    this.appManager = null
    /**
     * Array of device objects
     * @type {Device[]}
     * @protected
     */
    this.devices = []
    /**
     * websocket callback for open, close, error used by {@link LinkSocket}
     * @type {function(event: Event)}
     * @protected
     */
    this.wsCallback = this.wsCallback.bind(this)
    /**
     * function used by port
     * @type {function()}
     */
    this.getMaxTraceLevel = this.getMaxTraceLevel.bind(this)
    /** callback function called when the websocket connection is established
     * @type {function()} */
    this.onConnected = null
    /**
     * flag indicating if the application Logger has been set (appManager)
     * @type {boolean}
     * @private
     */
    this.loggerSet = false
    /**
     * flag indicating if the device manager Logger has been set (devMan)
     * @type {boolean}
     * @private
     */
    this.dmLoggerSet = false
    /**
     * max trace level to send
     * @type {number}
     * @private
     */
    this.maxTraceLevelToSend = TraceLevels.LOG_SECURE
    /**
     * max length of the log message to send
     * @type {number}
     * @private
     */
    this.maxLogLthToSend = 1000
    /**
     * add time to log messages
     * @type {boolean}
     * @private
     */
    this.addTimeToTrace = true
    /**
     * if false then websocket will not be used - from constructor
     * @type {Boolean}
     * @private
     */
    this.noWebsocket = noWebsocket
    // buffered logging
    /**
     * Maximum messages to buffer when there is no websocket connection
     * @type {number}
     * @private
     */
    this.maxMessagesToBuffer = 100
    /**
     * Array of buffered log messages
     * @type {String[]}
     * @private
     */
    this.logBuffer = []
    /**
     * logging function for device manager
     * @type {function(level: number, msg: String, addTS: boolean)}
     */
    this.dmLog = this.dmLog.bind(this)
    /**
     * if true then memory usage will be logged
     * @type {boolean}
     * @private
     */
    this.logMemoryUsageFlag = false
    /**
     * reference to websocket
     * @type {LinkSocket}
     * @protected
     */
    this.socket = new LinkSocket(url, port, this.wsCallback, this.getMaxTraceLevel, noWebsocket, this.dmLog)

    /**
     * default location - only for no CUSS testing
     * @type {String}
     * @protected
     */
    this.defaultLoc = defaultLocation

    /**
     * reference to {@link TSDManager} class
     * @type {TSDManager}
     */
    this.TSDMan = null

    /** callback function called when the required device become OK
     * @type {function()} */
    this.onReqDeviceOK = null

    /**
     * timeout for promise (getCurrentStatus, statusIsOK)
     * @type {number}
     * @private
     */
    this.promiseTimeout = 1000

    /**@private*/
    this.defaultKioskId = KIOSK_ID_DEFAULT
    //    this.dmLog(TraceLevels.LOG_SYSTEM, '***** DeviceManager trace level: ' + this.maxTraceLevelToSend)
    // list of combined device ids, if at all devices in list are not OK then go to OOS even they are not required devices
    this.combinedDevices = []
  }

  /**
   * websocket callback for open, close, error used by {@link LinkSocket}
   * @type {function(event: Event)}
   * @callback
   * @protected
   */
  wsCallback(event) {
    if (event.type === 'open' && this.onConnected !== null) {      
      if (this.setLogger(DM_LOG, false)) this.dmLoggerSet = true
      this.dmLog(TraceLevels.LOG_SYSTEM, '***** DeviceManager ver: ' + this.getVersion())
      this.dmLog(TraceLevels.LOG_SYSTEM, '***** DeviceManager build date: ' + this.getBuildDate())
      this.dmLog(TraceLevels.LOG_SYSTEM, '***** Browser: ' + navigator.appVersion)
      this.onConnected()      
    }
  }

  /**
   * add devices to combinedDevices
   * @param {number[]} deviceIds - one of {@link src/constants/Constants.js~deviceIds}.
   */
  setCombinedDevices(deviceIds) {
    this.combinedDevices = deviceIds
  }

  /**
   * add devices used by the application
   * @param {number} deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @param {function(event: Event)} callback for device events and responses
   * @param {Boolean} isRequired - required or optional device
   */
  addDevices(deviceId, callback, isRequired) {
    switch (deviceId) {
      case deviceIds.BARCODE_READER:
        let barcodeReader = new BarcodeReader(this.socket, this, 'BARCODE_READER')
        barcodeReader.OnDeviceEvent = callback
        barcodeReader.IsRequired = isRequired
        barcodeReader.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(barcodeReader)
        break
      case deviceIds.CARD_READER:
        let cardReader = new CardReader(this.socket, this, 'CARD_READER')
        cardReader.OnDeviceEvent = callback
        cardReader.IsRequired = isRequired
        cardReader.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(cardReader)
        break
      case deviceIds.PASSPORT_READER:
        let passportReader = new PassportReader(this.socket, this, 'PASSPORT_READER')
        passportReader.OnDeviceEvent = callback
        passportReader.IsRequired = isRequired
        passportReader.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(passportReader)
        break
      case deviceIds.ATB_PRINTER:
        let atpPrinter = new ATBPrinter(this.socket, this, 'ATB_PRINTER')
        atpPrinter.OnDeviceEvent = callback
        atpPrinter.IsRequired = isRequired
        atpPrinter.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(atpPrinter)
        break
      case deviceIds.BAGTAG_PRINTER:
        let bagtagPrinter = new BagtagPrinter(this.socket, this, 'BAGTAG_PRINTER')
        bagtagPrinter.OnDeviceEvent = callback
        bagtagPrinter.IsRequired = isRequired
        bagtagPrinter.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(bagtagPrinter)
        break
      case deviceIds.AEA_BAGDROP:
        let aeaBagdrop = new AEABagdrop(this.socket, this, 'AEA_BAGDROP')
        aeaBagdrop.OnDeviceEvent = callback
        aeaBagdrop.IsRequired = isRequired
        aeaBagdrop.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(aeaBagdrop)
        break
      case deviceIds.BAG_CONVEYOR:
        break
      case deviceIds.DISPENSER:
        break
      case deviceIds.CUSS_ACCESSIBILITY:
        let accessibility = new Accessibility(this.socket, this, 'CUSS_ACCESSIBILITY')
        accessibility.OnDeviceEvent = callback
        accessibility.IsRequired = isRequired
        accessibility.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(accessibility)
        break

      case deviceIds.FACE_TRACKING:
        let faceTracking = new FaceTracking(this.socket, this, 'FACE_TRACKING')
        faceTracking.OnDeviceEvent = callback
        faceTracking.IsRequired = isRequired
        faceTracking.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(faceTracking)
        break

      case deviceIds.LASER_PRINTER:
        let laserPrinter = new LaserPrinter(this.socket, this, 'LASER_PRINTER')
        laserPrinter.OnDeviceEvent = callback
        laserPrinter.IsRequired = isRequired
        laserPrinter.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(laserPrinter)
        break

      case deviceIds.FINGERPRINT_SCANNER:
        let fingerPrintScanner = new FingerPrintScanner(this.socket, this, 'FINGERPRINT_SCANNER')
        fingerPrintScanner.OnDeviceEvent = callback
        fingerPrintScanner.IsRequired = isRequired
        fingerPrintScanner.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(fingerPrintScanner)
        break

      case deviceIds.IRIS_CAMERA:
        let irisCamera = new IrisCamera(this.socket, this, 'IRIS_CAMERA')
        irisCamera.OnDeviceEvent = callback
        irisCamera.IsRequired = isRequired
        irisCamera.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(irisCamera)
        break

      case deviceIds.EGATE:
        let eGate = new EGate(this.socket, this, 'EGATE')
        eGate.OnDeviceEvent = callback
        eGate.IsRequired = isRequired
        eGate.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(eGate)
        break
      case deviceIds.VBD_MODE:
        let vbdMode = new VBDMode(this.socket, this, 'VBD_MODE')
        vbdMode.OnDeviceEvent = callback
        vbdMode.IsRequired = isRequired
        vbdMode.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(vbdMode)
        break
      case deviceIds.BAGGAGE_SCALE:
        let baggageScale = new BaggageScale(this.socket, this, 'BAGGAGE_SCALE')
        baggageScale.OnDeviceEvent = callback
        baggageScale.IsRequired = isRequired
        baggageScale.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(baggageScale)
        break
      case deviceIds.PAYMENT:
        let payment = new Payment(this.socket, this, 'PAYMENT')
        payment.OnDeviceEvent = callback
        payment.IsRequired = isRequired
        payment.setPromiseTimeout(this.promiseTimeout)
        this.devices.push(payment)
        break
    }
  }

  /**
   * Create AppManager and callback functions
   * @param {function(event: Event)} cbOnChange - callback function onChange
   * @param {function()} cbOnActive - callback function on Active
   * @param {function(event: Event)} cbOnEvent - callback function of event or response
   */
  setAppManager(cbOnChange, cbOnActive, cbOnEvent) {
    let appMgr = new AppManager(this.socket, this)
    appMgr.OnChange = cbOnChange
    appMgr.OnActive = cbOnActive
    appMgr.OnDeviceEvent = cbOnEvent
    appMgr.setPromiseTimeout(this.promiseTimeout)
    this.appManager = appMgr
  }

  /**
   * Connect to websocket server
   */
  connect() {
    if (this.noWebsocket === true) {
      console.log('DeviceManager::connect(): noWebsocket.')
      // emulate socket connected
      this.onConnected()
      // emulate onSocketOpened
      // set default kioskID and location
      this.appManager.kioskId = 'YYZTEST' //dmConfig.kioskId ? dmConfig.kioskId : KIOSK_ID_DEFAULT
      this.appManager.cussKioskId = KIOSK_ID_DEFAULT
      this.appManager.smKioskId = KIOSK_ID_DEFAULT
      this.appManager.location = this.defaultLoc ? this.defaultLoc : LOCATION_DEFAULT
      this.appManager.airportCode = this.defaultLoc ? this.defaultLoc : LOCATION_DEFAULT
      // set all devices to OK
      for (let i = 0; i < this.devices.length; i++) {
        this.devices[i].isDeviceOk = true
      }
      this.appManager.onSocketOpened()
    } else {
      this.socket.open()
    }
  }

  /**
   * close the websocket
   */
  disConnect() {
    if (this.socket.isOpened) {
      this.socket.close()
    }
  }

  /**
   * Returns connection status to the websocket
   * @returns {boolean} true when websocket is connected
   */
  isConnected() {
    return this.socket.isOpened
  }

  /**
   * Returns device object based on device Id
   * @param deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @returns {Device} or null when deviceId was not added yet
   */
  getDevice(deviceId) {
    let dev = null
    this.devices.map((d) => {
      if (d.deviceId === deviceId) dev = d
    })
    return dev
  }

  /**
   * Returns AppManager
   * @returns {AppManager}
   */
  getAppManager() {
    return this.appManager
  }

  /**
   * Set the application logger
   * @param {String} name - name of the logger
   * @param {boolean} defaultLogger
   * @returns {boolean} return true when the logger is set otherwise return false
   */
  setLogger(name, defaultLogger) {
    if (this.socket.isOpened) {
      this.socket.sendCommand(deviceIds.LOGGER, 'setLogger', name, defaultLogger)
      this.loggerSet = true
      return true
    } else if (this.noWebsocket === true) {
      //console.log('setLogger - emulation')
      return true
    } else {
      console.log('setLogger - socket is not opened')
      return false
    }
  }

  /**
   * Send the message to the default logger
   * @param {number} level - one of {@link src/constants/Constants.js~TraceLevels}.
   * @param {String} msg - message to log.
   * @returns {boolean} true when the message was sent
   */
  sendLogMsg(level, msg) {
    if (this.socket.isOpened) {
      if (Array.isArray(msg)) this.socket.sendCommand(deviceIds.LOGGER, 'logs', level, msg)
      else this.socket.sendCommand(deviceIds.LOGGER, 'log', level, msg)

      return true
    } else if (this.noWebsocket === true) {
      //console.log('sendLogMsg: ' + msg)
      return true
    } else {
      console.log('sendLogMsg (socket is not opened) AppMsg: ' + msg)
      return false
    }
  }

  /**
   * Send the message to the specific logger
   * @param {String} logName - name of log file
   * @param {number} level - one of {@link src/constants/Constants.js~TraceLevels}.
   * @param {String} msg - message to log.
   * @returns {boolean} true when the message was sent
   */
  sendNamedLogMsg(logName, level, msg) {
    if (this.socket.isOpened) {
      return this.socket.sendCommand(deviceIds.LOGGER, 'log', logName, level, msg)
    } else if (this.noWebsocket === true) {
      //console.log('sendLogMsg: ' + msg)
      return true
    } else {
      console.log('sendNamedLogMsg (socket is not opened) AppMsg: ' + msg)
      return false
    }
  }
  /**
   * Returns status of the logger
   * @returns {boolean} Returns true when the logger is set otherwise returns false
   */
  isLoggerSet() {
    return this.loggerSet
  }

  /**
   * Sets the maximum trace level
   * @param {number} level - one of {@link src/constants/Constants.js~TraceLevels}.
   */
  setMaxTraceLevel(level) {
    this.maxTraceLevelToSend = level
  }

  /**
   * Gets the maximum trace level
   * @returns {number} maximum trace lavel
   */
  getMaxTraceLevel() {
    return this.maxTraceLevelToSend
  }

  /**
   * Sets the maximum length of the log message. Longer messages will be truncated to this value.
   * @param {number} lth - maximum log message
   */
  setMaxLogLth(lth) {
    if (isInteger(lth)) this.maxLogLthToSend = lth
  }

  /**
   * Controls adding timestamp to the log message.
   * @param {boolean} flag - if true than the local timestamp will be added to the log message.
   */
  setAddTimeToTrace(flag) {
    this.addTimeToTrace = flag
  }

  /**
   * Sets the maximum number of messages to be stored in the internal log buffer when websocket is not yet opened.
   * @param {number} max - maximum of log messages in the temp buffer.
   */
  setMaxMessagesToBuffer(max) {
    if (isInteger(max)) this.maxMessagesToBuffer = max
  }

  /**
   * Sets the default timeout for the websocket requests
   * @param {number} to - timeout for websocket requests
   */
  setDefaultTimeout(to) {
    if (isInteger(to)) {
      if (this.socket) {
        this.socket.setDefaultTimeout(to)
      }
    }
  }

  /**
   *  Sets the device promise timeout.
   *  @param {Number} timeout - Promise timeout.
   */
  setPromiseTimeout(timeout) {
    this.promiseTimeout = timeout
  }

  /**
   * Checks if all required devices are OK.
   * @param {boolean} full - when false then the check will be short-circuited.
   * @returns {boolean} returns true when all devices are OK otherwise returns false.
   */
  areAllRequiredDevicesOK(full) {
    let ok = true
    for (let i = 0; i < this.devices.length; i++) {
      this.dmLog(
        TraceLevels.LOG_SYSTEM,
        'areAllRequiredDevicesOK name: ' +
          this.devices[i].name +
          ' isRequired: ' +
          this.devices[i].isRequired +
          ' isDeviceOk: ' +
          this.devices[i].isDeviceOk
      )
      if (this.devices[i].isRequired && !this.devices[i].isDeviceOk) {
        ok = false
        if (!full) break
      }
    }
    return ok
  }

  /**
   * Sets the TSDManager and links TSDManager logger to the DeviceManager logger.
   * @param {TSDManager} tsdman {@link TSDManager}.
   */
  setTSDManager(tsdman) {
    this.TSDMan = tsdman
    this.TSDMan.setLogger(this.dmLog)
  }

  /**
   * Adds the device interaction event to the TSDManager (if defined)
   * @param {String} eventType - response or event name received from the device.
   * @param {number} deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @param {String} deviceName - device name as defined in the device class (e.g.: 'Barcode Reader')
   * @param {String} [code] - response or event value received from the device.
   */
  tsdDeviceEvent(eventType, deviceId, deviceName, code) {
    if (this.TSDMan != null) {
      this.TSDMan.addDeviceInterEvent(eventType, deviceId, deviceName, code)
    }
  }

  /**
   * Set callback for onReqDeviceOK (required device become OK)
   * @type {function(string: deviceName)}
   */
  setOnRequiredDeviceOK(cbOnRequiredDeviceOK) {
    this.onReqDeviceOK = cbOnRequiredDeviceOK
  }

  /**
   log memory usage -chrome (works with --enable-precise-memory-info)
     --enable-precise-memory-info Make the values returned to window.performance.memory more granular
     and more up to date in shared worker. Without this flag, the memory information is still
     available, but it is bucketized and updated less frequently. This flag also apply to workers.
   @private
  */
  logMemoryUsage() {
    const oneMB = 1048576
    if (!this.logMemoryUsageFlag) {
      return
    }
    if (!window.performance || !window.performance.memory) {
      return
    }
    this.dmLog(
      TraceLevels.LOG_EXT_TRACE,
      '---> totalJSHeapSize: ' +
        performance.memory.totalJSHeapSize / oneMB +
        ' usedJSHeapSize: ' +
        performance.memory.usedJSHeapSize / oneMB +
        ' jsHeapSizeLimit: ' +
        performance.memory.jsHeapSizeLimit / oneMB
    )
  }

  /**
   * Controls logging memory usage in Chrome.
   * @param {boolean} logMemory - true to use Chrome feature to get memory usage
   */
  setLogMemoryUsage(logMemory) {
    this.logMemoryUsageFlag = logMemory
  }
  /**
   * Device manager logger. The message will be sent to websocket when the trace level is not greater than defined maximum.
   * When the log message is longer than defined maximum then it will be truncated to the max length.
   * When websocket is not opened yet then message will be stored in the internal buffer and sent later when websocket is opened.
   * @param {number} level - one of {@link src/constants/Constants.js~TraceLevels}.
   * @param {String} msg - message to log.
   * @param {boolean} [addTS] - if true then local timestamp will be added to the message
   * @protected
   */
  dmLog(level, msg, addTS) {
    if (level <= this.maxTraceLevelToSend) {
      //check message length - if too long cut it
      if (msg.length > this.maxLogLthToSend) msg = msg.substring(0, this.maxLogLthToSend) + ' ...'

      if (this.isConnected() && this.dmLoggerSet) {
        // check if there are buffered messages first */
        if (this.logBuffer.length > 0) {
          // process stored messages first
          this.logBuffer.push(getTime() + ' DM End of buffered messages:')
          this.socket.sendCommand(deviceIds.LOGGER, 'logs', DM_LOG, level, this.logBuffer)
          this.logBuffer.length = 0 // clear the buffer
          console.log('DM End of buffered messages.')
        }

        if (this.addTimeToTrace) msg = getTime() + ' ' + msg
        else if (addTS === true) msg = getTime() + ' ' + msg
        //        console.log('DM dmlog: ' + msg)  //testonly
        this.socket.sendCommand(deviceIds.LOGGER, 'log', DM_LOG, level, msg)
      } else if (this.maxMessagesToBuffer > 0) {
        if (this.logBuffer.length < this.maxMessagesToBuffer) {
          if (this.logBuffer.length === 0) {
            this.logBuffer.push(getTime() + ' DM Start of buffered messages:')
            console.log('DM Start of buffered messages.')
          }
          this.logBuffer.push(getTime() + ' ' + msg)
          console.log('DM BF: ' + msg)
        } else if (this.logBuffer.length === this.maxMessagesToBuffer) {
          this.logBuffer.push(getTime() + ' DM msg buffer exceeded!')
          console.log('DM msg buffer exceeded!')
        } else {
          console.log('DM no room to buffer: ' + msg)
        }
      } else console.log('DM dmlog: ' + msg)
    }
  }

  /**
   * Set default kiosk id - used when CUSS is not used.
   * @param {String} defaultKioskId - the kiosk id is for no CUSS testing
   */
  setDefaultKioskId(defaultKioskId) {
    this.defaultKioskId = defaultKioskId
  }

  /*global VERSION*/
  /**
   * Returns the version of the device manager. Retrieved from package.json during the build.
   * @returns {String} version
   */
  getVersion() {
    return VERSION
  }

  /**
   * Return the build date
   * @returns {String} build date
   */
  /*global BUILD_DATE*/
  getBuildDate() {
    return '"' + BUILD_DATE + '"'
  }
}
