import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** eGate listener.
 *  @extends {Listener}
 */
export default class EGateListener extends Listener {
  /**
   *  Default openGateForPax callback.
   *  @param {number} data return code from setup
   */
  openGateForPax(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.openGateForPax() called')
    this.setEvent('openGateForPax', data)
  }

  /**
   *  Default eGatePassengerFraud callback ( occurs when there is a pax fraud (eg. tailgating, crossover etc).
   *  @param {int} data error type
   */
  eGatePassengerFraud(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerFraud() called')
    this.setEvent('eGatePassengerFraud', data)
  }

  /**
   *  Default eGatePassengerEntered callback.
   */
  /* eGatePassengerEntered() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerEntered() called')
    this.setEvent('eGatePassengerEntered', null)
  } */

  /**
   *  Default eGatePassengerBlockedEntry callback.
   */
  eGatePassengerBlockedEntry() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerBlockedEntry() called')
    this.setEvent('eGatePassengerBlockedEntry', null)
  }

  /**
   *  Default eGatePassengerBlockedExit callback.
   */
  eGatePassengerBlockedExit() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerBlockedExit() called')
    this.setEvent('eGatePassengerBlockedExit', null)
  }

  /**
   *  Default eGatePassengerClearedEntry callback.
   */
  eGatePassengerClearedEntry() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerClearedEntry() called')
    this.setEvent('eGatePassengerClearedEntry', null)
  }

  /**
   *  Default eGatePassengerClearedExit callback.
   */
  eGatePassengerClearedExit() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerClearedExit() called')
    this.setEvent('eGatePassengerClearedExit', null)
  }

  /**
   *  Default eGatePassengerLeft callback.
   */
  eGatePassengerLeft() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerLeft() called')
    this.setEvent('eGatePassengerLeft', null)
  }

  /**
   *  Default eGatePassengerMoved callback.
   */
  eGatePassengerMoved() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerMoved() called')
    this.setEvent('eGatePassengerMoved', null)
  }

  /**
   *  Default eGateDoorException callback due to gate vandalism.
   *  @param {int} data error type
   */
  eGateDoorException(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateDoorException() called')
    this.setEvent('eGateDoorException', data)
  }

  /**
   *  Default eGateDoorExceptionCleared callback due to clear gate vandalism.
   */
  eGateDoorExceptionCleared() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateDoorExceptionCleared() called')
    this.setEvent('eGateDoorExceptionCleared', null)
  }

  eGatePaxStartedPassage() {
    {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePaxStartedPassage() called')
      this.setEvent('eGatePaxStartedPassage', null)
    }
  }

  /**
   *  Default eGateCriticalError callback.
   */
  eGateCriticalError() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateCriticalError() called')
    this.setEvent('eGateCriticalError', null)
  }

  /**
   *  Default eGateLostConnection callback.
   */
  eGateLostConnection() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateLostConnection() called')
    this.setEvent('eGateLostConnection', null)
  }

  /**
   *  Default eGatePaxProcessedOK callback.
   */
  eGatePaxProcessedOK() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePaxProcessedOK() called')
    this.setEvent('eGatePaxProcessedOK', null)
  }

  /**
   *  Default eGatePaxProcessedError callback.
   */
  eGatePaxProcessedError() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePaxProcessedError() called')
    this.setEvent('eGatePaxProcessedError', null)
  }

  /**
   *  Default eGateClosed callback.
   */
  eGateClosed() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateClosed() called')
    this.setEvent('eGateClosed', null)
  }

  /**
   *  Default eGateClosed callback.
   */
  eGateOpened() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateOpened() called')
    this.setEvent('eGateOpened', null)
  }

  /**
   *  Default eGateStatusChange callback.
   */
  eGateStatusChange() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateStatusChange() called')
    this.setEvent('eGateStatusChange', null)
  }

  /**
   *  Default eGateUnknownState callback.
   *  @param {int} state unknown
   */
  eGateUnknownState(state) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateUnknownState() called')
    this.setEvent('eGateUnknownState', state)
  }

  /**
   *  Default isGateInManualBoarding callback.
   *  @param {int} data flag which can be 1 or 0
   */
  isGateInManualBoarding(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.isGateInManualBoarding() called')
    this.setEvent('isGateInManualBoarding', data)
  }

  /**
   *  passenger turn back callback.
   */
  eGatePassengerTurnback() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerTurnback() called')
    this.setEvent('eGatePassengerTurnback', null)
  }

  /**
   *  egate mode has been changed to
   *  @param constant.EGATE_MODE
   */
  eGateModeChange(mode) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateModeChanged() called')
    this.setEvent('eGateModeChange', mode)
  }

  /**
   *  eGate Emergency Fire Alarm callback
   */
  eGateEmergencyFireAlarm() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateEmergencyFireAlarm() called')
    this.setEvent('eGateEmergencyFireAlarm', null)
  }

  /**
   *  eGate entry opened
   */
  eGateOpenedEntry() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateOpenedEntry() called')
    this.setEvent('eGateOpenedEntry', null)
  }

  /**
   *  eGate exit opened
   */
  eGateOpenedExit() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateOpenedExit() called')
    this.setEvent('eGateOpenedExit', null)
  }

  /**
   *  eGate entry closed
   */
  eGateClosedEntry() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateClosedEntry() called')
    this.setEvent('eGateClosedEntry', null)
  }

  /**
   *  eGate exit closed
   */
  eGateClosedExit() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateClosedExit() called')
    this.setEvent('eGateClosedExit', null)
  }

  /**
   *  eGate pax in mantrap
   */
  eGatePassengerInMantrap() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerInMantrap() called')
    this.setEvent('eGatePassengerInMantrap', null)
  }

  /**
   *  eGate exit door exception
   */
  eGateExitDoorException() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateExitDoorException() called')
    this.setEvent('eGateExitDoorException', null)
  }

  /**
   *  eGate exit door exception is cleared
   */
  eGateExitDoorExceptionCleared() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGateExitDoorExceptionCleared() called')
    this.setEvent('eGateExitDoorExceptionCleared', null)
  }

  /**
   *  eGate fraud jump in mantrap detected
   */
  eGatePassengerFraudJumpInMantrap() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerFraudJumpInMantrap() called')
    this.setEvent('eGatePassengerFraudJumpInMantrap', null)
  }

  /**
   *  eGate fraud jump out mantrap detected
   */
  eGatePassengerFraudJumpOutMantrap() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerFraudJumpOutMantrap() called')
    this.setEvent('eGatePassengerFraudJumpOutMantrap', null)
  }

  /**
   *  eGate an Object detected in mantrap
   */
  eGatePassengerLeftObjectInMantrap() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerLeftObjectInMantrap() called')
    this.setEvent('eGatePassengerLeftObjectInMantrap', null)
  }

  /**
   *  eGate passenger can press in mantrap to ask for help
   */
  eGatePassengerRequestedMantrapAssistance() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.eGatePassengerRequestedMantrapAssistance() called')
    this.setEvent('eGatePassengerRequestedMantrapAssistance', null)
  }

  queryGateMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.queryGateMode() called')
    this.setEvent('queryGateMode', response)
  }
  enableEmergencyMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.enableEmergencyMode() called')
    this.setEvent('enableEmergencyMode', response)
  }
  disableEmergencyMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.disableEmergencyMode() called')
    this.setEvent('disableEmergencyMode', response)
  }
  startWarningAlarm(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.startWarningAlarm() called')
    this.setEvent('startWarningAlarm', response)
  }
  startStandardAlarm(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.startStandardAlarm() called')
    this.setEvent('startStandardAlarm', response)
  }
  clearAlarm(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.clearAlarm() called')
    this.setEvent('clearAlarm', response)
  }
  isPassengerInLaneAfterBarriersOpen(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.isPassengerInLaneAfterBarriersOpen() called')
    this.setEvent('isPassengerInLaneAfterBarriersOpen', response)
  }
  isGateInAlarm(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.isGateInAlarm() called')
    this.setEvent('isGateInAlarm', response)
  }
  isGateInVandalismState(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'EGateListener.isGateInVandalismState() called')
    this.setEvent('isGateInVandalismState', response)
  }
}
