import React, { useRef, useContext, useEffect } from 'react'
import { useSelector, connect, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { FunctionCodes, ServiceNames, END_TXN_REASON, TOTAL_WEIGHT_UNIT } from 'constants/Constants'
import usePopup from 'hooks/usePopup'
import useUIBase from 'hooks/ui/useUIBase'
import { PageHeader, PageTitle, PageSubTitle, DynamicImage } from 'components/styledComponents'
import { playSound, history, getBuildAccessibility } from 'main'
import { Footer } from '../footer'

const ConfirmTotalWeight = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  // const totalWeightLimit = useSelector((state) => state.localData.totalWeightLimit)
  const totalWeightLimit = { totalWeightLimit: 0, unit: 'WEIGHT_KG' }
  const exceedLimitPopupRef = useRef(null)
  const exceedLimitPopupProps = {
    popupRef: exceedLimitPopupRef,
    contentText: intl.formatMessage(messages.ConfirmTotalWeightQuestion, {
      weight: totalWeightLimit.totalWeightLimit + ' ' + TOTAL_WEIGHT_UNIT[totalWeightLimit.unit]
    }),
    buttons: [
      { id: 'btnYes', text: intl.formatMessage(messages.buttonYes) },
      { id: 'btnNo', text: intl.formatMessage(messages.buttonNo) }
    ]
  }
  const [exceedLimitPopup, showExceedLimitPopup] = usePopup(exceedLimitPopupProps)
  const title = intl.formatMessage(messages.TotalWeightTitle)
  const subtitle = intl.formatMessage(messages.TotalWeightQuestion, {
    weight: totalWeightLimit.totalWeightLimit + ' ' + TOTAL_WEIGHT_UNIT[totalWeightLimit.unit]
  })

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'ConfirmTotalWeight',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [[title, subtitle].join('. ')] },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonConfirm), buttonId: 'confirmBtn' },
          { id: 'cancelBtn', text: intl.formatMessage(messages.buttonDecline), buttonId: 'cancelBtn' }
        ]
      }
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const location = `baggage.svg`
  const animationSection = (
    <DynamicImage imageName={location} cssName={'emb_animation_drawbox'} width={450} height={400} />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {subtitle}
      </PageSubTitle>
    </>
  )

  const header = <PageHeader>{intl.formatMessage(messages.TotalWeightHeader)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={!appMode}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isCancelRequired={true}
      isLangRequired={true}
      cancelBtnText={intl.formatMessage(messages.buttonDecline)}
      cancelAction={() => {
        playSound.beepOK()
        showExceedLimitPopup()
      }}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={() => {
        playSound.beepOK()
        dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_CONFIRM_WEIGHT_LIMIT))
      }}
    />
  )

  const popupSection = <>{exceedLimitPopup()}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ConfirmTotalWeight
