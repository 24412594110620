import {
  getDeviceManager,
  getTSDManager,
  history,
  getSBDAppMan,
  getEventLogger,
  getVersion,
  getSummaryStore
} from 'main'
import { deviceIds, TraceLevels, SessionInfoEventType } from '../embross-device-manager'
import { updateError, updateLocalError } from '../actions/commonActions'
import { updateLocalData, setBagProcessStatus } from '../actions/localActions'
import {
  completeCUSSTransaction,
  sendErrorLog,
  getEndTxtState,
  doETSTransactionEnd,
  handleAppTransfer
} from '../utils/appTransactions'
import { callBagUpdate, callBagUpdateAsynch } from '../actions/etsTransactions/bagUpdateSBD'
import AEACommand from '../utils/AEACommand'
import {
  delay,
  isEmpty,
  formatBagTagNumber,
  formatBagTagDisplay,
  goToLocalGenericError,
  goToLocalError,
  navigate,
  findOrdinalByTag,
} from 'utils/helper'
import {
  BAG_TXN_NOMORE,
  SBDUpdateAction,
  BaggageStatus,
  RETRY,
  ETS_TXN_STATUS,
  TYPE_KIOSK,
  PRINT_DOC_TYPE
} from '../constants/Constants'
import { ErrCodes } from '../constants/Errors'
import { appLog, logEvent, sendEventLog } from 'utils/Logger'
import { buildPrintTemplate } from '../utils/localPrintTemplate'
import { startCUSSTransaction } from 'utils/appTransactions'
import { startEtsTransaction } from 'actions/etsTransactions/sessionAct'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { AEABagdropOnEvent } from 'devices/callbacks'
import { v4 as uuidv4 } from 'uuid'

export default class AEASBDAppManager {
  constructor(store) {
    this.store = store
    this.bagdropLimits = new Object()
    this.appFlow = -2
    this.inIngestLoop = false
    this.SQStart = -1
    this.BQStart = 0
    this.BHSXStart = 0
    this.dispatchBHSXTimer = 0 //used for max waiting time when dispatch bag but BHS is busy
    this.IntrusionStart = 0
    this.PutBagStart = 0
    this.IntrusionDuringDispatch = false
    this.bagRepositionCount = 0
    this.bagMaxWeight = 0
    this.bagMaxWeightUnit = ''
    this.delayedNavigationTimer = 0
    this.quitReason = ''
    this.quitDetail = ''
    this.store.dispatch(updateLocalData('appFlow', this.appFlow))
    this.aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
    this.aeaBagdrop.OnDeviceEvent = this.aeaBagdropCallback.bind(this)
    this.epCmdOK = false
    this.rcCmdOK = false
    this.inRevalidatingBag = false
    this.heavyBagsList = ''
    this.setDefaultBagdropLimits()
  }

  aeaBagdropCallback(event) {
    //appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback() is called from client: ' + event.key)

    switch (event.key) {
      case 'commandCompleted':
        this.commandCompleted(event.value)
        break
      case 'unsolicitedMessage':
        this.unsolicitedMessage(event.value)
        break
      case 'statusResponse':
        this.status(event.value)
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback statusChange: ' + event.value)
        this.statusChange(event.value)
        break
      case 'statusIsOK':
        // used by OOS - skip here
        break
      case 'setupAEA':
      case 'sendAEA':
        this.processReturnCode(event.key, event.value)
        break
      case 'getKioskDeviceHelp':
        AEABagdropOnEvent(event)
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback unexpected event: ' + event.key + ' ' + event.value)
    }
  }

  notifyActive() {
    this.appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.notifyActive appFlow: ' + this.appFlow)
    if (!this.epCmdOK) {
      // let EPcmd = 'EP#BSTMODE=T'
      let EPcmd = 'EP'
      if (config.cmdEPparameters !== '') {
        EPcmd = EPcmd + config.cmdEPparameters
      }
      this.aeaBagdrop.setupAEA(EPcmd, 1000)
    }
    if (!this.rcCmdOK) {
      this.aeaBagdrop.setupAEA('RC', 10000)
    }
    this.setAppFlow(0)
    /*
    if (this.appFlow === -2) {
      this.setAppFlow(-1)
      //setupAEA('RC', 10000)
      //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'setupAEA', 'setupAEA_RC', 'RC', 10000)
      this.aeaBagdrop.setupAEA('RC', 10000)
      //appLog(TraceLevels.LOG_EXT_TRACE,'RC sent ')
    } else {

      this.setAppFlow(0)
      if (!this.epCmdOK) {
        let EPcmd = 'EP#BSTMODE=T'
        if (config.cmdEPparameters !== '')
          EPcmd = EPcmd + config.cmdEPparameters
        this.aeaBagdrop.setupAEA(EPcmd, 1000)
      }
    }
*/
  }

  hostResponse() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'Host response callback............')
  }

  getInIngestLoop() {
    return this.inIngestLoop
  }

  revalidateBag() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'RevalidateBag - from getItinerary - pooling')
    this.inRevalidatingBag = true
    // bagtag may not be in BQ response - get the last bagtag
    appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ')
    this.setAppFlow(5)
    this.BQStart = new Date().getTime()
    this.aeaBagdrop.sendAEA('BQ', -10000)
    this.store.dispatch(setBagProcessStatus(1, true))
  }

  setAppFlow(newAppFlow) {
    this.appFlow = newAppFlow
    this.store.dispatch(updateLocalData('appFlow', this.appFlow))
  }

  //handle initialization CommandCompleted  states -2,-1 and 0
  ccInit(msg) {
    const PSAM = this.store.getState().kioskInfo.PSAM
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    if (this.appFlow <= 0) {
      /*********************************************************************************************
       *   RC or EP was called                                                                      *
       *********************************************************************************************/
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'])
      switch (msg['OK']) {
        case 'RC':
          this.saveBagdropLimits(msg) // save SBD limits
          this.rcCmdOK = true
          /*
            let EPcmd = 'EP#BSTMODE=T'
            if (config.cmdEPparameters !== '')
              EPcmd = EPcmd + config.cmdEPparameters
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'setupAEA', 'setupAEA_RC', EPcmd, 10000)
            this.aeaBagdrop.setupAEA(EPcmd, 1000)
            */
          // when bagdrop systm start, call BQ to check is there a bag on the belt
          // let commandResult = this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, 'Welcome')
          // if (commandResult !== -99 && commandResult !== -100) {
          //   this.setAppFlow(20)
          // }
          break

        case 'EP':
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp EP result BSTMODE=' + msg['BSTMODE'])
          this.epCmdOK = true
          this.setAppFlow(0)
          break

        case 'SQ':
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
          if (msg['SD'] === 'EMCY') {
            goToLocalGenericError(
              'commandCompleted appFlow 0',
              ErrCodes.CONVEYOR_ESTOP,
              'Error_ConveyorEStop',
              'OOS',
              null
            )
          } else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
            // bag placed
            appLog(TraceLevels.LOG_EXT_TRACE, 'call CC#PA to get confirm message')
            let commandResult = this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, 'Welcome')
            if (commandResult !== -99 && commandResult !== -100) {
              navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitBagProcess' } }, 20)
            }
          } else if (msg['SD'] === 'NORM' && this.SQStart === 0) {
            //On intrusion screen && intrusion cleared
            // instead of OK button
            appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Welcome')
          } else if (msg['SD'] === 'INTR') {
            // this.store.dispatch(updateLocalData('updateBqCommand', ''))
            if (this.SQStart !== -1) {
              let newTime = new Date().getTime()
              if (this.SQStart > 0 && newTime - this.SQStart > config.maxClearBagTagLookupIntrusionTime * 1000) {
                this.SQStart = 0
                this.IntrusionStart = new Date().getTime()
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'goto --> Intrusion screen: ' + (newTime - this.SQStart) + ' IntrusionStart: ' + this.IntrusionStart
                )
                logEvent('BagOnBelt,intrusion start')
                this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
                this.stopDelayedNavigation()
                navigate({ pathname: 'Intrusion', state: { welcomeFlag: true } }, 9)
                //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
                appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from  ccInit')
                this.aeaBagdrop.sendAEA('SQ', -10000)
              } else {
                // try again SQ
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'cmdComp call SQ again newTime: ' + newTime + ' elapsed: ' + (newTime - this.SQStart)
                )
                delay(config.delayAEAcmd).then(
                  function () {
                    this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'CCInit')
                  }.bind(this)
                )
              }
            } else if (this.SQStart === -1) {
              // there is initial intrusion and bag on the belt
              this.SQStart = new Date().getTime()
              appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQstart + ' call SQ (CCInit)')
              delay(config.delayAEAcmd).then(
                function () {
                  this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'CCInit')
                }.bind(this)
              )
            }
            //  else {
            //   appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing SQstart - skip - wait for intrusion.')
            // }
          } else if (msg['SD'] !== 'NORM' && msg['SD'] !== 'BHSX') {
            appLog(TraceLevels.LOG_EXT_TRACE, 'SD indicates an error condition: ' + msg['SD'])
            goToLocalGenericError(
              'commandCompleted appFlow 0',
              ErrCodes.SEE_AGENT_GENERAL,
              'Error_Default',
              'END_TXN',
              null
            )
          } else if (config.repeatSQOnPutBagOnBelt > 0) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'calling SQ again to wait for bag present...')
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'repeatSQOnWelcome')
              }.bind(this)
            )
          }
          break
        default:
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
      }
    } else {
      /*********************************************************************************************
       *   EP was called again                                                                     *
       *********************************************************************************************/
      if (msg['OK'] === 'EP') {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp EP result BSTMODE=' + msg['BSTMODE'])
        this.epCmdOK = true
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response.')
      }
    }
  }

  /*********************************************************************************************
   *   SQ called - waiting to clear intrusion                                                   *
   *********************************************************************************************/
  cc_2(msg) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
    //        appLog(TraceLevels.LOG_EXT_TRACE,'current page: ' + this.getCurrentPage())
    if (msg['OK'] === 'SQ') {
      if (msg['SD'] === 'EMCY') {
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ESTOP, '', 'commandCompleted appFlow 2', 'OOS'))
        goToLocalGenericError('commandCompleted appFlow 2', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', null)
        // history.push('error')
      } else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
        // bag placed
        appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Bag Processing screen')
        logEvent('BagOnBelt,Bag placed')
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        this.setAppFlow(3)
        this.store.dispatch(updateLocalData('bagtag', null))
        this.store.dispatch(updateLocalData('prevBagOnQBelt', msg['SD'] === 'BHSX'))
        navigate('BagProcessing', 3)
        // make sure that Put bag on belt was displayed config.minPutBag seconds
        /* if (history.location && history.location.pathname && history.location.pathname === '/PutBagOnBelt') {
          this.navigateWithMinDisplay('BagProcessing', config.minPutBag, 'PutBagStart')
        } */
        //				} else if (msg['SD']=='BHSX') {  //try again
        //					appLog(TraceLevels.LOG_EXT_TRACE,'cmdComp call SQ again');
        //					aeaCommand.delay(config.delayAEAcmd, 'SQ');
      } else if (msg['SD'] === 'NORM' && this.SQStart === 0) {
        //On intrusion screen && intrusion cleared
        // instead of OK button
        appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Put bag on belt')
        this.SQStart = -1
        navigate('PutBagOnBelt', 2)
      } else if (msg['SD'] === 'INTR') {
        // this.store.dispatch(updateLocalData('updateBqCommand', ''))
        if (this.SQStart !== -1) {
          let newTime = new Date().getTime()
          if (this.SQStart > 0 && newTime - this.SQStart > config.maxClearIntrusionTime * 1000) {
            this.SQStart = 0
            this.IntrusionStart = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'goto --> Intrusion screen: ' + (newTime - this.SQStart) + ' IntrusionStart: ' + this.IntrusionStart
            )
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
            this.stopDelayedNavigation()
            navigate('Intrusion', 9)
            logEvent('BagOnBelt,intrusion start')
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_2')
            this.aeaBagdrop.sendAEA('SQ', -10000)
          } else {
            // try again SQ
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'cmdComp call SQ again newTime: ' + newTime + ' elapsed: ' + (newTime - this.SQStart)
            )
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
              }.bind(this)
            )
          }
        } else if (this.SQStart === -1 ) {
          // there is initial intrusion and bag on the belt
          this.SQStart = new Date().getTime()
          appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQstart + ' call SQ (CC2)')
          delay(config.delayAEAcmd).then(
            function () {
              this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
            }.bind(this)
          )
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing SQstart - skip - wait for intrusion.')
        }
      } else if (msg['SD'] !== 'NORM' && msg['SD'] !== 'BHSX') {
        appLog(TraceLevels.LOG_EXT_TRACE, 'SD indicates an error condition: ' + msg['SD'])
        // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'commandCompleted appFlow 2'))
        goToLocalGenericError(
          'commandCompleted appFlow 2',
          ErrCodes.SEE_AGENT_GENERAL,
          'Error_Default',
          'END_TXN',
          null
        )
        // history.push('error')
      } else if (config.repeatSQOnPutBagOnBelt > 0) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'calling SQ again to wait for bag present...')
        //this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'repeatSQOnPutBagOnBelt')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'repeatSQOnPutBagOnBelt')
          }.bind(this)
        )
        //         } else if (msg['SD'] == 'NORM' && this.SQStart > 0) {  intrusion cleared without going to intrusion page
        //       	appLog(TraceLevels.LOG_EXT_TRACE,'clear intrusion timer.')
        //           this.SQStart = -1
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
    }
  }

  /*********************************************************************************************
   *   CC#PA was called - only PROK#P expected  (BGNO can happen with delayed navigation)      *
   *********************************************************************************************/
  cc_4(msg) {
    if (msg['ERR'] != null) {
      let errMsg = ''
      let errcode = msg['ERR']
      let msgCode = ''
      let params = null
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)
      if (errcode.indexOf('ERR5#') === 0) {
        /*let param0 = ''
        let param1 = ''*/
        errcode = errcode.substring(5)
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp errorcode: ' + errcode)
        if (errcode === 'BGNO') {
          //errMsg = ErrCodes.BAG_MISSING
          logEvent('BagOnBelt,error=bag missing')
          appLog(TraceLevels.LOG_EXT_TRACE, 'CC#PA BGNO --> Put bag on belt')
          this.SQStart = -1
          navigate('PutBagOnBelt', 2)
          return
        } else if (errcode === 'OVLT') {
          if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_LONG_ADDBAG
            msgCode = 'Error_BagLongAddBag'
          } else {
            errMsg = ErrCodes.BAG_LONG
            msgCode = 'Error_BagLong'
          }
        } else if (errcode === 'BAGJ') {
          errMsg = ErrCodes.BAG_JAMMED
          msgCode = 'Error_BagJammed'
        } else if (errcode === 'OVWT') {
          if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_MAX_WEIGHT_ADDBAG
            msgCode = 'Error_BagOverweightMaxAddBag'
          } else {
            errMsg = ErrCodes.BAG_MAX_WEIGHT
            msgCode = 'Error_BagOverweightMax'
          }
          params = {
            maxWeight: this.bagdropLimits.upperScaleLimit,
            weightUnit: this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVWU') {
          if (config.useBinForSmallBags) {
            errMsg = ErrCodes.BAG_UNDERWEIGHT_USEBIN
            msgCode = 'Error_BagUnderweightUseBin'
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_UNDERWEIGHT_ADDBAG
            msgCode = 'Error_BagUnderweightAddBag'
          } else {
            errMsg = ErrCodes.BAG_UNDERWEIGHT
            msgCode = 'Error_BagUnderweight'
          }
          params = {
            minWeight: this.bagdropLimits.lowerScaleLimit,
            weightUnit: this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVFL') {
          if (config.useBinForSmallBags) {
            errMsg = ErrCodes.BAG_UNDERHEIGHT_USEBIN
            msgCode = 'Error_BagUnderheightUseBin'
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_UNDERHEIGHT_ADDBAG
            msgCode = 'Error_BagUnderheightAddBag'
          } else {
            errMsg = ErrCodes.BAG_UNDERHEIGHT
            msgCode = 'Error_BagUnderheight'
          }
        } else if (errcode === 'BGMV') {
          errMsg = ErrCodes.BAG_UNSTABLE
          msgCode = 'Error_UnableToWeigh'
        } else if (errcode === 'CBIO' || errcode === 'UNKN') {
          errMsg = ErrCodes.CONVEYOR_ERROR
          msgCode = 'Error_HWError'
        } else if (errcode === 'BHSS') {
          errMsg = ErrCodes.BHS_ERROR
          msgCode = 'Error_HWError'
        } else if (errcode !== 'OVHG' && errcode !== 'BGPR' && errcode !== 'OVSH' && errcode !== 'INTR') {
          errMsg = ErrCodes.SEE_AGENT_GENERAL
          msgCode = 'Error_Default'
        }

        if (errMsg !== '')
          this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 4', 'END_TXN', params))
        logEvent('BagOnBelt,Error=' + errMsg)
        // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 4', param0, param1))
      }
      // TODO: why set appFlow = 0? doesn't make scense.
      //this.appFlow = 0
      //this.store.dispatch(updateLocalData('appFlow', this.appFlow))
      if (errcode === 'OVHG') {
        // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 4'))
        this.checkMaxReposition(
          'commandCompleted appFlow 4',
          ErrCodes.BAG_TOO_TALL,
          'Error_BagTooTall',
          'PutBagOnBelt',
          null
        )
      } else if (errcode === 'OVSH') {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 4'))
        let tempmsgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 4', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null)
      } else if (errcode === 'BGPR') {
        // this.store.dispatch(updateError(ErrCodes.BAG_NOSCAN, '', 'commandCompleted appFlow 4'))
        this.checkMaxReposition(
          'commandCompleted appFlow 4',
          ErrCodes.BAG_NOSCAN,
          'RepositionBag1',
          'PutBagOnBelt',
          null
        )
      } else if (errcode === 'TOMB') {
        // this.store.dispatch(updateError(ErrCodes.MULTIPLE_BAGS, '', 'commandCompleted appFlow 4'))
        appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
        this.setAppFlow(2)
        goToLocalError(
          'commandCompleted appFlow 4',
          ErrCodes.MULTIPLE_BAGS,
          'ErrorMismatch3',
          'PutBagOnBelt',
          null,
          'ErrorMismatch'
        )
      } else if (
        errMsg == ErrCodes.BAG_MAX_WEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_LONG_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERWEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERWEIGHT_USEBIN ||
        errMsg == ErrCodes.BAG_UNDERHEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERHEIGHT_USEBIN
      ) {
        this.checkMaxReposition('commandCompleted appFlow 4', errMsg, msgCode, 'PutBagOnBelt', params)
      } else if (errcode === 'INTR') {
        // this.store.dispatch(updateLocalData('updateBqCommand', ''))
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.IntrusionStart = new Date().getTime()
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          'wait for intrusion to clear (load) -> goto Intrusion IntrusionStart: ' + this.IntrusionStart
        )
        this.stopDelayedNavigation()
        logEvent('BagOnBelt,intrusion start')
        navigate('Intrusion', 9)
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_4')
        this.aeaBagdrop.sendAEA('SQ', -10000)
      } else {
        navigate('error', 10)
      }
    } else if (msg['OK'] === 'PR') {
      //only response to CCPA
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call BQ ')
      this.setAppFlow(5)
      this.BQStart = new Date().getTime()
      //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
      appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ')
      this.aeaBagdrop.sendAEA('BQ', -10000)
      this.store.dispatch(updateLocalData('bagtag', null))
      this.store.dispatch(setBagProcessStatus(1, true))
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected OK=' + msg['OK'])
    }
  }

  /*********************************************************************************************
   *   BQ was called then SQ was called to check if 'ready to induct' BHSX                     *
   *********************************************************************************************/
  cc_5(msg) {
    let bagMeasurements = ''
    let bagTagObject = ''
    const summaryStore = getSummaryStore()

    if (msg['OK'] !== 'BQ' && msg['OK'] !== 'SQ') {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected OK=' + msg['OK'])
      return
    }
    /***********************************************
     *  response to BQ
     ***********************************************/
    if (msg['OK'] === 'BQ') {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'cmdComp OK=' +
          msg['OK'] +
          ' LP=' +
          msg['LP'] +
          ' HT=' +
          msg['HT'] +
          ' LT=' +
          msg['LT'] +
          ' SW=' +
          msg['SW'] +
          ' CW=' +
          msg['CW'] +
          ' BS=' +
          msg['BS']
      )
      if (msg['SW'] === '5' || msg['SW'] === '6') {
        let maxWeight = 40
        let weightUnit = this.bagdropLimits.measurement ? this.bagdropLimits.measurement : 'KG'
        if (msg['SW'] === '5' && this.bagdropLimits.upperScaleLimit) {
          // by JIRA ticket BDA-190. this condition will never meet in platform layer. therefore, this block will never use.
          // maxWeight = this.bagdropLimits.upperScaleLimit
        } else if (msg['SW'] === '6' && this.bagdropLimits.upperScaleLimit) {
          maxWeight = this.bagdropLimits.upperScaleLimit
        }
        // check if more bags can be processed
        // add the current bagtag to heavy bags list
        if (msg['LP'] && msg['LP'] !== 'ERR' && msg['LP'].length <= 10) {
          this.heavyBagsList = this.heavyBagsList + formatBagTagDisplay(msg['LP']) + ','
        }
        if (this.getNumberOfNotActivatedBags() > 1) {
          // this.store.dispatch(
          //   updateError(ErrCodes.BAG_MAX_WEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', maxWeight, weightUnit)
          // )
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_MAX_WEIGHT_ADDBAG,
            'Error_BagOverweightMaxAddBag',
            'PutBagOnBelt',
            { maxWeight: maxWeight, weightUnit: weightUnit }
          )
        } else {
          this.setAppFlow(0)
          // this.store.dispatch(
          //   updateError(ErrCodes.BAG_MAX_WEIGHT, '', 'commandCompleted appFlow 5', maxWeight, weightUnit)
          // )
          goToLocalGenericError(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_MAX_WEIGHT,
            'Error_BagOverweightMax',
            'END_TXN',
            { maxWeight: maxWeight, weightUnit: weightUnit }
          )
          // history.push('error')
        }
      } else if (msg['SW'] === '0') {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_MISSING, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError('commandCompleted appFlow 5', ErrCodes.BAG_MISSING, 'Error_BagMissing', 'END_TXN', null)
        logEvent('BagOnBelt,Bag mising')
        // history.push('error')
      } else if (msg['SW'] === '2') {
        // unstable weight
        if (this.BQStart > 0) {
          let newTime = new Date().getTime()
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            'cmdComp BQStart=' +
              this.BQStart +
              ' newTime=' +
              newTime +
              ' timeout=' +
              config.maxWaitTimeForStableWeight * 1000
          )
          if (newTime - this.BQStart > config.maxWaitTimeForStableWeight * 1000) {
            this.setAppFlow(0)
            logEvent('BagOnBelt,Bag unstable')
            // this.store.dispatch(updateError(ErrCodes.BAG_UNSTABLE, '', 'commandCompleted appFlow 5'))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNSTABLE,
              'Error_UnableToWeigh',
              'END_TXN',
              null
            )
            // history.push('error')
          } else {
            // try again BQ
            appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ again')
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
            this.aeaBagdrop.sendAEA('BQ', -10000)
          }
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing BQStart')
        }
      } else if (msg['SW'] === '4') {
        // weight is stable
        const targetOrdinal = findOrdinalByTag(msg['LP'])
        if (targetOrdinal !== null) {
          summaryStore.startBagData(
            targetOrdinal,
            msg['LP'],
            parseInt(msg['CW'].substring(0, msg['CW'].length - 2)),
            msg['CW'].substring(msg['CW'].length - 2),
            parseInt(msg['HT'].substring(0, msg['HT'].length - 2)),
            parseInt(msg['LT'].substring(0, msg['LT'].length - 2)),
            parseInt(msg['WT'].substring(0, msg['WT'].length - 2)),
            this.bagdropLimits.dimensions === 'M' ? 'CM' : 'IN'
          )
          summaryStore.CurrentBagtag = msg['LP']
          logEvent('BagOnBelt,Tag Detected')
          logEvent('BagTag#,' + msg['LP'])
          logEvent('BagDimensions,width=' + msg['WT'] + ' height=' + msg['HT'] + ' length=' + msg['LT'])
          logEvent('BagWeight,' + msg['CW'])
        }
        // weight is stable
        this.store.dispatch(updateLocalData('updateBagWeight', msg['CW']))
        this.store.dispatch(setBagProcessStatus(2))
        let currBagtag = msg['LP']
        if (this.inRevalidatingBag && currBagtag === 'ERR') {
          currBagtag = this.store.getState().localData.bagtag
          this.inRevalidatingBag = false
        }

        if (!this.validateBagtag(currBagtag))
          // exit if not valid (navigation started in the validation code if errors)
          return

        this.store.dispatch(setBagProcessStatus(2, true))
        //			displayBagdropLimits()

        // msg['BT'] value meaning:
        // 0 - no tub
        // 1 - tub detected
        // 2 - bag is not conveyable needs tub

        let tubStatus = msg['BT']

        // tubProvided value:
        // true - airport provide tub for pax
        // false - airport don't provide tub
        let tubProvided = true
        let tubUsed = msg['BT'] === '1'

        if (tubUsed) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'TUB used - skip underweight, too short and too flat.')
        }

        if (
          !this.validateWeightAndDimensions(
            msg['CW'],
            msg['HT'],
            msg['LT'],
            msg['WT'],
            tubStatus,
            tubProvided,
            currBagtag
          )
        ) {
          // exit if not valid (navigation started in the validation code if errors)
          return
        }
        if (!this.validateHeavyBag(msg['BC'], msg['CW'])) {
          //if not valid show error
          /* appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error heavy bag label.')
          goToLocalGenericError('heavy label', ErrCodes.BAG_HEAVY_ATTACHLABEL, 'Error_HeavyLabel', 'PutBagOnBelt', {
            heavyWeight: config.heavyBagWeight
          }) */
          return
        }

        //Dispatch bag - section
        // check if the previous bag is still being processed - (Q - belt cleared) config param - do several checks (delay)

        // disable bagtag scanner
        if (config.callCW === 'Y') {
          //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CW', -10000)
          this.aeaBagdrop.sendAEA('CW', -10000)
          appLog(TraceLevels.LOG_EXT_TRACE, '==> Disable scanner CW')
        }
        // send Bag info to host - just before dispatch
        let width = msg['WT']
        if (width == null) width = -1

        bagMeasurements = [msg['CW'], msg['HT'], msg['LT'], width, tubUsed]
        this.store.dispatch(updateLocalData('bagMeasurements', bagMeasurements))
        // this.store.dispatch(updateLocalData('updateBqCommand', msg))

        if (this.store.getState().localData.prevBagOnQBelt) {
          //execute SQ as there was a bag on Q belt (could be gone by now)
          this.BHSXStart = 0
          //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
          this.aeaBagdrop.sendAEA('SQ', -10000)
          appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ (CC5)')
          this.store.dispatch(setBagProcessStatus(3))
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'call BAG VERIFY ---------->')
          this.store.dispatch(setBagProcessStatus(4))
          bagTagObject = this.store.getState().localData.bagtagObject //set in validateBagtag
          let overCode = this.getOverrideCode(bagTagObject.baseTagNo)
          // redirect to accept bag screen
          navigate('AcceptingBag')
          delay(config.delayBagUpdateCall).then(function () {
            callBagUpdate(bagTagObject, SBDUpdateAction.VERIFY, bagMeasurements, overCode)
          })
          // if all OK then  host response should execute CC#RA
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp incorrect SW status - ')
      }
      /***********************************************
       *  response to SQ - waiting for BHSX to clear - timeout
       ***********************************************/
    } else {
      //add more code to analyze possible errors
      if (msg['SD'] !== 'BHSX') {
        //- prev bag gone - can execute dispatch
        this.store.dispatch(setBagProcessStatus(3, true))
        this.store.dispatch(setBagProcessStatus(4))
        bagTagObject = this.store.getState().localData.bagtagObject //set in validateBagtag
        bagMeasurements = this.store.getState().localData.bagMeasurements //set in validateBagtag
        let overCode = this.getOverrideCode(bagTagObject.baseTagNo)
        delay(config.delayBagUpdateCall).then(function () {
          callBagUpdate(bagTagObject, SBDUpdateAction.VERIFY, bagMeasurements, overCode)
        })
      } else if (msg['SD'] === 'BHSX') {
        // check if previous bag gone  - loop
        appLog(TraceLevels.LOG_EXT_TRACE, 'CC5 call SQ when SD=BHSX BHSXStart: ' + this.BHSXStart)
        navigate('PleaseWait')
        if (this.BHSXStart === 0) {
          delay(config.delayAEAcmd).then(
            function () {
              this.sendAEACommand('SQ', config.defaultAeaTimeout, 5, 'CC5-1')
            }.bind(this)
          )
          this.BHSXStart = new Date().getTime()
        } else if (this.BHSXStart > 0) {
          let newTime = new Date().getTime()
          if (newTime - this.BHSXStart > config.maxClearQBelt * 1000) {
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'config.maxClearQBelt elapsed ' + config.maxClearQBelt + ' goto --> error screen '
            )
            this.BHSXStart = -1
            // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'maxClearQBelt'))
            goToLocalGenericError('maxClearQBelt', ErrCodes.SEE_AGENT_GENERAL, 'Error_Default', 'END_TXN', null)
            // history.push('error')
          } else {
            // try again SQ
            appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call SQ again (CC5)')
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 5, 'CC5-2')
              }.bind(this)
            )
          }
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'SQ missing BHSX ')
      }
    }
  }

  /*********************************************************************************************
   *   CC#RA was called                                                                        *
   *********************************************************************************************/
  cc_6(msg) {
    let bagTagObject = ''
    let bagMeasurements = ''
    const summaryStore = getSummaryStore()

    if (msg['ERR'] != null) {
      let errcode = msg['ERR']
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)
      if (errcode.indexOf('ERR5#') === 0) {
        errcode = errcode.substring(5)
        let errMsg = ''
        let msgCode = ''
        let transitionPage = ''
        let params = null
        if (errcode === 'BGNO') {
          errMsg = ErrCodes.BAG_MISSING
          msgCode = 'Error_BagMissing'
          transitionPage = 'END_TXN'
        } else if (errcode === 'OVLT') {
          errMsg = ErrCodes.BAG_LONG
          msgCode = 'Error_BagLong'
          transitionPage = 'END_TXN'
        } else if (errcode === 'BAGJ') {
          errMsg = ErrCodes.BAG_JAMMED
          msgCode = 'Error_BagJammed'
          transitionPage = 'OOS'
        } else if (errcode === 'OVWT') {
          errMsg = ErrCodes.BAG_MAX_WEIGHT
          msgCode = 'Error_BagOverweightMax'
          transitionPage = 'END_TXN'
          params = {
            maxWeight: this.bagdropLimits.upperScaleLimit,
            weightUnit: this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVWU') {
          errMsg = ErrCodes.BAG_UNDERWEIGHT
          msgCode = 'Error_BagUnderweight'
          transitionPage = 'END_TXN'
          params = {
            minWeight: this.bagdropLimits.lowerScaleLimit,
            weightUnit: this.bagdropLimits.measurement
          }
        } else if (errcode === 'BHSX') {
          // //previous bag on queue belt
          if (this.IntrusionDuringDispatch) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'Dispatch --> Put bag on belt (IntrusionDuringDispatch)')
            this.SQstart = -1
            navigate('PutBagOnBelt', 2)
            return
          } else {
            if (this.dispatchBHSXTimer == 0) {
              this.dispatchBHSXTimer = new Date().getTime()
              delay(config.delayDispatchRetry).then(() => {
                appLog(TraceLevels.LOG_EXT_TRACE, 'send CC#RA again...')
                if (config.isCUSSRequired) {
                  this.sendAEACommand('CC#RA', config.defaultAeaTimeout, this.appFlow, 'CC6-BHSX').bind(this)
                }
              })
              return
            } else if (new Date().getTime() - this.dispatchBHSXTimer < config.maxRetryBHSX * 1000) {
              delay(config.delayDispatchRetry).then(() => {
                appLog(TraceLevels.LOG_EXT_TRACE, 'send CC#RA again...')
                if (config.isCUSSRequired) {
                  this.sendAEACommand('CC#RA', config.defaultAeaTimeout, this.appFlow, 'CC6-BHSX').bind(this)
                }
              })
              return
            } else {
              errMsg = ErrCodes.BHS_ERROR
              msgCode = 'Error_BHSX_Timeout'
              transitionPage = 'END_TXN'
            }
          }
        } else if (errcode !== 'OVHG' && errcode !== 'OVSH' && errcode !== 'INTR') {
          errMsg = ErrCodes.SEE_AGENT_GENERAL
          msgCode = 'Error_Default'
          transitionPage = 'END_TXN'
        }
        if (errMsg !== '')
          this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 6', transitionPage, params))
        logEvent('BagOnBelt,error=' + errMsg)
        // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 6'))
      }
      //this.setAppFlow(0) /// ?????
      if (errcode === 'OVHG') {
        // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 6'))
        this.checkMaxReposition(
          'commandCompleted appFlow 6',
          ErrCodes.BAG_TOO_TALL,
          'Error_BagTooTall',
          'PutBagOnBelt',
          null
        )
      } else if (errcode === 'OVSH') {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 6'))
        let tempmsgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 6', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null)
      } else if (errcode === 'INTR') {
        // this.store.dispatch(updateLocalData('updateBqCommand', ''))
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.IntrusionStart = new Date().getTime()
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          'wait for intrusion to clear (dispatch) IntrusionStart: ' + this.IntrusionStart
        )
        this.stopDelayedNavigation()
        logEvent('BagOnBelt,error=intrusion start')
        navigate('Intrusion', 9)
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_6')
        this.aeaBagdrop.sendAEA('SQ', -10000)
      } else {
        navigate('error', 10)
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'call BAG_ACCEPTED ---------->')
      this.store.dispatch(setBagProcessStatus(5))
      this.resetIntrusionDuringDispatch()
      bagTagObject = this.store.getState().localData.bagtagObject
      bagMeasurements = this.store.getState().localData.bagMeasurements
      let overCode = this.getOverrideCode(bagTagObject.baseTagNo)
      logEvent('BagInducted,' + bagTagObject.baseTagNo)
      delay(config.delayBagUpdateCall).then(function () {
        callBagUpdate(bagTagObject, SBDUpdateAction.ACCEPTED, bagMeasurements, overCode)
      })
      // host response will decide where to go next: Closing Remarks, Print or Put Bag
    }
  }

  /*********************************************************************************************
   *   SQ called - waiting to clear intrusion (from Bag processing)                            *
   *********************************************************************************************/
  cc_9(msg) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
    if (msg['OK'] === 'SQ') {
      if (msg['SD'] === 'EMCY') {
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ESTOP, '', 'commandCompleted appFlow 9', 'OOS'))
        goToLocalGenericError('commandCompleted appFlow 9', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', null)
        // history.push('error')
      } else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
        this.resetIntrusionDuringDispatch()
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        logEvent('BagOnBelt,intrusion cleared')
        if (history.location.state && history.location.state.welcomeFlag) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Welcome screen')
          this.setAppFlow(0)
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay('Welcome', config.minIntrusion, 'IntrusionStart')
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Bag Processing screen')
          this.setAppFlow(3)
          this.store.dispatch(updateLocalData('prevBagOnQBelt', msg['SD'] === 'BHSX'))
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay('BagProcessing', config.minIntrusion, 'IntrusionStart')
        }
      } else if (msg['SD'] === 'BHSX' || msg['SD'] === 'INTR') {
        //try again
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call SQ again (CC9)')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 9, 'CC9')
          }.bind(this)
        )
      } else if (msg['SD'] === 'NORM') {
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.resetIntrusionDuringDispatch()
        if (history.location.state && history.location.state.welcomeFlag) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion NO BAG --> Welcome screen')
          this.setAppFlow(0)
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay('Welcome', config.minIntrusion, 'IntrusionStart')
        } else {
          //NO BAG
          // instead of OK button
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Put bag on belt')
          this.setAppFlow(2)
          this.SQstart = -1
          this.navigateWithMinDisplay('PutBagOnBelt', config.minIntrusion, 'IntrusionStart')
        }
      } else if (msg['SD'] !== 'NORM') {
        // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'maxClearQBelt'))
        goToLocalGenericError('maxClearQBelt', ErrCodes.SEE_AGENT_GENERAL, 'Error_Default', 'END_TXN', null)
        // history.push('error')
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '')
    }
  }

  /*********************************************************************************************
   *   CC#PA called - Welcome screen bagtag lookup process                                      *
   *********************************************************************************************/
  cc_20(msg) {
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    if (config.isBagtagLookupEnable) {
      if (msg['ERR'] != null) {
        let errcode = msg['ERR']
        let errMsg = ''
        let msgCode = ''
        let params = null
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)
        if (errcode.indexOf('ERR5#') === 0) {
          errcode = errcode.substring(5)
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp errorcode: ' + errcode)
          if (errcode === 'BGNO') {
            //errMsg = ErrCodes.BAG_MISSING
            appLog(TraceLevels.LOG_EXT_TRACE, 'CC#PA BGNO --> Welcome, repeat SQ cmd to check status')
            // this.setAppFlow(0)
            // history.push('Welcome')
            errMsg = ErrCodes.BAG_MISSING
            msgCode = 'Error_BagMissing'
            // this.aeaBagdrop.sendAEA('SQ', -10000)
            sendEventLog('eventLog', 'this is event log message')
          } else if (errcode === 'OVLT') {
            errMsg = ErrCodes.BAG_LONG
            msgCode = 'Error_BagLong'
          } else if (errcode === 'BAGJ') {
            errMsg = ErrCodes.BAG_JAMMED
            msgCode = 'Error_BagJammed'
          } else if (errcode === 'OVWT') {
            errMsg = ErrCodes.BAG_MAX_WEIGHT
            msgCode = 'Error_BagOverweightMax'
            params = {
              maxWeight: this.bagdropLimits.upperScaleLimit,
              weightUnit: this.bagdropLimits.measurement
            }
          } else if (errcode === 'OVWU') {
            errMsg = ErrCodes.BAG_UNDERWEIGHT
            msgCode = 'Error_BagUnderweight'
            params = {
              minWeight: this.bagdropLimits.lowerScaleLimit,
              weightUnit: this.bagdropLimits.measurement
            }
          } else if (errcode === 'OVFL') {
            errMsg = ErrCodes.BAG_UNDERHEIGHT
            msgCode = 'Error_BagUnderheight'
          } else if (errcode === 'BGMV') {
            errMsg = ErrCodes.BAG_UNSTABLE
            msgCode = 'Error_UnableToWeigh'
          } else if (errcode === 'CBIO' || errcode === 'UNKN') {
            errMsg = ErrCodes.CONVEYOR_ERROR
            msgCode = 'Error_HWError'
          } else if (errcode === 'BHSS') {
            errMsg = ErrCodes.BHS_ERROR
            msgCode = 'Error_HWError'
          } else if (errcode === 'TOMB') {
            // this.store.dispatch(updateError(ErrCodes.MULTIPLE_BAGS, '', 'commandCompleted appFlow 4'))
            appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
            this.setAppFlow(10)
            goToLocalError(
              'commandCompleted appFlow 20',
              ErrCodes.MULTIPLE_BAGS,
              'ErrorMismatch3',
              'END_TXN',
              null,
              'ErrorMismatch'
            )
            return
            // history.push('ErrorMismatch')
          } else if (errcode === 'BGPR') {
            appLog(TraceLevels.LOG_EXT_TRACE, 'no bagtag found goto --> Error.')
            this.setAppFlow(10)
            goToLocalGenericError('commandCompleted appFlow 0', ErrCodes.BAG_NOSCAN, 'RepositionBag1', 'END_TXN', null)
            return
          } else if (errcode === 'INTR') {
            // this.store.dispatch(updateLocalData('updateBqCommand', ''))
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
            this.setAppFlow(9)
            this.IntrusionStart = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'wait for intrusion to clear (load) -> goto Intrusion IntrusionStart: ' + this.IntrusionStart
            )
            this.stopDelayedNavigation()
            // history.push('Intrusion')
            navigate({ pathname: 'Intrusion', state: { welcomeFlag: true } })
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_20')
            this.aeaBagdrop.sendAEA('SQ', -10000)
            return
          } else if (errcode === 'OVHG') {
            errMsg = ErrCodes.BAG_TOO_TALL
            msgCode = 'Error_BagTooTall'
          } else if (errcode === 'OVSH') {
            errMsg = ErrCodes.BAG_SHORT
            msgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
          } else if (errcode !== 'OVHG' && errcode !== 'BGPR' && errcode !== 'OVSH' && errcode !== 'INTR') {
            errMsg = ErrCodes.SEE_AGENT_GENERAL
            msgCode = 'Error_Default'
          }

          if (errMsg === ErrCodes.BHS_ERROR || errMsg === ErrCodes.CONVEYOR_ERROR || errMsg === ErrCodes.BAG_JAMMED) {
            goToLocalGenericError('commandCompleted appFlow 20', errMsg, msgCode, 'OOS', null)
          } else if (errMsg !== '') {
            logEvent('BagOnBelt,error=' + errMsg)
            this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 20', 'END_TXN', params))
          }
        }
        navigate('error', 10)
      } else if (msg['OK']) {
        switch (msg['OK']) {
          case 'PR':
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ')
            this.BQStart = new Date().getTime()
            this.aeaBagdrop.sendAEA('BQ', -10000)
            break
          case 'BQ':
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              `cmdComp BQ result !cussTxnStarted : ${!cussTxnStarted}, this.appFlow: ${this.appFlow}, msg['BS']: ${
                msg['BS']
              }`
            )
            if ((msg['BS'] === 'BAGP' || msg['BS'] === 'BAGE') && msg['SW'] === '4') {
              this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
              // change to uniqe a appflow when we start cussTransaction and send the bagtagnumber to ets.
              let bagtagnumber = formatBagTagDisplay(msg['LP'])
              console.log(`Event:  scanned bagtag : ${bagtagnumber}`)
              const eventLogger = getEventLogger()
              // TODO: need figure out how to deal with more bags in same transaction, we should keep same clientSessionId in same ETS session
              let etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
              if (eventLogger && !eventLogger.clientSessionId && etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
                eventLogger.ClientSessionId = uuidv4()
              }
              logEvent('BagOnBelt,Detected')
              // appLog(TraceLevels.LOG_TRACE, `Event:  scanned bagtag : ${bagtagnumber}`)
              if (!msg['HT'] || !msg['LT'] || !msg['WT']) {
                appLog(TraceLevels.LOG_EXT_TRACE, 'missing dimension --> HW Error.')
                this.setAppFlow(10)
                goToLocalGenericError(
                  'commandCompleted appFlow 0',
                  ErrCodes.CONVEYOR_ERROR,
                  'Error_HWError',
                  'OOS',
                  null
                )
              } else if (bagtagnumber) {
                this.store.dispatch(updateLocalData('bagtag', bagtagnumber))
                logEvent('BagOnBelt,Tag Detected')
                logEvent('BagTag#,' + bagtagnumber)
                logEvent('BagDimensions,width=' + msg['WT'] + ' height=' + msg['HT'] + ' length=' + msg['LT'])
                logEvent('BagWeight,' + msg['CW'])
                if (bagtagnumber === 'ERR') {
                  appLog(TraceLevels.LOG_EXT_TRACE, 'no bagtag found goto --> Error.')
                  this.setAppFlow(10)
                  goToLocalGenericError(
                    'commandCompleted appFlow 20',
                    ErrCodes.BAG_NOSCAN,
                    'RepositionBag1',
                    'END_TXN',
                    null
                  )
                } else if (bagtagnumber.length > 10) {
                  // one bagtag length is 10
                  appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
                  this.setAppFlow(10)
                  goToLocalError(
                    'commandCompleted appFlow 20',
                    ErrCodes.MULTIPLE_TAGS,
                    'ErrorMismatch2',
                    'END_TXN',
                    null,
                    'ErrorMismatch'
                  )
                } else {
                  this.store.dispatch(updateLocalData('updateBagWeight', msg['CW']))
                  getDeviceManager().getDevice(deviceIds.FACE_TRACKING).hide()
                  getDeviceManager().getDevice(deviceIds.FACE_TRACKING).OnDeviceEvent = null
                  this.setAppFlow(17)
                  let location = {
                    state: {
                      from: 'Welcome',
                      statistics: {
                        paxOrdinal: null,
                        paxName: null
                      }
                    }
                  }
                  if (!cussTxnStarted) {
                    getSBDAppMan().resetData()
                    startCUSSTransaction()
                  }
                  if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_OK) {
                    if (appConfig.hostURL) {
                      this.store.dispatch(getItinerarySBD({ barcodeData: bagtagnumber }, location))
                    }
                  } else {
                    getSBDAppMan().enableISBD()
                    if (appConfig.hostURL) {
                      startEtsTransaction(this.store, TYPE_KIOSK, getVersion(), { barcodeData: bagtagnumber })
                    } else {
                      if (config.isTransferEnabled) {
                        //todo: maybe we need another flow value
                        const airlineCode = bagtagnumber.substring(1, 4)
                        const airlineInfo = config.transferInfo.find((item) => {return item.airlineCode === airlineCode})                    
                        if (airlineInfo && config.isCUSSRequired) {
                          appLog(TraceLevels.LOG_EXT_TRACE, 'transfer to: ' + airlineInfo.companyCode + ' ' + airlineInfo.applicationName)
                          handleAppTransfer(airlineInfo.companyCode, airlineInfo.applicationName, null, null)
                        } else {
                          //todo: show airline is not supported
                          console.log('airline is not supported')
                        }
                      }
                    }
                  }
                  // this.store.dispatch(updateLocalData('updateBqCommand', msg))
                }
              }
            } else {
              if (this.BQStart > 0) {
                let newTime = new Date().getTime()
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'cmdComp BQStart=' +
                    this.BQStart +
                    ' newTime=' +
                    newTime +
                    ' timeout=' +
                    config.maxWaitTimeForStableWeight * 1000
                )
                if (newTime - this.BQStart > config.maxWaitTimeForStableWeight * 1000) {
                  this.store.dispatch(
                    updateLocalError(
                      ErrCodes.BAG_UNSTABLE,
                      'Error_UnableToWeigh',
                      'commandCompleted appFlow 20',
                      'END_TXN',
                      null
                    )
                  )
                  navigate('error', 10)
                } else {
                  // try again BQ
                  appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ again')
                  //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
                  this.aeaBagdrop.sendAEA('BQ', -10000)
                }
              } else {
                appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing BQStart')
              }

              // this.aeaBagdrop.sendAEA('SQ', -10000)
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                `BQOK return msg['BS']= ${msg['BS']} and msg['SW']=${msg['SW']} lead to not successful startup`
              )
            }
            break
          default:
            navigate('Welcome', 0)
            // this.aeaBagdrop.sendAEA('SQ', -10000)
            appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
        }
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '==> Bagtag lookup disable, set appflow back to 0')
      navigate('Welcome', 0)
    }
  }

  commandCompleted(response) {
    this.appFlow = this.store.getState().localData.appFlow
    //  appLog(TraceLevels.LOG_EXT_TRACE,'AEASBDAppManager.commandCompleted appFlow: ' + this.appFlow + ' response1: ' + response[0])
    //    response = removeArrayBrackets(response[0])
    appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp: appFlow: ' + this.appFlow + ' response: ' + response)
    //this.store.dispatch(updateLocalData('AEA_BagdropMsg', response))
    let cmd = new AEACommand()
    let rc = cmd.parse(response)
    // return of rc not 0  - log?
    if (rc !== 0) {
      //appLog(TraceLevels.LOG_EXT_TRACE,'cmdComp parse rc:'+rc + ' errorCode: '+state.get('error.errorCode'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp parse rc:' + rc)
      return
    }
    let msg = cmd.getParsedMessage()

    //skip LA responses
    if (msg['OK'] === 'LA') return

    switch (this.appFlow) {
      case -2:
      case -1:
      case 0:
      case 1:
        this.ccInit(msg) // RC, EP
        break
      case 2:
        this.cc_2(msg) // SQ called - waiting to clear intrusion
        break
      case 4:
        this.cc_4(msg) // CC#PA was called
        break
      case 5:
        this.cc_5(msg) // BQ was called then SQ was called to check if 'ready to induct' BHSX
        break
      case 6:
        this.cc_6(msg) //CC#RA was called
        break
      case 7:
        /*********************************************************************************************
         *   Waiting for bag to be inducted - if config.asynchBagProcessing='N' or ('S' and last bag)*
         *********************************************************************************************/
        break
      case 9:
        this.cc_9(msg) //SQ called - waiting to clear intrusion (from Bag processing)
        break
      case 10:
        // skip CC#C0 response processing
        break
      case 20:
        this.cc_20(msg)
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response.')
    }
  }

  unsolicitedMessage(message) {
    this.appFlow = this.store.getState().localData.appFlow
    //const PSAM = this.store.getState().kioskInfo.PSAM
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    appLog(TraceLevels.LOG_EXT_TRACE, '==> unsolicitedMessage appFlow: ' + this.appFlow + ' message: ' + message)
    //    message = removeArrayBrackets(message[0])
    //    appLog(TraceLevels.LOG_EXT_TRACE,'--> uMsg received: ' + message)
    if (message == null) return

    let cmd = new AEACommand()
    let rc = cmd.parse(message)
    // return of rc not 0  - log?
    if (rc !== 0) {
      //appLog(TraceLevels.LOG_EXT_TRACE,'cmdComp parse rc:'+rc + ' errorCode: '+state.get('error.errorCode'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg parse rc:' + rc)
      return
    }
    let msg = cmd.getParsedMessage()

    // skip BQNI messages
    if (message.indexOf('BQNI') >= 0) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped: ' + message)
      if (msg['BS'] === 'BAGP' || msg['BS'] === 'BAGE') {
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
      }
      // console.log(`scan bagtag log condition, !cussTxnStarted : ${!cussTxnStarted}, msg['NI'] :${msg['NI']}`)
      // scan bagtag to get PNR
      // if (config.isBagtagLookupEnable) {
      //   if (this.appFlow < 1) {
      //     let bagtagnumber = formatBagTagDisplay(msg['LP'])
      //     console.log(`Event:  scanned bagtag : ${bagtagnumber}`)
      //     // change to uniqe a appflow when we start cussTransaction and send the bagtagnumber to ets.
      //     if ((msg['BS'] === 'BAGP' || msg['BS'] === 'BAGE') && msg['SW'] === '4' && bagtagnumber) {
      //       // appLog(TraceLevels.LOG_TRACE, `Event:  scanned bagtag : ${bagtagnumber}`)
      //       if (!msg['HT'] || !msg['LT'] || !msg['WT']) {
      //         appLog(TraceLevels.LOG_EXT_TRACE, 'missing dimension --> HW Error.')
      //         this.setAppFlow(10)
      //         goToLocalGenericError('ccInit', ErrCodes.CONVEYOR_ERROR, 'Error_HWError', 'OOS', null)
      //       } else {
      //         this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
      //         this.store.dispatch(updateLocalData('bagtag', bagtagnumber))
      //         if (bagtagnumber.length > 10) {
      //           // one bagtag length is 10
      //           appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
      //           goToLocalError(
      //             'unsolicitedMessage',
      //             ErrCodes.MULTIPLE_TAGS,
      //             'ErrorMismatch2',
      //             'END_TXN',
      //             null,
      //             'ErrorMismatch'
      //           )
      //         } else {
      //           getDeviceManager()
      //             .getDevice(deviceIds.FACE_TRACKING)
      //             .hide()
      //           getDeviceManager().getDevice(deviceIds.FACE_TRACKING).OnDeviceEvent = null
      //           this.setAppFlow(17)
      //           let location = {
      //             state: {
      //               from: 'Welcome',
      //               statistics: {
      //                 paxOrdinal: null,
      //                 paxName: null
      //               }
      //             }
      //           }
      //           if (!cussTxnStarted) {
      //             getSBDAppMan().resetData()
      //             startCUSSTransaction()
      //           }
      //           if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_OK) {
      //             this.store.dispatch(getItinerarySBD({ barcodeData: bagtagnumber }, location))
      //           } else {
      //             getSBDAppMan().enableISBD()
      //             startEtsTransaction(this.store, TYPE_KIOSK, getVersion(), { barcodeData: bagtagnumber })
      //           }
      //         }
      //       }
      //     } else {
      //       appLog(TraceLevels.LOG_EXT_TRACE, 'call CC#PA to get confirm message')
      //       let commandResult = this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, 'Welcome')
      //       if (commandResult !== -99 && commandResult !== -100) {
      //         this.setAppFlow(20)
      //       }
      //     }
      //   }
      // }

      //
      return
    }
    this.store.dispatch(updateLocalData('AEA_BagdropMsg', message))

    // check if emergency pressed
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'uMsg: ' + msg['CMD'] + ' appFlow: ' + this.appFlow + ' page: ' + this.getCurrentPage()
    )

    if (msg['NI'] === 'SQ' && msg['SD'] === 'NORM') {
      appLog(TraceLevels.LOG_EXT_TRACE, `INTRUSION : clear bq command on application level`)
      this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
    }

    if (msg['NI'] === 'SQ' && msg['SD'] === 'INTR') {
      appLog(TraceLevels.LOG_EXT_TRACE, `INTRUSION : clear bq command on application level`)
      // this.store.dispatch(updateLocalData('updateBqCommand', ''))
    }

    if (this.appFlow <= 0 && config.isBagtagLookupEnable) {
      if (msg['NI'] === 'SQ') {
        if (msg['SD'] === 'INTR') {
          // start when bag is there?   && msg['BS']!='0'
          if (this.SQStart > 0) {
            let curTime = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'uMsg INTR already timer started: ' +
                this.SQStart +
                ' time: ' +
                curTime +
                ' elapsed: ' +
                (curTime - this.SQStart)
            )
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg INTR timer started. SQ executed.')
            this.SQStart = new Date().getTime()
            appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQStart + ' call SQ (UM2)')
            delay(config.delayAEAcmd).then(
              function () {
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay (UM2)')
                this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM2')
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after SQ (UM2)')
              }.bind(this)
            )
          }
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg SQNI skipped.')
        }
      }
    }

    if (this.appFlow > 6 || this.appFlow < 2) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped (not in bag dispatching process): ' + message)
      return
    }

    let OOSFlag = false
    if (msg['NI'] === 'SQ') {
      let transactionStarted = this.store.getState().sessions.cussTxnStarted
      let errorCode = ''
      let msgCode = ''
      let transitionPage = ''
      if (msg['OS'] === '1') {
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_OFFLINE, '', 'uMsg', 'OOS'))
        errorCode = ErrCodes.CONVEYOR_OFFLINE
        msgCode = 'Error_HWError'
        transitionPage = 'OOS'
        OOSFlag = true
      } else {
        switch (msg['SD']) {
          case 'EMCY':
            // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ESTOP, '', 'uMsg', 'OOS'))
            errorCode = ErrCodes.CONVEYOR_ESTOP
            msgCode = 'Error_ConveyorEStop'
            transitionPage = 'OOS'
            OOSFlag = true
            break
          case 'TEHE':
            // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ERROR, '', 'uMsg', 'OOS'))
            errorCode = ErrCodes.CONVEYOR_ERROR
            msgCode = 'Error_HWError'
            transitionPage = 'OOS'
            OOSFlag = true
            break
          case 'BHSS':
            // this.store.dispatch(updateError(ErrCodes.BHS_ERROR, '', 'uMsg', 'OOS'))
            errorCode = ErrCodes.BHS_ERROR
            msgCode = 'Error_HWError'
            transitionPage = 'OOS'
            OOSFlag = true
            break
          case 'UNKN':
          case 'BAGR':
            // this.store.dispatch(updateError(ErrCodes.CONVEYOR_BAG_ERROR, '', 'uMsg'))
            errorCode = ErrCodes.CONVEYOR_BAG_ERROR
            msgCode = 'Error_HWError'
            transitionPage = 'OOS'
            if (!transactionStarted) OOSFlag = true
            break
          case 'BAGJ':
            // this.store.dispatch(updateError(ErrCodes.BAG_JAMMED, '', 'uMsg'))
            errorCode = ErrCodes.BAG_JAMMED
            msgCode = 'Error_BagJammed'
            transitionPage = 'OOS'
            if (!transactionStarted) OOSFlag = true
            break
          case 'BAGX': // not implemented in aeasbd
            break
          case 'INTR': // intrusion - check if not delayed navigation to other pages
            if (this.delayedNavigationTimer !== 0 && this.getCurrentPage() === 'Intrusion') {
              appLog(TraceLevels.LOG_EXT_TRACE, 'SQNI in delayedNavigation - stay on intrusion page.')
              this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
              this.stopDelayedNavigation() // stay on intrusion  page
              this.setAppFlow(9)
              //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
              appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from unsolicitedMessage')
              this.aeaBagdrop.sendAEA('SQ', -10000)
              return
            } else {
              // this.store.dispatch(updateError(null)) // clear error
            }
            break
          default:
          // should not clear error by unsolicitedMessage. this might lead to error page display turn into generic error (due to sudden clear on error object)
          // this.store.dispatch(updateError(null)) // clear error
        }
      }

      if (OOSFlag) {
        // if no transaction yet - goto OOS
        appLog(TraceLevels.LOG_EXT_TRACE, 'SQNI --> outOfService; transaction started: ' + transactionStarted)
        if (!transactionStarted) {
          this.resetData()
          completeCUSSTransaction(true)
          return
        }
      }

      if (errorCode !== '') {
        this.stopDelayedNavigation()
        goToLocalGenericError('uMsg', errorCode, msgCode, transitionPage, null)
        // history.push('error')
        return
      }
    }

    if (this.appFlow > 20) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped (in biometrics): ' + message)
      return
    }

    if (this.appFlow === 2) {
      // Put bag on belt - expected SQNI
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg NI=' + msg['NI'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
      if (msg['NI'] === 'SQ') {
        // if ((msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX') && msg['BS'] !== '0') {
        //   this.store.dispatch(updateLocalData('prevBagOnQBelt', msg['SD'] === 'BHSX'))
        //   this.setAppFlow(3)
        //   history.push('BagProcessing')
        // } else
        if (msg['SD'] === 'INTR') {
          // start when bag is there?   && msg['BS']!='0'
          if (this.SQStart > 0) {
            let curTime = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'uMsg INTR already timer started: ' +
                this.SQStart +
                ' time: ' +
                curTime +
                ' elapsed: ' +
                (curTime - this.SQStart)
            )
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg INTR timer started. SQ executed.')
            this.SQStart = new Date().getTime()
            appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQStart + ' call SQ (UM2)')
            delay(config.delayAEAcmd).then(
              function () {
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay (UM2)')
                this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM2')
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after SQ (UM2)')
              }.bind(this)
            )
          }
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg SQNI skipped.')
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped.')
      }
    } else if (this.appFlow >= 4 && this.appFlow <= 5 && msg['NI'] === 'SQ') {
      // skip SQ during dispatch
      if (msg['SD'] === 'INTR') {
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.IntrusionStart = new Date().getTime()
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          'wait for intrusion to clear - call SQ (UM45)  --> goto Intrusion IntrusionStart: ' + this.IntrusionStart
        )
        navigate('Intrusion', 9)
        this.stopDelayedNavigation()
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM45')
          }.bind(this)
        )
      }
    } else if (this.appFlow === 6) {
      // CC#Rx called
      if (msg['NI'] === 'SQ' && msg['SD'] === 'INTR') {
        this.IntrusionDuringDispatch = true
        appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg Intrusion during dispatch.')
      }
      //HDCPROK#B
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg unexpected message.')
    }
  }

  status(statusCode) {
    this.appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, '==> AEASBDAppManager.status appFlow: ' + this.appFlow + ' status: ' + statusCode)
    if (this.appFlow === 7 && statusCode < 1000) {
      this.inIngestLoop = false
      let bagTagObject = this.store.getState().localData.bagtagObject
      let bagMeasurements = this.store.getState().localData.bagMeasurements
      delay(config.delayBagUpdateCall).then(function () {
        callBagUpdate(bagTagObject, SBDUpdateAction.INGESTED, bagMeasurements)
      })
    } else if (statusCode < 1000) {
      // must be asynch wait	- check if prev object defined
      this.inIngestLoop = false
      let bagTagObject = this.store.getState().localData.prevBagtagObject
      let bagMeasurements = this.store.getState().localData.prevBagMeasurements
      if (!isEmpty(bagTagObject))
        delay(config.delayBagUpdateCall).then(function () {
          callBagUpdateAsynch(bagTagObject, SBDUpdateAction.INGESTED, bagMeasurements)
        })
      else appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status no prev bagtag object - skip')
    } else {
      if (this.inIngestLoop === false) {
        if (config.asynchBagProcessing === 'S' && this.getNumberOfNotActivatedBags() === 0) {
          // only last bag is processed in a synchronous way
          this.inIngestLoop = true
          navigate('PleaseWait')
          //delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
          delay(config.delayBagUpdateCall).then(
            function () {
              this.aeaBagdrop.status()
            }.bind(this)
          )
        } else {
          // bag processing asynch between transactions
          // on interval check status - if status indicates that PE1 is cleared
          // then execute callBagUpdate with the prevBagtag and BAG_INGESTED status
          let currBagtag = this.store.getState().localData.bagtagObject
          let currBagMeasurements = this.store.getState().localData.bagMeasurements
          this.store.dispatch(updateLocalData('prevBagtagObject', currBagtag))
          this.store.dispatch(updateLocalData('prevBagMeasurements', currBagMeasurements))
          //waitForBagOnAirportBelt(true);   //asynch
          this.inIngestLoop = true
          // delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
          delay(config.delayBagUpdateCall).then(
            function () {
              this.aeaBagdrop.status()
            }.bind(this)
          )
          appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after waitForBagOnAirportBelt(true)')
          if (this.getNumberOfNotActivatedBags() === 0) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status goto --> Closing Remarks')
            this.appFlow = 8
            if (config.enablePrintReceipt) {
              navigate('PrintReceipt', 8)
            } else {
              navigate('ClosingRemarks', 8)
            }
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status goto --> Put Bag on Belt')
            this.appFlow = 2
            navigate('PutBagOnBelt', 2)
          }
        }
      } else {
        //delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
        //delay(config.delayAEAcmd).then(this.aeaBagdrop.status())
        appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status before delay')
        delay(config.delayAEAcmd).then(
          function () {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay')
            this.aeaBagdrop.status()
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after status')
          }.bind(this)
        )
      }
    }
  }

  // handle fatal status changes  e.g. 302,true
  statusChange(statusCodeType) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '==> statusChange: ' + statusCodeType + ' appFlow: ' + appFlow + ' page: ' + this.getCurrentPage()
    )
    let fatalError = false
    if (Array.isArray(statusCodeType) && statusCodeType.length === 2) {
      fatalError = statusCodeType[1]
    } else if (!Array.isArray(statusCodeType) && statusCodeType.indexOf(',') > 0) {
      let sData = statusCodeType.split(',')
      fatalError = sData[1] === 'true'
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange unexpected format.')
    }
    if (fatalError) {
      // if no transaction yet - goto OOS
      let transactionStarted = this.store.getState().sessions.cussTxnStarted
      appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange --> outOfService; transaction started: ' + transactionStarted)
      if (!transactionStarted) {
        let appm = getDeviceManager().getAppManager()
        if (appm && !appm.getIsActive()) {
          appLog(TraceLevels.LOG_TRACE, '==> statusChange: app is not in active state - skip it.')
        } else {
          this.resetData()
          completeCUSSTransaction(true)
        }
      } else if (this.getCurrentPage() !== 'error') {
        this.stopDelayedNavigation()
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ERROR, '', 'statusChange', 'OOS'))
        goToLocalGenericError('statusChange', ErrCodes.CONVEYOR_ERROR, 'Error_HWError', 'OOS', null)
        // history.push('error')
      }
    }
  }

  // data is rc,cmd
  processReturnCode(aeaCall, data) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, 'processReturnCode: ' + data + ' appFlow: ' + appFlow)
    let anData = null
    if (!Array.isArray(data) && data.indexOf(',') > 0) {
      anData = data.split(',')
    } else if (Array.isArray(data) && data.length === 2) {
      anData = data
    }
    if (anData != null) {
      if (anData[0] !== '0' && anData[0] !== 0) {
        // to do: process exception
        let cmd = anData[1].substr(0, 2)
        if (appFlow === -1 && cmd === 'EP') {
          // the EP will be execute on activate again - skip here
          this.epCmdOK = false
          return
        }
        appLog(TraceLevels.LOG_ALERT, 'unexpected rc from AEA cmd: ' + data + ' appFlow: ' + appFlow)
      }
    }
  }

  sendAEACommand(cmd, to, validInAppFlow, msg) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '==> aeaCommand: ' +
        cmd +
        ' validInAppFlow: ' +
        validInAppFlow +
        ' appFlow: ' +
        appFlow +
        ' to: ' +
        to +
        '  msg: ' +
        msg
    )
    if (appFlow !== validInAppFlow && validInAppFlow !== 100) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'aeaCommand: not valid appFlow skip it.')
      return -100
    }
    if (to == null) to = -10000
    if (!isEmpty(this.store.getState().errorDetails)) {
      // error handling
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'aeaCommand: ' + cmd + ' skipped  errorDetails: ' + this.store.getState().errorDetails
      )
      return -99
    }
    //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', cmd, to)
    this.aeaBagdrop.sendAEA(cmd, to)
  }

  setDefaultBagdropLimits() {
    this.bagdropLimits.upperScaleLimit = 200
    this.bagdropLimits.lowerScaleLimit = 1
    this.bagdropLimits.heavyScaleLimit = 80
    this.bagdropLimits.measurement = 'KG'
    this.bagdropLimits.dimensions = 'M'
    this.bagdropLimits.widthMin = 0
    this.bagdropLimits.widthMax = 0
    this.bagdropLimits.lengthMin = 100
    this.bagdropLimits.lengthMax = 1300
    this.bagdropLimits.heightMin = 50
    this.bagdropLimits.heightMax = 800
    this.bagdropLimits.maxBags = 1
  }

  // save bagdrop limits in the this
  saveBagdropLimits(parsedResponse) {
    // scale limits
    if (parsedResponse['W'] != null) {
      let w = parsedResponse['W']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC W: ' + w)
      if (w.length === 13) {
        this._extractValues(w, 'upperScaleLimit', 'U', 0, 3)
        this._extractValues(w, 'lowerScaleLimit', 'L', 4, 2)
        this._extractValues(w, 'heavyScaleLimit', 'H', 7, 2)
        if (w.substr(10, 2) === 'KG' || w.substr(10, 2) === 'LB') {
          this.bagdropLimits.measurement = w.substr(10, 2)
          this.store.dispatch(updateLocalData('updateBagWeightUnit', w.substr(10, 2)))
        } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect measurement')
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC W: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Wxxxxx')
    // dimensions limits
    if (parsedResponse['Z'] != null) {
      let w = parsedResponse['Z']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: ' + w)
      if (w.length === 31) {
        if (w.substr(0, 1) === 'I' || w.substr(0, 1) === 'M') this.bagdropLimits.dimensions = w.substr(0, 1)
        else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect measurement')
        this._extractValues(w, 'widthMin', 'W', 1, 4)
        this._extractValues(w, 'widthMax', '.', 6, 4)
        this._extractValues(w, 'lengthMin', 'L', 11, 4)
        this._extractValues(w, 'lengthMax', '.', 16, 4)
        this._extractValues(w, 'heightMin', 'H', 21, 4)
        this._extractValues(w, 'heightMax', '.', 26, 4)
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Zxxxxx')
    // belt (conveyor) limits
    if (parsedResponse['T'] != null) {
      let w = parsedResponse['T']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC T: ' + w)
      if (w.length === 8) {
        this._extractValues(w, 'maxBags', 'M', 0, 1)
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Txxxxx')

    this.displayBagdropLimits()
  }

  displayBagdropLimits() {
    // display limits
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits upperScaleLimit: ' + this.bagdropLimits.upperScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lowerScaleLimit: ' + this.bagdropLimits.lowerScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heavyScaleLimit: ' + this.bagdropLimits.heavyScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits measurement: ' + this.bagdropLimits.measurement)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits dimensions: ' + this.bagdropLimits.dimensions)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits widthMin: ' + this.bagdropLimits.widthMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits widthMax: ' + this.bagdropLimits.widthMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lengthMin: ' + this.bagdropLimits.lengthMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lengthMax: ' + this.bagdropLimits.lengthMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heightMin: ' + this.bagdropLimits.heightMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heightMax: ' + this.bagdropLimits.heightMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits maxBags: ' + this.bagdropLimits.maxBags)
  }

  _extractValues = function (w, limit, charcode, sPos, len) {
    if (w.charAt(sPos) === charcode) {
      const t = parseInt(w.substr(sPos + 1, len), 10)
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC ' + limit + ': ' + w.substr(sPos + 1, len) + ' t=' + t)
      if (isNaN(t)) appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect ' + limit)
      else this.bagdropLimits[limit] = t
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect ' + limit)
  }

  /*
   * input cw - CW string from BQ command
   * input [maxBagWeight] - optional max bag weight
   * input [maxBagWeightUnit] - optional max bag weight unit
   * input [tubUsed] - optional if true then tab used skip under weight error
   * >0 - weight
   * -1 too heavy
   * -2 too light
   * -3 cw format error
   * -4 undefined
   * if the maxBagWeight or maxBagWeightUnit are not provided (or empty strings) or maxBagWeight is not a number or maxBagWeightUnit is not unit used by SBD
   *    then the additional check is skipped
   */
  checkWeight(cw, maxBagWeight, bagMaxWeightUnit, tubUsed) {
    if (cw == null) return -4
    if (cw.lastIndexOf(this.bagdropLimits.measurement) !== cw.length - 2) return -3
    const weight = parseFloat(cw.substring(0, cw.length - 2))
    if (isNaN(weight)) return -3
    if (!tubUsed && weight < this.bagdropLimits.lowerScaleLimit) return -2
    if (weight > this.bagdropLimits.upperScaleLimit) return -1

    if (
      maxBagWeight !== '' &&
      bagMaxWeightUnit !== '' &&
      !isNaN(maxBagWeight) &&
      bagMaxWeightUnit === this.bagdropLimits.measurement
    ) {
      if (weight > parseFloat(maxBagWeight)) return -1
    }

    return weight
  }

  /* dimType 1-height, 2-length, 3-width
   * dim - dimension
   * tubUsed - if true then tub used - skip too small, short errors
   * >0 - dimension OK
   *  0 - not supported by bagdrop
   * -1 too tall/long
   * -2 too flat/short
   * -3 format error
   * -4 undefined
   */
  checkDimension(dimType, dim, tubUsed) {
    if (dim == null) return -4
    let min = 0
    let max = 0
    switch (dimType) {
      case 1:
        min = this.bagdropLimits.heightMin
        max = this.bagdropLimits.heightMax
        break
      case 2:
        min = this.bagdropLimits.lengthMin
        max = this.bagdropLimits.lengthMax
        break
      case 3:
        min = this.bagdropLimits.widthMin
        max = this.bagdropLimits.widthMax
        break
    }
    const dimUnit = this.bagdropLimits.dimensions === 'M' ? 'CM' : 'IN'
    if (dim.lastIndexOf(dimUnit) !== dim.length - 2) return -3
    if (dim === 'ERR')
      if (max === 0) return 0
      else return -4
    const dimension = parseInt(dim.substring(0, dim.length - 2))
    appLog(TraceLevels.LOG_EXT_TRACE, 'checkDimension: ' + dim + ' ' + dimension)
    if (isNaN(dimension)) return -3
    let tempDimension = dimension
    if (dimUnit === 'CM')
      // convert mm to cm
      tempDimension = dimension * 10
    if (!tubUsed && tempDimension < min) return -2
    if (tempDimension > max) return -1

    return dimension
  }

  /* if maxHtoLratio <= 1 or l or h are null or undefined or ERR then return false
   *  else return true when l * ratio < h
   */
  overHtoLratio(maxHtoLratio, h, l) {
    if (maxHtoLratio <= 1 || l == null || l === 'ERR' || h == null || h === 'ERR') return false
    let lInt = parseInt(l.substring(0, l.length - 2))
    let hInt = parseInt(h.substring(0, h.length - 2))
    if (isNaN(lInt) || isNaN(hInt)) return false
    return lInt * maxHtoLratio < hInt
  }

  /* if maxHtoWratio <= 1 or l or h are null or undefined or ERR then return false
   *  else return true when l * ratio < h
   */
  overHtoWratio(maxHtoWratio, h, w) {
    if (maxHtoWratio <= 1 || w == null || w === 'ERR' || h == null || h === 'ERR') return false
    if (config.minWidth && config.minWidth > w) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'overHtoWratio - skip width check: ' + w + ' minWidth: ' + config.minWidth)
      return false
    }
    let wInt = parseInt(w.substring(0, w.length - 2))
    let hInt = parseInt(h.substring(0, h.length - 2))
    if (isNaN(wInt) || isNaN(hInt)) return false
    return wInt * maxHtoWratio < hInt
  }

  getSelectedPassenger() {
    return this.store.getState().responses.itineraryInfo.passengerBagtagDetails.passenger
    //let passengers = this.store.getState().responses.passengerBagtagDetails
    /*
     for (var i = 0; i < passengers.length; i++){
     if (passengers[i].status == 'selected')
     return passengers[i];
     }
     */
    //return passengers[0]
  }

  getBags() {
    let bags = []
    if (this.store.getState().responses.itineraryInfo) {
      let itInfo = this.store.getState().responses.itineraryInfo
      if (itInfo.passengerBagtagDetails) {
        for (let i = 0; i < itInfo.passengerBagtagDetails.length; i++) {
          for (let j = 0; j < itInfo.passengerBagtagDetails[i].bagTags.length; j++) {
            let bpScanned = itInfo.passengerBagtagDetails[i].bpScanned
            let bag = itInfo.passengerBagtagDetails[i].bagTags[j]
            bag.paxOrdinal = itInfo.passengerBagtagDetails[i].passenger.ordinal
            bag.paxName =
              itInfo.passengerBagtagDetails[i].passenger.firstName +
              ' ' +
              itInfo.passengerBagtagDetails[i].passenger.lastName
            bag.bpScanned = bpScanned
            bags.push(bag)
          }
        }
      }
    }
    return bags
  }

  // skip bags on heavyBagsList
  getNumberOfNotActivatedBags() {
    const bags = this.getBags()
    let NumberOfNotActivatedBags = 0
    for (let i = 0; i < bags.length; i++) {
      //if (bags[i].status=='BAG_PENDING')
      if (
        bags[i].bpScanned &&
        bags[i].status !== BaggageStatus.ACTIVATED &&
        this.heavyBagsList.indexOf(bags[i].baseTagNo + ',') < 0
      ) {
        NumberOfNotActivatedBags++
      }
    }
    return NumberOfNotActivatedBags
  }

  getCurrentFlight() {
    const city = this.store.getState().kioskInfo.airportCode
    const flights = this.store.getState().responses.itineraryInfo.flights
    for (let i = 0; i < flights.length; i++) {
      if (flights[i].departureSchedule.schedule.airport.code === city) return flights[i]
    }
    return null
  }

  /** Complete the quit transaction with the given reason.*/
  doQuit(reason, text, OOSTransition, from) {
    //updated code to handle BAGUPDATE (error, timeout, quit, no more bags)
    history.push('pleaseWait')
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'doQuit: appFlow: ' + appFlow + ' reason: ' + reason + ' ' + (OOSTransition === 'OOS' ? 'OOS' : '')
    )
    if (from) {
      logEvent('ETSTransaction End,' + reason + ' from ' + from)
    } else {
      logEvent('ETSTransaction End, ' + reason)
    }

    const eventLogger = getEventLogger()
    if (eventLogger) {
      eventLogger.ClientSessionId = null
      eventLogger.postEventLogs()
    }

    //stop any delayed navigation
    this.stopDelayedNavigation()

    if (appFlow === 6 && reason === ErrCodes.USER_QUIT) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'doQuit: skip - dispatch active: ' + appFlow + ' ' + this.getCurrentPage())
    } else if (appFlow === 8) {
      // call doQuit in closing Remarks
      this.quitReason = reason
      this.quitDetail = OOSTransition
      this.handleQuitProcess()
      // this.resetData()
    } else if (appFlow > 1 && appFlow !== 10 && appFlow < 20) {
      // to avoid double click; not allowed when CC#RA started
      this.setAppFlow(10)
      this.quitReason = reason
      this.quitDetail = OOSTransition
      // this.resetData()
      this.disableISBD()
      if (config.sendBagUpdateExitCases === 'Y') {
        callBagUpdate('', reason, []) // the quit reason applies to all bags which are not COMPLETE
      } else if (appFlow !== 8) {
        // closing remarks already displayed
        if (config.enablePrintReceipt && this.store.getState().localData.numberOfProcessedBags > 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Build template for print')
          this.store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
        } else if (this.store.getState().localData.numberOfProcessedBags > 0) {
          if (config.waitForIngested && this.inIngestLoop) {
            navigate('PleaseWait')
          } else {
            delay(0.1).then(
              function () {
                this.handleQuitProcess()
              }.bind(this)
            )
          }
        } else {
          delay(0.1).then(
            function () {
              this.handleQuitProcess()
            }.bind(this)
          )
        }
      }
    } else if (appFlow <= 1 || appFlow > 20) {
      sendErrorLog(reason)
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'doQuit: isTransactionStarted: ' + this.store.getState().sessions.cussTxnStarted
      )
      if (appFlow >= 1 || this.store.getState().sessions.cussTxnStarted) {
        let endTxnState = getEndTxtState(reason)
        doETSTransactionEnd(endTxnState, reason)
        // this.resetData()
      }
      // let lang = this.store.getState().localData.locale.toUpperCase()
      // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_END, lang, formatTSDdescription(reason))
      if (OOSTransition === 'OOS') {
        completeCUSSTransaction(true)
      } else {
        completeCUSSTransaction(false)
      }
    } else if (appFlow === 10) {
      // this.resetData()
      // error during print from ErrorPage
      this.quitReason = reason
      this.quitDetail = OOSTransition

      this.handleQuitProcess()
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'doQuit: unexpected appFlow: ' + appFlow + ' ' + this.getCurrentPage())
      // all appFlow codes handles above
    }
  }

  handleQuitProcess() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'Quit reason: ' + this.quitReason + ' ' + this.quitDetail)
    if (this.quitReason === BAG_TXN_NOMORE) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'handleQuitProcess goto --> ClosingRemarks')
      this.setAppFlow(8)
      navigate('ClosingRemarks', 8)
      return
    }
    sendErrorLog(this.quitReason)
    let endTxnState = getEndTxtState(this.quitReason)
    doETSTransactionEnd(endTxnState, this.quitReason)
    // let lang = this.store.getState().localData.locale.toUpperCase()
    // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_END, lang, formatTSDdescription(this.quitReason))
    completeCUSSTransaction(this.quitDetail === 'OOS')
    this.resetData()
  }

  enableISBD() {
    if (config.callCR === 'T') {
      this.sendAEACommand('CR', config.cancelTimeout, 100, 'enableISBD') // valid in any appFlow
    }
  }

  disableISBD() {
    if (config.callCR === 'T') {
      this.sendAEACommand('CW', config.cancelTimeout, 100, 'disableISBD') // valid in any appFlow
    } else {
      //      this.sendAEACommand('CC#C1', config.cancelTimeout, 100, 'disableISBD')  // valid in any appFlow
    }
  }

  resetIntrusionDuringDispatch() {
    this.IntrusionDuringDispatch = false
  }

  resetData() {
    //reset transaction level variables
    this.inIngestLoop = false
    this.SQStart = -1
    this.BQStart = 0
    this.BHSXStart = 0
    this.IntrusionStart = 0
    this.PutBagStart = 0
    this.IntrusionDuringDispatch = false
    this.bagRepositionCount = 0
    this.bagMaxWeight = 0
    this.bagMaxWeightUnit = ''
    this.bagIngested = 0 // maybe not needed
    if (this.delayedNavigationTimer > 0) clearTimeout(this.delayedNavigationTimer)
    this.delayedNavigationTimer = 0
    this.quitReason = ''
    this.quitDetail = ''
    this.heavyBagsList = ''

    this.store.dispatch(updateLocalData('numberOfProcessedBags', 0))
    this.store.dispatch(updateError(null))
  }

  setSQStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setSQStart: ' + val)
    this.SQStart = val
  }

  setIntrusionStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setIntrusionStart: ' + val)
    this.IntrusionStart = val
  }

  setPutBagStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setPutBagStart: ' + val)
    this.PutBagStart = val
  }

  getCurrentPage() {
    return history.location
  }

  resetRepositionCount() {
    this.bagRepositionCount = 0
  }

  navigateAfterDelay(page) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'navigateAfterDelay page: ' + page + ' timerId: ' + this.delayedNavigationTimer)
    if (this.delayedNavigationTimer !== 0) {
      this.delayedNavigationTimer = 0
      navigate(page)
    }
  }

  getErrorCode() {
    /*let ed = this.store.getState().errorDetails
    let ec = this.store.getState().errorDetails ? this.store.getState().errorDetails.errCode : ''
    let nt = Object.keys(this.store.getState().errorDetails).length === 0*/
    if (!isEmpty(this.store.getState().errorDetails)) return this.store.getState().errorDetails.errCode
    else return ''
  }

  stopDelayedNavigation() {
    let delayedNavigationTimer = this.delayedNavigationTimer
    if (delayedNavigationTimer !== 0) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'stopDelayedNavigation timerId: ' + delayedNavigationTimer)
      clearTimeout(delayedNavigationTimer)
      this.delayedNavigationTimer = 0
    }
  }

  navigateWithMinDisplay(page, minDisplay, startTimerName) {
    // make sure that Intrusion/PutBagOnBelt was displayed minDisplay seconds
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'navigateWithMinDisplay page: ' + page + ' minDisplay: ' + minDisplay + ' startTimerName: ' + startTimerName
    )
    let oldDelayedNavigationTimer = this.delayedNavigationTimer
    let startTimer = this.IntrusionStart
    if (startTimerName === 'PutBagStart') {
      startTimer = this.PutBagStart
    } else {
      startTimer = this.IntrusionStart
    }
    //  	var startTimer = state.getNumber(startTimerName);
    //  	state.set(startTimerName, -1); // clear timer
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'navigateWithMinDisplay startTimer: ' +
        startTimerName +
        ': ' +
        startTimer +
        ' oldDelayedNavigationTimer: ' +
        oldDelayedNavigationTimer
    )
    if (startTimer != null) {
      if (oldDelayedNavigationTimer !== 0) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'navigateWithMinDisplay - already waiting - skip this one')
      } else {
        let newTime = new Date().getTime()
        appLog(TraceLevels.LOG_EXT_TRACE, 'startTimer delta: ' + (newTime - startTimer))
        if (newTime - startTimer > minDisplay * 1000 - config.minDeltaToStartTimer) navigate(page)
        else {
          let pageDelay = minDisplay * 1000 - (newTime - startTimer) //1000
          //var delayedNavigationTimer = navigateAfterDelay.delay(pageDelay,page)
          //  				this.delayedNavigationTimer = window.setTimeout(function(page) {
          //  					this.navigateAfterDelay(page)
          //  		        }, pageDelay)
          this.delayedNavigationTimer = setTimeout(this.navigateAfterDelay.bind(this, page), pageDelay)
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            'startTimer page delay: ' + pageDelay + ' timerid: ' + this.delayedNavigationTimer
          )
        }
      }
    } else {
      navigate(page)
    }
  }

  checkMaxReposition(funcName, errCode, msgCode, transitionPage, params, summaryParams = null) {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'bagRepositionCount: ' +
        this.bagRepositionCount +
        ' config.maxBagRepositionRetries: ' +
        config.maxBagRepositionRetries
    )
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'checkMaxReposition params:' +
        ' call from: ' +
        funcName +
        ' error Code: ' +
        errCode +
        ' tansition after error page: ' +
        transitionPage +
        ' params for the message: ' +
        params
    )
    this.setAppFlow(10)
    if (
      config.maxBagRepositionRetries &&
      config.maxBagRepositionRetries >= 0 &&
      this.bagRepositionCount >= config.maxBagRepositionRetries
    ) {
      // this.store.dispatch(updateError(ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED, '', 'checkMaxReposition'))
      goToLocalGenericError(
        funcName,
        ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED,
        'Error_RepositionTriesExceeded',
        'END_TXN',
        null,
        summaryParams
      )
      // history.push('error')
    } else {
      //TODO should we set appFlow =2 here ? should be 10
      //this.setAppFlow(2)
      this.bagRepositionCount++
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Reposition bag screen.')
      goToLocalGenericError(funcName, errCode, msgCode, transitionPage, params, summaryParams)
      // history.push('RepositionBag')
    }
  }

  validateBagtag(
    currBagtag // return false when the bag tag not valid
  ) {
    //check - only one bagtag allowed
    appLog(TraceLevels.LOG_EXT_TRACE, 'Bagtag: ' + currBagtag)
    if (currBagtag == null || currBagtag === 'ERR') {
      // should not happen - but happens
      // this.store.dispatch(updateError(ErrCodes.BAG_NOSCAN, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition('commandCompleted appFlow 5', ErrCodes.BAG_NOSCAN, 'RepositionBag1', 'PutBagOnBelt', null)
      return false
    }
    this.store.dispatch(updateLocalData('bagtag', currBagtag))

    if (currBagtag.length > 10) {
      // one bagtag length is 10
      // this.store.dispatch(updateError(ErrCodes.MULTIPLE_TAGS, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.MULTIPLE_TAGS,
        'ErrorMismatch2',
        'PutBagOnBelt',
        null,
        'ErrorMismatch'
      )
      // history.push('ErrorMismatch')
      return false
    }

    // Assumed that batgatg length is 10 at this point
    //check if bagtag is on the list of passenger's bagtags with 'not activated' status
    //check if the pax scanned the BP - if not
    let bags = this.getBags()
    let bagtagFound = false
    let alreadyActive = false
    let bpNotScanned = false
    let notActiveBags = false
    let scannedPax = this.store.getState().responses.itineraryInfo.scanSequence
    let compBagTag = formatBagTagNumber(currBagtag)
    for (let i = 0; i < bags.length; i++) {
      delete bags[i].bpScanned
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'bagtag i: ' +
          i +
          ' status: ' +
          bags[i].status +
          ' number: ' +
          bags[i].baseTagNo +
          ' paxOrd: ' +
          bags[i].paxOrdinal
      )
      //if (bags[i].status=='BAG_PENDING')
      if (bags[i].status !== BaggageStatus.ACTIVATED) {
        notActiveBags = true
        if (bags[i].baseTagNo === compBagTag) {
          // if (scannedPax.indexOf(bags[i].paxOrdinal) > -1) {
          bagtagFound = true
          this.store.dispatch(updateLocalData('bagtagObject', bags[i]))
          if (scannedPax.indexOf(bags[i].paxOrdinal) <= -1) {
            this.store.dispatch(updateLocalData('updateErrorName', bags[i].paxName))
          }
          // }
          //  else {
          //   bpNotScanned = true
          //   this.store.dispatch(updateLocalData('updateErrorName', bags[i].paxName))
          //   appLog(
          //     TraceLevels.LOG_EXT_TRACE,
          //     'bagtag: ' + compBagTag + ' belongs to pax: ' + bags[i].paxOrdinal + ' - not scanned BP yet'
          //   )
          // }
          /*
          if (config.validateMaxWeightFromETS) {
            this.bagMaxWeight = bags[i].limits.maxWeight
            this.bagMaxWeightUnit = bags[i].limits.weightUnit
          }
          */
          break
        }
      } else if (bags[i].baseTagNo === compBagTag) {
        alreadyActive = true
      }
    }
    if (alreadyActive) {
      // goto error page
      // this.store.dispatch(
      //   updateError(
      //     ErrCodes.TAG_ALREADY_ACTIVE,
      //     '',
      //     'commandCompleted appFlow 5',
      //     notActiveBags && !config.endTxnWhenBagtagAlreadyActive ? RETRY : ''
      //   )
      // )
      let transitionPage = notActiveBags && !config.endTxnWhenBagtagAlreadyActive ? 'PutBagOnBelt' : 'END_TXN'
      goToLocalGenericError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_ALREADY_ACTIVE,
        'Error_BagtagAlreadyActive',
        transitionPage,
        null
      )
      // history.push('error')
      return false
    }
    if (bpNotScanned) {
      // goto Error Mismatch
      // add name as details
      // this.store.dispatch(updateError(ErrCodes.TAG_SCANNED_BP_NOT_SCANNED, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatchBP.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_SCANNED_BP_NOT_SCANNED,
        'ErrorMismatch4',
        'PutBagOnBelt',
        null,
        'ErrorBPMismatch'
      )
      // history.push('ErrorBPMismatch')
      return false
    }
    if (!bagtagFound) {
      // goto Error Mismatch
      // this.store.dispatch(updateError(ErrCodes.TAG_NOT_FOUND_IN_PNR, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_NOT_FOUND_IN_PNR,
        'ErrorMismatch1',
        'PutBagOnBelt',
        null,
        'ErrorMismatch'
      )
      // history.push('ErrorMismatch')
      return false
    }
    // clear Mismatch BP error
    this.store.dispatch(updateLocalData('updateMismatchBPError', false))

    return true // ALL OK
  }

  validateHeavyBag(heavyLabel, cw) {
    const weight = parseFloat(cw.substring(0, cw.length - 2))
    if (config.checkHeavyLabel) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'heavy label check is true')
      if (weight > config.heavyBagWeight) {
        //bag is heavy as weighs more than config weight. Attach heavy label
        appLog(TraceLevels.LOG_EXT_TRACE, 'bag weight: ' + weight)
        appLog(TraceLevels.LOG_EXT_TRACE, 'heavy label ' + heavyLabel)
        if (!heavyLabel || heavyLabel === '' || heavyLabel === 'undefined') {
          //heavy label not attached for heavy bag
          return false
        }
        if (heavyLabel === 'HEAVY') {
          return true
        } else {
          return false
        }
      }
    }
    return true
  }
  // return false when not valid
  validateWeightAndDimensions(weight, height, len, width, tubStatus, tubProvided, bagtag) {
    // check if all values in SBD limits
    let tubUsed = tubStatus === '1'
    let tubRequirementCheck = tubStatus === '2'
    if (tubRequirementCheck) {
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_REQUIRE_USEBIN,
        'Error_BagRequireUseBin',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }
    // Check weight
    let weightCheck = this.checkWeight(weight, this.bagMaxWeight, this.bagMaxWeightUnit, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp weight: ' + weightCheck)
    //let errMsg = ''
    if (weightCheck < 0) {
      let param0 = ''
      let param1 = this.bagdropLimits.measurement
      switch (weightCheck) {
        case -1:
          param0 = this.bagdropLimits.upperScaleLimit
          if (this.getNumberOfNotActivatedBags() > 1) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_MAX_WEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            this.checkMaxReposition(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_MAX_WEIGHT_ADDBAG,
              'Error_BagOverweightMaxAddBag',
              'PutBagOnBelt',
              { maxWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
          } else {
            this.setAppFlow(0)
            // this.store.dispatch(updateError(ErrCodes.BAG_MAX_WEIGHT, '', 'commandCompleted appFlow 5', param0, param1))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_MAX_WEIGHT,
              'Error_BagOverweightMax',
              'END_TXN',
              { maxWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
            // history.push('error')
          }
          break
        case -2:
          param0 = this.bagdropLimits.lowerScaleLimit
          if (config.useBinForSmallBags) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_UNDERWEIGHT_USEBIN, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            if (tubProvided) {
              this.checkMaxReposition(
                'commandCompleted appFlow 5',
                ErrCodes.BAG_UNDERWEIGHT_USEBIN,
                'Error_BagUnderweightUseBin',
                'PutBagOnBelt',
                { minWeight: param0, weightUnit: param1 },
                {
                  tagNumber: bagtag
                }
              )
            } else {
              // if tub not provide from airport
              this.checkMaxReposition(
                'commandCompleted appFlow 5',
                ErrCodes.BAG_UNDERWEIGHT_USEBIN,
                'Error_BagUnderweightUseBin',
                'PutBagOnBelt',
                { minWeight: param0, weightUnit: param1 },
                {
                  tagNumber: bagtag
                }
              )
            }
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_UNDERWEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            this.checkMaxReposition(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNDERWEIGHT_ADDBAG,
              'Error_BagUnderweightAddBag',
              'PutBagOnBelt',
              { minWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
          } else {
            this.setAppFlow(0)
            // this.store.dispatch(updateError(ErrCodes.BAG_UNDERWEIGHT, '', 'commandCompleted appFlow 5', param0, param1))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNDERWEIGHT,
              'Error_BagUnderweight',
              'END_TXN',
              { minWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
            // history.push('error')
          }
          break
        default:
          // errMsg = ErrCodes.BAG_UNABLE_TO_WEIGHT
          // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 5'))
          this.setAppFlow(0)
          goToLocalGenericError(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNABLE_TO_WEIGHT,
            'Error_UnableToWeigh',
            'END_TXN',
            null,
            {
              tagNumber: bagtag
            }
          )
          // history.push('error')
          break
      }
      return false
    }

    // Check height
    let heightCheck = this.checkDimension(1, height, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp height: ' + heightCheck)
    if (heightCheck === -1) {
      // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_TOO_TALL,
        'Error_BagTooTall',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    } else if (heightCheck === -2) {
      if (config.useBinForSmallBags) {
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT_USEBIN, '', 'commandCompleted appFlow 5'))
        if (tubProvided) {
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNDERHEIGHT_USEBIN,
            'Error_BagUnderheightUseBin',
            'PutBagOnBelt',
            null,
            {
              tagNumber: bagtag
            }
          )
        } else {
          // if tub not provide from airport
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNDERHEIGHT_USEBIN,
            'Error_BagUnderheightUseBin',
            'PutBagOnBelt',
            null,
            {
              tagNumber: bagtag
            }
          )
        }
      } else if (this.getNumberOfNotActivatedBags() > 1) {
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT_ADDBAG, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_UNDERHEIGHT_ADDBAG,
          'Error_BagUnderheightAddBag',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      } else {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_UNDERHEIGHT,
          'Error_BagUnderheight',
          'END_TXN',
          null,
          {
            tagNumber: bagtag
          }
        )
        // history.push('error')
      }
      return false
    } else if (heightCheck < -2) {
      // this.store.dispatch(updateError(ErrCodes.BAG_UNABLE_TO_MEASURE, '', 'commandCompleted appFlow 5'))
      this.setAppFlow(0)
      goToLocalGenericError(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_UNABLE_TO_MEASURE,
        'Error_UnableToMeasure',
        'END_TXN',
        null,
        {
          tagNumber: bagtag
        }
      )
      // history.push('error')
      return false
    }

    // Check length
    const lengthCheck = this.checkDimension(2, len, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp length: ' + lengthCheck)
    if (lengthCheck === -1) {
      if (this.getNumberOfNotActivatedBags() > 1) {
        // this.store.dispatch(updateError(ErrCodes.BAG_LONG_ADDBAG, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_LONG_ADDBAG,
          'Error_BagLongAddBag',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      } else {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_LONG, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError('commandCompleted appFlow 5', ErrCodes.BAG_LONG, 'Error_BagLong', 'END_TXN', null, {
          tagNumber: bagtag
        })
        // history.push('error')
      }
      return false
    } else if (lengthCheck === -2) {
      // check if height to length ratio is is OK
      if (this.overHtoLratio(config.maxHtoLratioTooShort, height, len))
        // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_RATIO_TOO_HIGH,
          'RepositionBag2',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      // continue with bag too short
      else {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 5'))
        let tempmsgCode = config.useBinForSmallBags && tubProvided ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 5', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null, {
          tagNumber: bagtag
        })
      }
      return false
    } else if (lengthCheck < -2) {
      // this.store.dispatch(updateError(ErrCodes.BAG_UNABLE_TO_MEASURE, '', 'commandCompleted appFlow 5'))
      this.setAppFlow(0)
      goToLocalGenericError(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_UNABLE_TO_MEASURE,
        'Error_UnableToMeasure',
        'END_TXN',
        null,
        {
          tagNumber: bagtag
        }
      )
      // history.push('error')
      return false
    }
    // check vertical
    // check if height to length ratio is is OK
    if (this.overHtoLratio(config.maxHtoLratioAll, height, len)) {
      // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_RATIO_TOO_HIGH,
        'RepositionBag2',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }

    // check if height to width ratio is is OK (when width is available)
    if (this.overHtoWratio(config.maxHtoWratioAll, height, width)) {
      // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_RATIO_TOO_HIGH,
        'RepositionBag2',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }

    return true // All OK
  }

  getOverrideCode(bagtag) {
    let ovr = this.store.getState().localData.overrideCode
    if (ovr && bagtag && ovr.bagtag === bagtag) {
      return ovr.code
    } else {
      return ''
    }
  }
  getAirLineInfoFromBagtag(bagtag) {
    const airlineCode = bagtag.substring(1, 4)
    const airLine = config.transferInfo.find((item) => {return item.digitCode === airlineCode})
    return airLine
  }
}
