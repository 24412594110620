/**
 * ETS Request and Response - checkTravelDoc
 */

import { store, history, getSummaryStore } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { TransitionCodes, checkTravelDocAction, PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { isNotEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer, getNextPassenger, navigate } from 'utils/helper'
import { updateLocalData } from 'actions/localActions'

function buildRequest(mediaData) {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      currentClientTime: 0,
      asyncRequested: false,
      //paxOrdinal: paxOrdinal,
      forDomesticAPIS: false,
      action: checkTravelDocAction.CHECK_VISA_REQUIRED,
      // action: 'MODIFY',
      jsonExtend: null,
      mediaTrackData: mediaData,
      checkinContractReturn: false,
    }
  )
}

export function checkTravelDoc(mediaData) {
  // const acuantClient = getAcuantClient()
  const summaryStore = getSummaryStore()
  let request = buildRequest(mediaData)
  let currentPassenger = null
  let validateResult = store.getState().localData.validatePaxResult
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'checkTravelDoc() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitDocCheck' } })
    return fetchWithTimeout('checkTravelDoc/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'checkTravelDoc() receive response ... ' + JSON.stringify(json, replacer))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          if (json.etsResponse.jsonExtend) {
            dispatch(updateLocalData('updateIsLastPassenger', true))
          } else {
            dispatch(updateLocalData('updateIsLastPassenger', false))
          }
          currentPassenger = json.etsResponse.itineraryInfo.passengers[0]
          for (let i = 0; i < validateResult.length; i++) {
            if (validateResult[i].ordinal === currentPassenger.ordinal) {
              validateResult[i].passportScanned = true
              validateResult[i].visaCheck = json.etsResponse.visaRequired
                ? PASSENGER_VALIDATION_STATUS.UNKNOWN
                : PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
            }
          }
          dispatch(updateLocalData('updateValidatePaxResult', validateResult))
          dispatch(updateLocalData('updateCurrentPassenger', currentPassenger))
          switch (json.transition) {
            case TransitionCodes.CUSTOM_4:
              summaryStore.endDocScan(true)
              navigate('AgentScan', 21)
              // dispatch(genericCommand('validateAgent', null, null))
              break
            case 'FACE_RECOGNITION':
              summaryStore.endDocScan(true)

              // no privacy Statement for Core app, defualt as true
              let privacyAccept = true
              summaryStore.startFacialData(privacyAccept)
              /* if (config.enablePassportPhotoCheck) {*/
              navigate('TakePhoto', 21)
              //}
              break
            case 'PAX_VALIDATION':
              navigate('ScanDocuments', 21)
              break
            default:
          }
        } else if (json.error) {
          goToFetchGenericError('checkTravelDoc', json, dispatch, { error: json.errorCode })
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'checkTravelDoc', 'error')
      })
  }
}
