import React from 'react'
import PropTypes from 'prop-types'
import { Button} from 'components'
import { Spacer } from 'components/styledComponents'
import './LanguageButton.scss'

const LanguageButton = (props) => {
  return (
    <Button
      id={props.lang}
      color={props.color}
      bgColor={props.bgColor}
      height={props.height}
      width={props.width}
      onClick={props.onClick}
      fontSize={props.fontSize}
      justifyContent={props.justifyContent}
      cssName={props.cssName}
    >
      {props.showIcon ? <div className="lang-btn-icon" /> : ''}
      {/* <Spacer width="12px" /> */}
      <div>{props.text}</div>
    </Button>
  )
}

LanguageButton.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  text: PropTypes.string,
  lang: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconSize: PropTypes.number,
}
LanguageButton.defaultProps = {
  className: 'button-wrapper',
  iconSize: 64,
}

export default LanguageButton
