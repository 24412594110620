import {deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import AccessibilityListener from 'listeners/accessibilityListener'
/**
 *  Device representing an Accessibility
 *  @extends {Device}
 */
export default class Accessibility extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Accessibility'
    /**
     * Device id - deviceIds.CUSS_ACCESSIBILITY {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.CUSS_ACCESSIBILITY
    /** @type {Number} set by getStatusHeadset in listener */
    this.headsetStatus = null
    /** @type {Boolean} set by isHeadsetAvailable in listener */
    this.headsetAvailable = null
//  this.headsetInserted = null
//  this.headsetDeviceHelp = null
    /** @type {Number} set by getStatusKeypad in listener */
    this.keypadStatus = null
//  this.keypadAvailable = null
//  this.keypadType = null
//  this.keypadEvents = null
//  this.keypadDeviceHelp = null
//  this.availableLanguages = null
//  this.defaultLanguage = null
//  this.deviceHelpLanguages = null
//  this.kioskDeviceHelp = null
    /** @type {Number} set by getStatusTTS in listener */
    this.ttsStatus = null
//  this.ttsAvailable = null
//  this.accessibilitySupported = null
    /** @type {Boolean} set by headsetInserted() and headsetRemoved() in listener */
    this.enabled = false
    this.setListener(new AccessibilityListener())
  }

  /** Asynch call to check if accessibility is supported. */
  isAccessibilitySupported()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'isAccessibilitySupported')
      this.logMessage(TraceLevels.LOG_TRACE, 'isAccessibilitySupported()')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isAccessibilitySupported() exception: ' + e)
    }
  }

  /** Asynch call to check if headset is available. */
  isHeadsetAvailable()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'isHeadsetAvailable')
      this.logMessage(TraceLevels.LOG_TRACE, 'isHeadsetAvailable(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isHeadsetAvailable() exception: ' + e)
    }
  }

  /** Asynch call to check if headset is inserted. */
  isHeadsetInserted()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'isHeadsetInserted')
      this.logMessage(TraceLevels.LOG_TRACE, 'isHeadsetInserted(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isHeadsetInserted() exception: ' + e)
    }
  }

  /** Asynch call to get the status of the headset. */
  getStatusHeadset()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getStatusHeadset')
      this.logMessage(TraceLevels.LOG_TRACE, 'getStatusHeadset(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getStatusHeadset() exception: ' + e)
    }
  }

  /** Asynch call to enable the headset. */
  enableHeadset()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'enableHeadset()')
      this.socket.sendCommand(this.deviceId, 'enableHeadset')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'enableHeadset() exception: ' + e)
    }
  }

  /** Asynch call to disable the headset. */
  disableHeadset()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'disableHeadset()')
      this.socket.sendCommand(this.deviceId, 'disableHeadset')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'disableHeadset() exception: ' + e)
    }
  }

  /** Asynch call to get the status of the TTS. */
  getStatusTTS()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getStatusTTS')
      this.logMessage(TraceLevels.LOG_TRACE, 'getStatusTTS(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getStatusTTS() exception: ' + e)
    }
  }
  /** Asynch call to enable the TTS. */
  enableTTS()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'enableTTS()')
      this.socket.sendCommand(this.deviceId, 'enableTTS')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'enableTTS() exception: ' + e)
    }
  }
  /** Asynch call to disable the TTS. */
  disableTTS()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'disableTTS()')
      this.socket.sendCommand(this.deviceId, 'disableTTS')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'disableTTS() exception: ' + e)
    }
  }

  /** Asynch call to check if the TTS is available. */
  isTTSAvailable()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'isTTSAvailable')
      this.logMessage(TraceLevels.LOG_TRACE, 'isTTSAvailable(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isTTSAvailable() exception: ' + e)
    }
  }

  /** Asynch call play the text.
   * @param {String} data - string to play - plain or in ssml format
   * @param {Boolean} blocking - send blocking play command to the platform
   */
  speak(data, blocking)
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'speak', data, blocking)
      this.logMessage(TraceLevels.LOG_TRACE, 'speak(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'speak() exception: ' + e)
    }
  }

  /** Asynch call to play the text.
   * @param {String} data - string to play - plain or in ssml format
   */
  play(data)
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'play', data)
      this.logMessage(TraceLevels.LOG_TRACE, 'play(), data:' + (data ? (data.substring(0, 40) + '...') : ''))
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'play() exception: ' + e)
    }
  }

  /** Asynch call to pause playing the text. */
  pause()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'pause()')
      this.socket.sendCommand(this.deviceId, 'pause')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'pause() exception: ' + e)
    }
  }

  /** Asynch call to resume playing the text. */
  resume()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'resume()')
      this.socket.sendCommand(this.deviceId, 'resume')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'resume() exception: ' + e)
    }
  }

  /** Asynch call to stop playing the text. */
  stop()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'stop()')
      this.socket.sendCommand(this.deviceId, 'stop')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'stop() exception: ' + e)
    }
  }

  /** Asynch call to get the status of the keypad. */
  getStatusKeypad()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getStatusKeypad')
      this.logMessage(TraceLevels.LOG_TRACE, 'getStatusKeypad(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getStatusKeypad() exception: ' + e)
    }
  }
  /** Asynch call to enable the keypad. */
  enableKeypad()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'enableKeypad()')
      this.socket.sendCommand(this.deviceId, 'enableKeypad')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'enableKeypad() exception: ' + e)
    }
  }
  /** Asynch call to disable the keypad. */
  disableKeypad()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'disableKeypad()')
      this.socket.sendCommand(this.deviceId, 'disableKeypad')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'disableKeypad() exception: ' + e)
    }
  }
  /** Asynch call to get supported keypad events. */
  getKeypadEvents()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getKeypadEvents')
      this.logMessage(TraceLevels.LOG_TRACE, 'getKeypadEvents(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getKeypadEvents() exception: ' + e)
    }
  }
  /** Asynch call to get the keypad type. */
  getKeypadType()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getKeypadType')
      this.logMessage(TraceLevels.LOG_TRACE, 'getKeypadType(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getKeypadType() exception: ' + e)
    }
  }
  /** Asynch call to check if the keypad is available. */
  isKeypadAvailable()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'isKeypadAvailable')
      this.logMessage(TraceLevels.LOG_TRACE, 'isKeypadAvailable(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isKeypadAvailable() exception: ' + e)
    }
  }

  /** Asynch call to get status of the keypad, headset and TTS - expected 3 separate responses. */
  status()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getStatusHeadset')
      this.socket.sendRequest(this.deviceId, 'getStatusKeypad')
      this.socket.sendRequest(this.deviceId, 'getStatusTTS')
      this.logMessage(TraceLevels.LOG_TRACE, 'status(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'status() exception: ' + e)
    }
  }
  /** Asynch call to get the headset device help. */
  getHeadsetDeviceHelp()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getHeadsetDeviceHelp')
      this.logMessage(TraceLevels.LOG_TRACE, 'getHeadsetDeviceHelp(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getHeadsetDeviceHelp() exception: ' + e)
    }
  }
  /** Asynch call to get the keypad device help. */
  getKeypadDeviceHelp()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getKeypadDeviceHelp')
      this.logMessage(TraceLevels.LOG_TRACE, 'getKeypadDeviceHelp(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getKeypadDeviceHelp() exception: ' + e)
    }
  }
  /** Asynch call to get the list of available languages. */
  getAvailableLanguages()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getAvailableLanguages')
      this.logMessage(TraceLevels.LOG_TRACE, 'getAvailableLanguages(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getAvailableLanguages() exception: ' + e)
    }
  }

  /** Asynch call to get the device help languages. */
  getDeviceHelpLanguages()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getDeviceHelpLanguages')
      this.logMessage(TraceLevels.LOG_TRACE, 'getDeviceHelpLanguages(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getDeviceHelpLanguages() exception: ' + e)
    }
  }

  /** Asynch call to get the default language. */
  getDefaultLanguage()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getDefaultLanguage')
      this.logMessage(TraceLevels.LOG_TRACE, 'getDefaultLanguage(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getDefaultLanguage() exception: ' + e)
    }
  }
  /** Asynch call to set the default language.
  * @param {String} lang - default language
  */
  setDefaultLanguage(lang)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'setDefaultLanguage()')
      this.socket.sendCommand(this.deviceId, 'setDefaultLanguage', lang)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'setDefaultLanguage() exception: ' + e)
    }
  }

  /** Asynch call to blank the display. */
  blankDisplay()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'blankDisplay()')
      this.socket.sendCommand(this.deviceId, 'blankDisplay')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'blankDisplay() exception: ' + e)
    }
  }
  /** Asynch call to show the display. */
  showDisplay()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'showDisplay()')
      this.socket.sendCommand(this.deviceId, 'showDisplay')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'showDisplay() exception: ' + e)
    }
  }
  /** Asynch call to suppress the touch screen. */
  suppressTouchScreen()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'suppressTouchScreen()')
      this.socket.sendCommand(this.deviceId, 'suppressTouchScreen')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'suppressTouchScreen() exception: ' + e)
    }
  }
  /** Asynch call to resume the touch screen. */
  resumeTouchScreen()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'resumeTouchScreen()')
      this.socket.sendCommand(this.deviceId, 'resumeTouchScreen')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'resumeTouchScreen() exception: ' + e)
    }
  }
  /** Asynch call to get the diagonal of the screen in mm. */
  getScreenDiagonal()
  {
    try
    {
      this.socket.sendRequest(this.deviceId, 'getScreenDiagonal')
      this.logMessage(TraceLevels.LOG_TRACE, 'getScreenDiagonal(): ')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getScreenDiagonal() exception: ' + e)
    }
  }
  /** Asynch call to execute mouse click.
  * @param {Number} x - x position
  * @param {Number} y - y position
  */
  click(x, y)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'click()')
      if (x !== undefined && y !== undefined) {
        this.socket.sendCommand(this.deviceId, 'click', x, y)
      } else {
        this.socket.sendCommand(this.deviceId, 'click', 1, 1)
      }
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'click() exception: ' + e)
    }
  }
}
