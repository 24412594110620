import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { PageText } from 'components/styledComponents'

export const SwitchBaseStatus = {
  CHECKED: '1',
  UNCHECKED: '-1',
  CHECKED_DISABLED: '2',
  UNCHECKED_DISABLED: '-2'
}

/* interface SwitchBaseProps {
  id: string
  text: string
  status: string
  onClick: Function | undefined
  additionalText?: string
  additionalText1?: string
  additionalText2?: string
  iconStyleChecked?: string
  iconStyleUnchecked?: string
  iconStyleCheckedDisabled?: string
  iconStyleUncheckedDisabled?: string
  containerStyle?: string
  labelPlacement?: string
  labelStyle?: string
  additionalTextStyle?: string
  additionalText1Style?: string
  additionalText2Style?: string
  tabIndex?: number
  checked?: boolean
  disableLabel?: boolean
  updateStatus?: boolean
} */

const SwitchBase = (props) => {
  const {
    id,
    text,
    additionalText,
    additionalText1,
    additionalText2,
    containerStyle,
    labelPlacement,
    labelStyle,
    additionalTextStyle,
    additionalText1Style,
    additionalText2Style,
    iconStyleChecked,
    iconStyleUnchecked,
    iconStyleCheckedDisabled,
    iconStyleUncheckedDisabled,
    tabIndex,
    disableLabel,
    updateStatus
  } = props

  const [status, setStatus] = React.useState(props.status)
  const themeContext = useContext(ThemeContext)

  React.useEffect(() => {
    if (updateStatus) {
      setStatus(props.status)
    }
  }, [updateStatus, props.status])

  React.useEffect(() => {
    // unselect the selected switch previously(for radio button)
    if (props.checked === true && status === SwitchBaseStatus.UNCHECKED) {
      setStatus(SwitchBaseStatus.CHECKED)
    }
    if (!updateStatus && props.checked === false && status === SwitchBaseStatus.CHECKED) {
      setStatus(SwitchBaseStatus.UNCHECKED)
    }
  }, [props.checked])

  const handleOnClick = (e) => {
    if (props.checked) return // Radio button not able to uncheck
    let id = e.currentTarget.id
    if (status === SwitchBaseStatus.CHECKED) {
      setStatus(SwitchBaseStatus.UNCHECKED)
      props.onClick(id, SwitchBaseStatus.UNCHECKED)
    } else if (status === SwitchBaseStatus.UNCHECKED) {
      setStatus(SwitchBaseStatus.CHECKED)
      props.onClick(id, SwitchBaseStatus.CHECKED)
    }
  }

  const renderSwitchBaseIconDiv = () => {
    let iconClassName = ''
    switch (status) {
      case SwitchBaseStatus.CHECKED:
        iconClassName = iconStyleChecked
        break
      case SwitchBaseStatus.UNCHECKED:
        iconClassName = iconStyleUnchecked
        break
      case SwitchBaseStatus.CHECKED_DISABLED:
        iconClassName = iconStyleCheckedDisabled
        break
      case SwitchBaseStatus.UNCHECKED_DISABLED:
        iconClassName = iconStyleUncheckedDisabled
        break
      default:
        iconClassName = ''
    }
    return <div className={iconClassName} aria-checked={status || ''} />
  }

  const additionalDiv1 = additionalText1 ? <span className={additionalText1Style}>{additionalText1}</span> : ''
  const additionalDiv2 = additionalText2 ? <span className={additionalText2Style}>{additionalText2}</span> : ''

  const renderButton = disableLabel ? (
    renderSwitchBaseIconDiv()
  ) : (
    <>
      {labelPlacement === 'end' && renderSwitchBaseIconDiv()}
      <div dir="auto" className={labelStyle} style={{ color: themeContext.PrimaryFontColor }}>
        <PageText fontWeight='300' justifyContent='flex-start'>{text}</PageText>
        <div className={additionalTextStyle}>
          {additionalText}
          {additionalDiv1}
          {additionalDiv2}
        </div>
      </div>
      {labelPlacement === 'start' && renderSwitchBaseIconDiv()}
    </>
  )

  return (
    <button dir="auto" className={containerStyle} key={id} id={id} onClick={handleOnClick} tabIndex={tabIndex}>
      {renderButton}
    </button>
  )
}

SwitchBase.defaultProps = {
  useImageIcon: false,
  status: SwitchBaseStatus.UNCHECKED,
  tabIndex: '0',
  labelPlacement: 'end',
  disableLabel: false,
  updateStatus: true
}

export default SwitchBase
