import React, { useEffect, useContext } from 'react'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Footer } from '../footer'
import { getSBDAppMan, playSound, getDeviceManager, history } from 'main'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { TestButtons } from 'components'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { turnLights } from 'utils/lights'
import { ThemeContext } from 'styled-components'
import { useIntl } from 'react-intl'
import { PageTitle, PageSubTitle, PageHeader, PageSubContent, DynamicImage } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'

const AgentScan = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const scannedCallback = (scannedValue) => {
    dispatch(genericCommand('validateAgent', { barcodeData: scannedValue }, null))
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  useEffect(() => {
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) call barcode enable function')
      enable()
      turnLights('kioskAgentHelp', true)
    }
    return () => {
      console.log('component is unmounted.')
      disable()
      turnLights('kioskAgentHelp', false)
    }
  }, [])
  const handleActions = (e) => {
    appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'buttonScanBarcodeTEST':
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: 'EMBROSSTEST'
        })
        break
      case 'buttonScanBarcodeTESTDamaged':
        barcodeReaderCallback({
          key: 'barcodeDamaged',
          value: null
        })
        break
      case 'buttonScanBarcodeTESTERROR':
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: ''
        })
        break
      case 'buttonVerifyDocuments':
        history.push('VerifyDocuments')
        break
    }
  }

  // const errIcon = <div className="errorPage_defaultIcon" />
  // const errImage = <div className="errorPage_defaultImage" />

  const testButtons = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Scan',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonScanBarcodeTESTDamaged',
      text: 'Damaged',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonScanBarcodeTESTERROR',
      text: 'Error',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonVerifyDocuments',
      text: 'Verify Document',
      handler: handleActions,
      group: 0
    }
  ]

  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )

  const location = `agent-scan.png`
  const animationSection = <DynamicImage imageName={location} width={600} height={400} />
  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.WaitAgentVerifyDocsTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.WaitAgentVerifyDocsSubTitle)}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      {intl.formatMessage(messages.RepositionBagTitle)}
    </PageHeader>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={true}
        testData={testButtons}
      />
    </>
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>

  // return (
  //   <div className="page-container">
  //     <AuroraPanel height={auroraHeight} width={'100%'}>
  //       <PageContent flexFlow={'column'}>
  //         <PageTitle alignItems="center" flexFlow="row">
  //           {errIcon}
  //           {intl.formatMessage(messages.RepositionBagTitle)}
  //         </PageTitle>
  //         <ItineraryInfo itineraryData={null} />
  //         <PageContent flexFlow={isLandscape ? 'row' : 'column'}>
  //           <PageSubContent height={isLandscape ? null : '300px'} flex={1} width={contentWidth}>
  //             {isLandscape ? animationSection : textSection}
  //           </PageSubContent>
  //           <PageSubContent minHeight={'500px'} flex={2} width={contentWidth}>
  //             {isLandscape ? textSection : animationSection}
  //           </PageSubContent>
  //         </PageContent>
  //       </PageContent>
  //     </AuroraPanel>
  //     <PageFooter>
  //       <Footer
  //         isQuitRequired={true}
  //         quitBtnStyle={'btn-red-up'}
  //         quitBtnText={intl.formatMessage(messages.buttonQuit)}
  //         isBackRequired={false}
  //         isSkipRequired={false}
  //         isConfirmRequired={false}
  //         langBtnStyle={'btn-green-up'}
  //         isLangRequired={true}
  //       />
  //       <TestButtons data={testButtons} />
  //     </PageFooter>
  //   </div>
  // )

  // return (
  //   <div className="page-container">
  //     {popupContainer.retryMediaAccess}
  //     <div className="page-content" id="a_buttonGroup">
  //       <div className="pad_headerText" id="errorHeader" tabIndex="0">
  //         <span className="page-title">
  //           {/* <span className="errorPage_iconWrapper">{errIcon}</span> */}
  //           <span className="errorPage_text_innerSpan">
  //             <FormattedMessage {...messages.PleaseWaitTitle} />
  //           </span>
  //         </span>
  //       </div>
  //       <div className="pad-body" id="errorBody">
  //         <FormattedMessage {...messages.WaitAgentVerifyDocs} />
  //         <div className="emb_animation_wrapper_bottom">{errImage}</div>
  //       </div>
  //     </div>
  //     <Footer
  //       isQuitRequired={true}
  //       quitBtnStyle={'btn-red-up'}
  //       quitBtnText={intl.formatMessage(messages.buttonQuit)}
  //       isBackRequired={false}
  //       isSkipRequired={false}
  //       isConfirmRequired={false}
  //       langBtnStyle={'btn-green-up'}
  //       isLangRequired={true}
  //     />
  //     <TestButtons data={testButtons} />
  //   </div>
  // )
}

export default AgentScan

// const AgentScan = (props) => {
//   const dispatch = useDispatch()
//   const scannedCallback = (scannedValue) => {
//     dispatch(genericCommand('validateAgent', { barcodeData: scannedValue }, null))
//   }
//   const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
//   useEffect(() => {
//     if (config.isCUSSRequired) {
//       appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) call barcode enable function')
//       enable()
//       turnLights('kioskAgentHelp', true)
//     }
//     return () => {
//       console.log('component is unmounted.')
//       disable()
//       turnLights('kioskAgentHelp', false)
//     }
//   }, [])
//   const handleActions = (e) => {
//     appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')
//     playSound.beepOK()
//     switch (e.currentTarget.id) {
//       case 'buttonScanBarcodeTEST':
//         barcodeReaderCallback({
//           key: 'barcodeReadInternal',
//           value: 'EMBROSSTEST',
//         })
//         break
//       case 'buttonScanBarcodeTESTDamaged':
//         barcodeReaderCallback({
//           key: 'barcodeDamaged',
//           value: null,
//         })
//         break
//       case 'buttonScanBarcodeTESTERROR':
//         barcodeReaderCallback({
//           key: 'barcodeReadInternal',
//           value: '',
//         })
//         break
//     }
//   }
//   const { formatMessage } = props.intl
//   const errIcon = <div className="errorPage_defaultIcon" />
//   const errImage = <div className="errorPage_defaultImage" />
//   const testButtons = [
//     {
//       id: 'buttonScanBarcodeTEST',
//       text: 'Scan',
//       handler: handleActions,
//       cssName: 'test-button-up',
//     },
//     {
//       id: 'buttonScanBarcodeTESTDamaged',
//       text: 'Damaged',
//       handler: handleActions,
//       cssName: 'test-button-up',
//     },
//     {
//       id: 'buttonScanBarcodeTESTERROR',
//       text: 'Error',
//       handler: handleActions,
//       cssName: 'test-button-up',
//     },
//   ]
//   return (
//     <div className="page-container">
//       {popupContainer.retryMediaAccess}
//       <div className="page-content" id="a_buttonGroup">
//         <div className="pad_headerText" id="errorHeader" tabIndex="0">
//           <span className="page-title">
//             {/* <span className="errorPage_iconWrapper">{errIcon}</span> */}
//             <span className="errorPage_text_innerSpan">
//               <FormattedMessage {...messages.PleaseWaitTitle} />
//             </span>
//           </span>
//         </div>
//         <div className="pad-body" id="errorBody">
//           <FormattedMessage {...messages.WaitAgentVerifyDocs} />
//           <div className="emb_animation_wrapper_bottom">{errImage}</div>
//         </div>
//       </div>
//       <Footer
//         isQuitRequired={true}
//         quitBtnStyle={'btn-red-up'}
//         quitBtnText={formatMessage(messages.buttonQuit)}
//         isBackRequired={false}
//         isSkipRequired={false}
//         isConfirmRequired={false}
//         langBtnStyle={'btn-green-up'}
//         isLangRequired={true}
//       />
//       <TestButtons data={testButtons} />
//     </div>
//   )
// }
