import { store, getDeviceManager, getOOSManager, getTSDManager, history } from 'main'
import { updateSessions } from 'actions/sessionActions'
import { updateAppData } from 'actions/appActions'
import { updateLocalData } from 'actions/localActions'
import { endEtsTransaction, initialStore } from 'actions/etsTransactions/sessionAct'
import { postTSD } from 'actions/postTSD'
import { END_TXN_REASON, ETS_TXN_STATUS, StatusCodes, OOS, EventTypeName } from 'constants/Constants'
import { ErrorDictionary, ErrType, ErrCodes } from 'constants/Errors'
import { isNotEmpty, navigate } from 'utils/helper'
import { TraceLevels, SessionInfoEventType } from '../embross-device-manager'
import { appLog } from 'utils/Logger'

export function startCUSSTransaction() {
  sendAppLog('0001', 'Transaction Start')
  let lang = store.getState().localData.locale.toUpperCase()
  // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_SUMMARY, lang)
  store.dispatch(updateSessions('cussTxnStarted', true))
  appLog(TraceLevels.LOG_TRACE, 'startCUSSTransaction.')
  getOOSManager().setCheckDeviceActive(false) // disable checking devices during transaction
  // for PSAM apps send ACTIVE_ACTIVE
  if (store.getState().kioskInfo.PSAM) {
    appLog(TraceLevels.LOG_TRACE, 'send setActiveWhenActive')
    store.dispatch(updateAppData('onActive', true))
    getDeviceManager().getAppManager().setActiveWhenActive()
  }
}

export function handleQuitProcess(quitReason, quitDetail) {
  appLog(TraceLevels.LOG_TRACE, 'handleQuitProcess reason: ' + quitReason + ' detail: ' + quitDetail)
  sendErrorLog(quitReason)
  let endTxnState = getEndTxtState(quitReason)
  doETSTransactionEnd(endTxnState, quitReason)
  // let lang = store.getState().localData.locale.toUpperCase()
  // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_END, lang, formatTSDdescription(quitReason))
  if (quitDetail == OOS) completeCUSSTransaction(true)
  else completeCUSSTransaction(false)
}

export function doETSTransactionEnd(reason, reasonCode = null) {
  let endReason = reason
  let errCode = ''
  switch (reason) {
    case ErrCodes.SCREEN_TIMEOUT:
    case ErrCodes.CUSS_TIMEOUT:
      endReason = END_TXN_REASON.TIMEOUT
      errCode = reason
      break
    case ErrCodes.USER_QUIT:
      endReason = END_TXN_REASON.USER_QUIT
      break
    case END_TXN_REASON.COMPLETE_OK:
      endReason = END_TXN_REASON.COMPLETE_OK
      break
    default:
      endReason = END_TXN_REASON.ERROR
      errCode = reason
      break
  }
  let etsTxnStatus = store.getState().sessions.ETSTxnStatus
  appLog(TraceLevels.LOG_TRACE, 'doETSTransactionEnd: ' + reason + ' --> ' + endReason + ' errCode: ' + errCode)

  if (etsTxnStatus != ETS_TXN_STATUS.ETS_TXN_NONE) {
    store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_NONE))
    if (isNotEmpty(store.getState().sessions) && isNotEmpty(store.getState().sessions.sessionInfo)) {
      endEtsTransaction(store, endReason, errCode, reasonCode)
    } else {
      appLog(
        TraceLevels.LOG_TRACE,
        'doETSTransactionEnd ETS call skipped as sessionInfo is empty, etsTxnStatus: ' + etsTxnStatus
      )
    }
  } else {
    appLog(TraceLevels.LOG_TRACE, 'doETSTransactionEnd ETS call skipped as etsTxnStatus: ' + etsTxnStatus)
  }
}

export function completeCUSSTransaction(goUnavailable) {
  appLog(TraceLevels.LOG_TRACE, ' ==> completeCUSSTransaction: ' + goUnavailable)
  // send stats
  if (config.dataToCollector) {
    postTSD(store.getState().kioskInfo.kioskId, store.getState().kioskInfo.airportCode)
  }
  // check if sent ok?
  getTSDManager().clearTxn()

  let wasPSAM = store.getState().kioskInfo.PSAM
  // initialize store (clear data and initializing)
  appLog(TraceLevels.LOG_EXT_TRACE, 'completeCUSSTransaction initialize store...')

  // clear onactive stage
  store.dispatch(updateAppData('onActive', false))
  //initialStore(store.dispatch)

  if (!goUnavailable) {
    //check if all required devices are available
    if (config.isCUSSRequired && !getDeviceManager().areAllRequiredDevicesOK(true)) {
      goUnavailable = true
      appLog(TraceLevels.LOG_TRACE, 'completeCUSSTransaction force to unavailable - not all required devices are OK')
    }
  }
  getOOSManager().completeTransaction(goUnavailable) // sends available or unavailable to CUSS
  if (goUnavailable) {
    if (config.isCUSSRequired) {
      navigate('OutOfService')
    } else {
      navigate('Welcome')
    }
  } else {
    if (config.isCUSSRequired && !wasPSAM) {
      navigate('Inactive')
    } else {
      navigate('Welcome')
    }
  }
}

export function sendErrorLog(code) {
  if (store.getState().sessions.cussTxnStarted) {
    if (code === END_TXN_REASON.COMPLETE_OK) {
      sendAppLog('0002', 'Transaction End')
    } else {
      let errPos = ErrorDictionary.map((err) => {
        return err.errCode
      }).indexOf(code)
      if (errPos < 0) {
        appLog(TraceLevels.LOG_TRACE, 'sendErrorLog code: ' + code + ' not defined in ErrorDictionary.')
        code = ErrCodes.SEE_AGENT_GENERAL
        errPos = ErrorDictionary.map((err) => {
          return err.errCode
        }).indexOf(code)
      }
      let errID = ''
      let errMsg = ''
      if (errPos >= 0) {
        let errObj = ErrorDictionary[errPos]
        errID = errObj.errID
        errMsg = errObj.msg
      }
      sendAppLog('0003', 'Transaction end - error ' + errID + ' (' + errMsg + ')')
    }
    getOOSManager().setCheckDeviceActive(true) // enable checking devices after transaction ended
  } else {
    appLog(TraceLevels.LOG_TRACE, 'sendErrorLog - CUSS transaction not started, code: ' + code)
  }
}

export function getEndTxtState(code) {
  if (code == '' || code == undefined) code = 'TXN_DEVICE_ERROR'
  if (
    code == 'TXN_OK' ||
    code == 'SCREEN_TIMEOUT' ||
    code == 'USER_QUIT' ||
    code == 'TXN_DEVICE_ERROR' ||
    code == 'TXN_BHS_ERROR' ||
    code == 'TXN_SESSION_TIME'
  )
    return code
  else {
    if (code.startsWith('DEVICE_ERROR')) code = 'DEVICE_ERROR'
    switch (code) {
      case 'BHS_ERROR':
        return 'TXN_BHS_ERROR'
      case 'CONVEYOR_OFFLINE':
      case 'CONVEYOR_ERROR':
      case 'CONVEYOR_BAG_ERROR':
      case 'DEVICE_ERROR':
        return 'TXN_DEVICE_ERROR'
      default:
        return code
    }
  }
}

export function reasonToStatusCode(reason) {
  let statusCode = StatusCodes.ERROR
  if (reason === ErrCodes.USER_QUIT) statusCode = StatusCodes.QUIT
  else if (reason === ErrCodes.SCREEN_TIMEOUT) statusCode = StatusCodes.TIMEOUT
  else if (reason === '') statusCode = StatusCodes.COMPLETE
  return statusCode
}

export function formatTSDEvents(kioskId, airportCode, events) {
  if (config.statsVersion === 3) {
    const serverTime = store.getState().localData.serverTime
    const clientBaseTime = store.getState().localData.startTransactionTime
    const carrierCode = store.getState().localData.carrierCode

    let tempList = events.map((event) => {
      let eventObj = {
        eventSource: kioskId,
        locationID: airportCode,
        sessionID: event.sessionID,
        subClientID: carrierCode,
        eventID: event.stateData.eventID,
        eventType: event.eventType,
        stateData: event.stateData,
      }
      serverTime
        ? (eventObj.eventTime = event.eventTime - clientBaseTime + serverTime)
        : (eventObj.eventTime = event.eventTime)
      if (event.eventType === EventTypeName.SESSION_SUMMARY_DETAILS) {
        let startTime = serverTime ? serverTime : clientBaseTime
        eventObj.stateData.startTime = startTime
        eventObj.stateData.endTime = eventObj.stateData.sessionData.totalDuration
          ? startTime + eventObj.stateData.sessionData.totalDuration * 1000
          : startTime + eventObj.stateData.sessionData.timeDuration * 1000
      }
      return eventObj
    })

    return tempList
  } else {
    return events
  }
}

export function sendAppLog(code, text) {
  getDeviceManager()
    .getAppManager()
    .sendApplicationLog(100, 'CDS_APPLOG,' + code + ',' + text)
  // test for noCUSS
  appLog(TraceLevels.LOG_TRACE, '==> ' + 100 + ' CDS_APPLOG,' + code + ',' + text)
}

function transactionToObject(str) {
  let transactionObj = {}
  str.split(', ').forEach((detail) => {
    let breakDown = detail.split('=')
    transactionObj = { ...transactionObj, ...{ [`${breakDown[0]}`]: breakDown[1] } }
  })
  return transactionObj
}

export function formatTSDdescription(reason, errorCode) {
  console.log('STORE:::', store.getState())
  let responses = store.getState().responses
  const localData = store.getState().localData
  const events = getTSDManager().getEvents()
  let timeInteraction = 0
  let transactionDetail = []
  let errorCodeList = []
  let overRideCode = ''
  let totalWeight = 0
  let numOfTubs = 0
  let numOfBags = 0
  let bagOverRide = 0
  let carrierCode = ''
  if (localData) {
    if (localData.transactionDetail) {
      transactionDetail = localData.transactionDetail
    }
    if (localData.errorCodeList) {
      errorCodeList = localData.errorCodeList
    }
    if (localData.overrideCodeScan) {
      overRideCode = localData.overrideCodeScan
    }
    if (localData.BagsDetail) {
      localData.BagsDetail.forEach((bagData) => {
        let weight = parseFloat(bagData.measurements[0].replace('KG', ''))
        totalWeight += weight
        if (bagData.measurements.length >= 5 && bagData.measurements[4]) {
          numOfTubs++
        }
        if (bagData.overrideCode !== '') {
          bagOverRide++
        }
        numOfBags++
      })
    }
  }
  let startTime
  let endTime
  let touchCount = 0
  events.forEach((event) => {
    if (event.eventType === 'APP_STATE_EVENT_DETAILS' && event.stateData.eventID === 'TXN_START') {
      startTime = event.eventTime
    }
    if (event.eventType === 'APP_STATE_EVENT_DETAILS' && event.stateData.eventID === 'TXN_END') {
      endTime = event.eventTime
    }
    if (event.sessionID !== '' && event.eventType === 'SCREEN_INTERACTION_EVENT_DETAILS') {
      touchCount++
    }
  })

  timeInteraction = (endTime - startTime) / 1000

  let pnr = responses && responses.itineraryInfo ? responses.itineraryInfo.recordLocator : ''
  let desc = ''
  let summaryDetail = {}

  // store pnr number
  summaryDetail.pnr = pnr
  // desc = 'PNR=' + pnr

  // store transaction time
  summaryDetail.timeDuration = timeInteraction
  // desc = `${desc},TimeDuration= ${timeInteraction}sec`

  // store touch count
  // summaryDetail.touchCount = touchCount
  // desc = `${desc},touchCount= ${touchCount}`

  // store too late/too early overRideCode
  summaryDetail.overrideCode = overRideCode
  // desc = `${desc},overRideCode= ${overRideCode}`

  // store baggage detail
  summaryDetail.numOfBags = numOfBags
  summaryDetail.totalWeight = totalWeight
  summaryDetail.numOfTubs = numOfTubs
  summaryDetail.bagOverride = bagOverRide
  summaryDetail.transactionDetail = formatTracnsactionDetail(transactionDetail)
  // summaryDetail.TransactionDetail = JSON.stringify(transactionDetail)
  // summaryDetail.TransactionDetail = transactionDetail.map(detailStr => transactionToObject(detailStr))
  // desc = `${desc},TransactionDetail= ${JSON.stringify(transactionDetail)}`

  if (responses && responses.itineraryInfo && responses.itineraryInfo.scanSequence) {
    summaryDetail.numOfPax = responses.itineraryInfo.scanSequence.length
  } else {
    summaryDetail.numOfPax = 0
  }

  // store all error code in the transaction.

  summaryDetail.errorCode = errorCodeList

  summaryDetail.endTxnCode = errorCode ? errorCode : END_TXN_REASON.COMPLETE_OK

  if (
    responses &&
    responses.itineraryInfo &&
    responses.itineraryInfo.outboundFlights &&
    responses.itineraryInfo.outboundFlights.length > 0
  ) {
    let flightDetail = responses.itineraryInfo.outboundFlights[0]
    if (flightDetail.departureFlight === true) {
      carrierCode = flightDetail.opCarrierCode
    }
  }

  summaryDetail.carrierCode = carrierCode

  return summaryDetail
}

function formatTracnsactionDetail(eventString) {
  const formatData = eventString.map((str) => {
    const bagData = str.split(',')
    let bagtagNo = ''
    let pnr = ''
    let carrier = ''
    let flightNo = ''
    let from = ''
    let dest = ''
    let paxOrdinal = ''
    let bagWeight = ''
    let bagHeight = ''
    let bagLength = ''
    let tubUsed = false
    let overrideUsed = false

    bagData.forEach((ele) => {
      const keyValuePair = ele.split('=')
      switch (keyValuePair[0].trim()) {
        case 'BAG':
          bagtagNo = keyValuePair[1].trim()
          break
        case 'PNR':
          pnr = keyValuePair[1].trim()
          break
        case 'CARRIER':
          carrier = keyValuePair[1].trim()
          break
        case 'FLT':
          flightNo = keyValuePair[1].trim()
          break
        case 'FROM':
          from = keyValuePair[1].trim()
          break
        case 'DEST':
          dest = keyValuePair[1].trim()
          break
        case 'PAX':
          paxOrdinal = keyValuePair[1].trim()
          break
        case 'WEIGHT':
          let weight = keyValuePair[1].trim()
          bagWeight = { value: parseFloat(weight), unit: weight.replace(`${parseFloat(weight)}`, '') }
          break
        case 'HEIGHT':
          let height = keyValuePair[1].trim()
          bagHeight = { value: parseFloat(height), unit: height.replace(`${parseFloat(height)}`, '') }
          break
        case 'LENGTH':
          let length = keyValuePair[1].trim()
          bagLength = { value: parseFloat(length), unit: length.replace(`${parseFloat(length)}`, '') }
          break
        case 'TUB':
          tubUsed = true
          break
        case 'OVR':
          overrideUsed = true
          break
        default:
          break
      }
    })
    return {
      bagtagNo: bagtagNo,
      carrier: carrier,
      flightNo: flightNo,
      from: from,
      dest: dest,
      paxOrdinal: paxOrdinal,
      bagWeight: bagWeight,
      bagHeight: bagHeight,
      bagLength: bagLength,
      tubUsed: tubUsed,
      overrideUsed: overrideUsed,
    }
  })

  return formatData
}

export const handleAppTransfer = (
  targetAppCompanyCode,
  targetAppApplicationName,
  transferData,
  errorCode
) => {
  appLog(TraceLevels.LOG_TRACE, '(handleAppTransfer) transfer application:' + targetAppCompanyCode + '_' + targetAppApplicationName)
  doETSTransactionEnd(END_TXN_REASON.TRANSFER)
  if (config.isCUSSRequired) {
    let appMgr = getDeviceManager().getAppManager()
    // appMgr.applicationTransfer('WS','NGA','TRANSFER_INFO={}')
    appMgr.applicationTransfer(targetAppCompanyCode, targetAppApplicationName, transferData)
    appMgr.available()
  } else {
    appLog(TraceLevels.LOG_TRACE, 'CUSS is not required. do not transfer.')
  }
}
