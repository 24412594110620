import React, { useState } from 'react'
import { PageContent, PageTitle, PageSubContent } from 'components/styledComponents'
import { Button } from 'components'
import { history } from 'main'

const Inactive = (props) => {
  const testButton = config.isCUSSRequired ? (
    ''
  ) : (
    <PageSubContent>
      <Button
        id="mybutton"
        height="75px"
        justifyContent="center"
        onClick={() => {
          history.push('Welcome')
        }}
      >
        Start
      </Button>
    </PageSubContent>
  )

  return (
    <PageContent flexFlow="column">
      <PageTitle height="100px" color='black'>Application is initializing ...</PageTitle>
      <PageSubContent>{testButton}</PageSubContent>
    </PageContent>
  )
}
export default Inactive
