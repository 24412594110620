import { CommonActions } from 'constants/Constants'

export function updateErrorMsg (state = {}, action){
  switch (action.type) {
    case CommonActions.ERROR:
      return action.details
    default:
      return state
  }
}
