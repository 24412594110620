import React from 'react'
import Button, { ButtonProps } from './button'
export const IconLocation = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT'
}
/* interface IconButtonProps extends Omit<ButtonProps, 'children' | 'bgImage'> {
  text: string
  iconLocation: IconLocation
  icon: string
} */
const IconButton = (props) => {
  const { text, iconLocation, icon, iconColor, iconWidth, iconHeight, ...buttonProps } = props

  const containerStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

  const iconContainerStyle = {
    display: 'flex',
    flexFlow: 'column',
    //textAlign: 'left',
    //backgroundPosition: 'center center',
    //backgroundRepeat: 'no-repeat',
    //backgroundSize: 'contain',
    //backgroundImage: `url(images/${icon})`,
    width: iconWidth ? iconWidth : '15px',
    height: iconHeight ? iconHeight : '15px',
    backgroundColor: iconColor,
    WebkitMask: `url(images/${icon})`,
    WebkitMaskSize: 'contain',
    WebkitMaskRepeat: 'no-repeat',
    WebkitMaskPosition: 'center center'
    //paddingLeft: '20px',
    //paddingRight: '20px'
  }

  const textContainerStyle = {
    display: 'flex',
    flexFlow: 'column',
    alignItems:
      iconLocation === IconLocation.RIGHT ? 'flex-start' : iconLocation === IconLocation.LEFT ? 'flex-end' : 'center',
    flex: 1
    //padding: '10px'
  }
  const content = icon ? (
    iconLocation === IconLocation.RIGHT ? (
      <>
        <div style={textContainerStyle}>{text}</div>
        <div style={iconContainerStyle} />
      </>
    ) : (
      <>
        <div style={iconContainerStyle} />
        <div style={textContainerStyle}>{text}</div>
      </>
    )
  ) : (
    <>
      <div style={textContainerStyle}>{text}</div>
    </>
  )
  return (
    <Button {...buttonProps}>
      <div style={containerStyle}>{content}</div>
    </Button>
  )
}

IconButton.defaultProps = {
  width: '200px',
  height: '75px',
  iconLocation: IconLocation.RIGHT,
  bgColor: '#465e7e'
}
export default IconButton
