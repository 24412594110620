import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { TraceLevels } from 'embross-device-manager'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { ErrCodes } from 'constants/Errors'
import { PageTitle, PageSubTitle, PageHeader, Spacer, DynamicImage } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { getDeviceManager, getSBDAppMan, playSound, getBuildAccessibility } from 'main'
import { populateItineraryInfo, formatBagTagNumber, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'

const ErrorMismatch = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const errorDetails = useSelector((state) => state.errorDetails)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const bagtag = useSelector((state) => state.localData.bagtag)
  const locale = useSelector((state) => state.localData.locale)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    dispatch(updateLocalData('updateBagWeight', '0'))
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '>> (ErrorMismatch.js) componentDidMount() - this.props.appFlow = "' +
        appFlow +
        '" error: ' +
        JSON.stringify(errorDetails)
    )
    appLog(TraceLevels.LOG_EXT_TRACE, '==> CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
    getDeviceManager()
      .getAppManager()
      .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    return () => {
      dispatch(updateLocalData('updateBqCommand', ''))
      dispatch(updateError(null))
    }
  }, [])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(ErrorMismatch.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    const errTransition = errorDetails.transition
    switch (e.currentTarget.id) {
      case 'confirmBtn':
        if (errTransition) {
          if (errTransition === 'PutBagOnBelt') {
            navigate(errTransition, 2)
          } else if (errTransition === 'END_TXN') {
            SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, '')
          } else if (errTransition === 'OOS') {
            SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, OOS)
          } else {
            navigate(errTransition)
          }
        } else {
          SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, '')
        }
        break
      default:
    }
  }

  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )

  const animationSize = useMedia(null, [400, 300, 400], 400)
  const animationSection = (
    <DynamicImage
      imageName={`bag-tag-error.svg`}
      cssName={'animation'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  let heading = intl.formatMessage(messages.ErrorMismatch1_hdr) ////TAG_NOT_FOUND_IN_PNR
  let instMsg = intl.formatMessage(messages.ErrorMismatch1_inst)
  let scannedBagtagMsg =
    bagtag && bagtag.length > 10
      ? intl.formatMessage(messages.ScannedBagTag2, { bagtag: formatBagTagNumber(bagtag) })
      : intl.formatMessage(messages.ScannedBagTag, { bagtag: formatBagTagNumber(bagtag) })

  if (errorDetails.errCode === ErrCodes.MULTIPLE_TAGS) {
    heading = intl.formatMessage(messages.ErrorMismatch2_hdr)
    instMsg = intl.formatMessage(messages.ErrorMismatch2_inst)
  } else if (errorDetails.errCode === ErrCodes.MULTIPLE_BAGS) {
    heading = intl.formatMessage(messages.ErrorMismatch3_hdr)
    instMsg = intl.formatMessage(messages.ErrorMismatch3_inst)
  }

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {heading}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {instMsg} {themeContext.showMismatchBagtag ? { scannedBagtagMsg } : ''}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.RepositionBagTitle)}
    </PageHeader>
  )

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : { displayName: '' }
    const errorMsg = [heading, instMsg, scannedBagtagMsg]
    const accDef = {
      pathName: 'ErrorMismatch',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [itinerary.displayName, errorMsg.join('. ')]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleActions}
      confirmBtnText={intl.formatMessage(messages.buttonOk)}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ErrorMismatch
