import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
const HelpAct = (props) => {
  const intl = useIntl()

  const themeContext = useContext(ThemeContext)

  const handleHelpAction = () => {}

  return (
    <>
      <Button
        id={'helpBtn'}
        onClick={handleHelpAction}
        color={themeContext.HelpButton.color}
        bgColor={themeContext.HelpButton.bgColor}
        width={themeContext.HelpButton.width}
        height={themeContext.HelpButton.height}
        fontSize={18}
        cssName={'help-btn'}
      >
        <div className="help-btn-icon" />
        {intl.formatMessage(messages.buttonHelp)}
      </Button>
    </>
  )
}
HelpAct.propTypes = {
  helpBtnStyle: PropTypes.string,
  helpAction: PropTypes.func,
}

HelpAct.defaultProps = {
  helpAction: null,
}
export default HelpAct
