/**********************************************************/
// this callback is called when both kioskId and location 
// is received from platform. for example we can use this 
//callback to change config at kiosk level or location level.
/******************************************************** */
export const kioskIdAndLocationReady = (kioskId, location) => {
  console.log('kioskIdAndLocationReady, kioskId:', kioskId, ', location:', location)
  if (location === 'ATL') {
    config.isBoardingpassLookupEnable = false
    config.isFaceRecognitionEnable = true
    console.log('enable face recognition:')
  }
}
