/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import React, { useEffect, useContext, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { startEtsTransaction, initialStore } from 'actions/etsTransactions/sessionAct'
import { TYPE_KIOSK, ETS_TXN_STATUS } from 'constants/Constants'
import {
  PageHeader,
  PageTitle,
  PageSubTitle,
  DynamicImage,
  PageSubContent,
  PageText
} from 'components/styledComponents'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useFaceTracking from 'hooks/useFaceTracking'
import {
  getDeviceManager,
  playSound,
  store,
  getSBDAppMan,
  getVersion,
  history,
  getBuildAccessibility,
  getEventLogger
} from 'main'
import WelcomeFooter from 'custom/layouts/Footer'
import { startCUSSTransaction } from 'utils/appTransactions'
import { generateBP, getConveyorPosition } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { v4 as uuidv4 } from 'uuid'
const Welcome = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const animationSize = useMedia(null, [400, 350, 400], 400)
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const locale = useSelector((state) => state.localData.locale)
  const dispatch = useDispatch()
  const etsTxnStatus = useSelector((state) => state.sessions.ETSTxnStatus)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ActiveStage = useSelector((state) => state.kioskInfo.onActive)
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const [photoMode, setPhotoMode] = useState(false)
  const timeoutRef = useRef(null)

  console.log('kioskType:', JSON.stringify(kioskType), ' sbdModel:', sbdModel)
  const scannedCallback = (scannedValue) => {
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      const eventLogger = getEventLogger()
      if (eventLogger && !eventLogger.clientSessionId) {
        eventLogger.ClientSessionId = uuidv4()
      }
      logEvent('ScanBP,' + scannedValue)
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      if (appConfig.hostURL) {
        startEtsTransaction(store, TYPE_KIOSK, getVersion(), { barcodeData: scannedValue })
      }
    } else {
      dispatch(getItinerarySBD({ barcodeData: scannedValue }))
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  const sendResultCallback = (value) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTracking send result callback is called, value: ' + value)
    //sendPhotoMatch(event.value.split(',')[1])
    const photoImage = value.split(',')[1].trim()
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      startEtsTransaction(store, TYPE_KIOSK, getVersion(), { uid: photoImage })
    } else {
      dispatch(getItinerarySBD({ uid: value }))
    }
  }
  const [
    faceTrackingEnable,
    faceTrackingDisable,
    faceTrackingShow,
    faceTrackingHide,
    faceTrackingCallback,
    liveStreamUrl
  ] = useFaceTracking(sendResultCallback)

  const welcomeMsg = config.isBagtagLookupEnable
    ? 'WelcomePutBagOnBelt'
    : config.isBoardingpassLookupEnable && config.isFaceRecognitionEnable
    ? 'WelcomeFaceService'
    : config.isBoardingpassLookupEnable
    ? 'WelcomeScanBoardingPass'
    : 'WelcomeFaceService'

  const welcomeTitle = config.isBagtagLookupEnable
    ? 'WelcomeTitlePutBagOnBelt'
    : config.isBoardingpassLookupEnable && config.isFaceRecognitionEnable
    ? 'WelcomeTitleScanBoardingPassFaceService'
    : config.isBoardingpassLookupEnable
    ? 'WelcomeTitleScanBoardingPass'
    : 'WelcomeTitleFaceService'
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const conveyorPosition = intl.formatMessage(messages[getConveyorPosition()])
    // console.log(conveyorPosition)
    const screenMessage = `${intl.formatMessage(messages[welcomeMsg])}`
    const accDef = {
      pathName: 'Welcome',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicText',
            textParameters: [screenMessage]
          }
        ],
        help: ['HelpBardeLocation', 'HelpBeltLocation']
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // useEffect(() => {
  //   if (config.isCUSSRequired) {
  //     appLog(TraceLevels.LOG_TRACE, '(Welcome.js) call barcode enable function')
  //     if (config.isBoardingpassLookupEnable) {
  //       enable()
  //     }
  //     // if (config.isFaceRecognitionEnable) {
  //     //   faceTrackingEnable(config.bestShotTimeWelcome)
  //     // }
  //   }
  //   return () => {
  //     if (config.isBoardingpassLookupEnable) {
  //       disable()
  //     }
  //     if (config.isFaceRecognitionEnable) {
  //       faceTrackingDisable()
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      if (ActiveStage) {
        if (config.isBagtagLookupEnable) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (Welcome.js) componentDidMount() - send SQ')
          getSBDAppMan().setSQStart(-1)
          aeaBagdrop.sendAEA('SQ', -10000)
        }
        if (config.isBoardingpassLookupEnable) {
          enable()
        }
      }
    }
    return () => {
      if (config.isBoardingpassLookupEnable) {
        disable()
      }
      /* if (config.isFaceRecognitionEnable) {
        console.log('face disabled from useEffect - active Stage')
        faceTrackingDisable()
      } */
    }
  }, [ActiveStage])



  const handleActions = (e) => {
    let barcode = ''
    const { pnr, firstName, lastName, from, to, airLine, flightNumber, bagtag } = config.testData[0]
    const SBDAppManager = getSBDAppMan()
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          if (config.isBagtagLookupEnable) {
            barcode = generateBP(pnr, firstName, lastName, config.defaultLocation, to, airLine, flightNumber)
            barcodeReaderCallback({
              key: 'barcodeReadInternal',
              value: barcode
            })
            // SBDAppManager.setAppFlow(20)
            // aeaBagdrop.onDeviceEvent({
            //   key: 'commandCompleted',
            //   value: 'HDCBQOK#LP=' + bagtag[0] + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
            // })
            /* aeaBagdrop.onDeviceEvent({
              key: 'commandCompleted',
              value: 'HDCERR5#BGPR'
            }) */
          } else {
            // testPassenger = testPassengers[0]
            barcode = generateBP(pnr, firstName, lastName, from, to, airLine, flightNumber)
            // barcode =
            //   'M1TEST/ONE            E8I9B26 DTWLASE1 1770 213Y13A0 000090F&gt;50B1OO8303BWS 0E83******00000 &gt;460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk=='
            // barcode = `M1TOLHR/ONE           EJD9AB2 DTWJFKDL 5671 352Y010B0001 155>318  W     DL              2900621416096380                           DL3J87FEU5010B^160MEUCIQCE3TVBBSn9RS3tvFsNv8jvAJWTkgienNM7k9/BTh9dLwIgYz37f/TC2nYLColaw2gitlqUJ8ZII9eFLGh4R640FCc=`
            // barcode =
            //   'M1TOSLC/THREEBAGS     EHZKGEM DTWSLCDL 0939 304C002C0001 155>318  W     DL              2900621412918830                           DL3J891CXVT02C^160MEQCIHGVa1E5wahuDpf+dedM2I+Sj7653DRjwKnPo5LFS8BBAiAzddRLFw0CKrAmVFFcyaVh6sFb8iy7miTFVsZeEZ9iQw=='
            //barcode = config.test
            //barcode = 'M1B/B                  PZCNTH HYDBLRI5 1527 176Y038F0001 347&gt;1182 K0143BNK 048702367000229487000000000000'
            //barcode ='M1DTWTOCLT/PAXTWO     EW8QGIU AUHCLTDL 0508 354Y025D0001 155>318  W     WS              2900621403998520                           WS3J8LX7ZES25D'
            // barcode =
            //   'M1SMITH/MARK          EDOM123 YYZYULNK 0123 036Y014K2101 90F>50B1OO8303BWS 0E8380000000000 ^460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk9BGGk1XDkPdJrfzAiBLSzIjq3EHP6CZOxFacKwJ41G2RqsJLvRRREGt4sTFrA==1580928289,1580928311'
            barcodeReaderCallback({
              key: 'barcodeReadInternal',
              value: barcode
            })
          }

          break
        case 'buttonScanBarcodeERROR':
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: ''
          })
          break
        case 'buttonFaceMatchTEST':
          // faceTrackingCallback({
          //   key: 'faceDescriptor',
          //   value: config.photoData
          // })
          aeaBagdrop.onDeviceEvent({
            // key: 'unsolicitedMessage',
            // value: 'HDCBQNI#LP=1581370468#HT=18CM#WT=36CM#LT=37CM#SW=4#CW=0.6KG#BS=BAGP',
            key: 'commandCompleted',
            // value: 'HDCERR5#BGPR'
            // value: 'HDCPROK#PA'
            // value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
            value: 'HDCBQOK#LP=8006868001#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0LB#BS=BAGP'
          })
          break
        case 'buttonScanDocumentTEST':
          history.push('scanDocuments')
          break
        case 'TakePhoto':
          faceTrackingCallback({
            key: 'faceDescriptor',
            value: config.testPhoto
          })
          break
        case 'AgentScan':
          history.push('agentScan')
          break
        case 'ShowDisclaimer':
          history.push('showDisclaimer')
          break
        case 'PrintBagTag':
          history.push('printBagTag')
          break
        case 'myTest':
          /* SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCBQOK#LP=' + bagtag[0] + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP',
          }) */
          /* barcode = {
            pathname: 'pleaseWait',
            state: {
              messageId: 'PleaseWaitFindReservation'
            }
          }
          history.push(barcode) */
          history.push('nextBagQuestion')
          break
        default:
      }
    }
  }
  const testData = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonScanBarcodeTEST',
      text: config.isBagtagLookupEnable ? 'Scan bagtag' : 'Scan barcode',
      group: 0,
      handler: handleActions
    },
    // {
    //   id: 'buttonFaceMatchTEST',
    //   text: 'Face match',
    //   cssName: 'test-button-up',
    //   handler: handleActions
    // },
    {
      id: 'buttonScanDocumentTEST',
      text: 'ScanDoc',
      group: 0,
      handler: handleActions
    },
    {
      id: 'TakePhoto',
      text: 'TakePhoto',
      group: 0,
      handler: handleActions
    },
    {
      id: 'AgentScan',
      text: 'AgentScan',
      group: 0,
      handler: handleActions
    },
    {
      id: 'ShowDisclaimer',
      text: 'ShowDisclaimer',
      group: 0,
      handler: handleActions
    },
    {
      id: 'PrintBagTag',
      text: 'PrintBagTag',
      group: 0,
      handler: handleActions
    },
    {
      id: 'myTest',
      text: 'test Screen',
      group: 0,
      handler: handleActions
    }
  ]

  useEffect(() => {
    const resetHandle = () => {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
      timeoutRef.current = setTimeout(() => {
        setPhotoMode(false)
      }, 30 * 1000)
    }
    if (photoMode) {
      document.addEventListener('click', resetHandle)
      if (config.isCUSSRequired) {
        if (ActiveStage) {
          faceTrackingEnable(config.bestShotTimeWelcome)
          timeoutRef.current = setTimeout(() => {
            setPhotoMode(false)
          }, 30 * 1000)
        }
      }
      return () => {
        clearTimeout(timeoutRef.current)
        document.removeEventListener('click', resetHandle)
        if (config.isFaceRecognitionEnable) {
          console.log('face disabled from useEffect - photo mode:', photoMode)
          faceTrackingDisable()
        }
      }
    }
  }, [photoMode])

  const handlephotoAction = (e) => {
    const eventLogger = getEventLogger()
    let etsTxnStatus = store.getState().sessions.ETSTxnStatus
    if (eventLogger && !eventLogger.clientSessionId && etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      eventLogger.ClientSessionId = uuidv4()
    }
    logEvent('FaceMatch,initiated')
    setPhotoMode(true)
  }

  const handleQuitAction = () => {
    setPhotoMode(false)
  }

  const imageLocation = config.isBagtagLookupEnable
    ? `${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`
    : config.isBoardingpassLookupEnable && config.isFaceRecognitionEnable
    ? `${themeContext.AnimationPath}/${sbdModel}/faceService.png`
    : config.isBoardingpassLookupEnable
    ? `${themeContext.AnimationPath}/${sbdModel}/BoardingPassScan.gif`
    : `${themeContext.AnimationPath}/${sbdModel}/faceService.svg`

  let animationSection = null
  let textSection = null
  if (photoMode) {
    animationSection = (
      <PageSubContent width="374px" height="560px" padding="0">
        {liveStreamUrl ? (
          <div>
            <img src={liveStreamUrl} style={{ width: '374px', height: '560px', borderRadius: '18px' }} alt="face" />
          </div>
        ) : null}
      </PageSubContent>
    )

    textSection = null
  } else {
    animationSection = (
      <DynamicImage
        imageName={imageLocation}
        width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
        height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
      />
    )

    textSection = (
      <>
        <PageTitle alignItems="center" justifyContent="center">
          {intl.formatMessage(messages[welcomeTitle])}
        </PageTitle>
        <PageSubTitle justifyContent="center">{intl.formatMessage(messages[welcomeMsg])}</PageSubTitle>
      </>
    )
  }

  const header = null

  const footer = (
    <WelcomeFooter
      isQuitRequired={!appMode || photoMode}
      quitAction={handleQuitAction}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      isLangRequired={true}
      displayItinerary={false}
      isPhotoRequired={config.isFaceRecognitionEnable && !photoMode}
      photoAction={handlephotoAction}
      disablePhotoAction={!ActiveStage}
      displayPhotoInstruction={photoMode}
      testData={testData}
    />
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default Welcome
