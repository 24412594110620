import React, { useEffect, useState, useRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getLanguageCodeFromId } from 'utils/helper'
import './clock.scss'
import { ThemeContext } from 'styled-components'

const Clock = (props) => {
  const themeContext = useContext(ThemeContext)

  const getDateObject = (dateString) => {
    let dateObj = null
    let partDate = []
    let partTime = []
    let step1 = dateString.split('T')
    if (step1.length === 2) {
      partDate = step1[0].split('-')
      partTime = step1[1].split(':')
      if (partDate.length === 3 && partTime.length === 3) {
        dateObj = new Date(
          parseInt(partDate[0]),
          parseInt(partDate[1]),
          parseInt(partDate[2]),
          parseInt(partTime[0]),
          parseInt(partTime[1]),
          parseInt(partTime[2])
        )
      }
    }
    return dateObj
  }
  const interval = props.showSeconds ? 1000 : 60000
  const showTimeOption = props.showSeconds
    ? {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      }
    : {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
  const [currentDate, setCurrentDate] = useState(props.serverTime ? getDateObject(props.clientTime) : new Date())
  const intervalRef = useRef()
  const intl = useIntl()

  const tick = () => {
    if (currentDate) {
      setCurrentDate(new Date(currentDate.getTime() + interval))
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(tick, interval)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [currentDate])
  return (
    <div className="clock-wrapper" style={{width: themeContext.clockWidth}}>
      <div className={'clock-time'} id={'a_clock'} tabIndex={0}>
        {new Intl.DateTimeFormat(getLanguageCodeFromId(props.locale), showTimeOption).format(currentDate)}
      </div>
      <div className={'clock-date'} id={'a_clock'} tabIndex={0}>
        {new Intl.DateTimeFormat(getLanguageCodeFromId(props.locale), {
          dateStyle: 'medium',
        }).format(currentDate)}
      </div>
    </div>
  )
}

Clock.defaultProps = {
  showSeconds: false,
  locale: 'en',
}

export default Clock
