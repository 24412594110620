import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import './popup.scss'

const Popup = (props) => {
  const themeContext = useContext(ThemeContext)
  const { visible, showOverlay, children, width, height, isFullPage } = props
  const style = {
    marginLeft: `-${themeContext.popup.width / 2}px`,
    marginTop: `-${themeContext.popup.height / 2}px`,
    width: `${themeContext.popup.width}px`,
    height: `${themeContext.popup.height}px`,
    backgroundColor: `${themeContext.PopupBgColor}`
  }
  return (
    <section className={visible ? 'popup-show' : 'popup-hide'}>
      {showOverlay ? <div className={visible ? 'overlay-show' : 'overlay-hide'} /> : ''}
      <div className="popup-dialog" style={style}>
        {children}
      </div>
    </section>
  )
}

Popup.defaultProps = {
  visible: false,
  isFullPage: false,
  showOverlay: true,
  width: '600px',
  height: '800px'
}

export default Popup
