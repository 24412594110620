import { CommonActions } from 'constants/Constants'
import { SourceType } from 'constants/Errors'

export function updateError(errorCode, msg, source, ...moreArgs) {
  console.log('updateError: code = ' + errorCode + ' msg = ' + msg + ' ...moreArgs:', moreArgs)
  if (errorCode) {
    return {
      type: CommonActions.ERROR,
      details: {
        sourceType: SourceType.LOCAL,
        errCode: errorCode,
        msg: msg,
        source: source,
        params: moreArgs
      }
    }
  } else {
    return {
      type: CommonActions.ERROR,
      details: {}
    }
  }
}

export function updateLocalError(errorCode, msg, source, transition, params){
  console.log('updateLocalError: code = ' + errorCode + ' msg = ' + msg + ' transition', transition, ' ...moreArgs:', params)
  if (errorCode) {
    return {
      type: CommonActions.ERROR,
      details: {
        sourceType: SourceType.LOCAL,
        errCode: errorCode,
        msg: msg,
        source: source,
        transition: transition,
        params: params
      }
    }
  } else {
    return {
      type: CommonActions.ERROR,
      details: {}
    }
  }
}

export function updateFetchError(errorCode, msg, source, transition) {
  console.log('updateFetchError: code = ' + errorCode + ' msg = ' + msg + ' transition:', transition)
  if (errorCode) {
    return {
      type: CommonActions.ERROR,
      details: {
        sourceType: SourceType.FETCH,
        errCode: errorCode,
        msg: msg,
        source: source,
        transition: transition
      }
    }
  } else {
    return {
      type: CommonActions.ERROR,
      details: {}
    }
  }
}
