import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { PageTitle, PageSubTitle, BagProcessingInnerSpan, DynamicImage } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility, playSound } from 'main'
import { Footer } from '../footer'
import { formatBagTagNumber, navigate } from 'utils/helper'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const AcceptingBag = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const myFlow = useRef(0)
  const animationSize = useMedia(null, [400, 350, 400], 400)
  const title = intl.formatMessage(messages.BagProcessingTitle2)
  const subtitle = intl.formatMessage(messages.BagProcessingSubTitle2)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'AcceptingBag',
      startIndex: 0,
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters: [title, subtitle] }]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const handleActions = (e) => {
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'myTest':
        console.log('current flow:', appFlow)
        navigate('ClosingRemarks')
        break
    }
  }

  let testButtons = [
    {
      id: 'myTest',
      text: 'my test',
      handler: handleActions,
      group: 0
    }
  ]

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/bagAccepting_new.gif`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )
  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {subtitle}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isLangRequired={false}
      testData={testButtons}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header: null, topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AcceptingBag
