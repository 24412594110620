import { SessionActions, ETS_TXN_STATUS } from 'constants/Constants'

export function updateSessions(
  state = {
    cussTxnStarted: false,
    ETSTxnStatus: ETS_TXN_STATUS.ETS_TXN_NONE,
    ETSVersion: null,
    ETSTime: null,
    ETSTimeDelta: null,
    OOS: false,
    ETSRequest: false,
    notificationText: '',
  },
  action
) {
  let data = Object.assign({}, state)
  switch (action.type) {
    case SessionActions.UPDATE_SESSION:
      data.sessionInfo = action.sessionInfo
      break
    case SessionActions.UPDATE_SESSION_CONFIG:
      data.configElements = action.configElements
      break
    case SessionActions.CURRENT_SERVICE_NAME:
      data.currentServiceName = action.currentServiceName
      break
    case SessionActions.UPDATE_TRANSITION:
      data.transition = action.transition
      break
    case SessionActions.UPDATE_TRANSITION_OPTION:
      data.transitionOption = action.transitionOption
      break
    case SessionActions.CUSS_TXN_STARTED:
      data.cussTxnStarted = action.cussTxnStarted
      break
    // case SessionActions.UPDATE_PSAM:
    //   data.PSAM = action.psam
    //   break
    case SessionActions.UPDATE_NOTIFICATION_TEXT:
      data.notificationText = action.notificationText
      break
    /* case SessionActions.UPDATE_ON_ACTIVE:
      data.onActive = action.onActive
      break */
    case SessionActions.UPDATE_ETS_TXN_STATUS:
      data.ETSTxnStatus = action.ETSTxnStatus
      break
    case SessionActions.UPDATE_SESSION_STATS:
      data.sessionStats = action.sessionStats
      break
    case SessionActions.UPDATE_ETS_VERSION:
      data.ETSVersion = action.version
      break
    case SessionActions.UPDATE_ETS_TIME:
      data.ETSTime = action.etsTime
      break
    case SessionActions.UPDATE_ETS_TIME_DELTA:
      data.ETSTimeDelta = action.delta
      break
    case SessionActions.UPDATE_OOS_STATUS:
      data.OOS = action.oos
      break
    case SessionActions.UPDATE_ETS_REQUEST:
      data.ETSRequest = action.etsRequest
      break
    case SessionActions.INITIAL_SESSION_DATA:
      return {
        cussTxnStarted: false,
        ETSTxnStatus: ETS_TXN_STATUS.ETS_TXN_NONE,
        ETSVersion: data.ETSVersion,
        ETSTime: data.ETSTime,
        ETSTimeDelta: data.ETSTimeDelta,
        OOS: false,
        ETSRequest: false,
        notificationText: '',
      }
    case SessionActions.CLEAR_SESSION_DATA:
      return ''
    default:
      return state
  }
  return data
}
