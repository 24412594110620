import React, { useState } from 'react'
import { Footer } from '../footer'
import { getAccessibilityDevice, history, playSound } from 'main'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useUIBase from 'hooks/ui/useUIBase'
import EMBTable from 'components/EMBTable'
import {
  PageHeader,
  PageContent,
  PageSubContent,
  PageFooter,
  PageText,
  PageTitle,
  PageSubTitle,
  Spacer
} from 'components/styledComponents'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { formatCurrency } from 'utils/helper'

const OverweightPayment = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()

  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const [results, setResults] = useState(paymentInfo)

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_TRACE,
      '(OverweightPayment.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        console.log('handleConfirmAction')
        history.push('PaymentCardSwipe')
        break
      default:
        break
    }
  }

  const textSection = (
    <>
      <PageSubContent width={isLandscape ? '900px' : '600px'}>
        <PageText justifyContent={'center'}>
          {intl.formatMessage(messages.OverweightPaymentTitle, { amount: formatCurrency(results.totalAmount) })}
        </PageText>
        <Spacer height={100} />
        <PageText justifyContent={'center'}>
          {intl.formatMessage(messages.BaggageAllowanceText, { baggageAllowance: results.baggageAllowance })}
        </PageText>
        <Spacer height={50} />
        <PageText justifyContent={'center'}>
          {intl.formatMessage(messages.TotalWeightText, { totalBagWeight: results.totalBagWeight })}
        </PageText>
        <Spacer height={100} />
      </PageSubContent>
    </>
  )

  const header = <PageHeader alignItems="center">{intl.formatMessage(messages.OverweightPayment)}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmBtnText={intl.formatMessage(messages.buttonContinue)}
        confirmAction={handleActions}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default OverweightPayment
