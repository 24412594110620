import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import { updateLocalData } from 'actions/localActions'
import { BaggageStatus } from 'constants/Constants'
import { PageTitle, PageSubTitle, BagProcessingInnerSpan, DynamicImage } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, history, playSound } from 'main'
import { appLog } from 'utils/Logger'
import { getBags } from 'utils/bagProcessUtils'
import { formatBagTagNumber, navigate } from 'utils/helper'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const BagProcessing = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const direction = useSelector((state) => state.localData.direction)
  const bagProcessStatus = useSelector((state) => state.localData.bagProcessStatus)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagtag = useSelector((state) => state.localData.bagtag)
  const prevBagOnQBelt = useSelector((state) => state.localData.prevBagOnQBelt)
  const bqCommand = useSelector((state) => state.localData.bqCommand)
  const locale = useSelector((state) => state.localData.locale)
  const initialIndicator = useRef(true)
  const myFlow = useRef(0)
  const previousPrevBagOnQBelt = usePrevious(prevBagOnQBelt)
  const previousBagProcessStatus = usePrevious(bagProcessStatus)
  const [statusArr, setStatusArr] = useState([
    { id: 0, status: 1 },
    { id: 1, status: 0 },
    { id: 2, status: 0 },
    { id: 3, status: -1 },
    { id: 4, status: 0 },
    { id: 5, status: 0 }
  ])
  const [displayFullTable, setDisplayFullTable] = useState(null)
  const title = intl.formatMessage(messages.BagProcessingTitle1)
  const subtitle = intl.formatMessage(messages.BagProcessingSubTitle1)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'BagProcessing',
      startIndex: 0,
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters: [title, subtitle] }]
      }
    }
    buildAccessibility(accDef)
  }

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(PutBagOnBelt.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    let bagTag = ''
    let bags = null
    let unActiveBags = null
    let bagMeasurements = null
    switch (e.currentTarget.id) {
      case 'buttonWeight':
        dispatch(updateLocalData('appFlow', 5))
        bags = getBags(itineraryInfo)
        console.log('bags:', bags)
        if (bags && bags.length > 0) {
          unActiveBags = bags.filter((bag) => {
            return bag.status !== BaggageStatus.ACTIVATED
          })
        }
        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
        }

        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          // value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=BAGP'
          // value: 'HDCBQOK#LP=' + bagTag + '#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=1.9KG#BT=0#BS=BAGP'
          //value: `HDCBQOK#LP=${bagTag}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=1.9KG#BT=0#BS=BAGP`
          value: `HDCBQOK#LP=${bagTag}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=5.9KG#BT=0#BS=BAGP`
        })
        setTimeout(() => {
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#R0'
          })
        }, 2000)
        break
      case 'buttonHeavy':
        bags = getBags(itineraryInfo)
        console.log('bags:', bags)
        if (bags && bags.length > 0) {
          unActiveBags = bags.filter((bag) => {
            return bag.status !== BaggageStatus.ACTIVATED
          })
        }
        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
        }
        bagTag = bags[0].baseTagNo
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCBQOK#LP=' + bagTag + '#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=33KG#BT=0#BS=BAGP'
        })
        setTimeout(() => {
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#R0'
          })
        }, 2000)
        break
      case 'buttonOverWeight':
        bags = getBags(itineraryInfo)
        console.log('bags:', bags)
        if (bags && bags.length > 0) {
          unActiveBags = bags.filter((bag) => {
            return bag.status !== BaggageStatus.ACTIVATED
          })
        }
        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
        }
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=148.0KG#BS=BAGP'
        })
        break
      case 'buttonAccept':
        // dispatch(setBagProcessStatus(5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCPROK#R0'
        })
        break
      case 'buttonIntrusion':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'unsolicitedMessage',
          value: 'HDCSQNI#OS=0#BC=0#CB=5#SW=0#BB=0#BS=1#CA=0#SD=INTR'
        })
        break
      case 'buttonBagJam':
        dispatch(updateLocalData('appFlow', 6))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#BAGJ'
        })
        break
      case 'buttonTooTall':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVHG'
        })
        break
      case 'buttonMultiTags':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value:
            'HDCBQOK#LP=0220123456#HT=25CM#WT=45CM#LT=56CM#SW=4#CW=23.8KG#LP=0220123457#HT=35CM#WT=55CM#LT=56CM#SW=4#CW=32.1KG'
        })
        break
      case 'buttonTooLong':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVLT'
        })
        break
      case 'buttonMaxWeight':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVWT'
        })
        break
      case 'buttonCannotRead':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#BGPR'
        })
        break
      case 'buttonTooShort':
        dispatch(updateLocalData('appFlow', 6))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVSH'
        })
        break
      case 'buttonEstop':
        dispatch(updateLocalData('appFlow', 2))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=EMCY'
        })
        break
      case 'buttonMyTest':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: `HDCBQOK#LP=9998869212#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=1.2KG#BT=0#BS=BAGP`
        })
        break
    }
  }

  // update status array
  useEffect(() => {
    if (previousBagProcessStatus !== bagProcessStatus || previousPrevBagOnQBelt !== prevBagOnQBelt) {
      let newStatusArr = statusArr.map((ele) => {
        let status = ele.status
        if (ele.id === 3) {
          status = prevBagOnQBelt === true && ele.status < 0 ? 0 : ele.status
        } else if (ele.id < bagProcessStatus.statusId) {
          status = 2
        } else if (ele.id === bagProcessStatus.statusId) {
          status = bagProcessStatus.checkmark == true ? 2 : 1
        }
        return { id: ele.id, status: status }
      })

      setStatusArr(newStatusArr)
    }
  }, [bagProcessStatus, statusArr, prevBagOnQBelt])

  // generate display information
  useEffect(() => {
    let displayArr = []

    if (!config.BagProcessingValidating) {
      // display bag processing details

      const generateProcessDetail = (processElement) => {
        let checkColumn,
          messageColumn = null
        switch (processElement.status) {
          case 0:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing_checkmark_before" />
              </div>
            )
            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'right' : 'left' }}>
                <div
                  className="bagProcessing-text-before"
                  style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}
                >
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case 1:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing-checkmark-current" />
              </div>
            )

            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                <div
                  className="bagProcessing-text-current"
                  style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}
                >
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case 2:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing-checkmark-after" />
              </div>
            )

            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                <div className="bagProcessing-text-after" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case -1:
          default:
            break
        }
        return [checkColumn, messageColumn]
      }

      statusArr.forEach((statusElement, i) => {
        let statusName
        switch (statusElement.id) {
          case 0:
            statusName = intl.formatMessage(messages.BagProcessingStep1)
            break
          case 1:
            statusName = intl.formatMessage(messages.BagProcessingStep2)
            break
          case 2:
            statusName = `${intl.formatMessage(messages.BagProcessingStep3)} ${
              bagtag == null ? '' : formatBagTagNumber(bagtag)
            }`
            break
          case 3:
            statusName = intl.formatMessage(messages.BagProcessingStep4)
            break
          case 4:
            statusName = intl.formatMessage(messages.BagProcessingStep5)
            break
          case 5:
            statusName = intl.formatMessage(messages.BagProcessingStep6)
            break
          default:
            break
        }
        const statusObj = {
          id: statusElement.id,
          text: statusName,
          status: statusElement.status
        }
        const [checkColumn, messageColumn] = generateProcessDetail(statusObj)

        if (messageColumn !== null) {
          displayArr.push(
            <div key={i} className="emb-BagTags-tr" id={'BagProcessingStep' + i} tabIndex="0">
              {checkColumn}
              {messageColumn}
            </div>
          )
        }
      })

      setDisplayFullTable(<div className="emb-BagTags-table">{displayArr}</div>)
    }
  }, [statusArr, bagtag])

  // update sbd belt communication
  useLayoutEffect(() => {
    if (initialIndicator.current) {
      if (appFlow !== 5) {
        dispatch(updateLocalData('bagtag', null))
      }
      if (bqCommand && bqCommand !== '' && appFlow !== 5) {
        navigate('PleaseWait', 5)
        getSBDAppMan().cc_5(bqCommand)
      } else if (appFlow === 2) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Enable SBD via getSBDAppMan...')
        getSBDAppMan().setSQStart(-1)
        getSBDAppMan().setPutBagStart(new Date().getTime())

        //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

        if (config.callCR == 'Y' && config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - Enable Scanner CR')
          //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
          aeaBagdrop.sendAEA('CR', -10000)
        }

        // check if bag is there - delay for config.minPutBag*1000
        //state.set("PutBagStart", (new Date()).getTime()

        if (config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - send SQ')
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      } else if (appFlow == 3) {
        // load to visible area  CC#PA
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Start appFlow: 3 --> call CC#PA')
        dispatch(updateLocalData('appFlow', 4))
        //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CC#PA', -15000)
        //      aeaBagdrop.sendAEA('CC#PA', -15000)
        getSBDAppMan().sendAEACommand('CC#PA', config.loadTimeout, 4, 'bagProcessing')
        appLog(TraceLevels.LOG_EXT_TRACE, 'Bag processing CC#PA')
      }

      initialIndicator.current = false
    } else {
      if (appFlow === 2) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Enable SBD via getSBDAppMan...')
        getSBDAppMan().setSQStart(-1)
        getSBDAppMan().setPutBagStart(new Date().getTime())

        //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

        if (config.callCR == 'Y' && config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - Enable Scanner CR')
          //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
          aeaBagdrop.sendAEA('CR', -10000)
        }

        // check if bag is there - delay for config.minPutBag*1000
        //state.set("PutBagStart", (new Date()).getTime()

        if (config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - send SQ')
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      } else if (appFlow == 3) {
        // load to visible area  CC#PA
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Start appFlow: 3 --> call CC#PA')
        dispatch(updateLocalData('appFlow', 4))
        //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CC#PA', -15000)
        //      aeaBagdrop.sendAEA('CC#PA', -15000)
        getSBDAppMan().sendAEACommand('CC#PA', config.loadTimeout, 4, 'bagProcessing')
        appLog(TraceLevels.LOG_EXT_TRACE, 'Bag processing CC#PA')
      }
    }
  }, [appFlow, bqCommand])

  // end if (bag processing details

  let displayData = config.showBagProcessingDetails ? (
    displayFullTable
  ) : (
    <div className="bagProcessing-infoText-wrapper">
      <div className="bagProcessing-infoText1">{intl.formatMessage(messages.BagProcessingValidating)}</div>
      <div className="bagProcessing_infoText2">
        {intl.formatMessage(messages.BagProcessingValidatingBagtag, { bagtag: formatBagTagNumber(bagtag) })}
      </div>
    </div>
  )

  let testButtons = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonWeight',
      text: 'process',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonHeavy',
      text: 'Heavy bag',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonOverWeight',
      text: 'Over weight',
      handler: handleActions,
      group: 0
    },
    {
      id: 'ERROR-DESC',
      text: 'Error Cases:',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonIntrusion',
      text: 'Intrusion',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonBagJam',
      text: 'Bag Jam',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooTall',
      text: 'Too Tall',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMultiTags',
      text: 'Multi Tags',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooLong',
      text: 'Too Long',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMaxWeight',
      text: 'Max Weight',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonCannotRead',
      text: 'Cannot Read',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooShort',
      text: 'Too Flat',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonEstop',
      text: 'E STOP',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMyTest',
      text: 'My Test',
      handler: handleActions,
      group: 1
    }
  ]
  const animationSize = useMedia(null, [420, 350, 420], 420)
  const titleAlignment = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const tableAlignment = useMedia(null, ['center', 'center', 'flex-end'])
  const animationSection = (
    <DynamicImage
      //imageName={`${themeContext.AnimationPath}/${sbdModel}/${bagProcessingAnimation}`}
      imageName={`${themeContext.AnimationPath}/${sbdModel}/bagProgress_movement_new.gif`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )
  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {subtitle}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isLangRequired={false}
      testData={testButtons}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header: null, topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default BagProcessing
