import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CustomPage, Welcome } from 'custom/layouts'

export const customRoutes = [
  { path: `${PUBLIC_URL}/Welcome`, element: <Welcome /> },
  {
    element: <CustomPage />,
    path: `${PUBLIC_URL}/customPage`
  }
]

/* export const customRoutes = () => {
  return (
    <Routes>
      <Route path={`${PUBLIC_URL}/customPage`} element={<CustomPage />} />
    </Routes>
  )
} */
