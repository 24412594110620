import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { getSBDAppMan, playSound, getDeviceManager, getBuildAccessibility } from 'main'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { TransitionCodes, SBDUpdateAction } from 'constants/Constants'
import { SourceType, ErrCodes, ContinueErrCodes, ErrorDictionary, ErrType } from 'constants/Errors'
import { END_TXN_REASON, TIMEOUT_POPUP, TIMEOUT_YES_BTN, TIMEOUT_NO_BTN, OOS, RETRY } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { PageHeader, PageTitle, PageSubTitle, DynamicImage, Spacer } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { turnLights } from 'utils/lights'
import { appLog, logEvent } from 'utils/Logger'
import { replacer, populateItineraryInfo, isNotEmpty, checkLandscape, isEmpty, navigate } from 'utils/helper'
import { getEventLogger } from '../../main'

const mapTransitionToPage = (source, transition, appFlow) => {
  let result = ''
  switch (transition) {
    case TransitionCodes.BARCODE_RETRY:
      if (appFlow === 5) {
        result = 'ErrorBPMismatch'
      } else {
        result = 'END_TXN'
      }
      break
    case TransitionCodes.CUSTOM_4:
      result = 'genericCommand'
      break
    case TransitionCodes.SBD_PROCESS:
      result = 'PutBagOnBelt'
      break
    case TransitionCodes.SCAN_BP:
      result = 'ScanBoardingPass'
      break
    case TransitionCodes.PAX_VALIDATION:
      result = 'ScanDocuments'
      break
    case TransitionCodes.AGENT_CHECK:
      result = 'AgentScan'
      break
    case TransitionCodes.END_TXN:
    case null:
    default:
      result = 'END_TXN'
      break
  }
  return result
}

const mapErrImage = (errCode, sbdModel) => {
  const animationSize = useMedia(null, [400, 350, 400], 400)
  const warningSize = useMedia(null, [400, 350, 400], 400)
  const themeContext = useContext(ThemeContext)
  let location = `generic-agent.svg`
  switch (errCode) {
    case ErrCodes.BAG_NOSCAN:
      // 450*450
      // errImgClass = 'emb_animation_wrapper_bottom'
      // if (sbdModel === 'VBD') {
      //   location = `BagtagNotFound490VBD.png`
      // } else if (sbdModel === 'VBDR') {
      //   location = `BagtagNotFound490VBDR.png`
      // } else {
      //   location = `BagtagNotFound450.png`
      // }
      location = `bag-tag-error.svg`
      break
    // case ErrCodes.BAG_TOO_TALL:
    //   location = `${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`
    //   break
    case ErrCodes.BAG_UNDERHEIGHT:
    case ErrCodes.BAG_UNDERHEIGHT_USEBIN:
    case ErrCodes.BAG_UNDERHEIGHT_ADDBAG:
      location = `baggage-length.svg`
      break
    case ErrCodes.BAG_UNDERWEIGHT:
    case ErrCodes.BAG_UNDERWEIGHT_USEBIN:
    case ErrCodes.BAG_UNDERWEIGHT_ADDBAG:
      location = `baggage-error-red.svg`
      break
    case ErrCodes.BAG_MAX_WEIGHT:
    case ErrCodes.BAG_MAX_WEIGHT_ADDBAG:
    case ErrCodes.MAX_POOL_WEIGHT:
    case ErrCodes.BAG_OVERWEIGHT:
    case ErrCodes.OVERWEIGHT_AGENT:
    case ErrCodes.OVERWEIGHT_LAST:
      location = `baggage-error-red.svg`
      break
    case ErrCodes.BAG_LONG:
    case ErrCodes.BAG_LONG_ADDBAG:
      location = `baggage-width.svg`
      break
    case ErrCodes.NO_BAGS:
      location = `baggage-error-red.svg`
      break
    case ErrCodes.BAG_RATIO_TOO_HIGH:
      location = `baggage-length.svg`
      break
    case ErrCodes.OVERWEIGHT_AGENT:
    case ErrCodes.OVERWEIGHT_LAST:
      location = `baggage-error-red.svg`
      break
    case ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED:
      location = `generic-agent.svg`
      break
    case ErrCodes.BAG_TOO_TALL:
    case ErrCodes.BAG_RATIO_TOO_HIGH:
    case ErrCodes.BAG_SHORT:
      location = `baggage-length.svg`
      break
    case ErrCodes.WRONG_AIRPORT:
      location=`airport.svg`
      break
    case ErrCodes.TOO_EARLY_BAGGAGE:
      location=`too-early.svg`
      break
    case ErrCodes.TOO_LATE_BAGGAGE: 
      location=`too-late.svg`
      break
    case ErrCodes.CONVEYOR_ESTOP:
      location=`estop.png`
      break
    // case ErrCodes.CONVEYOR_INTRUSION:
    //   result = <img className="emb_animation_drawbox" src={IntrusionAnimation} />
    default:
      location = `generic-agent.svg`
      break
  }
  if (location === 'generic-agent.svg') {
    return (
      <DynamicImage
        imageName={location}
        cssName={'emb_animation_drawbox'}
        width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
        height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
      />
    )
  } else {
    return (
      <DynamicImage
        imageName={location}
        cssName={'emb_animation_drawbox'}
        width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
        height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
      />
    )
  }
}

const GenericErrors = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const errorDetails = useSelector((state) => state.errorDetails)
  const locale = useSelector((state) => state.localData.locale)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const iconSize = useMedia(null, [50, 40, 45], 45)
  const themeContext = useContext(ThemeContext)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )
  const errImage = mapErrImage(errorDetails.errCode, sbdModel)
  const [errMsgTitle, setErrMsgTitle] = useState('')
  const [errMsgSubTitle, setErrMsgSubTitle] = useState('')
  let timeoutRef = null
  let SBDAppManager = getSBDAppMan()

  const getErrMsg = (errorCode, msgs, language) => {
    let errMsg = {
      errorTitle: '',
      errorSubTitle: ''
    }
    let message = msgs.filter((msg) => msg.languageCode.toUpperCase() === language.toUpperCase())
    if (message.length > 0) {
      let msgParts = message[0].screenMsg.split('***')
      if (msgParts.length > 1) {
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: msgParts[1]
        }
      } else {
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: ''
        }
      }
    }
    return errMsg
  }

  const setErrorMessage = () => {
    let errorMessage = {
      errorTitle: '',
      errorSubTitle: ''
    }
    if (errorDetails.sourceType === SourceType.FETCH) {
      if (Array.isArray(errorDetails.msg)) {
        errorMessage = getErrMsg(errorDetails.errCode, errorDetails.msg, locale)
      }
    } else {
      let msgParts = []
      let msgText = ''
      if (messages[errorDetails.msg]) {
        if (errorDetails.params) {
          msgText = intl.formatMessage(messages[errorDetails.msg], errorDetails.params)
        } else {
          msgText = intl.formatMessage(messages[errorDetails.msg])
        }
      }
      if (msgText.length > 0) {
        msgParts = msgText.split('\n\n')
      }
      if (msgParts.length == 1) {
        errorMessage.errorTitle = msgParts[0]
      } else if (msgParts.length > 1) {
        errorMessage.errorTitle = msgParts[0]
        errorMessage.errorSubTitle = msgParts[1]
      }
    }
    return errorMessage
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - error source type = "' + errorDetails.sourceType + '"')
    const errorMessage = setErrorMessage()
    setErrMsgTitle(errorMessage.errorTitle)
    setErrMsgSubTitle(errorMessage.errorSubTitle)

    /** accessibility **/
    handleAccessibility()
  }, [locale, errorDetails])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : { displayName: '' }
    const errorMessage = setErrorMessage()
    const genericErrors = [errorMessage.errorTitle, errorMessage.errorSubTitle]
    const accDef = {
      pathName: 'error',
      startIndex: 0,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [itinerary.displayName, genericErrors.join('. ')]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - errCode = "' + errorDetails.errCode + '"')
    logEvent('Error, ' + errorDetails.errCode)
    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) -  UNMOUNTED ...')
      getDeviceManager()
        .getAppManager()
        .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
      dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
      dispatch(updateLocalData('updateBqCommand', ''))
      dispatch(updateError(null)) // RESET ERROR
    }
  }, [])

  const handleExitPage = () => {
    const errTransition = errorDetails.transition
    const source = errorDetails.source
    const sourceType = errorDetails.sourceType
    console.log('WHERE ERROR PAGE GO TO:::', errorDetails)

    if (sourceType === SourceType.FETCH) {
      const transitionPage = mapTransitionToPage(source, errTransition, appFlow)
      if (transitionPage === 'END_TXN') {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        getEventLogger().clientSessionId = null
      } else {
        if (transitionPage === 'PutBagOnBelt') {
          // when we want to go back to putbagonbelt page, remember to set the flow to 2.
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (transitionPage === 'genericCommand') {
          let updatedItem = {}
          validatePaxResult.forEach((item) => {
            if (item.ordinal === currentPassenger.ordinal) {
              updatedItem = item
            }
          })

          const updatePaxStatus = {
            ordinal: currentPassenger.ordinal,
            docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            faceMatch:
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED ||
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
          }
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        } else {
          navigate(transitionPage)
        }
      }
    } else {
      if (errTransition) {
        if (errTransition === 'PutBagOnBelt') {
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (errTransition === 'END_TXN') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        } else if (errTransition === 'OOS') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, OOS)
        } else {
          navigate(errTransition)
        }
      } else {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
      }
    }
  }

  const handleActions = (e) => {
    if (timeoutRef !== null) {
      timeoutRef.getWrappedInstance().resetTimer()
    }
    appLog(TraceLevels.LOG_TRACE, '(ErrorPage.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')

    playSound.beepOK()

    /// TEST CODE

    // end OF TEST CODE

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        handleExitPage()
        break

      default:
        break
    }
  }

  const animationSection = errImage
  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {errMsgTitle}
      </PageTitle>
      <PageSubTitle justifyPageSubTitle="center" color={themeContext.PrimaryFontColor}>
        {errMsgSubTitle}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.ErrorTitle)}
    </PageHeader>
  )

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={!(errorDetails && errorDetails.confirmDisable)}
      isLangRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonOk)}
      confirmAction={handleActions}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '100%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '100%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default GenericErrors
