import { TransitionCodes, CURRENCY_FORMAT, CONVEYOR_POSITION } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { appLog, logEvent, sendEventLog } from 'utils/Logger'
import { store, history, getEventLogger, getSummaryStore, getSBDAppMan } from 'main'
import { updateLocalError } from 'actions/commonActions'
import { updateLocalData, setBagProcessStatus } from '../actions/localActions'

export function removeArrayBrackets(str) {
  if (str.indexOf('[') === 0) return str.substring(1, str.length - 1)
  else return str
}

export function getDateObjFromETSDateStr(ETSDateStr) {
  let items = ETSDateStr.split('-')
  return {
    year: items[0],
    month: items[1],
    day: items[2]
  }
}

export function getComponentFromETSTransition(transition) {
  let comp = ''
  switch (transition) {
    case TransitionCodes.SECURITY_QUESTION:
      comp = 'securityQuestion'
      break
    case TransitionCodes.IDENTIFY_PNR:
      comp = 'identification'
      break
      case TransitionCodes.DANGEROUS_GOODS: 
    case TransitionCodes.DANGEROUS_GOODS_QUESTIONS:
      // comp = 'bagsHazMat'
      comp = 'hazMatQuestion'
      break
    default:
  }
  appLog(TraceLevels.LOG_EXT_TRACE, 'component:', comp)
  return comp
}

export function getValueByIdFromConfig(configArray, id) {
  let retValue = null
  // eslint-disable-next-line no-unused-vars
  for (let obj in configArray) {
    if (obj.hasOwnProperty('id') && obj.id === id) {
      retValue = obj.value
    }
  }
  return retValue
}

export function getKeyByValue(source, value) {
  let target = ''
  // eslint-disable-next-line no-unused-vars
  for (let prop in source) {
    if (source.hasOwnProperty(prop)) {
      if (source[prop] === value) target = prop
    }
  }
  return target
}

export function getValueByIdFromConfigObjectMap(configMap, id) {
  if (configMap == null) return null
  let retValue = null
  if (configMap.hasOwnProperty(id)) {
    retValue = configMap[id].value
  }
  return retValue
}

export function getValueByIdFromConfigMap(configMap, id) {
  if (configMap == null) return null
  let retValue = null
  if (configMap.hasOwnProperty(id)) {
    retValue = configMap[id]
  }
  return retValue
}

export function getTimeoutServiceConfig(serviceConfigInfo) {
  let config = new Map()
  serviceConfigInfo.map((item) => {
    if (item.section === 'EKCTimeoutSettings') config.set(item.id, parseInt(item.value, 10))
  })
  return config
}
export function getNavCodeFromServiceName(navMap, serviceName) {
  let navCode = null
  // eslint-disable-next-line no-unused-vars
  for (let item of navMap) {
    if (item.serviceName === serviceName) {
      navCode = item.navigationCode
    }
  }
  return navCode
}

export function applyParameters(targetStr, params) {
  let resultStr = targetStr === undefined ? '' : targetStr
  if (params && params.length > 0) {
    for (let i = 0; i < params.length; i++) {
      resultStr = resultStr.replace('{' + i + '}', params[i])
      // if (resultStr.includes('<say-as interpret-as="characters">{' + i + '}</say-as>')) {
      //   resultStr = resultStr.replace('{' + i + '}', params[i].split('').join(', '))
      // } else {
      //   resultStr = resultStr.replace('{' + i + '}', params[i])
      // }
    }
  }
  return resultStr
}

export function appyParametersToMessage(targetMsg, params) {
  let resultMsg = targetMsg === undefined ? '' : targetMsg
  if (params && Object.keys(params).length > 0) {
    for (let key in params) {
      resultMsg = resultMsg.replace('{' + key + '}', params[key])
    }
  }
  return resultMsg
}

export function xmlToJson(xml) {
  // Create the return object
  let obj = {}

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {}
      for (let j = 0; j < xml.attributes.length; j++) {
        let attribute = xml.attributes.item(j)
        obj['@attributes'][attribute.nodeName] = attribute.value
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue
  }

  // do children
  // If just one text node inside
  if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
    obj = xml.childNodes[0].nodeValue
  } else if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      let item = xml.childNodes.item(i)
      let nodeName = item.nodeName
      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlToJson(item)
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          let old = obj[nodeName]
          obj[nodeName] = []
          obj[nodeName].push(old)
        }
        obj[nodeName].push(xmlToJson(item))
      }
    }
  }
  return obj
}

export function getDeviceNameById(deviceId) {
  let devName = ''
  switch (deviceId) {
    case deviceIds.ATB_PRINTER:
      devName = 'ATB_Printer'
      break
    case deviceIds.BARCODE_READER:
      devName = 'Barcode_Reader'
      break
    case deviceIds.CARD_READER:
      devName = 'Card_Reader'
      break
    case deviceIds.CUSS_ACCESSIBILITY:
      devName = 'Cuss_Accessibility'
      break
    case deviceIds.PASSPORT_READER:
      devName = 'Passport_Reader'
      break
    case deviceIds.BAGTAG_PRINTER:
      devName = 'Bagtag_Printer'
      break
    default:
      devName = ''
  }
  return devName
}

export function delay(sec) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve()
    }, sec * 1000)
  })
}

export function isInteger(value) {
  if (undefined === value || value === null) {
    return false
  }
  return value % 1 === 0
}

export function configsToMap(arr) {
  if (arr == null) return null
  return arr.reduce(function (total, current) {
    total[current.id] = current.value
    return total
  }, {})
}

export function configsToMapWithSection(arr) {
  if (arr == null) return null
  return arr.reduce(function (total, current) {
    total[current.id] = { value: current.value, section: current.section }
    return total
  }, {})
}

export function getSubstringForLog(text, maxLth) {
  if (typeof text === 'string' && text.length > maxLth) {
    return text.substring(0, maxLth) + '...'
  } else return text
}
export function replacer(key, value) {
  if (config.logToConsole) {
    return value
  }
  // Filtering out properties
  if (value !== null && value !== undefined && typeof value === 'string') {
    if (
      key === 'lastName' ||
      key === 'firstName' ||
      key === 'paxName' ||
      key === 'travelerName' ||
      key === 'paxFullName' ||
      key === 'mrz' ||
      key === 'rfmrz' ||
      key === 'track1' ||
      key === 'track2' ||
      key === 'track3' ||
      key === 'barcodeData' ||
      key === 'passengerFirstName' ||
      key === 'passengerLastName' ||
      key === 'dateOfBirth' ||
      key === 'bcbp' ||
      key === 'travelerNumber'
    ) {
      return value.replace(/./gi, 'N')
    } else if (key === 'receiptPrintStream' || key === 'boardingPassPrintStream' || key === 'kacPrintstream') {
      return '...'
    }
  }
  if (key !== 'endpointAddress' && typeof value === 'string' && value.length > config.logMaxElementLength) {
    return value.substring(0, config.logMaxElementLength) + '...'
  }
  return value
}

export function maskPCI(value, deviceId = -1) {
  if (config.logToConsole) {
    return value
  }
  try {
    let maxLth = Math.min(config.logMaxPCIElementLength, 6) // no more than 6 chars unmasked
    switch (deviceId) {
      case deviceIds.PASSPORT_READER:
        maxLth = 5
        break
      case deviceIds.BARCODE_READER:
        if (value.length > 20 && value.indexOf('M1') === 0) {
          return 'M1' + repeatChar('N', 18) + value.substring(20)
        } else maxLth = 0
        break
      default:
    }
    if (maxLth > 0) {
      return value.substring(0, maxLth) + (value.length > maxLth ? repeatChar('N', value.length - maxLth) : '')
    } else {
      return value.replace(/./gi, 'N')
    }
  } catch (err) {
    appLog(TraceLevels.LOG_ALERT, 'maskPCI error:' + err)
    return ''
  }
}

export function groupInfantCheck(passengers) {
  return passengers.filter((pass) => pass.withInfant).length > 0 ? true : false
}

export function isEmpty(obj) {
  return (
    obj === undefined ||
    obj === null ||
    obj === '' ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0)
  )
}

export function isNotEmpty(obj) {
  return !isEmpty(obj)
}

/*export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}*/
export function clone(originalObject, circular) {
  // First create an empty object with
  // same prototype of our original source

  let propertyIndex,
    descriptor,
    keys,
    current,
    nextSource,
    indexOf,
    copies = [
      {
        source: originalObject,
        target: Array.isArray(originalObject) ? [] : Object.create(Object.getPrototypeOf(originalObject))
      }
    ],
    cloneObject = copies[0].target,
    sourceReferences = [originalObject],
    targetReferences = [cloneObject]

  // First in, first out
  // eslint-disable-next-line
  while ((current = copies.shift())) {
    keys = Object.getOwnPropertyNames(current.source)

    for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
      // Save the source's descriptor
      descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex])

      if (!descriptor.value || typeof descriptor.value !== 'object') {
        Object.defineProperty(current.target, keys[propertyIndex], descriptor)
        continue
      }

      nextSource = descriptor.value
      descriptor.value = Array.isArray(nextSource) ? [] : Object.create(Object.getPrototypeOf(nextSource))

      if (circular) {
        indexOf = sourceReferences.indexOf(nextSource)

        if (indexOf !== -1) {
          // The source is already referenced, just assign reference
          descriptor.value = targetReferences[indexOf]
          Object.defineProperty(current.target, keys[propertyIndex], descriptor)
          continue
        }

        sourceReferences.push(nextSource)
        targetReferences.push(descriptor.value)
      }

      Object.defineProperty(current.target, keys[propertyIndex], descriptor)

      copies.push({ source: nextSource, target: descriptor.value })
    }
  }

  return cloneObject
}

export function repeatChar(ch, n) {
  let ret = ''
  for (ret = ''; ret.length < n; ) ret += ch
  return ret
}

export function getScreenId(pathName) {
  let pathParts = pathName.split('/')
  let screenId = ''
  if (pathParts.length > 2) {
    //screenId = pathParts[2]
    if (pathParts[2] === '') {
      screenId = 'OutOfService'
    } else {
      screenId = pathParts[2]
    }
  } else {
    if (pathParts[1] === '') {
      screenId = 'OutOfService'
    } else {
      screenId = pathParts[1]
    }
  }
  return screenId.toLowerCase()
}

export function getDateObjectFromETS(dateString) {
  let dateObj = null
  let partDate = []
  let partTime = []
  let step1 = dateString.split('T')
  if (step1.length === 2) {
    partDate = step1[0].split('-')
    partTime = step1[1].split(':')
    if (partDate.length === 3 && partTime.length === 3) {
      dateObj = new Date(
        parseInt(partDate[0]),
        parseInt(partDate[1]) - 1,
        parseInt(partDate[2]),
        parseInt(partTime[0]),
        parseInt(partTime[1]),
        parseInt(partTime[2])
      )
    }
  }
  return dateObj
}

export const showCurrency = (locale, currencyCode, value, format = CURRENCY_FORMAT.INT) => {
  let retString = null
  let option = { style: 'currency', currency: currencyCode }
  let optionInt = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: currencyCode
  }
  let optionCode = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code'
  }
  let numberFormat = new Intl.NumberFormat(locale, option)
  switch (format) {
    case CURRENCY_FORMAT.LONG:
      retString = numberFormat.format(value) + ' ' + currencyCode
      break
    case CURRENCY_FORMAT.SHORT:
      retString = numberFormat.format(value)
      break
    case CURRENCY_FORMAT.INT:
      numberFormat = new Intl.NumberFormat(locale, optionInt)
      retString = numberFormat.format(value)
      break
    case CURRENCY_FORMAT.CODE:
      numberFormat = new Intl.NumberFormat(locale, optionCode)
      if (value === 0) {
        retString = numberFormat.format(value.toFixed(2)).replace(currencyCode, '') + ' ' + currencyCode
      }
      retString = numberFormat.format(value).replace(currencyCode, '') + ' ' + currencyCode

      break
    default:
      retString = value.toString()
  }
  return retString
}

export const getEnabledElement = (id) => {
  let nodeList = document.querySelectorAll('#' + id)
  let element = null
  if (nodeList.length > 0) {
    nodeList.forEach((node) => {
      if (node.attributes['aria-disabled'] && node.attributes['aria-disabled'].nodeValue === 'false') {
        element = node
      }
    })
  }
  return element
}
export function getLocalTimeAMPM(oldDateTime) {
  if (!oldDateTime) return ''
  //appLog(TraceLevels.LOG_LEVEL6, '...getLocalTimeAMPM() - Old DateTime Value: "' + oldDateTime + '"')
  let timeStr = oldDateTime.substring(oldDateTime.indexOf('-') + 1)
  let hourStr_24H = timeStr.substring(0, timeStr.indexOf(':'))
  let minuteStr = timeStr.substring(timeStr.indexOf(':') + 1, timeStr.lastIndexOf(':'))
  //let secondStr = timeStr.substring(timeStr.lastIndexOf(':')+1)
  let hourInt_24H = parseInt(hourStr_24H)
  let hourStr_12H = ''
  let ampm = ''

  if (hourInt_24H == 0 || hourInt_24H == 12 || hourInt_24H == 24) {
    hourStr_12H = '12'
    ampm = hourInt_24H == 12 ? 'PM' : 'AM'
  } else if (hourInt_24H < 12) {
    hourStr_12H = '' + hourInt_24H
    ampm = 'AM'
  } else {
    // else if (hourInt_24H > 12)
    hourStr_12H = '' + (hourInt_24H - 12)
    ampm = 'PM'
  }
  /* appLog(
    TraceLevels.LOG_LEVEL6,
    '...getLocalTimeAMPM() - New Return Value: "' + hourStr_12H + ':' + minuteStr + ampm + '"'
  ) */
  return hourStr_12H + ':' + minuteStr + ' ' + ampm
}
// bag drop functions
export function getTestBP(key, seq = 'A', airport = '', tlte) {
  // first two chars
  let bp = bp1
  if (key.length >= 2) {
    bp = bpx.replace('XX', key.substring(0, 2))
    bp = bp.replace('#', seq.substring(0, 1))
    if (airport) {
      bp = bp.replace(/LAS/g, airport.substring(0, 3))
    }
    // too late or too early
    if (tlte) {
      bp = bp.replace('YY', tlte.substring(0, 2))
    }
  }
  return bp
}
export function generateBP(
  pnr,
  firstName,
  lastName,
  from,
  to,
  airline,
  flightNumber,
  seatClass = 'B',
  seatNumber = '25A',
  sequence = '0012',
  paxStatus = '1'
) {
  let today = new Date()
  //let jd = (now.valueOf() - now.getTimezoneOffset() * 60000) / 86400000 + 2440588
  let jd = Math.ceil((today - new Date(today.getFullYear(), 0, 1)) / 86400000)
  //let jd = Math.floor(now.valueOf() / (1000 * 60 * 60 * 24) - 0.5) + 2440588
  let bp =
    'M1' +
    (lastName.substring(0, 10) + '/' + firstName.substring(0, 9)).toUpperCase().padEnd(20, ' ') +
    'E' +
    pnr.toUpperCase().padEnd(7) +
    from.toUpperCase().substring(0, 3) +
    to.toUpperCase().substring(0, 3) +
    airline.toUpperCase().substring(0, 3) +
    ' ' +
    flightNumber.padStart(4, '0') +
    ' ' +
    jd.toString().padStart(3, '0') +
    seatClass.substring(0, 1) +
    seatNumber.padStart(4, '0') +
    sequence.toString().padStart(4, '0') +
    ' ' +
    paxStatus.substring(0, 1) +
    '00'
  return bp
}
export function getScanSequence(prevSeq, passengerBagtagDetails) {
  let scanSequence = []
  if (Array.isArray(passengerBagtagDetails)) {
    scanSequence = prevSeq
    for (let i = 0; i < passengerBagtagDetails.length; i++) {
      if (passengerBagtagDetails[i].bpScanned) {
        let paxOrd = passengerBagtagDetails[i].passenger.ordinal
        if (prevSeq.indexOf(paxOrd) < 0) {
          scanSequence.push(passengerBagtagDetails[i].passenger.ordinal)
          // break
        }
      }
    }
  } else {
    scanSequence.push(passengerBagtagDetails.passenger.ordinal)
  }
  return scanSequence
}
export function formatBagTagNumber(lpn) {
  let bagtags = lpn
  if (bagtags && bagtags.length > 10) {
    let bags = ''
    do {
      if (bags == '') bags = formatBagTagDisplay(bagtags.substring(0, 10))
      else bags = bags + ', ' + formatBagTagDisplay(bagtags.substring(0, 10))
    } while ((bagtags = bagtags.substring(10, bagtags.length)) != '')
    return bags
  } else {
    return formatBagTagDisplay(lpn)
  }
}

export function formatBagTagDisplay(bagtag) {
  return config.useOnlyTagNumber && bagtag != undefined && bagtag != null && bagtag.length == 10
    ? bagtag.substring(4)
    : bagtag
}

export function updateUseOnlyTagNumber(bagtagDetails) {
  // update the useOnlyTagNumber in the config - based on the length of the bagtag from Fusion (6 or 10 )
  let abort = false
  for (let k = 0; !abort && k < bagtagDetails.length; k++) {
    for (let j = 0; !abort && j < bagtagDetails[k].bagTags.length; j++) {
      let bagtagLth = bagtagDetails[k].bagTags[j].baseTagNo ? bagtagDetails[k].bagTags[j].baseTagNo.length : 0
      if (bagtagLth == 6) {
        config['useOnlyTagNumber'] = true
        abort = true
      } else if (bagtagLth == 10) {
        config['useOnlyTagNumber'] = false
        abort = true
      }
    }
  }
}

export function updateConfigFromString(key, newValue) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'updateConfigFromString() key: ' + key + ' value: ' + newValue)
  let numConv = Number(newValue)
  if (!isNaN(numConv)) {
    config[key] = numConv
  } else {
    if (newValue === 'True' || newValue === 'true') {
      config[key] = true
    } else if (newValue === 'False' || newValue === 'false') {
      config[key] = false
    } else {
      config[key] = newValue
    }
  }
}

export function format1107msg(bagtag, itineraryInfo, bagData) {
  const summaryStore = getSummaryStore()
  //BAG=122112233, PNR=HAQZMM, CARRIER=WS, FLT=0225, FROM=YOW, DEST=YEG, PAX=0, WEIGHT=12.4KG, HEIGHT=25CM, LENGTH=60CM
  // bagdata {bagTagObject, measurements, overrideCode}
  let msg = 'BAG=' + bagtag
  if (itineraryInfo) {
    try {
      msg += ', PNR=' + itineraryInfo.recordLocator
      let flightsArr = itineraryInfo.outboundFlights
      let flightInfoAdded = false //to avoid multiple flight info
      for (let j = 0; j < flightsArr.length && !flightInfoAdded; j++) {
        if (flightsArr[j].departureFlight === true) {
          msg += ', CARRIER=' + flightsArr[j].opCarrierCode
          msg += ', FLT=' + flightsArr[j].opFlightNo
          msg += ', FROM=' + flightsArr[j].departureSchedule.schedule.airport.code
          msg += ', DEST=' + flightsArr[j].arrivalSchedule.schedule.airport.code
          flightInfoAdded = true
        }
      }
      msg += ', PAX=' + bagData.bagTagObject.paxOrdinal
      msg += ', WEIGHT=' + bagData.measurements[0]
      msg += ', HEIGHT=' + bagData.measurements[1]
      msg += ', LENGTH=' + bagData.measurements[2]
      if (bagData.measurements.length >= 4 && bagData.measurements[3] > 0) {
        msg += ', WIDTH=' + bagData.measurements[3]
      }
      if (bagData.measurements.length >= 5 && bagData.measurements[4]) {
        summaryStore.updateTubNumber()
        msg += ', TUB'
      }
      if (bagData.overrideCode !== '') {
        msg += ', OVR=YES'
      }
    } catch (err) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'format1107msg() error: ' + err)
    }
  }
  return msg
}
export function buildBagtagObject(tagNo) {
  return {
    issuingCarrierNo: null,
    issuingCarrierCode: null,
    baseTagNo: tagNo,
    securityNo: null,
    finalDestination: null,
    forExcessBag: false,
    status: 'ADDED',
    bagIdentifier: null,
    jsonExtend: null
  }
}

export function buildTestBagMeasurements() {
  return ['24.0KG', '24CM', '55CM', 'ERR', false]
}
export function populateItineraryInfo(itineraryInfo) {
  let flightsArr = itineraryInfo != null ? itineraryInfo.outboundFlights : []
  let paxName = ''
  // when more than one passenger: scanSequence[0] name + number of additional scanned BPs
  if (Array.isArray(itineraryInfo.passengerBagtagDetails)) {
    for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
      let name =
        itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
        ' ' +
        itineraryInfo.passengerBagtagDetails[i].passenger.lastName
      if (isNotEmpty(itineraryInfo.scanSequence)) {
        if (itineraryInfo.passengerBagtagDetails[i].passenger.ordinal === itineraryInfo.scanSequence[0]) {
          paxName = name
        }
      } else if (i === 0) {
        paxName = name
      }
    }
  } else if (itineraryInfo.passengerBagtagDetails === undefined) {
    paxName = itineraryInfo.name
  } else {
    paxName =
      itineraryInfo.passengerBagtagDetails.passenger.firstName +
      ' ' +
      itineraryInfo.passengerBagtagDetails.passenger.lastName
  }

  /* appLog(
    TraceLevels.LOG_EXT_TRACE,
    '..populateItineraryInfo() - Found Passenger Name = "' + paxName.replace(/./gi, 'N') + '"'
  ) */

  if (flightsArr !== undefined) {
    for (let j = 0; j < flightsArr.length; j++) {
      if (flightsArr[j].departureFlight === true) {
        let boardTime = getLocalTimeAMPM(flightsArr[j].departureSchedule.boardTime)
        let departureTime = flightsArr[j].departureSchedule.schedule.estimatedTime
          ? getLocalTimeAMPM(flightsArr[j].departureSchedule.schedule.estimatedTime)
          : getLocalTimeAMPM(flightsArr[j].departureSchedule.schedule.scheduledTime)
        let arrivalTime = flightsArr[j].arrivalSchedule.schedule.estimatedTime
          ? getLocalTimeAMPM(flightsArr[j].arrivalSchedule.schedule.estimatedTime)
          : getLocalTimeAMPM(flightsArr[j].arrivalSchedule.schedule.scheduledTime)
        let groupSize = itineraryInfo.scanSequence ? itineraryInfo.scanSequence.length : 0
        /* appLog(TraceLevels.LOG_LEVEL6, '..populateItineraryInfo() -     --> displayName = "' + paxName + '"')
        appLog(TraceLevels.LOG_LEVEL6, '..populateItineraryInfo() -     --> groupSize = "' + groupSize + '"')
        appLog(
          TraceLevels.LOG_LEVEL6,
          '..populateItineraryInfo() -     --> flightCode = "' +
            flightsArr[j].opCarrierCode +
            flightsArr[j].departureSchedule.schedule.airport.code +
            ' - ' +
            flightsArr[j].arrivalSchedule.schedule.airport.code +
            flightsArr[j].opFlightNo +
            '"'
        )
        appLog(
          TraceLevels.LOG_LEVEL6,
          '..populateItineraryInfo() -     --> departureCity = "' +
            flightsArr[j].departureSchedule.schedule.airport.code +
            '"'
        )
        appLog(
          TraceLevels.LOG_LEVEL6,
          '..populateItineraryInfo() -     --> arrivalCity = "' +
            flightsArr[j].arrivalSchedule.schedule.airport.code +
            '"'
        )
        appLog(TraceLevels.LOG_LEVEL6, '..populateItineraryInfo() -     --> departureTime = "' + departureTime + '"')
        appLog(TraceLevels.LOG_LEVEL6, '..populateItineraryInfo() -     --> boardTime = "' + boardTime + '"')
        appLog(
          TraceLevels.LOG_LEVEL6,
          '..populateItineraryInfo() -     --> gate = "' + flightsArr[j].departureSchedule.gateNumber + '"'
        ) */

        return {
          displayName: paxName,
          groupSize: groupSize,
          flightNumber: flightsArr[j].opFlightNo,
          airlineCode: flightsArr[j].opCarrierCode,
          departureCity:
            flightsArr[j].departureSchedule.schedule.airport.name !== null
              ? flightsArr[j].departureSchedule.schedule.airport.name
              : flightsArr[j].departureSchedule.schedule.airport.code,
          departureCityCode: flightsArr[j].departureSchedule.schedule.airport.code,
          arrivalCity:
            flightsArr[j].arrivalSchedule.schedule.airport.name !== null
              ? flightsArr[j].arrivalSchedule.schedule.airport.name
              : flightsArr[j].arrivalSchedule.schedule.airport.code,
          arrivalCityCode: flightsArr[j].arrivalSchedule.schedule.airport.code,
          // departureCity: flightsArr[j].departureSchedule.schedule.airport.code,
          // arrivalCity: flightsArr[j].arrivalSchedule.schedule.airport.code,
          departureTime: departureTime,
          arrivalTime: arrivalTime,
          boardTime: boardTime,
          gate: flightsArr[j].departureSchedule.gateNumber,
          allowance: itineraryInfo.allowance
        }
      }
    }
  } else {
    let time = getLocalTimeAMPM(itineraryInfo.flightTime)
    return {
      displayName: paxName,
      flightCode: itineraryInfo.flightNumber,
      departureCity: itineraryInfo.departFlight,
      arrivalCity: itineraryInfo.arrivalFlight,
      departureTime: time
    }
  }

  return null
}
export function populateBagtagInfo(itineraryInfo) {
  // populate only bagtags for pax who scanned the boarding pass
  appLog(TraceLevels.LOG_EXT_TRACE, 'populateBagtagInfo() - ***** START *****')

  let bagStatusArr = []
  if (itineraryInfo.passengerBagtagDetails) {
    for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
      if (itineraryInfo.passengerBagtagDetails[i].bpScanned) {
        for (let j = 0; j < itineraryInfo.passengerBagtagDetails[i].bagTags.length; j++) {
          let tempBagtagStatusObj = {
            bagtagID: itineraryInfo.passengerBagtagDetails[i].bagTags[j].baseTagNo,
            paxOrdinal: itineraryInfo.passengerBagtagDetails[i].passenger.ordinal,
            status: itineraryInfo.passengerBagtagDetails[i].bagTags[j].status
          }
          bagStatusArr.push(tempBagtagStatusObj)
        }
      }
    }
  }
  return bagStatusArr
}

export function getNextPassenger(validatePaxResult, checkField) {
  let remains = null
  remains = validatePaxResult.filter((pax) => {
    return pax[checkField] === false
  })
  if (remains.length > 0) {
    return remains[0]
  } else {
    return null
  }
}

export function calBaggageLimit(pax, bagType) {
  let allowObj = pax.baggageAllowance.filter((item) => item.baggageType === bagType)[0]
  let checkObj = pax.baggageChecked.filter((item) => item.baggageType === bagType)[0]
  let priceObj = null
  if (pax.baggagePricingInfo) {
    priceObj = pax.baggagePricingInfo.baggagePricingElements.filter((item) => {
      return item.baggageType === bagType
    })[0]
  }
  let displayAmount = countMinLimit(checkObj, priceObj)
  let maxAmount = countMaxLimit(allowObj, checkObj, priceObj)
  return { minLimit: 0, maxLimit: maxAmount, displayAmount: displayAmount }
}

function countMinLimit(checked, priceObj) {
  let result = 0
  if (isNotEmpty(priceObj) && isNotEmpty(checked)) {
    result = checked.checkedCount
  }
  // if (isNotEmpty(checked)) {
  //   // result = checked.checkedCount - checked.printedCount
  //   result = checked.checkedCount
  // }
  return result > 0 ? result : 0
}

function countMaxLimit(allowance, checked, priceObj) {
  let result
  if (isNotEmpty(priceObj) && isNotEmpty(checked)) {
    result = priceObj.maxLimit - checked.printedCount
  }
  // if (isNotEmpty(checked)) {
  //   // result = allowance.freeAllowance - checked.printedCount
  //   result = allowance.freeAllowance > checked.printedCount ? allowance.freeAllowance : checked.printedCount
  // }
  return result
}

export function getObjectFromArrayByKey(objectArray, key, value) {
  let parts = []
  let result = []
  if (key.indexOf('/') > -1) {
    parts = key.split('/')
    result = objectArray.filter((obj) => {
      return obj[parts[0]][parts[1]] === value
    })
  } else {
    result = objectArray.filter((obj) => {
      return obj[key] === value
    })
  }
  if (result && result.length > 0) {
    return result[0]
  } else {
    return null
  }
}

export function handleLocalErrors(err, onPage, errorPage) {
  appLog(TraceLevels.LOG_ALERT, onPage + ' catch:' + JSON.stringify(err) + ' err.message:' + err.message)
  appLog(TraceLevels.LOG_ALERT, onPage + ' catch err.details:' + err.detail + ' errCode: ' + err.errCode)
  if (store.getState().sessions.OOS) {
    return store.dispatch(transitions(TransitionCodes.OOS_ERROR, null))
  }
  if (err.errorCode != undefined) {
    store.dispatch(updateLocalError(err.errorCode, err.screenMsgs, err.source, err.transition, err.params))
  } else {
    store.dispatch(updateLocalError(err.detail, err.message, err.source, err.transition, err.params))
  }
  let flowName = ''
  switch (onPage) {
    case 'commandCompleted appFlow 0':
    case 'commandCompleted appFlow 2':
    case 'commandCompleted appFlow 20':
    case 'checkMaxReposition':
    case 'commandCompleted appFlow 4':
    case 'commandCompleted appFlow 5':
    case 'commandCompleted appFlow 6':
    case 'commandCompleted appFlow 9':
      flowName = 'BagOnBelt,error='
      break
    default:
      flowName = ''
  }
  if (flowName !== '') {
    logEvent(flowName + err.errorCode)
  }

  if (err.transition === 'END_TXN') {
    getEventLogger().ClientSessionId = null
  }

  //sendEventLog('logTest', 'this is sendEventLog test')
  history.push(errorPage)
  // navigate to the Error page - set the error code and message before
}

export function goToLocalError(funcName, errCode, msgCode, transitionPage, params, errorPage, summaryParams = null) {
  const summaryStore = getSummaryStore()
  if (summaryParams !== null) {
    if (summaryParams.log === false) {
      // scan error
      // summaryStore.updateDocScanError(summaryParams.error)
    } else if (summaryParams.tagNumber !== undefined) {
      // bag error (have bagtag with it)
      summaryStore.updateBagData(summaryParams.tagNumber, 'errors', errCode)
    }
  } else {
    // generalError
    summaryStore.GeneralErrors = errCode
  }

  const errorObj = {
    errorCode: errCode,
    screenMsgs: msgCode,
    source: funcName,
    params: params,
    transition: transitionPage
  }
  handleLocalErrors(errorObj, funcName, errorPage)
}

export function goToLocalGenericError(funcName, errCode, msgCode, transitionPage, params, summaryParams = null) {
  goToLocalError(funcName, errCode, msgCode, transitionPage, params, 'error', summaryParams)
}

export function checkLandscape() {
  const screenHeight = window.screen.availHeight
  const screenWidth = window.screen.availWidth
  const screenOrientation = window.screen.availWidth > window.screen.availHeight ? true : false
  return [screenOrientation, screenWidth, screenHeight]
}

export function removeNextLineForSpeechTex(str) {
  let strArray = str.split('')
  return strArray
    .map((char) => {
      if (char === '\n') {
        return ' '
      } else {
        return char
      }
    })
    .join('')
}

export function getConveyorPosition() {
  const res = config.kioskType ? config.kioskType.split('_') : ['EMBROSS']
  const pos = res.pop()
  switch (pos.toUpperCase()) {
    case 'RIGHT':
      return CONVEYOR_POSITION.RIGHT
    case 'LEFT':
      return CONVEYOR_POSITION.LEFT
    default:
      return CONVEYOR_POSITION.LEFT
  }
}

export function navigate(path, appFlow) {
  if (appFlow === 0 || appFlow) {
    getSBDAppMan().setAppFlow(appFlow)
  }
  if (typeof path === 'string') {
    history.push(path)
  } else {
    history.push(path.pathname, path.state)
  }  
}

export function compareScientificNumber(value, target) {
  const valueNumber = value.toString().split('e')
  const targetNumber = target.toString().split('e')
  if (valueNumber.length === 1) {
    valueNumber.concat(['0'])
  }
  if (parseFloat(valueNumber[1]) < parseFloat(targetNumber[1])) {
    return true
  } else if (parseFloat(valueNumber[1]) > parseFloat(targetNumber[1])) {
    return false
  } else if (parseFloat(valueNumber[1]) === parseFloat(targetNumber[1])) {
    if (parseFloat(valueNumber[0]) <= parseFloat(targetNumber[0])) {
      return true
    } else if (parseFloat(valueNumber[0]) > parseFloat(targetNumber[0])) {
      return false
    }
  }
}

export function formatCurrency(value) {
  return `$${value.toFixed(2)}`
}

export function stringMatch(target, matchKey) {
  try {
    const upperCaseTarget = target.toUpperCase()
    const upperCaseMatchKey = matchKey.toUpperCase()

    return upperCaseTarget.match(upperCaseMatchKey).length === 1
  } catch (err) {
    return false
  }
}

export function findOrdinalByTag(bagtag) {
  const passengerBagtagDetails = store.getState().responses.itineraryInfo.passengerBagtagDetails
  let result = null
  passengerBagtagDetails.forEach((pax) => {
    const bagList = pax.bagTags
    const match = bagList.filter((bagObj) => bagObj.baseTagNo === bagtag).length
    if (match > 0) {
      result = pax.passenger.ordinal
    }
  })
  return result
}

export const getImgFromLocation = (location) => {
  switch (location) {
    default:
      return {
        headerIconPath: 'icon-svg/toronto-pearson-airport-logo.svg',
        outOfServiceImagePath: 'oos.png',
      }
  }
}

export function val2Str(val)
{
	return (val) ? val : ''
}

export function getLanguageCodeFromId(id) {
  const lang = config.languages.filter((item) => {return item.id === id})
  if (lang) {
    return lang[0].languageCode
  } else {
    return 'en-US'
  }
}