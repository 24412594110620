import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components'
import { playSound } from 'main'

export default class SkipAct extends React.Component {
  constructor(props) {
    super(props)
    this.handleSkipAction = this.handleSkipAction.bind(this)
  }

  handleSkipAction(e) {
    playSound.beepOK()
    console.log('handleSkipAction() ... event id : ' + e.currentTarget.id)
    this.props.skipAction(e)
  }

  render() {
    const { skipBtnText, skipBtnStyle } = this.props
    return (
      <Button id={'skipBtn'} onClick={this.handleSkipAction} cssName={skipBtnStyle} disable={this.props.isDisabled}>
        {skipBtnText}
      </Button>
    )
  }
}

SkipAct.propTypes = {
  skipAction: PropTypes.func,
  skipBtnText: PropTypes.string,
  skipBtnStyle: PropTypes.string,
  HelpID: PropTypes.string,
  isDisabled: PropTypes.bool,
}

SkipAct.defaultProps = {
  skipBtnText: 'Skip',
  skipBtnStyle: 'btn-nav-up',
}
