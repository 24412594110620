import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import ItineraryInfo from 'components/ItineraryInfo/ItineraryInfo'
import QuitAct from './QuitAct'
import BackAct from './BackAct'
import LangAct from './LangAct'
import SkipAct from './SkipAct'
import ConfirmAct from './ConfirmAct'
import HelpAct from './HelpAct'
import CancelAct from './CancelAct'
import PassengerInfo from './PassengerInfo'
import { isEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { PageFooter, DynamicImage, Spacer, PageContent, PageSubContent } from 'components/styledComponents'
import { TestButtons } from 'components'
import { ThemeContext } from 'styled-components'
import useCheckLandscape from 'hooks/useCheckLandscape'
import './footer.scss'

const Footer = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const isLandscape = useCheckLandscape()
  /* const dispatch = useDispatch()
  const locale = useSelector(state => state.localData.locale) */
  const { formatMessage } = intl
  const {
    btnSpace,
    isQuitRequired,
    quitBtnText,
    quitBtnStyle,
    quitAction,
    isLangRequired,
    langBtnStyle,
    isBackRequired,
    backBtnText,
    backBtnStyle,
    backAction,
    isSkipRequired,
    isSkipDisabled,
    isSkipInCenter,
    skipBtnText,
    skipAction,
    skipBtnStyle,
    isConfirmRequired,
    confirmBtnText,
    confirmAction,
    confirmBtnStyle,
    disableConfirmAction,
    isCancelRequired,
    cancelBtnText,
    cancelAction,
    displayItinerary,
    isHelpRequired,
    confirmIcon,
    testData
  } = props

  const imageLocation = `${themeContext.BrandLogo}`

  const brandLogo = <DynamicImage imageName={imageLocation} width={71} height={12} />

  const carrierCode = useSelector((state) => state.localData.carrierCode)
  const kioskInfo = useSelector((state) => state.kioskInfo)

  const airlineLogo = config.useDefaultAirlineLogo ? (
    themeContext.airlineLogo ? (
      <DynamicImage
        imageName={themeContext.airlineLogo}
        height={themeContext.airlineLogoHeight}
        width={themeContext.airlineLogoWidth}
        backgroundPosition={'center right'}
      />
    ) : (
      ''
    )
  ) : carrierCode ? (
    <DynamicImage
      imageName={'airlineLogo-' + carrierCode + '.png'}
      height={themeContext.airlineLogoHeight}
      width={themeContext.airlineLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    ''
  )

  const airportLogo = config.useDefaultAirportLogo ? (
    <DynamicImage
      imageName={themeContext.airportLogo}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    <DynamicImage
      imageName={`airportLogo-${kioskInfo.airportCode}.png`}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  )

  const footerLogo = config.swapLogo ? (
    <div className={'logo-wrapper-footer'}>{airportLogo}</div>
  ) : (
    <div className={'logo-wrapper-footer'}>{airlineLogo}</div>
  )

  const buttonSection = (
    <PageContent key="testButtons" flexFlow="row" justifyContent={isLandscape ? 'flex-end' : 'center'}>
      {isBackRequired ? (
        <BackAct
          backBtnStyle={backBtnStyle}
          backBtnText={isEmpty(backBtnText) ? formatMessage(messages.buttonBack) : backBtnText}
          backAction={backAction}
        />
      ) : (
        ''
      )}
      {isSkipRequired && !isSkipInCenter ? (
        <SkipAct
          skipBtnText={isEmpty(skipBtnText) ? formatMessage(messages.buttonSkip) : skipBtnText}
          skipAction={skipAction}
          skipBtnStyle={skipBtnStyle}
          isDisabled={isSkipDisabled}
        />
      ) : (
        ''
      )}
      {isCancelRequired ? (
        <CancelAct
          cancelBtnText={isEmpty(cancelBtnText) ? formatMessage(messages.buttonCancel) : cancelBtnText}
          cancelAction={cancelAction}
        />
      ) : (
        ''
      )}
      {isBackRequired || isSkipRequired || isCancelRequired ? <Spacer width="20px" /> : ''}
      {isConfirmRequired ? (
        <ConfirmAct
          confirmBtnText={isEmpty(confirmBtnText) ? formatMessage(messages.buttonConfirm) : confirmBtnText}
          confirmAction={confirmAction}
          disableAction={disableConfirmAction}
          confirmBtnStyle={confirmBtnStyle}
          confirmIcon={confirmIcon}
        />
      ) : (
        ''
      )}
      {testData ? <TestButtons data={testData} showPopup={true} showTestButton={config.showTestButtons} /> : ''}
    </PageContent>
  )
  return (
    <PageFooter>
      {/* {isLandscape ? '' : buttonSection} */}
      <PageContent flexFlow={isLandscape ? 'row' : 'column-reverse'} padding={isLandscape ? '0 50px' : '20px 0'}>
        {displayItinerary ? <ItineraryInfo /> : <div></div>}
        {buttonSection}
      </PageContent>
      {/* <Spacer width="10px" /> */}
      <PageSubContent
        flexFlow="row"
        width="100%"
        padding="0"
        style={{
          height: '75px',
          backgroundColor: themeContext.Footer.bgColorCenter,
          boxShadow: themeContext.Footer.disableBoxShadow ? '' : 'inset 0px 0px 3px #0000001c'
        }}
      >
        {isQuitRequired ? (
          <QuitAct quitBtnText={quitBtnText} quitBtnStyle={quitBtnStyle} quitAction={quitAction} />
        ) : (
          ''
        )}
        {/* <Spacer width="10px" /> */}
        {isLangRequired ? <LangAct langBtnStyle={langBtnStyle} /> : ''}
        {isLangRequired ? <Spacer width="10px" /> : ''}
        {isSkipRequired && isSkipInCenter ? (
          <SkipAct
            skipBtnText={skipBtnText}
            skipAction={skipAction}
            skipBtnStyle={skipBtnStyle}
            isDisabled={isSkipDisabled}
          />
        ) : (
          ''
        )}

        {isHelpRequired ? <HelpAct /> : ''}
        <PageContent alignItems="flex-end">{footerLogo}</PageContent>
      </PageSubContent>
      <div className="footer-bottom-brand-bar">{themeContext.showEMBlogo ? brandLogo : ''}</div>
    </PageFooter>
  )
}

Footer.propTypes = {
  isQuitRequired: PropTypes.bool,
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func,
  backAction: PropTypes.func,
  skipAction: PropTypes.func,
  confirmAction: PropTypes.func,
  disableConfirmAction: PropTypes.bool,
  isBackRequired: PropTypes.bool,
  isLangRequired: PropTypes.bool,
  isSkipRequired: PropTypes.bool,
  isSkipInCenter: PropTypes.bool,
  isConfirmRequired: PropTypes.bool,
  backBtnText: PropTypes.string,
  backBtnStyle: PropTypes.string,
  skipBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  btnSpace: PropTypes.string,
  isSkipDisabled: PropTypes.bool,
  skipBtnStyle: PropTypes.string,
  displayStyle: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  testData: PropTypes.array,
  displayItinerary: PropTypes.bool,
  confirmBtnStyle: PropTypes.string,
  backBtnStyle: PropTypes.string,
  isCancelRequired: PropTypes.bool,
  cancelBtnText: PropTypes.string
}

Footer.defaultProps = {
  isQuitRequired: true,
  isLangRequired: true,
  isSkipInCenter: false,
  isSkipDisabled: false,
  btnSpace: 'btn-nav-space',
  displayItinerary: true,
  confirmBtnStyle: 'confirm-btn',
  backBtnStyle: 'back-btn',
  isCancelRequired: false,
  isHelpRequired: false
}

export default Footer
