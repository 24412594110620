import { getDeviceManager, getEventLogger } from 'main'

var msgBuffer = []

export function setLogger(name) {
  let dm = getDeviceManager()
  if (!!dm) return dm.setLogger(name)
  else {
    console.log('setLogger - Device Manager not ready')
    return false
  }
}

export function appLog(level, msg, addTS) {
  if (config.logToConsole) {
    console.log(msg)
  }
  if (level <= config.maxTraceLevelToSend) {
    let dm = getDeviceManager()
    if (!!dm && dm.isConnected() && dm.isLoggerSet()) {
      if (msgBuffer.length > 0) {
        // process stored messages first
        msgBuffer.push(getTime() + ' End of buffered messages.')
        console.log('End of buffered messages.')
        dm.sendLogMsg(level, msgBuffer)
        msgBuffer.length = 0 // clear the buffer
      }
      if (config.addTimeToTrace) msg = getTime() + ' ' + msg
      else if (addTS == true) msg = getTime() + ' ' + msg
      dm.sendLogMsg(level, msg)
      console.log(msg)
    } else if (config.maxMessagesToBuffer > 0) {
      if (msgBuffer.length < config.maxMessagesToBuffer) {
        if (msgBuffer.length == 0) {
          msgBuffer.push(getTime() + ' Start of buffered messages.')
          console.log('Start of buffered messages.')
        }
        msgBuffer.push(getTime() + ' ' + msg)
        console.log(msg)
      } else if (msgBuffer.length == config.maxMessagesToBuffer) {
        msgBuffer.push(getTime() + ' msg buffer exceeded!')
        console.log(' msg buffer exceeded!')
      } else {
        console.log(msg)
      }
    }
    // else console.log(msg)
  }
}

function getTime() {
  let myDate = new Date()
  return myDate.getHours() + ':' + myDate.getMinutes() + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds()
}

export const sendEventLog = (logName, message) => {
  const dm = getDeviceManager()
  if (dm.setLogger(logName, false)) {
    dm.sendNamedLogMsg(logName, 3, message)
  } else {
    console.log('set logger error:')
  }
  if (config.logToConsole) {
    console.log('[' + logName + '] ', message)
  }
}

export const logEvent = (message) => {
  const logger = getEventLogger()
  logger.logEvent(message)
}
