import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getAccessibilityDevice, getAccessibilityManager, getDeviceManager, getSBDAppMan } from 'main'
import { END_TXN_REASON, ACC_POPUPS } from 'constants/Constants'
import { Button, Popup } from 'components'
import { DynamicImage } from 'components/styledComponents'
import { replacer, maskPCI } from 'utils/helper'
import { appLog } from 'utils/Logger'

function useCardReader(cardReadCallback) {
  const intl = useIntl()
  let removeTimer = null
  let mediaData = null
  const anmRemoveCard = `animations/embross/${config.sbdModel}/paymentCardRemove.gif`
  let cardReader = getDeviceManager().getDevice(deviceIds.CARD_READER)
  const maxScanAttempts = config.mediaAccessRetryCount
  const retryScanRef = useRef(null)
  const removePopupRef = useRef(null)
  const [retryAttempts, setRetryAttempts] = useState(0)
  const accDef = {
    sequenceDef: {
      type: ACC_POPUPS.POPUP,
      sequence: [
        {
          id: 'retryMediaAccess',
          textId: 'TwoDynamicText',
          textParameters: [
            intl.formatMessage(messages.Error_BarcodeInvalid),
            intl.formatMessage(messages.Error_MediaRetriesRemaining, {
              remains: maxScanAttempts - (retryAttempts + 1)
            })
          ]
        },
        { id: 'btnOK', textId: 'ButtonOk', buttonId: 'btnOK' }
      ]
    }
  }

  const cardReaderCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, '(useCardReader.js) cardReaderOnEvent() is called from client: ' + event.key)
    switch (event.key) {
      case 'cardInserted':
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) Credit card => card inserted')
        //set timeout
        removeTimer = setTimeout(() => {
          if (this.removePopupRef) {
            removePopupRef.current.show()
            getAccessibilityManager().buildPopupGroup(true, getAccessibilityDevice(), accDef.removePassport)
          }
        }, config.timeoutTakeCard * 1000)
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) timer:' + this.removeTimer)
        break
      case 'cardDamaged':
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) Event:  cardDamaged')
        handleRetryAttempts()
        break
      case 'cardRemoved':
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) Event:  cardRemoved')
        if (removeTimer) {
          clearTimeout(removeTimer)
          removePopupRef.current.hide()
        }
        if (mediaData) {
          console.log('mediaData: ', mediaData)
          cardReadCallback(mediaData)
        } else {
          handleRetryAttempts()
        }
        break
      case 'cardReadInternal':
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) Event:  cardReadInternal: ' + maskPCI(event.value))
        if (event.value !== '') {
          let cardData = null
          cardData = JSON.parse(event.value)
          appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) cardData: ' + JSON.stringify(cardData, replacer))
          mediaData = Object.assign({}, cardData, {
            mediaType: MEDIATYPES.CC
          })
        }
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, '(PaymentCardSwipe.js) Event:  statusChange ' + event.value.toString())
        break
      default:
    }
  }

  const enable = () => {
    cardReader.OnDeviceEvent = cardReaderCallback
    cardReader.enable()
  }
  const disable = () => {
    cardReader.OnDeviceEvent = null
    cardReader.disable()
  }

  // General error handling for the device callback. Always call after the main callback function
  // useErrorAttemptHandle(eventValue)

  const handleRetryAttempts = () => {
    if (retryAttempts + 1 === maxScanAttempts) {
      getSBDAppMan().doQuit(END_TXN_REASON.MEDIA_ACCESS_RETRY, 'Maximum media access retries exceeded')
    } else {
      setRetryAttempts(retryAttempts + 1)
      retryScanRef.current.show()
      getAccessibilityManager().buildPopupGroup(true, getAccessibilityDevice(), accDef)
    }
  }

  const popupContainer = {
    removeCard: (
      <Popup ref={removePopupRef}>
        <div id="removePassport" tabIndex="0">
          <span className={'page-title'}>
            <FormattedMessage {...messages.PleaseRemoveCard} />
          </span>
        </div>
        <div className="emb-ScanDocuments-bodyContainerPopup">
          <div className="emb-body-center">
            <DynamicImage imageName={anmRemoveCard} cssName={'emb_animation_drawbox'} width={420} height={420} />
          </div>
        </div>
      </Popup>
    ),
    retryMediaAccess: (
      <Popup ref={retryScanRef}>
        <div className="popup-body">
          <div id="retryMediaAccess" className="popup-title" tabIndex="0">
            <FormattedMessage {...messages.Error_PassportInvalid} />
          </div>
          <div className="popup-content">
            <FormattedMessage
              {...messages.Error_MediaRetriesRemaining}
              values={{ remains: maxScanAttempts - retryAttempts }}
            />
          </div>
          <div className="popup-buttons-right">
            <Button id={'btnOK'} cssName={'btn-popup-up'} onClick={() => retryScanRef.current.hide()}>
              <FormattedMessage {...messages.buttonOk} />
            </Button>
          </div>
        </div>
      </Popup>
    )
  }

  return [enable, disable, cardReaderCallback, popupContainer]
}
export default useCardReader
