import React, { useRef, useEffect, useContext } from 'react'
import { useSelector, connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useIntl } from 'react-intl'
import { TraceLevels } from 'embross-device-manager'
// import { injectIntl, FormattedMessage } from 'react-intl'
import { Footer } from '../footer'
import { playSound, history } from 'main'
import { getBagDetails } from 'actions/etsTransactions/getBagDetails'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { addBags } from 'actions/etsTransactions/addBags'
import { PageHeader, PageTitle, PageSubTitle, PageText, PageSubContent, DynamicImage } from 'components/styledComponents'
import { PRINT_DOC_TYPE } from 'constants/Constants'
import { appLog } from 'utils/Logger'
import useUIBase from 'hooks/ui/useUIBase'
import { navigate } from 'utils/helper'
import { updateLocalData } from 'actions/localActions'
import { ThemeContext } from 'styled-components'

const NextBagQuestion = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const paxBagDetails = useSelector((state) => state.localData.BagsEntryDetail)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)

  useEffect(() => {
    // update application to indicate passenger has already answer the question of
    // continue bag process or not
    // (disable need more bag button on put bag on belt page)
    dispatch(updateLocalData('updateNextBagQuestion', true))
  }, [])

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/next-bag.png`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.NextBagQuestionTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.NextBagQuestionSubTitle)}
      </PageSubTitle>
    </>
  )

  const handleActions = (e) => {
    switch (e.currentTarget.id) {
      case 'backBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, No button press')
        if (config.enablePrintReceipt) {
          dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
        } else {
          //nextTransition = 'CLOSING_REMARKS'
          navigate('ClosingRemarks', 8)
        }
        break
      case 'confirmBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, Yes button press')
        //dispatch(getBagDetails())
        if (config.showBagEntry) {
          navigate('BagsEntry', 21)
        } else {
          const formatAddBag = (bagsDetail, bagType, addAmount) => {
            return bagsDetail.map((pax) => {
              const baggageUpdate = [
                {
                  baggageType: bagType,
                  quantity: addAmount,
                  baggageUnit: 'PIECE',
                },
              ]
              return {
                paxOrdinal: pax.paxOrdinal,
                baggageUpdate,
              }
            })
          }
          const bags = formatAddBag(paxBagDetails, 'REGULAR', 1)
          dispatch(addBags(bags))
        }
        break
      default:
        break
    }
  }

  // const header = <PageHeader>{intl.formatMessage(messages.TotalWeightTitle)}</PageHeader>
  const header = <PageHeader>NEXT BAG QUESTION</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={false}
      isBackRequired={true}
      backBtnText={intl.formatMessage(messages.buttonNo)}
      backAction={handleActions}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonYes)}
      confirmAction={handleActions}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default NextBagQuestion
