/******************************************************************************
 * [TakePhoto.js]
 *****************************************************************************/
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDeviceManager, playSound, getAltStore, history, getBuildAccessibility, getSummaryStore } from 'main'
import { updateLocalData } from 'actions/localActions'
import { PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { Button, TestButtons, Popup, AuroraPanel } from 'components'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { appLog, logEvent } from 'utils/Logger'
import { isNotEmpty, navigate, compareScientificNumber } from 'utils/helper'
import { Footer } from '../footer'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { ThemeContext } from 'styled-components'
import { useIntl } from 'react-intl'
import {
  PageContent,
  PageSubContent,
  PageTitle,
  PageSubTitle,
  PageText,
  PageFooter,
  PageHeader,
  Spacer,
  DynamicImage
} from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'

// one-to-one face service hook
// need to pass in passport image when enable the service and it will return a matching score between
// the image we pass in and the image it take.
const useOTOFaceService = (otoCallback, faceTrackingMode, defaultMessage, setLoadingStage) => {
  const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  const summaryStore = getSummaryStore()
  const [faceServiceTrigger, setFaceServiceTrigger] = useState(false)
  const [faceServiceStage, setFaceServiceStage] = useState(false)
  const [otoMessage, setOtoMessage] = useState(defaultMessage)
  const [errorStatus, setErrorStatus] = useState(false)
  const retryCount = useRef(0)
  const settingRef = useRef(null)
  const lowestCaptureScore = useRef(null)
  const lowestCaptureImage = useRef(null)

  const faceTrackingCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTrackingOnEvent() is called: ' + event.key + ' value: ' + event.value)
    switch (event.key) {
      case 'analyzeResult':
        appLog(TraceLevels.LOG_TRACE, 'Event: analyzeResult: ' + event.value)
        const photoResult = event.value.split(',')
        const score = parseFloat(event.value)

        if (
          lowestCaptureScore.current === null ||
          compareScientificNumber(photoResult[3], lowestCaptureScore.current)
        ) {
          lowestCaptureScore.current = photoResult[3]
          lowestCaptureImage.current = photoResult[0]
        }
        const lowestScoreMatchResult = compareScientificNumber(lowestCaptureScore.current, config.photoPassingScore)
          ? 'PASS'
          : 'FAIL'

        summaryStore.updateFacialData(lowestCaptureScore.current, lowestScoreMatchResult, null)

        if (!compareScientificNumber(photoResult[3], config.photoPassingScore)) {
          retryCount.current += 1
          summaryStore.updateFacialDataError('MATCH_SCORE_FAIL')
          if (retryCount.current === config.mediaAccessRetryCount) {
            logEvent('FACE MATCH: reach maximum retry limit')
            logEvent('FACE MATCH: fail')
            logEvent('FACE MATCH: match score: ' + score)
            setOtoMessage('reach maximum retry limit')
            setTimeout(() => {
              otoCallback(0)
            }, 1500)
          } else {
            logEvent('FACE MATCH: fail')
            logEvent('FACE MATCH: match score: ' + score)
            logEvent('FACE MATCH: retry ' + retryCount.current)
            setOtoMessage('Match score fail\nPlease retry')
            setErrorStatus(true)
            // setTimeout(() => {
            //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
            //   setLoadingStage(false)
            // }, 1500)
          }
        } else {
          otoCallback(score)
          logEvent('FACE MATCH: success')
          logEvent('FACE MATCH: match score: ' + score)
        }
        break
      case 'faceDescriptor':
        let descriptorResult = event.value.split(',')
        console.log('descriptorResult::::', descriptorResult)

        const photoData1 = descriptorResult[0].trim()
        const photoData2 = descriptorResult[1].trim()
        const compareScore = parseFloat(descriptorResult[2].trim())
        logEvent('FACE MATCH: score: ' + compareScore)
        console.log(
          `THIS IS WORKING AS EXPECTED, compare score = ${compareScore}, photoData = ${photoData1} \n ${photoData2}`
        )
        if (compareScore < config.photoPassingScore) {
          logEvent('FACE MATCH: retry')
          retryCount.current += 1
          summaryStore.updateFacialDataError('MATCH_SCORE_FAIL')
          if (retryCount.current === config.mediaAccessRetryCount) {
            setOtoMessage('reach maximum retry limit')
            setTimeout(() => {
              otoCallback(0)
            }, 1500)
          } else {
            setOtoMessage('Match score fail\nPlease retry')
            setErrorStatus(true)
            // setTimeout(() => {
            //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
            // }, 1500)
          }
        } else {
          otoCallback(compareScore)
        }

        break
      case 'enable':
        appLog(TraceLevels.LOG_TRACE, 'Event: enable: ' + event.value)
        if (event.value === '0') {
          setOtoMessage('Face Service start')
          setFaceServiceTrigger(true)
        }

        if (event.value === '0' && config.showLiveStream) {
          faceTracking.show(faceTrackingMode)
          faceTracking.focusApp()
        } else if (event.value !== '0') {
          summaryStore.updateFacialDataError('FACE_SERVICE_FAIL')
          setOtoMessage('Face Service fail to start')
          setTimeout(() => {
            otoCallback(0)
          }, 1500)
        }
        break
      case 'show':
        appLog(TraceLevels.LOG_TRACE, 'Event: show rc: ' + event.value)
        break
      case 'hide':
        appLog(TraceLevels.LOG_TRACE, 'Event: hide rc: ' + event.value)
        setFaceServiceTrigger(false)
        break
      case 'dataError':
        //      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  dataError: ' + event.value)
        // 1. hide camera stream
        // 2. show popup with instruction and Retry button
        // 3. if Retry button pressed (or timeout) then show camera (hide popup)
        /*this.faceTracking.hide()
        this.faceTracking.disable()*/
        retryCount.current += 1
        summaryStore.updateFacialDataError('DATA_ERROR')
        if (retryCount.current === config.mediaAccessRetryCount) {
          setOtoMessage('Reach maximum retry limit')
          setTimeout(() => {
            otoCallback(0)
          }, 1500)
        } else {
          setOtoMessage('photo capture fail\nPlease retry')
          setErrorStatus(true)
          // setTimeout(() => {
          //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
          // }, 1500)
        }
        break
      default:
        appLog(TraceLevels.LOG_TRACE, 'Event: unknownEvent : ' + event.key + ' event value' + event.value)
        break
    }
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_TRACE, 'faceServiceTrigger value: ' + faceServiceTrigger)
    logEvent('FACE MATCH: initiated')
    let timer = null
    if (faceServiceTrigger) {
      timer = setTimeout(() => {
        appLog(TraceLevels.LOG_TRACE, 'timeout trigger')
        tempDisable()
        retryCount.current += 1
        summaryStore.updateFacialDataError('TIMEOUT')
        if (retryCount.current === config.mediaAccessRetryCount) {
          logEvent('FACE MATCH: reach maximum retry limit:' + config.mediaAccessRetryCount)
          setOtoMessage('reach maximum retry limit')
          setTimeout(() => {
            otoCallback(0)
          }, 1500)
        } else {
          setOtoMessage(`timeout\nPlease retry`)
          setErrorStatus(true)
          // setTimeout(() => {
          //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
          //   setLoadingStage(false)
          // }, 1500)
        }
      }, config.photoFailWaitTime * 1000)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [faceServiceTrigger])

  const enable = (bestShotTime, dg2Image, cameraStatus = false) => {
    console.log('oto call enable')
    settingRef.current = {
      bestShotTime,
      dg2Image
    }
    faceTracking.OnDeviceEvent = faceTrackingCallback
    faceTracking.enable(bestShotTime, dg2Image)
    setFaceServiceStage(true)
  }

  const tempDisable = () => {
    console.log('oto temp disable')
    faceTracking.hide()
    faceTracking.disable()
    setFaceServiceStage(false)
    setFaceServiceTrigger(false)
  }

  const disable = () => {
    console.log('oto call disable')
    faceTracking.hide()
    faceTracking.disable()
    faceTracking.OnDeviceEvent = null
    setFaceServiceStage(false)
  }

  return [enable, disable, tempDisable, faceTrackingCallback, faceServiceStage, otoMessage, errorStatus, setErrorStatus]
}

const TakePhoto = (props) => {
  const intl = useIntl()
  const summaryStore = getSummaryStore()
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()
  const buildAccessibility = getBuildAccessibility()
  const paxFaceImage = useSelector((state) => state.localData.travelDocImage)
  const currentPassenger = useSelector((state) => state.localData.currentPassenger)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  // const paxPhotoStatus = useSelector(state => state.paxValidation.paxPhotoStatus)
  const paxPhotoStatus = false
  const [loadingStage, setLoadingStage] = useState(false)
  const [countNumber, setCountNumber] = useState(config.takePhotoPrepareTime)

  const handleClick = (e) => {
    appLog(TraceLevels.LOG_TRACE, '(TakePhoto.js) handleClick() ... ' + e.currentTarget.id)
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'buttonPhotoMatch':
        procesPhoto(0.3)
        break
      case 'buttonPhotoNotMatch':
        faceTrackingCallback({
          key: 'faceDescriptor',
          value: 'XX,XX,0.1'
        })
        // procesPhoto(0.5)
        break
      case 'buttonPhotoEnable':
        faceTrackingCallback({
          key: 'enable',
          value: '0'
        })
        break
      default:
        appLog(TraceLevels.LOG_TRACE, '(TakePhoto.js) default url: /')
        navigate('Welcome')
    }
  }

  const testData = [
    {
      id: 'buttonPhotoMatch',
      text: 'match case',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonPhotoNotMatch',
      text: 'not match case',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonPhotoEnable',
      text: 'enable',
      handler: handleClick,
      cssName: 'test-button-up'
    }
  ]

  const procesPhoto = (photoScore) => {
    //update reslut in validatePaxResult
    let passingScore = config.photoPassingScore
    let newValidateResults = []
    let updatedItem = null
    validatePaxResult.forEach((item) => {
      if (item.ordinal === currentPassenger.ordinal) {
        updatedItem = item
        updatedItem.bioCheck =
          photoScore >= passingScore ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.FAILED
        newValidateResults.push(updatedItem)
      } else {
        newValidateResults.push(item)
      }
    })

    const updatePaxStatus = {
      ordinal: currentPassenger.ordinal,
      docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
      faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED
    }

    summaryStore.endFacialData(updatedItem.bioCheck !== PASSENGER_VALIDATION_STATUS.PASSED)

    dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
    dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
  }

  const [
    enable,
    disable,
    tempDisable,
    faceTrackingCallback,
    faceServiceStage,
    otoMessage,
    errorStatus,
    setErrorStatus
  ] = useOTOFaceService(
    procesPhoto,
    config.faceTrackingMode,
    formatMessage(messages.PleaseWaitDefault),
    setLoadingStage
  )

  useEffect(() => {
    handleAccessibility()
    console.log('Take photo start.')
    enable(config.bestShotTime, paxFaceImage)
    if (paxPhotoStatus) {
      setLoadingStage(true)
    }
    return () => {
      console.log('take photo end.')
      summaryStore.updateFacialDataError('MISSING_DOC_IMAGE')
      disable()
      dispatch(updateLocalData('updateTravelDocImage', null))
    }
  }, [])

  //count down timer
  useEffect(() => {
    const refInterval = window.setInterval(() => {
      if (countNumber > 0) {
        setCountNumber(countNumber - 1)
      } else {
        setCountNumber('')
        return
      }
    }, 1000)

    return () => {
      clearInterval(refInterval)
    }
  }, [countNumber])

  const quitAction = (action, response) => {
    if (action === 'quitAction') {
      tempDisable()
    } else if (action === 'callback' && response === 'NO') {
      enable(config.bestShotTime, paxFaceImage)
    }
  }

  const retryAction = () => {
    setErrorStatus(false)
    setCountNumber(config.takePhotoPrepareTime)
  }

  // const liveStreamSection = (
  //   <PageSubContent width={'100%'} justifyContent={'center'}>
  //     <PageText justifyContent={'center'}>{otoMessage}</PageText>
  //   </PageSubContent>
  // )
  const imageSection = (
    <>
      <PageSubContent flexFlow={'row'} width={'100%'} padding={'0'}>
        <PageContent flexFlow={'column'} width={'30%'}>
          {paxFaceImage ? (
            <img
              alt="paxFaceImage"
              src={`data:image/jpeg;base64,${paxFaceImage}`}
              style={{ width: '120px', height: '150px' }}
            />
          ) : (
            <div style={{ width: '120px', height: '150px', background: 'grey' }}></div>
          )}
          <PageSubTitle minHeight={'40px'}>ID Photo</PageSubTitle>
        </PageContent>
        <PageContent width={'40%'} padding={'30px 0'}>
          <DynamicImage imageName={`${themeContext.AnimationPath}/pleaseWait_spinner.gif`} width={300} height={300}>
            <PageTitle fontSize={'90px'}>{countNumber}</PageTitle>
          </DynamicImage>
        </PageContent>
        <PageContent width={'30%'}></PageContent>
      </PageSubContent>
    </>
  )

  const imageSectionError = (
    <>
      <PageSubContent flexFlow={'row'} width={'100%'} padding={'0'}>
        <PageContent flexFlow={'column'} width={'40%'}>
          {paxFaceImage ? (
            <img
              alt="paxFaceImage"
              src={`data:image/jpeg;base64,${paxFaceImage}`}
              style={isLandscape ? { width: '300px', height: '350px' } : { width: '240px', height: '300px' }}
            />
          ) : (
            <div
              style={
                isLandscape
                  ? { width: '300px', height: '350px', background: 'grey' }
                  : { width: '240px', height: '300px', background: 'grey' }
              }
            ></div>
          )}
          <PageSubTitle minHeight={'20px'}>ID Photo</PageSubTitle>
        </PageContent>
        <PageContent flexFlow={'column'} width={'20%'}>
          <DynamicImage imageName={'error-icon.svg'} width={isLandscape ? 200 : 125} height={isLandscape ? 200 : 125} />
        </PageContent>
        <PageContent width={'40%'}>
          {paxFaceImage ? (
            <img
              alt="paxFaceImage"
              src={`data:image/jpeg;base64,${paxFaceImage}`}
              style={isLandscape ? { width: '300px', height: '350px' } : { width: '240px', height: '300px' }}
            />
          ) : (
            <div
              style={
                isLandscape
                  ? { width: '300px', height: '350px', background: 'grey' }
                  : { width: '240px', height: '300px', background: 'grey' }
              }
            ></div>
          )}
          <PageSubTitle minHeight={'20px'}>Face Photo</PageSubTitle>
        </PageContent>
      </PageSubContent>
    </>
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.TakePhoto_Title)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.TakePhoto_SubTitle)}
      </PageSubTitle>
    </>
  )
  const paxFullName = currentPassenger ? currentPassenger.firstName + ' ' + currentPassenger.lastName : null
  const header = <PageHeader alignItems="center">{paxFullName}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitAction={quitAction}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={errorStatus ? true : false}
        confirmBtnText={formatMessage(messages.buttonRetry)}
        confirmAction={retryAction}
        testData={testData}
      />
      {/* <TestButtons data={testButtons} /> */}
    </>
  )

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'takePhoto',
      startIndex: 0,
      isQuitRequired: true,
      isLangRequired: true,
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TakePhotoInstruction', textParameters: [paxFullName] }]
      }
    }
    buildAccessibility(accDef)
  }

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: errorStatus ? imageSectionError : imageSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  const auroraHeight = useMedia(null, [730, 730, 0], 1050)

  const pleaseWaitHeaderDisplay = <PageHeader>{formatMessage(messages.PleaseWaitTitle)}</PageHeader>

  const pleaseWaitBodyDisplay = (
    <>
      <PageContent flexFlow={isLandscape ? 'row' : 'column'}>
        <PageSubContent
          justifyContent={'flex-start'}
          width={isLandscape ? contentWidth.landscapeMode : contentWidth.portraitMode}
        >
          {isLandscape ? null : (
            <>
              {pleaseWaitHeaderDisplay}
              <Spacer height={150} />
            </>
          )}
          <PageText justifyContent="center" />
          <DynamicImage
            imageName={`pleaseWait_rowNoStops.gif`}
            cssName={'emb_animation_drawbox'}
            width={'75%'}
            height={'100px'}
          />
        </PageSubContent>
      </PageContent>
    </>
  )

  const loading = (
    <div className="page-container">
      <AuroraPanel width={'90%'} style={{ flex: 1 }} panelContentCss={'panel-content'} showTopBorder={false}>
        <PageContent flexFlow={'column'}>
          {isLandscape ? pleaseWaitHeaderDisplay : null}
          {pleaseWaitBodyDisplay}
        </PageContent>
      </AuroraPanel>
      <Spacer height={20} />
      <PageFooter />
    </div>
  )

  if (loadingStage) {
    return loading
  } else {
    return <>{UIDisplay}</>
  }

  // if (loadingStage) {
  // } else {
  //   return (
  //     <div className="page-container">
  //       <div className="page-content" id="a_buttonGroup">
  //         <div className="take-photo-name">{currentPassenger.firstName + ' ' + currentPassenger.lastName}</div>

  //         <Textfit className={'take-photo-title'} mode={'multi'} min={22} max={30}>
  //           {intl.formatMessage(messages.TakePhoto_InstructionText3)}
  //         </Textfit>

  //         <div className={'take-photo-area'}>
  //           <div className="animation-wrapper">
  //             <div className={'take-photo-preview-holder'}>
  //               {faceServiceStage ? (
  //                 <>
  //                   <div className={'take-photo-wait'}> {otoMessage}</div>
  //                   {config.showLiveStream ? (
  //                     <DynamicImage
  //                       imageName={`animations/embross/${config.sbdModel}/thanks.gif`}
  //                       cssName={'take-photo-animation-wait'}
  //                       width={380}
  //                       height={300}
  //                     />
  //                   ) : null}
  //                 </>
  //               ) : null}
  //             </div>
  //           </div>
  //           <div className={'takePhoto-instruction-div'} id="takePhotoInstruction" tabIndex="0">
  //             <div className={'takePhoto-instruction'}>
  //               <FormattedMessage {...messages.Instruction} />
  //             </div>
  //             <ul>
  //               <li>
  //                 <FormattedMessage {...messages.TakePhoto_InstructionText1} />
  //               </li>
  //               <li>
  //                 <FormattedMessage {...messages.TakePhoto_InstructionText2} />
  //               </li>
  //               <br />
  //             </ul>
  //           </div>
  //         </div>
  //         <div className="take-photo-preview" />
  //         <Footer
  //           isQuitRequired={true}
  //           quitBtnStyle={'btn-red-up'}
  //           quitBtnText={formatMessage(messages.buttonQuit)}
  //           quitAction={quitAction}
  //           isBackRequired={false}
  //           isSkipRequired={false}
  //           isConfirmRequired={false}
  //           langBtnStyle={'btn-green-up'}
  //         />
  //         <TestButtons data={testButtons} />
  //       </div>
  //     </div>
  //   )
  // }
}

export default TakePhoto
