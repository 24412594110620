import React, { useState, useEffect } from 'react'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { Footer } from '../footer'
import { getAccessibilityDevice, history, playSound } from 'main'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useCardReader from 'hooks/useCardReader'
import { formatCurrency } from 'utils/helper'
import { addPayment } from 'actions/etsTransactions/addPayment'
import { MEDIATYPES } from 'constants/Constants'

import {
  PageContent,
  PageSubContent,
  PageFooter,
  PageText,
  PageTitle,
  PageSubTitle,
  OverweightPaymentText,
  DynamicImage,
  Spacer
} from 'components/styledComponents'

const PaymentCardSwipe = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const [results, setResults] = useState(paymentInfo ? paymentInfo : { totalAmount: 0 })

  const cardReaderCallback = (mediaData) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  successful receive mediaData')
    dispatch(addPayment(false, mediaData, results.totalAmount, results.paymentObj.currencyCode))
  }
  const [enable, disable, passportReaderCallback, popupContainer] = useCardReader(cardReaderCallback)

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  enable card reader ...')
    enable()

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  disable card reader ...')
      disable()
    }
  }, [])

  const handleActions = (e) => {
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'testBtn': // TODO test mode only ... to be removed
          let mediaData = {
            mediaTrackData: {
              track1: 'B4012001021000605^SMITH/JOHN                 ^211210100000000100010001000000',
              track2: '4012001021000605=21121010000001010001',
              track3: null,
              mediaType: MEDIATYPES.CC
            }
          }
          dispatch(addPayment(false, mediaData, results.totalAmount, results.currencyCode))
          break
        default:
          history.push(e.currentTarget.id)
      }
    }
  }
  const testData = [
    {
      id: 'testBtn',
      text: 'TEST',
      cssName: 'test-button-up',
      handler: handleActions
    }
  ]

  const animationSize = useMedia(null, [440, 440, 420], 420)
  const animationSection = (
    <>
      <DynamicImage
        imageName={`animations/embross/${config.sbdModel}/paymentCardInsert.gif`}
        cssName={'emb_animation_drawbox'}
        width={animationSize}
        height={animationSize}
      />
      <DynamicImage imageName={`creditcards.png`} cssName={'emb_animation_drawbox'} width={500} height={100} />
    </>
  )

  const textSection = (
    <PageSubContent width={'100%'} justifyContent={'center'}>
      <PageSubTitle alignItems="center" height={100}>
        {intl.formatMessage(messages.AcceptCards)}
      </PageSubTitle>
      <Spacer height={30} />
      <PageSubTitle>- {intl.formatMessage(messages.AMEX)}</PageSubTitle>
      <PageSubTitle>- {intl.formatMessage(messages.MasterCard)}</PageSubTitle>
      <PageSubTitle>- {intl.formatMessage(messages.VISA)}</PageSubTitle>
    </PageSubContent>
  )

  const header = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <PageText>
        {intl.formatMessage(messages.PaymentCardSwipeTitle, { amount: formatCurrency(results.totalAmount) })}
      </PageText>
    </div>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testData}
      />
    </>
  )

  const popupSection = <>{popupContainer.removeCard}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    // { header, topSection: textSection, bottomSection: animationSection, footer, popupSection },
    { header, topSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default PaymentCardSwipe
