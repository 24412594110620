import React, { useRef, useState } from 'react'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getAccessibilityDevice, getAccessibilityManager, getDeviceManager, getSBDAppMan } from 'main'
import { appLog } from 'utils/Logger'
import { END_TXN_REASON, ACC_POPUPS } from 'constants/Constants'
import { Button, Popup } from 'components'
import { FormattedMessage, useIntl } from 'react-intl'
import { goToLocalGenericError } from 'utils/helper'
import { barcodeReaderOnEvent } from 'devices/callbacks'
function useBarcodeScanner(barcodeScannedCallback) {
  const intl = useIntl()
  let barcodeReader = getDeviceManager().getDevice(deviceIds.BARCODE_READER)
  const maxScanAttempts = config.mediaAccessRetryCount
  const retryScanRef = useRef(null)
  const [retryAttempts, setRetryAttempts] = useState(0)
  const [visible, setVisible] = useState(false)
  const accDef = {
    sequenceDef: {
      type: ACC_POPUPS.POPUP,
      sequence: [
        {
          id: 'retryMediaAccess',
          textId: 'TwoDynamicText',
          textParameters: [
            intl.formatMessage(messages.Error_BarcodeInvalid),
            intl.formatMessage(messages.Error_MediaRetriesRemaining, {
              remains: maxScanAttempts - (retryAttempts + 1)
            })
          ]
        },
        { id: 'btnOK', textId: 'ButtonOk', buttonId: 'btnOK' }
      ]
    }
  }

  const barcodeReaderCallback = (event) => {
    // deviceErrorHandler(event.key)
    appLog(TraceLevels.LOG_TRACE, '(useBarcodeScanner.js) barcodeReaderOnEvent() is called from client: ' + event.key)
    /*
      should only contain device specific logic in the callback. common device callback logic should put in the useErrorAttemptHandle hook.
    */
    switch (event.key) {
      case 'barcodeInserted':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  barcodeInserted')
        break
      case 'barcodeDamaged':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  barcodeDamaged')
        // updateDeviceError(ErrCodes.BARCODE_RETRIES_EXCEEDED, ErrCodes.BARCODE_UNREADABLE)
        handleRetryAttempts()
        break
      case 'barcodeRemoved':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  barcodeRemoved')
        break
      case 'barcodeReadInternal':
        appLog(TraceLevels.LOG_TRACE, 'Event:  barcodeReadInternal ')
        barcodeScannedCallback(event.value)
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  statusChange ' + event.value.toString())
        if (event.key === 'statusChange' && event.value[1]) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'callback: barcode  reader is disconnected')
          goToLocalGenericError('Callbacks', ErrCodes.BARCODE_ERROR, 'BarcodeReaderError', 'END_TXN')
        } else {
          console.log('callbacks: barcode reader status:', event.value[0])
        }
        break
    }
  }
  const enable = () => {
    barcodeReader.OnDeviceEvent = barcodeReaderCallback
    barcodeReader.enable()
  }
  const disable = () => {
    barcodeReader.OnDeviceEvent = barcodeReaderOnEvent
    barcodeReader.disable()
  }

  // General error handling for the device callback. Always call after the main callback function
  // useErrorAttemptHandle(eventValue)

  const handleRetryAttempts = () => {
    if (retryAttempts + 1 === maxScanAttempts) {
      getSBDAppMan().doQuit(END_TXN_REASON.MEDIA_ACCESS_RETRY, 'Maximum media access retries exceeded')
    } else {
      setRetryAttempts(retryAttempts + 1)
      setVisible(true)
      getAccessibilityManager().buildPopupGroup(true, getAccessibilityDevice(), accDef)
    }
  }

  const popupContainer = {
    retryMediaAccess: (
      <Popup visible={visible}>
        <div className="popup-body">
          <div id="retryMediaAccess" className="popup-title" tabIndex="0">
            <FormattedMessage {...messages.Error_BarcodeInvalid} />
          </div>
          <div className="popup-content" id="popup-content">
            <FormattedMessage
              {...messages.Error_MediaRetriesRemaining}
              values={{ remains: maxScanAttempts - retryAttempts }}
            />
          </div>
          <div className="popup-buttons-right">
            <Button id={'btnOK'} cssName={'btn-popup-up'} onClick={() => setVisible(false)}>
              <FormattedMessage {...messages.buttonOk} />
            </Button>
          </div>
        </div>
      </Popup>
    )
  }

  return [enable, disable, barcodeReaderCallback, popupContainer]
}
export default useBarcodeScanner
