/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { AuroraPanel } from 'components'
import { appLog } from 'utils/Logger'
import { getSBDAppMan, getVersion, history, getTimeoutMgr, getBuildAccessibility, getAccessibilityManager } from 'main'
import { Footer } from '../footer'
import { FunctionCodes, ServiceNames } from 'constants/Constants'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { ErrCodes } from 'constants/Errors'
import useMedia from 'hooks/useMedia'
import useCheckLandscape from 'hooks/useCheckLandscape'
import {
  PageContent,
  PageSubContent,
  PageTitle,
  PageText,
  PageSubTitle,
  PageHeader,
  DynamicImage,
  Spacer
} from 'components/styledComponents'
import { ThemeContext } from 'styled-components'
import useUIBase from 'hooks/ui/useUIBase'

const hazMatItems = [
  'BagsHazMat_Weapons',
  'BagsHazMat_Fireworks',
  'BagsHazMat_Flammable',
  'BagsHazMat_Batteries',
  'BagsHazMat_CompressedGas',
  'BagsHazMat_HouseholdItems',
  'BagsHazMat_Corrosives',
  'BagsHazMat_Other'
]

const DangerItem = ({ item }) => {
  const intl = useIntl()
  const isLandscape = useCheckLandscape()

  return (
    <div
      id={item}
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: isLandscape ? '8px 0' : '0'
      }}
    >
      <DynamicImage imageName={`${item}.png`} cssName={'animation'} width={90} height={55} />
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'left',
          width: '80%'
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px'
          }}
        >
          {intl.formatMessage(messages[item])}
        </div>
        <div
          style={{
            fontSize: '17px'
          }}
        >
          {intl.formatMessage(messages[`${item}_desc`])}
        </div>
      </div>
    </div>
  )
}

const HazMatQuestion = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const bottomFontSize = useMedia(null, [32, 25, 30], 30)
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const langBtnRequired = true
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const title = formatMessage(messages.HazMatTitle)
    const hazMatDetails = hazMatItems.map(
      (item) => `${formatMessage(messages[item])}. ${formatMessage(messages[`${item}_desc`]).replace('\n', ' ')}..`
    )
    const subTitle = formatMessage(messages.HazMatSubTitle)
    const accDef = {
      pathName: 'HazMatQuestion',
      startIndex: 0,
      isQuitRequired: !appMode,
      isLangRequired: langBtnRequired,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicTextWithDownKey',
            textParameters: [`${title}. ${hazMatDetails.join(' ')} ${subTitle}.`]
          },
          { id: 'confirmBtn', textId: 'NavConfirm', buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const handleActions = (e) => {
    switch (e.currentTarget.id) {
      case 'backBtn':
        // TODO: Call identify service once ETS is ready
        // dispatch(identifyService(FunctionCodes.BYPASSED, ServiceNames.SBD_PAX_VALIDATION))
        getSBDAppMan().doQuit(ErrCodes.USER_QUIT, '')
        break
      case 'confirmBtn':
        dispatch(identifyService(FunctionCodes.SET_COMPLETE, ServiceNames.DANGEROUS_GOODS_QUESTIONS))
        break
      default:
        break
    }
  }

  const dangerMessage = () => {
    const left = []
    const right = []
    const allInOne = []
    if (isLandscape) {
      for (let i = 0; i < hazMatItems.length; i++) {
        if (i < hazMatItems.length / 2) {
          left.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
        } else {
          right.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
        }
      }
      return (
        <PageContent flexFlow="rows" width="90%">
          <PageSubContent width={'49%'} justifyContent={'space-evenly'} flex={1}>
            {left}
          </PageSubContent>
          <Spacer width={'2%'} />
          <PageSubContent width={'49%'} justifyContent={'space-evenly'} flex={1}>
            {right}
          </PageSubContent>
        </PageContent>
      )
    } else {
      for (let i = 0; i < hazMatItems.length; i++) {
        allInOne.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
      }
      return (
        <PageSubContent width={'90%'} justifyContent={'space-evenly'} flex={1} padding={'0'}>
          {allInOne}
        </PageSubContent>
      )
    }
  }

  const animationSection = dangerMessage()

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        color={themeContext.PrimaryFontColor}
        height="80px"
        style={{ paddingBottom: isLandscape ? '' : '20px' }}
      >
        {formatMessage(messages.HazMatTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor} fontSize={'25px'}>
        {formatMessage(messages.HazMatSubTitle)}
      </PageSubTitle>
    </>
  )

  const header = null //<PageHeader alignItems="center">{formatMessage(messages.HazMatHeader)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      isLangRequired={langBtnRequired}
      confirmBtnText={formatMessage(messages.buttonConfirm)}
      confirmAction={handleActions}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default HazMatQuestion
