//import { push } from 'react-router-redux'
import { store, getAltStore, history } from 'main'
import { updateError } from 'actions/commonActions'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { replacer, isNotEmpty } from 'utils/helper'
import { reasonToStatusCode, continueQuitProcess } from 'utils/appTransactions'
import { TYPE_KIOSK, ServiceNames, TransitionCodes, FunctionCodes, StatusCodes } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

function buildGetBagTagPrintStreams() {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      jsonExtend: null,
      carrierCode: null,
      currentClientTime: 0,
      selectedLanguage: store.getState().localData.locale.toUpperCase(),
    }
  )
}

export function getBagTagPrintStreams(location) {
  appLog(TraceLevels.LOG_TRACE, 'getBagTagPrintStreams')

  return (dispatch) => {
    dispatch(push('pleaseWait'))
    let request = buildGetBagTagPrintStreams()
    appLog(TraceLevels.LOG_TRACE, 'getBagTagPrintStreams request ... ' + JSON.stringify(request, replacer))
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('SBDGetPrintStreams/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_TRACE, 'getBagTagPrintStreams response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        // check if OOS event happened between request(COMPLETE) and response
        // if request was QUIT, TIMEOUT or EEROR then transition code should be OK no need to modify
        if (store.getState().sessions.OOS && statusCode === StatusCodes.COMPLETE) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          // dispatch transition
          dispatch(updateSessions('updateTransition', json.transition))
          if (json.transition === TransitionCodes.PRINT_DOCUMENT) {
            // check if bagTagResources and passengerDocuments are not empty arrays
            if (
              json.etsResponse.bagTagResources &&
              Array.isArray(json.etsResponse.bagTagResources) &&
              json.etsResponse.passengerDocuments &&
              Array.isArray(json.etsResponse.passengerDocuments) &&
              isNotEmpty(json.etsResponse.passengerDocuments)
            ) {
              appLog(TraceLevels.LOG_EXT_TRACE, '(getBagTagPrintStreams.js) Dispatch --> "PrintBagtag" screen ...')
              dispatch(updateResponses('updateBagtagResources', json.etsResponse.bagTagResources))
              dispatch(updateResponses('updatePassengerDocuments', json.etsResponse.passengerDocuments))
              dispatch(updateLocalData('appFlow', 30))
              dispatch(push('PrintBagtag'))
            } else {
              appLog(TraceLevels.LOG_TRACE, 'getBagTagPrintStreams empty print streams.')
              dispatch(updateError(ErrCodes.ETS_MESSAGE_ERROR, '', 'getBagTagPrintStreams'))
              dispatch(push('error'))
            }
          } else {
            appLog(TraceLevels.LOG_TRACE, 'getBagTagPrintStreams invalid transition ' + json.transition)
            dispatch(updateError(ErrCodes.ETS_MESSAGE_ERROR, '', 'getBagTagPrintStreams'))
            dispatch(push('error'))
          }
        } else if (json.error) {
          goToFetchGenericError('getBagTagPrintStreams', json, dispatch)
          // appLog(
          //   TraceLevels.LOG_TRACE,
          //   'getBagTagPrintStreams errorCode:' + json.error.errorCode + ' description:' + json.error.description
          // )
          // let desc = json.error.screenMsgs ? json.error.screenMsgs : ''
          // dispatch(updateError(json.error.errorCode, desc, 'getBagTagPrintStreams'))
          // dispatch(push('error'))
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'getBagTagPrintStreams()', 'error')
        // let reason = store.getState().localData.quitReason
        // appLog(TraceLevels.LOG_ALERT, 'getBagTagPrintStreams catch quitReason:' + reason)
        // dispatch(updateSessions('updateETSRequest', false))
        // if (store.getState().sessions.OOS) {
        //   return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        // }
        // if (reason !== undefined && reason !== '') {
        //   let statusCode = reasonToStatusCode(reason)
        //   continueQuitProcess(statusCode)
        // } else {
        //   appLog(
        //     TraceLevels.LOG_ALERT,
        //     'getBagTagPrintStreams catch:' + JSON.stringify(err) + ' err.message:' + err.message
        //   )
        //   appLog(
        //     TraceLevels.LOG_ALERT,
        //     'getBagTagPrintStreams catch err.details:' + err.detail + ' errCode: ' + err.errCode
        //   )
        //   if (err.errCode != undefined) dispatch(updateError(err.errCode, err.message, err.source))
        //   else dispatch(updateError(err.detail, err.message, err.source))
        //   dispatch(push('error'))
        // }
        // navigate to the Error page - set the error code and message before
      })
  }
}
