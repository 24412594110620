import { TraceLevels, deviceIds, PromiseCodes, DeviceEventType } from 'constants/Constants'

/** Base class for devices.*/
export default class Device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm, name = 'Default Device') {
    /**@private*/
    this.listener = null
    /**@private*/
    this.defaultListener = null
    /**@private*/
    this.name = name
    /**@private*/
    this.dm = dm
    /**@private*/
    this.socket = socket
    /**
     * Device id
     * @type {Number} {@link src/constants/Constants.js~deviceIds}
     */
    this.deviceId = null
    /**@private*/
    this.onDeviceEvent = null
    /**
     * Map of device event listeners
     * @type {Map}
     * @protected
     */
    this.devEventListeners = new Map()
    /**@private*/
    this.deviceEvent = null
    /**@private*/
    this.deviceStatus = null
    /**
     * Is device OK flag
     * @type {Boolean}
     * @protected
     */
    this.isDeviceOk = false
    /**@private*/
    this.isRequired = false
    /**@private*/
    this.type = null
    /**@private*/
    this.maxTraceLevelToSend = TraceLevels.LOG_EXT_TRACE
    /**@private*/
    this.addTimeToTrace = true
    /**@private - called from inside function*/
    this.statusOK = ''
    /**@private*/
    this.prom = null
    /**@private*/
    this.pTimer = null
    /**@private*/
    this.promiseTimeout = 3000
    /**@private*/
    this.manualModeSwitch = false
  }
  /** propagate the event to TSDManager and then all listeners for the device
   * @param {Event} event  {key:eventName,value: value/null}
   * @example {key:'barcodeInserted', null}
   * */
  set DeviceEvent(event) {
    this.deviceEvent = event
    try {
      this.dm.tsdDeviceEvent(event.key, this.deviceId, this.name, event.value)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'set device tsdDeviceEvent : ' + this.deviceId + ' error: ' + e)
      return
    }
    try {
      if (this.onDeviceEvent && typeof this.onDeviceEvent === 'function') {
        this.onDeviceEvent(event)
      }
    } catch (e) {
      console.log('set device onDeviceEvent: ' + this.deviceId + ' error: ' + e)
      this.logMessage(TraceLevels.LOG_ALERT, 'set device onDeviceEvent: ' + this.deviceId + ' error: ' + e)
      return
    }
    // use listeners
    if (this.devEventListeners.size > 0) {
      try {
        for (let [id, callback] of this.devEventListeners) {
          if (typeof callback === 'function') {
            this.logMessage(TraceLevels.LOG_EXT_TRACE, 'call listener: ' + id + ' ' + event.key)
            callback(this.deviceId, event)
          }
        }
      } catch (e) {
        this.logMessage(TraceLevels.LOG_ALERT, 'set device devEventListeners: ' + this.deviceId + ' error: ' + e)
        return
      }
    }
  }

  /**
   *  Add the application listener associated with the device.
   *  @param {String} id of the application listener. To be used for removing a listener.
   *  @param {function(Event: event)} listener - application listener.
   */
  addListener(id, listener) {
    this.devEventListeners.set(id, listener)
  }

  /**
   *  Remove the application listener associated with the device.
   *  @param {String} id of the application listener to be removed.
   */
  removeListener(id) {
    this.devEventListeners.delete(id)
  }

  /**
   *  Sets the callback for device events.
   *  @param {function(event: Event)} callback for device events and responses
   */
  set OnDeviceEvent(callback) {
    this.onDeviceEvent = callback
  }

  /**
   *  Sets the flag is device required.
   *  @param {Boolean} isRequired - is required flag.
   */
  set IsRequired(isRequired) {
    this.isRequired = isRequired
  }

  /**
   *  Sets the maximum trace level.
   *  @param {Number} level - max trace level.
   */
  setMaxTraceLevel(level) {
    this.maxTraceLevelToSend = level
  }

  /**
   *  Sets the flag to add local time to trace messages.
   *  @param {Boolean} flag - controls adding local time to trace messages.
   */
  setAddTimeToTrace(flag) {
    this.addTimeToTrace = flag
  }

  /**
   *  Sets the device promise timeout.
   *  @param {Number} timeout - Promise timeout.
   */
  setPromiseTimeout(timeout) {
    this.promiseTimeout = timeout
  }

  /**
   *  Default logger.
   *  @param {Number} level - message level.
   *  @param {String} msg - message text
   */
  logMessage(level, msg) {
    if (this.dm) {
      this.dm.dmLog(level, msg)
    } else {
      console.log(msg)
    }
  }

  /**
   *  Set the listener associated with the device.
   *  @param {Listener} listener Listener associated with the device.
   */
  setListener(listener) {
    this.listener = listener
    listener.device = this
    this.socket.addListener(this.getListener())
  }

  /**
   *  Retrieve the listener associated with the device.
   *  @return {Listener} Listener associated with the device.
   */
  getListener() {
    return this.listener
  }

  /**
   * Retrieve the device name associated with the device.
   * @return {String} device name
   */
  getName() {
    return this.name
  }

  /** Asynch call get status of the device - true when device is OK and false otherwise.
   */
  isOK() {
    try {
      this.socket.sendRequest(this.deviceId, 'isOK')
      this.logMessage(TraceLevels.LOG_TRACE, 'isOK(): ' + this.name)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isOK() exception: ' + e)
    }
  }

  /** Asynch call get detailed status of the device.
   */
  status() {
    try {
      this.socket.sendRequest(this.deviceId, 'status')
      this.logMessage(TraceLevels.LOG_TRACE, 'status(): ' + this.name)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'status() exception: ' + e)
    }
  }

  /** Executes two calls: status() and isOK() for this device. The response will contain combined results separated by comma.
   * @param {Boolean} [returnPromise] - if true then the method will return a Promise.
  @return {Promise} optional when the returnPromise is true..
  */
  statusIsOK(returnPromise) {
    try {
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'statusIsOK(): ' +
          (returnPromise === undefined ? '' : 'returnPromise: ' + (returnPromise + ' ' + this.promiseTimeout))
      )
      let pr = null
      if (returnPromise === true) {
        if (this.prom == null) {
          pr = new Promise((resolve, reject) => {
            this.prom = [resolve, reject]
            this.pTimer = setTimeout(() => {
              if (this.prom) {
                this.prom[PromiseCodes.REJECT](PromiseCodes.TIMEOUT)
                this.prom = null
              }
            }, this.promiseTimeout)
          })
        } else {
          // should not happen create a temp promise and reject it
          pr = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(PromiseCodes.UNEXPECTED)
            }, 10)
          })
        }
      }
      this.socket.sendRequest(this.deviceId, 'statusIsOK')
      this.logMessage(TraceLevels.LOG_TRACE, 'statusIsOK(): ' + this.name)
      if (returnPromise === true) {
        return pr
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'statusIsOK() exception: ' + e)
    }
    return null
  }

  /** Enables this device.
   *  @param {Number} [enableType] - for card reader enableType can be specified as one of {@link src/constants/Constants.js~CardReaderEnableType}
   */
  enable(enableType = -1) {
    try {
      if (this.deviceId === deviceIds.CARD_READER) {
        this.logMessage(TraceLevels.LOG_TRACE, 'enable(): ' + this.deviceId + ' enableType: ' + enableType)
      } else {
        this.logMessage(TraceLevels.LOG_TRACE, 'enable(): ' + this.deviceId)
      }
      this.dm.tsdDeviceEvent(DeviceEventType.ENABLED, this.deviceId, this.name)
      if (enableType === -1) {
        this.socket.sendCommand(this.deviceId, 'enable')
      } else {
        this.socket.sendCommand(this.deviceId, 'enable', enableType)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'enable() exception: ' + e)
    }
  }

  /** Disables this device.*/
  disable() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'disable(): ' + this.deviceId)
      this.dm.tsdDeviceEvent(DeviceEventType.DISABLED, this.deviceId, this.name)
      this.socket.sendCommand(this.deviceId, 'disable')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'disable() exception: ' + e)
    }
  }

  /** get kiosk device help for accessibility (CUSS devices)
   * Response arrives in asynch mode.*/
  getKioskDeviceHelp() {
    try {
      this.socket.sendRequest(this.deviceId, 'getKioskDeviceHelp')
      this.logMessage(TraceLevels.LOG_TRACE, 'getKioskDeviceHelp(): ')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getKioskDeviceHelp() exception: ' + e)
    }
  }

  /** get the last device status: true or false
   * @returns {Boolean} */
  getIsDeviceOk() {
    return this.isDeviceOk
  }

  /** is the device required
   * @returns {Boolean} */
  isDeviceRequired() {
    return this.isRequired
  }

  getLastDeviceStatus() {
    return this.deviceStatus
  }

  /**
   *  Sets the flag is device acts as manual/automatic switch.
   *  @param {Boolean} manualModeSwitch - is manual/automatic flag.
   */
  setManualModeSwitch(manualModeSwitch) {
    this.manualModeSwitch = manualModeSwitch
  }
}
