import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from 'components'
import { playSound } from 'main'
import { ThemeContext } from 'styled-components'

const ConfirmAct = (props) => {
  const themeContext = useContext(ThemeContext)
  const handleConfirmAction = (e) => {
    playSound.beepOK()
    console.log('handleConfirmAction() ... event id : ' + e.currentTarget.id)
    props.confirmAction(e)
  }
  const { confirmBtnText } = props
  return (
    <IconButton
      id={'confirmBtn'}
      cssName={props.confirmBtnStyle}
      color={themeContext.ConfirmButton.color}
      bgColor={themeContext.ConfirmButton.bgColor}
      width={themeContext.ConfirmButton.width}
      height={themeContext.ConfirmButton.height}
      disable={props.disableAction}
      onClick={handleConfirmAction}
      fontSize={20}
      buttonPosition={themeContext.ConfirmButton.buttonPosition}
      iconLocation={themeContext.ConfirmButton.buttonPosition}
      text={confirmBtnText}
      icon={props.confirmIcon ? props.confirmIcon : themeContext.ConfirmButton.icon}
      iconColor={themeContext.ConfirmButton.iconColor}
      borderRadius={themeContext.ConfirmButton.borderRadius}
      border={themeContext.ConfirmButton.border ? themeContext.ConfirmButton.border : ''}
    />
  )
}

ConfirmAct.propTypes = {
  confirmAction: PropTypes.func,
  disableAction: PropTypes.bool,
  confirmBtnText: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  textSpanStyle: PropTypes.string,
  textContainerStyle: PropTypes.string,
  iconStyle: PropTypes.string
}

ConfirmAct.defaultProps = {
  confirmBtnText: 'Confirm',
  confirmBtnStyle: 'btn-nav-confirm-up',
  textSpanStyle: 'btn-nav-right-padding',
  textContainerStyle: 'row-container-space-around',
  iconStyle: 'font-icon'
}
export default ConfirmAct
