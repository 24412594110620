import React, { useEffect } from 'react'
import {SwitchBase} from 'components/switch'
import './checkbox.scss'

/* export interface CheckboxData {
  id: string
  text: string
  status: SwitchBaseStatus
} */

export {SwitchBaseStatus as CheckboxStatus } from 'components/switch'
const Checkbox = (props) => {
  const {
    data,
    containerStyle,
    labelPlacement,
    labelStyle,
    disableLabel,
    syncData,
    additionalTextStyle,
    additionalText1Style,
    additionalText2Style,
    iconStyleChecked,
    iconStyleUnchecked,
    iconStyleCheckedDisabled,
    iconStyleUncheckedDisabled
  } = props

  const [checkboxData, setCheckboxData] = React.useState(data)

  useEffect(() => {
    if(syncData) {
      setCheckboxData(data)
    }
  }, [syncData, data])

  const handleCheckboxChange = (id, status) => {
    const updatedCheckboxData = checkboxData.map((data) => {
      if (data.id === id) {
        data.status = status
      }
      return data
    })
    setCheckboxData(updatedCheckboxData)
    props.onClick(updatedCheckboxData)
  }

  return (
    <>
      {checkboxData.map((option) => (
        <SwitchBase
          key={option.id}
          id={option.id}
          text={option.text}
          status={option.status}
          onClick={handleCheckboxChange}
          containerStyle={containerStyle}
          labelPlacement={labelPlacement}
          labelStyle={labelStyle}
          disableLabel={disableLabel}
          additionalTextStyle={additionalTextStyle}
          additionalText1Style={additionalText1Style}
          additionalText2Style={additionalText2Style}
          iconStyleChecked={iconStyleChecked}
          iconStyleUnchecked={iconStyleUnchecked}
          iconStyleCheckedDisabled={iconStyleCheckedDisabled}
          iconStyleUncheckedDisabled={iconStyleUncheckedDisabled}
        />
      ))}
    </>
  )
}

Checkbox.defaultProps = {
  containerStyle: 'checkbox-container',
  disableLabel: false,
  syncData: true,
  labelStyle: 'checkbox-label',
  additionalTextStyle: 'checkbox-additional-text',
  additionalText1Style: 'checkbox-additional-text1',
  additionalText2Style: 'checkbox-additional-text2',
  iconStyleChecked: 'checkbox-checked',
  iconStyleUnchecked: 'checkbox-unchecked',
  iconStyleCheckedDisabled: 'checkbox-checked-disabled',
  iconStyleUncheckedDisabled: 'checkbox-unchecked-disabled'
}

export default Checkbox
