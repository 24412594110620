/**
 * ETS Request and Response - addBags
 */

import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { TransitionCodes } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { checkin } from 'actions/etsTransactions/checkin'
import { transitions } from 'actions/etsTransactions/transitions'
import { isNotEmpty, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer } from 'utils/helper'
import { updateLocalData } from '../localActions'

function buildAddBags(paxBagUpdateArray, fastPathFlag) {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      jsonExtend: null,
      passengersBaggageUpdate: paxBagUpdateArray,
      checkinContractReturn: fastPathFlag,
    }
  )
}

/** removes any unnecessary, zero-valued baggage info prior to sending to ETS. **/
function preparePaxBagUpdateArray(paxBagUpdateArray) {
  let newBagUpdateArray = []
  let paxOrdinal = -1
  let filteredBagUpdateArr = []
  let tempBagUpdateArr = []

  for (let i = 0; i < paxBagUpdateArray.length; i++) {
    paxOrdinal = paxBagUpdateArray[i].paxOrdinal
    filteredBagUpdateArr = []
    tempBagUpdateArr = paxBagUpdateArray[i].baggageUpdate

    for (let j = 0; j < tempBagUpdateArr.length; j++) {
      if (tempBagUpdateArr[j].quantity >= 0) {
        filteredBagUpdateArr.push(tempBagUpdateArr[j])
      }
    }

    if (filteredBagUpdateArr.length > 0) {
      let paxBagObj = {
        paxOrdinal: paxOrdinal,
        baggageUpdate: filteredBagUpdateArr,
      }

      // Spirit Specific ... bigBagCountUpdate
      if (paxBagUpdateArray[i].bigBagCountUpdate) {
        paxBagObj = Object.assign({}, paxBagObj, {
          bigBagCountUpdate: paxBagUpdateArray[i].bigBagCountUpdate,
        })
      }

      newBagUpdateArray.push(paxBagObj)

      /*
      newBagUpdateArray.push({
        paxOrdinal: paxOrdinal,
        baggageUpdate: filteredBagUpdateArr
      })
*/
    }
  }
  return newBagUpdateArray
}

export function addBags(paxBagUpdateArray, fastPathFlag) {
  // appLog(TraceLevels.LOG_EXT_TRACE, 'addBags() request begins ... ')
  let request = buildAddBags(preparePaxBagUpdateArray(paxBagUpdateArray), fastPathFlag)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'addBags() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait', 21)
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('addBags/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'addBags() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))

          switch (json.transition) {
            case TransitionCodes.CHECKIN_BAGGAGE:
              appLog(TraceLevels.LOG_EXT_TRACE, 'addBags() receive response then dispatch checkin request. ')
              dispatch(checkin(false, json.etsResponse.addBaggageOK, false))
              break
            case TransitionCodes.SBD_PROCESS:
              appLog(TraceLevels.LOG_EXT_TRACE, '(addBags.js) url: PutBagOnBelt')
              if (store.getState().localData.baggageLoadedStatus) {
                navigate('BagProcessing', 2)
              } else {
                navigate('PutBagOnBelt', 2)
              }
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'addBags() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else if (json.error) {
          goToFetchGenericError('addBags', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'addBags()', 'error')
      })
  }
}
