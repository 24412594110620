import React, { useState, Fragment, useContext, useRef } from 'react'
import { Button, IconButton } from 'components'
import QuitTxn from './QuitTxn'
import { ThemeContext } from 'styled-components'
import { playSound, getSBDAppMan, getAccessibilityDevice, getAccessibilityManager } from 'main'
import { END_TXN_REASON } from '../../constants/Constants'
import PropTypes from 'prop-types'
import { Spacer } from 'components/styledComponents'
import { useIntl } from 'react-intl'

const QuitAct = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const [visible, setVisible] = useState(false)
  const popupTimer = useRef(null)
  const { quitAction, quitBtnText } = props
  const accMgr = getAccessibilityManager()

  const screenMessage = `${formatMessage(messages.CancelTxnTitle)}. ${formatMessage(messages.CancelTxnSubTitle)}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'exitLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'cancelYes', text: formatMessage(messages.buttonQuit), buttonId: 'cancelYes' },
        { id: 'cancelNo', text: formatMessage(messages.buttonContinue), buttonId: 'cancelNo' }
      ]
    }
  }

  const handleQuitAction = () => {
    playSound.beepOK()
    startPopupTimer()
    setVisible(true)
    accMgr.buildPopupGroup(true, accDef)
  }

  const popupCallback = (answer) => {
    playSound.beepOK()
    clearPopupTimer()
    if (answer === 'YES') {
      console.log('answer is yes')
      getSBDAppMan().doQuit(END_TXN_REASON.USER_QUIT, '')
      if (props.quitAction) {
        props.quitAction('quitAction')
      }
    } else {
      console.log('answer is NO')
    }
    setVisible(false)
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true })
  }

  const startPopupTimer = () => {
    popupTimer.current = setTimeout(() => {
      popupCallback('NO')
    }, config.timeoutPopup * 1000)
    console.log(
      'QuitTxn startPopupTimer() ... START POPUP Timer ... ',
      popupTimer,
      ' ... duration ... ',
      config.timeoutPopup
    )
  }

  const clearPopupTimer = () => {
    if (popupTimer.current !== null) {
      clearTimeout(popupTimer.current)
      console.log('QuitTxn clearPopupTimer() ... STOP POPUP Timer ... ', popupTimer.current)
      popupTimer.current = null
    }
  }

  return (
    <Fragment>
      <QuitTxn visible={visible} popupCallback={popupCallback} buttonGap={'50px'} />
      <IconButton
        id={'quitBtn'}
        cssName={'quit-btn'}
        color={themeContext.QuitButton.color}
        bgColor={themeContext.QuitButton.bgColor}
        width={themeContext.QuitButton.width}
        height={themeContext.QuitButton.height}
        iconLocation={'LEFT'}
        //disable={props.disableAction}
        onClick={handleQuitAction}
        fontSize={18}
        text={quitBtnText}
        icon={'quit-icon.svg'}
        iconColor={themeContext.QuitButton.iconColor}
        disableShadow={themeContext.QuitButton.disableBoxShadow}
      />
    </Fragment>
  )
}
QuitAct.propTypes = {
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func
}

QuitAct.defaultProps = {
  quitBtnText: 'Quit',
  quitBtnStyle: 'btn-quit-up',
  quitAction: null
}
export default QuitAct
