export default class Timeout {
  constructor(timeout = 45 * 1000, timeoutCallback = null) {
    this.timeoutDuration = timeout
    this.timeoutCallback = timeoutCallback
    this.windowsKeyEventListener = this.windowsKeyEventListener.bind(this)
    this.windowsMouseEventListener = this.windowsMouseEventListener.bind(this)
    this.windowsFocusEventListener = this.windowsFocusEventListener.bind(this)
    this.handleTimeout = this.handleTimeout.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.setTimeoutCallback = this.setTimeoutCallback.bind(this)
    this.setTimeoutDuration = this.setTimeoutDuration.bind(this)
    this.timer = null
    this.isDisableTimeout = false
    this.windowsMouseEventListener()
  }

  setTimeoutCallback(cb) {
    this.timeoutCallback = cb
  }

  setTimeoutDuration(durationInMs) {
    this.timeoutDuration = durationInMs
  }

  setDisableTimeout(isDisable) {
    this.isDisableTimeout = isDisable
  }

  resetTimer(duration = this.timeoutDuration) {
    if (this.isDisableTimeout) {
      console.log("Timer resetTimer(): timeout is disabled. ")
    } else {
      console.log("Timer resetTimer() ... duration ... " + duration)
      this.stopTimer()
      this.startTimer(duration)
    }
  }

  startTimer(duration = this.timeoutDuration) {
    if (this.isDisableTimeout) {
      console.log("Timer startTimer(): timeout is disabled. ")
    } else {
      this.timer = setTimeout(this.handleTimeout, duration)
      console.log(
        "Timer startTimer() ... START ... ",
        this.timer,
        " ... duration ... ",
        duration
      )
    }
  }

  stopTimer() {
    console.log("Timer stopTimer() ...")
    if (!this.isDisableTimeout && this.timer) {
      clearTimeout(this.timer)
      console.log("Timer stopTimer()  ... STOP  ... ", this.timer)
      this.timer = null
    }
  }

  // listen windows onkeydown event then reset timer
  windowsKeyEventListener() {
    document.onkeydown = (e) => {
      console.log(
        "Timer windowsKeyEventListener() ... onkeydown ... key code ... ",
        e.keyCode
      )
      // reset timer
      if (this.timer) {
        this.resetTimer(this.timeoutDuration)
      }
    }
  }

  // listen windows onclick event then reset timer
  windowsMouseEventListener() {
    document.body.addEventListener(
      "click",
      () => {
        console.log("screen clicked.")
        if (this.timer) {
          this.resetTimer(this.timeoutDuration)
        }
      },
      true
    )
  }

  windowsFocusEventListener() {
    // reset timer
    if (this.timer) {
      this.resetTimer(this.timeoutDuration)
    }
  }

  handleTimeout() {
    console.log("Timer handleTimeout(): ")
    if (this.timeoutCallback) {
      this.timeoutCallback()
    }
  }
}
