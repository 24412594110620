import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import ItineraryInfo from 'components/ItineraryInfo/ItineraryInfo'
import QuitAct from 'layouts/footer/QuitAct'
import BackAct from 'layouts/footer/BackAct'
import LangAct from 'layouts/footer/LangAct'
import SkipAct from 'layouts/footer/SkipAct'
import PhotoAct from 'custom/layouts/PhotoAct'
import HelpAct from 'layouts/footer/HelpAct'
import CancelAct from 'layouts/footer/CancelAct'
import PassengerInfo from 'layouts/footer/PassengerInfo'
import { isEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { PageFooter, DynamicImage, Spacer, PageContent, PageSubContent, PageText } from 'components/styledComponents'
import { TestButtons } from 'components'
import { ThemeContext } from 'styled-components'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { IconButton } from 'components'
import 'layouts/footer/footer.scss'

const WelcomeFooter = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const isLandscape = useCheckLandscape()
  /* const dispatch = useDispatch()
  const locale = useSelector(state => state.localData.locale) */
  const { formatMessage } = intl
  const {
    btnSpace,
    isQuitRequired,
    quitBtnText,
    quitBtnStyle,
    quitAction,
    isLangRequired,
    langBtnStyle,
    isBackRequired,
    backBtnText,
    backBtnStyle,
    backAction,
    isSkipRequired,
    isSkipDisabled,
    isSkipInCenter,
    skipBtnText,
    skipAction,
    skipBtnStyle,
    isConfirmRequired,
    confirmBtnText,
    confirmAction,
    confirmBtnStyle,
    disableConfirmAction,
    isCancelRequired,
    cancelBtnText,
    cancelAction,
    isPhotoRequired,
    photoBtnText,
    photoAction,
    disablePhotoAction,
    photoBtnStyle,
    displayItinerary,
    isHelpRequired,
    displayPhotoInstruction,
    testData
  } = props

  const imageLocation = `${themeContext.BrandLogo}`

  const brandLogo = <DynamicImage imageName={imageLocation} width={71} height={12} />

  const carrierCode = useSelector((state) => state.localData.carrierCode)
  const kioskInfo = useSelector((state) => state.kioskInfo)

  const airlineLogo = config.useDefaultAirlineLogo ? (
    themeContext.airlineLogo ? (
      <DynamicImage
        imageName={themeContext.airlineLogo}
        height={themeContext.airlineLogoHeight}
        width={themeContext.airlineLogoWidth}
        backgroundPosition={'center right'}
      />
    ) : (
      ''
    )
  ) : carrierCode ? (
    <DynamicImage
      imageName={'airlineLogo-' + carrierCode + '.png'}
      height={themeContext.airlineLogoHeight}
      width={themeContext.airlineLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    ''
  )

  const airportLogo = config.useDefaultAirportLogo ? (
    <DynamicImage
      imageName={themeContext.airportLogo}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    <DynamicImage
      imageName={`airportLogo-${kioskInfo.airportCode}.png`}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  )

  const footerLogo = config.swapLogo ? (
    <div className={'logo-wrapper-footer'}>{airportLogo}</div>
  ) : (
    <div className={'logo-wrapper-footer'}>{airlineLogo}</div>
  )

  const buttonSection = (
    <PageContent key="testButtons" flexFlow="row" justifyContent={isLandscape ? 'flex-end' : 'center'}>
      <PageSubContent flex={1}></PageSubContent>
      <PageSubContent flex={1} padding='0'>
        {isPhotoRequired ? (
          <PhotoAct
            photoBtnText={isEmpty(photoBtnText) ? formatMessage(messages.buttonPhoto) : photoBtnText}
            photoAction={photoAction}
            disableAction={disablePhotoAction}
            photoBtnStyle={photoBtnStyle}
          />
        ) : (
          ''
        )}
        {displayPhotoInstruction ? (
          <>
            <PageSubContent flexFlow="row" height="100px" justifyContent="space-around" width="600px" padding="10px 0">
              <DynamicImage imageName="no-glasses-icon.svg" width="70" height="70" />
              <DynamicImage imageName="no-mask-icon.svg" width="70" height="70" />
              <DynamicImage imageName="no-hat-icon.svg" width="70" height="70" />
            </PageSubContent>
            <PageSubContent flexFlow="row" height="30px" justifyContent="space-around" width="600px" padding="10px 0">
              <PageText dir="auto" width="200px" height="30px" fontSize="13px" style={{ textTransform: 'uppercase' }}>
                {formatMessage(messages.photoInstruction1)}
              </PageText>
              <PageText dir="auto" width="200px" height="30px" fontSize="13px" style={{ textTransform: 'uppercase' }}>
                {formatMessage(messages.photoInstruction2)}
              </PageText>
              <PageText dir="auto" width="200px" height="30px" fontSize="13px" style={{ textTransform: 'uppercase' }}>
                {formatMessage(messages.photoInstruction3)}
              </PageText>
            </PageSubContent>
          </>
        ) : (
          ''
        )}
      </PageSubContent>
      <PageSubContent flex={1}>
        {testData ? <TestButtons data={testData} showPopup={true} showTestButton={config.showTestButtons} /> : ''}
      </PageSubContent>
    </PageContent>
  )
  return (
    <PageFooter>
      {/* {isLandscape ? '' : buttonSection} */}
      <PageContent flexFlow={isLandscape ? 'row' : 'column-reverse'} padding={isLandscape ? '0 50px' : '20px 0'}>
        {displayItinerary ? <ItineraryInfo /> : <div></div>}
        {buttonSection}
      </PageContent>
      {/* <Spacer width="10px" /> */}
      <PageSubContent
        flexFlow="row"
        width="100%"
        padding="0"
        style={{
          height: '75px',
          backgroundColor: themeContext.Footer.bgColorCenter,
          boxShadow: themeContext.Footer.disableBoxShadow ? '' : 'inset 0px 0px 3px #0000001c'
        }}
      >
        {isQuitRequired ? (
          <IconButton
            id={'quitBtn'}
            cssName={'quit-btn'}
            color={themeContext.QuitButton.color}
            bgColor={themeContext.QuitButton.bgColor}
            width={themeContext.QuitButton.width}
            height={themeContext.QuitButton.height}
            iconLocation={'LEFT'}
            //disable={props.disableAction}
            onClick={quitAction}
            fontSize={18}
            text={quitBtnText}
            icon={'quit-icon.svg'}
            iconColor={themeContext.QuitButton.iconColor}
            disableShadow={themeContext.QuitButton.disableBoxShadow}
          />
        ) : null}
        {/* <Spacer width="10px" /> */}
        {isLangRequired ? <LangAct langBtnStyle={langBtnStyle} /> : ''}
        {isLangRequired ? <Spacer width="10px" /> : ''}
        {isSkipRequired && isSkipInCenter ? (
          <SkipAct
            skipBtnText={skipBtnText}
            skipAction={skipAction}
            skipBtnStyle={skipBtnStyle}
            isDisabled={isSkipDisabled}
          />
        ) : (
          ''
        )}

        {isHelpRequired ? <HelpAct /> : ''}
        <PageContent alignItems="flex-end">{footerLogo}</PageContent>
      </PageSubContent>
      <div className="footer-bottom-brand-bar">{themeContext.showEMBlogo ? brandLogo : ''}</div>
    </PageFooter>
  )
}

WelcomeFooter.propTypes = {
  isQuitRequired: PropTypes.bool,
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func,
  backAction: PropTypes.func,
  skipAction: PropTypes.func,
  confirmAction: PropTypes.func,
  disableConfirmAction: PropTypes.bool,
  isBackRequired: PropTypes.bool,
  isLangRequired: PropTypes.bool,
  isSkipRequired: PropTypes.bool,
  isSkipInCenter: PropTypes.bool,
  isConfirmRequired: PropTypes.bool,
  backBtnText: PropTypes.string,
  backBtnStyle: PropTypes.string,
  skipBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  btnSpace: PropTypes.string,
  isSkipDisabled: PropTypes.bool,
  skipBtnStyle: PropTypes.string,
  displayStyle: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  testData: PropTypes.array,
  displayItinerary: PropTypes.bool,
  confirmBtnStyle: PropTypes.string,
  backBtnStyle: PropTypes.string,
  isCancelRequired: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  isPhotoRequired: PropTypes.bool,
  photoBtnText: PropTypes.string,
  photoAction: PropTypes.func,
  disablePhotoAction: PropTypes.bool,
  photoBtnStyle: PropTypes.string
}

WelcomeFooter.defaultProps = {
  isQuitRequired: true,
  isLangRequired: true,
  isSkipInCenter: false,
  isSkipDisabled: false,
  btnSpace: 'btn-nav-space',
  displayItinerary: true,
  confirmBtnStyle: 'confirm-btn',
  isPhotoRequired: false,
  photoBtnStyle: 'confirm-btn',
  backBtnStyle: 'back-btn',
  isCancelRequired: false,
  isHelpRequired: false
}

export default WelcomeFooter
