import { APPLICATION_MANAGER } from 'constants/Constants'

export function getAppData(
  state = {
    kioskId: config.defaultKioskId,
    cussKioskId: 'CUSS' + config.defaultKioskId,
    smKioskId: 'SM' + config.defaultKioskId,
    airportCode: config.defaultLocation,
    location: config.defaultLocation,
    companyCode: 'EMBROSS',
    appName: 'CKC',
    oosEvent: '',
    PSAM: true,
    onActive: false,
    SBD_MODEL: 'VBD',
    KIOSK_TYPE: 'EMBROSS'
  },
  action
) {
  // initial value
  let data = Object.assign({}, state)
  if (action.type === APPLICATION_MANAGER) {
    if (action.key) {
      data[action.key] = action.value
    }
    switch (action.key) {
      case 'kioskId':
        data.kioskId = action.value
        break
      case 'cussKioskId':
        data.cussKioskId = action.value
        break
      case 'smKioskId':
        data.smKioskId = action.value
        break
      case 'airportCode':
        data.airportCode = action.value
        break
      case 'location':
        data.location = action.value
        break
      case 'companyCode':
        data.companyCode = action.value
        break
      case 'appName':
        data.appName = action.value
        break
      case 'oosEvent':
        data.oosEvent = action.value
        break
      case 'PSAM':
        data.PSAM = action.value
        break
      case 'onActive':
        data.onActive = action.value
        break
      default:
        return state
    }
    return data
  } else return state
}
