import { TraceLevels, PromiseCodes, VBDModeStatus } from 'constants/Constants'

/** Base class for device listeners.*/
export default class Listener
{
  /** constructor for base listener */
  constructor() {
    /** link to device object.
     * @protected */
    this.device = null
  }

  /** message logger - uses the device logger
   * @param {number} level log level
   * @param {string} msg message to log
   */
  logMessage(level, msg) {
    if (this.device != null)
	{
      this.device.logMessage(level, msg)
    } else {
      console.log('Log: ' + msg)
    }
  }

  /** Set device event
   * @param {string} key name of the event
   * @param {Object} value event data
   */
  setEvent(key, value)
  {
    this.device.DeviceEvent = {key: key, value: value}
  }

  /** Command timeout listener
   * @param {string} method method which has not received response from the device in the allocated timeout
   */
  commandTimeout(method)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.commandTimeoutMethod() called for: ' + method)
    this.setEvent('commandTimeout', method)
  }

  /** Default callback for response to status() call
   * @param {number} response status value
   */
  statusResponse(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusResponse() called for: ' + response)
    this.setEvent('statusResponse', response)
  }
  
  /** Default callback for response to isOK() call
   * @param {string} response '1' when device is OK
   */
  isOK(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.isOK() ' + this.device.name + ' -> ' + response)
    this.device.isDeviceOk = response === '1'
    this.setEvent('isOK', this.device.isDeviceOk)
  }

  /**
   *  Default statusChange callback.  Called when the device encounters a change in status
   *  call this.device.dm.onReqDeviceOK when the required device become OK
   *  @param {String} response - array [rc, fatal]
   *    <ul><li>rc: the return code of the status</li>
   *    <li>fatal: Determine whether the status is fatal.  If fatal error, application should take appropriate measures
   *      to go out of service, or to check for a more specific error, such as out of stock conditions</li></ul>
   */
  statusChange(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusChange() ' + this.device.name + ' -> ' + response)
    let res = response.split(',').map((item) => item.trim())
    if (res.length === 2)
    {
      this.device.deviceStatus = parseInt(res[0], 10)
      //check if required device become OK
      if (this.device.isDeviceRequired && !this.device.isDeviceOk && res[1] === 'false') {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusChange() device become OK: ' + this.device.name)
        if (this.device.dm.onReqDeviceOK) {
          this.device.dm.onReqDeviceOK(this.device.deviceId)
        }
      }
      if (this.device.manualModeSwitch) {
        if (this.device.deviceStatus === VBDModeStatus.VBD_MODE_AUTOMATIC) {
          this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusChange() automatic mode: ' + this.device.deviceStatus)
        } else {
          this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusChange() manual mode: ' + this.device.deviceStatus)
        }
      }
      this.device.isDeviceOk = res[1] === 'false'
      response = [this.device.deviceStatus, !this.device.isDeviceOk]  // second parameter is fatal error
      this.setEvent('statusChange', response)
    } else {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusChange() incorrect Response! ' + response)
    }
  }
  
  /**
   *  Default statusIsOK callback.  It combines two calls in one.
   *  @param {String} response - array 'status, isOK'
   *    <ul><li>status: the return code of the status</li>
   *    <li>IsOK: true when device is OK</li></ul>
   */
  statusIsOK(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusIsOK() ' + this.device.name + ' -> ' + response)
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusIsOK() result ' + response)
    //this.setEvent('statusIsOK', response)
    this.device.statusOK = response
    let res = response.split(',')
    let isOKChanged = false
    if (res.length === 2)
    {
      this.device.deviceStatus = parseInt(res[0], 10)
      let newIsOK = res[1] === '1'
      if (newIsOK !== this.device.isDeviceOk) {
        isOKChanged = true
      }
      this.device.isDeviceOk = newIsOK
    } else {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.statusIsOK() incorrect Response! ' + response)
    }
    if (this.device.prom)
    {
      clearTimeout(this.device.pTimer)
      this.device.prom[PromiseCodes.RESOLVE](response)
      this.device.prom = null
      if (isOKChanged) {
        this.setEvent('statusIsOK', response)
      }
    } else {
      this.setEvent('statusIsOK', response)
    }
  }
  
  /** Default callback for response to getKioskDEviceHelp() call
   * @param {string} response device help from the CUSS platform
   */
  getKioskDeviceHelp(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getKioskDeviceHelp() ' + this.device.name)
    this.setEvent('getKioskDeviceHelp', response)
  }
}

