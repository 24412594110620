import { TraceLevels } from './constants/Constants'
import { AccEvents, ACCESSIBILITY_HANDLER, EZAKPKeys, ACC_POPUPS, ACC_KEY_GROUPS } from './accessibility/Constants'
import {
  applyParameters,
  isEmpty,
  getScreenId,
  getConveyorPosition,
  getTranslations,
  getSequenceById,
} from './accessibility/helper'
import { sleep } from './utils/helper'

/**
 * The AccManager class allows the application use keypad to control navigation and speech under CUSS platform.
 * @class
 * @classdesc
 */
export default class AccManager {
  /**
   * @param {Object} accessibility - accessibility reference: CUSS_Accessibility or KeypadSimulator
   * @param {Object} devMgr - embross-device-manager reference
   * @param {json: Object} options - options for accessibility
   * @param {json: Object} appConfig - application configuration
   */
  constructor(accessibility, devMgr, options, appConfig) {
    /** @private accessibility reference: CUSS_Accessibility or KeypadSimulator */
    this.accessibility = accessibility
    /** @private device manager reference */
    this.devMgr = devMgr
    //::::::::::::::::::::::::::::::: Options ::::::::::::::::::::::::::::::
    /** @private browser history of application */
    this.history = options && options.history
    /** @private first screen of application */
    this.firstScreen = options && options.firstScreen
    /** @private current locale of application */
    this.locale = (options && options.locale) || 'en'
    /** @private device info reference */
    this.deviceInfo = options && options.deviceInfo
    /** @private translations files  */
    this.translations = getTranslations(options && options.translations)

    /** :::::::: appConfig values :::::::::*/
    this.languages = appConfig ? appConfig.languages || appConfig.LanguagesDictionary : []
    this.accFocus =
      appConfig?.accFocus ?? (appConfig?.outlineOn ? appConfig.outlineOn : ':focus { outline: red solid 5px }')
    this.isCUSSRequired = appConfig && appConfig.isCUSSRequired
    this.theme = appConfig && appConfig.theme === 'default' ? 'EMBROSS' : appConfig.theme
    this.disablePlatformHelp = appConfig && appConfig.disablePlatformHelp
    this.barcodeScanFace = appConfig && appConfig.barcodeScanFace
    this.passportScanFace = appConfig && appConfig.passportScanFace
    this.paymentCardDirection = appConfig && appConfig.paymentCardDirection

    this.accInfo = null
    this.isKeypadSwitchOn = false
    this.accessibility.OnDeviceEvent = this.accCallback.bind(this)
    this.handleStatusChanged = this.handleStatusChanged.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePageLoaded = this.handlePageLoaded.bind(this)
    this.handlePopup = this.handlePopup.bind(this)
    this.handleLanguageChanged = this.handleLanguageChanged.bind(this)
    this.buildHelpGroup = this.buildHelpGroup.bind(this)
    this.setFocus = this.setFocus.bind(this)
    this.getLanguageDef = this.getLanguageDef.bind(this)
    this.setCurrentAIndex = this.setCurrentAIndex.bind(this)
    this.handleCountEvent = this.handleCountEvent.bind(this)
    /************************************************************************/
    // Helper functions
    /************************************************************************/
    this.handleNavigateToNext = this.handleNavigateToNext.bind(this)
    this.getSequenceSpeechText = this.getSequenceSpeechText.bind(this)
    this.moveToSubgroup = this.moveToSubgroup.bind(this)
    this.addHelp = this.addHelp.bind(this)
    this.getDeviceHelp = this.getDeviceHelp.bind(this)
    this.getCurrentSequence = this.getCurrentSequence.bind(this)
    this.playAccessibilityNotSupported = this.playAccessibilityNotSupported.bind(this)
    this.getScrollGroupIndex = this.getScrollGroupIndex.bind(this)
    this.getKeypadGroupIndex = this.getKeypadGroupIndex.bind(this)
    this.readButtonText = this.readButtonText.bind(this)
    /*****************************************************************************/
    //variables holding accessibility related information of current screen.
    /*****************************************************************************/
    this.languageDef = this.getLanguageDef(this.locale)
    this.firstLanguage = this.languages.length > 0 ? this.getLanguageDef(this.languages[0].id) : this.languageDef
    this.secondLanguage = this.languages.length > 1 ? this.getLanguageDef(this.languages[1].id) : this.firstLanguage
    // screen name
    this.currentPath = ''
    // accessibility sequences of current screen includes sequences and sub-sequences
    this.accessibleGroup = []
    // current accessibility sequences or sub-sequences
    this.currentGroup = []
    // current highlight sequence
    this.currentAIndex = -1
    // save parent sequence when working with sub-sequences
    this.groupArray = []
    // hold help speech texts, order: previous speech text, current screen help, device help ...
    this.helpArray = []
    // index of help group
    this.numberOfHelpPressed = -1
    // current focused element help
    this.currentHelp = ''
    // current input field object
    this.input = {}
    this.inputElement = null
    this.parentElement = null
    // handle keypad
    this.handler = ACCESSIBILITY_HANDLER.DEFAULT
    this.keypadRow = -1
    this.keypadCol = -1
    this.subListIndex = 0
    this.checkboxIndex = 0
    this.prevPath = ''
    this.scrollDirection = 0
    this.scrollClicked = false
    this.playedInitialMessage = false
    this.initialMessageInPlaying = false
    this.isHelpReady = false
    this.scrollGroupIndex = 0 // for scroll group
    this.keypadGroupIndex = 0 // for keypad group
    this.scrollGroupList = []
    this.keypadGroupList = ['number', 'letter', 'function']
  }

  /**
   * callback function called when accessibility returns an event
   * @param {Object} event - event from accessibility
   */
  accCallback(event) {
    // Not supported languages for accessibility
    if (this.translations[this.locale] === undefined) {
      // skip saying message when popup show/hide
      if (event.key !== AccEvents.POPUP_SHOW && event.key !== AccEvents.POPUP_HIDE) {
        this.accessibility.play('Accessibility is not supported for current language')
      }
      return
    }
    this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager accCallback() event: ' + JSON.stringify(event))
    this.languageDef = this.getLanguageDef(this.locale)
    document.body.focus()

    switch (event.key) {
      case AccEvents.STATUS_CHANGED:
        this.handleStatusChanged(event.value)
        break
      case AccEvents.KEY_PRESSED:
        this.handleKeyPress(event.value)
        break
      case AccEvents.PAGE_LOADED:
        this.handlePageLoaded(event.value)
        break
      case AccEvents.POPUP_SHOW:
        this.handlePopup(true, event.value)
        break
      case AccEvents.POPUP_HIDE:
        this.handlePopup(false, event.value)
        break
      case AccEvents.LANGUAGE_CHANGED:
        this.handleLanguageChanged(event.value)
        break
      case AccEvents.PLAY:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager accCallback() paly : ', event.value)
        break
      case AccEvents.LANGUAGE:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager accCallback() language : ', event.value)
        this.languageDef = this.getLanguageDef(event.value)
        break
    }
  }

  handleStatusChanged(value) {
    this.accessibility.enabled = value // CUSS_Accessibility or KeypadSimulator
    this.devMgr.dmLog(
      TraceLevels.LOG_EXT_TRACE,
      'AccManager handleCallback() accessibility.enabled is set to : ' + value
    )
    this.groupArray = [] // reset groupArray
    if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
      document.styleSheets[0].deleteRule(0)
    }
    // accessibility is enabled
    if (this.accessibility.enabled) {
      // accInfo is not defined at screen
      if (!this.accInfo) {
        const accessibilityDisabledText = this.getLanguageText(this.languageDef, 'NotSupportAccessibility', false)
        this.accessibility.play(accessibilityDisabledText)
        return
      }
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleCallback() enabled set outline')
      document.styleSheets[0].insertRule(this.accFocus, 0)
      document.body.focus()
      this.currentGroup = this.accInfo.sequenceDef
      this.currentAIndex = -1
      this.initialMessage = this.buildInitialMessage()
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'initialMessage:' + this.initialMessage)
      this.accessibility.play(this.initialMessage)
      this.playedInitialMessage = true
      this.initialMessageInPlaying = true
    } else {
      // accessibility is disabled
      if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
        document.styleSheets[0].deleteRule(0)
      }
      document.styleSheets[0].insertRule(':focus { outline: none; }', 0)
      this.handler = ACCESSIBILITY_HANDLER.DEFAULT

      if (!this.isCUSSRequired) {
        const accessibilityDisabledText = this.getLanguageText(this.languageDef, 'AccessibilityDisabled', false)
        this.accessibility.play(accessibilityDisabledText)
        this.currentAIndex = -1
      }
    }
  }

  handleKeyPress(keyValue) {
    if (this.handler === ACCESSIBILITY_HANDLER.KEYPAD) {
      this.handleKeypadEvent(keyValue)
      return
    }

    let element = null
    let numHelps = this.helpArray ? this.helpArray.length : 0

    switch (keyValue) {
      case EZAKPKeys.NAVPREVIOUS:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleKeyPress() key pressed: PREVIOUS')
        // Jump scroll by section group (scrollGroupId)
        if (this.currentGroup && this.currentGroup.enableScroll) {
          this.currentAIndex = this.getScrollGroupIndex(this.currentGroup.sequence, 'DECREASE', 1)
          this.setFocus()
          return
        }

        // Decrease count
        if (this.currentAIndex > 0 && this.currentGroup.sequence[this.currentAIndex].isCounter) {
          this.handleCountEvent(EZAKPKeys.NAVPREVIOUS)
        }
        // Disable previous event from popup
        if (this.currentGroup.type === ACC_POPUPS.POPUP) {
          return
        }

        // Get out from subgroup
        if (this.groupArray.length > 0) {
          let savedGroup = this.groupArray.pop()
          this.currentGroup = savedGroup.group
          this.currentAIndex = savedGroup.index
          this.setFocus()
        }
        break
      case EZAKPKeys.NAVNEXT:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleKeyPress() key pressed: NEXT')
        // Jump scroll by section group (scrollGroupId)
        if (this.currentGroup && this.currentGroup.enableScroll) {
          this.currentAIndex = this.getKeypadGroupIndex(this.currentGroup.sequence, 'INCREASE', 1)
          this.setFocus()
          return
        }

        // Navigate keypad by group
        if (this.currentGroup && this.currentGroup.enableKeypadGroups) {
          this.currentAIndex = this.getKeypadGroupIndex(this.currentGroup.sequence, 'INCREASE', 0)
          this.setFocus()
          return
        }

        // Increase count
        if (this.currentAIndex > 0 && this.currentGroup.sequence[this.currentAIndex].isCounter) {
          this.handleCountEvent(EZAKPKeys.NAVNEXT)
          return
        }
        // Next to continue/confirm button
        if (isEmpty(this.currentGroup.nextKeyNavId)) {
          return
        }
        this.handleNavigateToNext()

        break
      case EZAKPKeys.NAVUP:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleKeyPress() key pressed: UP')
        this.setCurrentAIndex(EZAKPKeys.NAVUP)
        this.setFocus()
        break
      case EZAKPKeys.NAVDOWN:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleKeyPress() key pressed: DOWN')
        this.setCurrentAIndex(EZAKPKeys.NAVDOWN)
        this.scrollDirection = 1
        this.setFocus()
        break
      case EZAKPKeys.NAVENTER:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleKeyPress() key pressed: ENTER')
        if (isEmpty(this.currentGroup)) return
        const currentSequence = this.currentGroup.sequence[this.currentAIndex]
        if (isEmpty(currentSequence)) return
        // item with buttonId
        if (currentSequence.buttonId) {
          element = document.getElementById(currentSequence.buttonId)
          /**
           * with subSequence
           */
          if (currentSequence.subSequence) {
            if (element) {
              element.click()
              this.scrollDirection = 0 // TODO: need to review this variable is relevant
              // Note: custom keypadGroupList can be set from application
              if (currentSequence.subSequence.keypadGroupList) {
                this.keypadGroupList = currentSequence.subSequence.keypadGroupList
              }
              this.moveToSubgroup(currentSequence)
            }
            /**
             * with exitOnClick: exit from subgroup and move to next sequence of parent group
             */
          } else if (currentSequence.exitOnClick) {
            if (element) {
              element.click()

              // Get out from subgroup and focus next sequence
              if (this.groupArray.length > 0) {
                const savedGroup = this.groupArray.pop()
                this.currentGroup = savedGroup.group
                // by default move to next sequence
                this.currentAIndex = currentSequence.stayOnExit ? savedGroup.index : savedGroup.index + 1
                this.setFocus()
              }
            }
            /**
             * with nextOnClick: move directly to nextNav (usually continue/confirm button)
             */
          } else if (currentSequence.nextNavOnClick) {
            if (element) {
              element.click()
              // go to next nav div (continue/confirm button)
              this.handleNavigateToNext()
            }
          } else if (currentSequence.gotoOnClick > -1) {
            element.click()
            this.currentAIndex = currentSequence.gotoOnClick
            // stay on popup (ie popup will not hide onClick event)
            if (currentSequence.exitOnClick === false) {
              this.setFocus()
            }
            // NOTE: can be replaced by gotoOnClick = 0
          } else if (currentSequence.resetIndexOnClick) {
            element.click()
            this.currentAIndex = 0

            /**
             * No subsequence. *** Regular button click ***
             */
          } else {
            if (element) {
              element.focus()
              // bilingual: language button displayed on screen, not popup.
              // if (element.id === 'langBtn' && this.languages.length < 3) {
              //   this.currentAIndex = 0
              //   if (this.locale === 'en') {
              //     currentSequence.language = this.languages[1].id
              //   } else {
              //     currentSequence.language = this.languages[0].id
              //   }
              //   this.languageDef = this.getLanguageDef(currentSequence.language)
              //   element.click()
              //   // wait enough to load accDef
              //   sleep(100).then(() => {
              //     this.setFocus()
              //   })
              // } else {
              //   element.click()
              // }

              element.click()

              if (this.currentGroup.type === ACCESSIBILITY_HANDLER.KEYPAD) {
                this.inputElement = element
                // disable speechText if input length is greater than input max length
                if (this.input.maxLength) {
                  if (this.inputElement.value.length < this.input.maxLength) {
                    this.accessibility.play(this.getSequenceSpeechText(currentSequence, this.currentGroup.type))
                  }
                } else if (this.inputElement.value.length > 0) {
                  this.accessibility.play(this.getSequenceSpeechText(currentSequence, this.currentGroup.type))
                }
              } else if (this.currentGroup.type === ACCESSIBILITY_HANDLER.CHECKBOX) {
                this.accessibility.play(this.getSequenceSpeechText(currentSequence, this.currentGroup.type))
              }
            }
          }
        } else if (currentSequence.inputId) {
          // this.handler = ACCESSIBILITY_HANDLER.KEYPAD
          this.keypadCol = 0
          this.keypadRow = 0
          this.input = currentSequence
          this.inputElement = document.getElementById(currentSequence.id)
          console.log(this.input, this.inputElement)
          this.moveToSubgroup(currentSequence)

          // this.handleKeypadEvent({ key: 'KeyEvent', value: 'ActiveKeypad' })
          /**:::::::::::::::::::::::::::::::::
           * Sequence without buttonId
           :::::::::::::::::::::::::::::::::::*/
        } else {
          if (document.getElementById(currentSequence.id)) {
            element = document.getElementById(currentSequence.id)
            // element.click()
            // this.currentGroup = this.getCurrentSequence(this.accessibleGroup)
            const speechText = this.getSequenceSpeechText(currentSequence)
            this.accessibility.play(speechText)
            this.currentHelp = speechText
          }
        }
        break
      case EZAKPKeys.NAVHELP:
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager handleCallback() key pressed: HELP')
        if (this.numberOfHelpPressed === -1) {
          if (this.currentHelp && this.currentHelp !== '') {
            // play current focused element help
            this.accessibility.play(this.currentHelp)
          } else {
            // play first help element - screen description
            this.numberOfHelpPressed++
            this.accessibility.play(this.helpArray[this.numberOfHelpPressed])
          }
        } else if (numHelps > 0 && this.numberOfHelpPressed < numHelps) {
          this.accessibility.play(this.helpArray[this.numberOfHelpPressed])
        }
        if (this.numberOfHelpPressed < numHelps) {
          this.numberOfHelpPressed++
        } else {
          this.accessibility.play(this.getLanguageText(this.languageDef, 'EndOfHelp'))
          this.numberOfHelpPressed = -1
        }
        break
      default:
        this.devMgr.dmLog(
          TraceLevels.LOG_EXT_TRACE,
          'AccManager handleCallback() key pressed, key: ' + e.value + ' is not handled.'
        )
    }
  }

  handlePopup(showPopup, value) {
    this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager accCallback() handelPopup : ', value)
    if (showPopup) {
      console.log('ACC_POPUPS.POPUP_SHOW: ', value)
      const index = this.currentAIndex > -1 ? this.currentAIndex : 0
      this.groupArray.push({
        id: this.currentGroup.sequence[index].id,
        group: this.currentGroup,
        index: this.currentAIndex,
      })
      this.currentGroup = value
      this.currentAIndex = 0
      this.handler = ACCESSIBILITY_HANDLER.DEFAULT // TODO: need to review this line
      this.setFocus()
    } else {
      console.log('ACC_POPUPS.POPUP_HIDE: ', value)
      if (this.groupArray.length > 0) {
        let savedGroup = this.groupArray.pop()
        if (savedGroup) {
          this.currentGroup = savedGroup.group
          // by default reset index when popup is closed.
          // if keepPrevIndex is set true, currentAIndex will not reset to 0
          if (value.keepPrevIndex === undefined) {
            this.currentAIndex = 0
          } else {
            this.currentAIndex = savedGroup.index
          }
        }
        if (value && value.disableFocus) {
          console.log('this.setFocus() is disabled...')
        } else if (value.action === 'QUIT' && value.response === 'YES') {
          console.log('action is QUIT. this.setFocus() is disabled...')
        } else if (value.action === 'UPDATE') {
          // when state value updated from popup component. Once screen loaded setFocus() will fire with updated state value.
          console.log('action is UPDATE. this.setFocus() is disabled...')
        } else {
          this.setFocus()
        }
      }
    }
  }

  handleLanguageChanged(lang) {
    this.devMgr.dmLog('AccManager accCallback() language changed : ', lang)
  }

  handlePageLoaded(value) {
    this.devMgr.dmLog('AccManager handleCallback() accessibility page loaded : ', value)
    this.getSequence(value)
  }

  /**
   * set current index when EZAKPKeys pressed
   * @param {string} action - EZAKPKeys action
   */
  setCurrentAIndex(action) {
    this.devMgr.dmLog(
      TraceLevels.LOG_EXT_TRACE,
      'setCurrentAIndex() this.currentGroup: ' + JSON.stringify(this.currentGroup)
    )
    if (this.currentGroup.sequence) {
      if (action === EZAKPKeys.NAVDOWN) {
        if (this.currentAIndex < this.currentGroup.sequence.length - 1) this.currentAIndex++
        else this.currentAIndex = 0
      } else if (EZAKPKeys.NAVUP) {
        if (this.currentAIndex > 0) this.currentAIndex--
        else this.currentAIndex = this.currentGroup.sequence.length - 1
      }
    } else {
      this.currentAIndex = -1
    }
  }

  /**
   * build accessibility sequence of screen
   * @param {Object} accDef - sequence of current screen
   * @param {string} locale - locale of the application
   */
  buildAccessibilityGroup(accDef, locale) {
    // Skip to build the accessibility group if current language is not supported
    if (this.translations[this.locale] === undefined) {
      if (this.accessibility.enabled) {
        this.accessibility.play('Accessibility is not supported for current language')
      }
      return
    }

    const { location } = this.history
    // accDef.pathname is not match with current screen path, set accessibility sequence as null
    if (accDef && accDef.pathName && accDef.pathName.toLowerCase() !== getScreenId(location.pathname)) {
      this.accInfo = null
    } else if (isEmpty(accDef)) {
      this.devMgr.dmLog(
        TraceLevels.LOG_EXT_TRACE,
        '(' + location.pathname + ') pathName in accDef is not defined for accessibility'
      )
      return
    }

    // language button
    // if (accDef && accDef.isLangRequired) {
    //   if (this.languages.length < 3) {
    //     const nextLocale = locale === this.languages[0].id ? this.languages[1].id : this.languages[0].id
    //     accDef.sequenceDef.sequence.push({
    //       id: 'langBtn',
    //       textId: nextLocale,
    //       buttonId: 'langBtn',
    //       language: nextLocale,
    //       gotoOnClick: 0,
    //     })
    //   } else {
    //     if (accDef.langBtnText) {
    //       accDef.sequenceDef.sequence.push({
    //         id: 'langBtn',
    //         text: accDef.langBtnText,
    //         buttonId: 'langBtn',
    //         gotoOnClick: 0,
    //       })
    //     } else {
    //       accDef.sequenceDef.sequence.push({ id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' })
    //     }
    //   }
    // }

    // quit button
    if (accDef && accDef.isQuitRequired) {
      if (accDef.quitBtnText) {
        accDef.sequenceDef.sequence.push({ id: 'quitBtn', text: accDef.quitBtnText, buttonId: 'quitBtn' })
      } else {
        accDef.sequenceDef.sequence.push({ id: 'quitBtn', textId: 'NavQuit', buttonId: 'quitBtn' })
      }
    }

    this.accInfo = accDef
    // console.log('this.accInfo: ', this.accInfo)
    if (this.accessibility.enabled) {
      this.devMgr.dmLog(
        TraceLevels.LOG_TRACE,
        `buildAccessibilityGroup() called { key: ${AccEvents.PAGE_LOADED}, value: ${JSON.stringify(accDef)}}`
      )
      this.accessibility.DeviceEvent = { key: AccEvents.PAGE_LOADED, value: accDef }
    }
  }

  /**
   * build accessibility sequence for popup
   * @param {Boolean} popupShow - true: popup show, false: popup hide
   * @param {Object} accDef - popup sequence
   * @param {Boolean} forward - forward to next screen on popup hide event
   */
  buildPopupGroup(popupShow, accDef, forward = false) {
    // Skip to build the accessibility popup group if current language is not supported
    if (this.translations[this.locale] === undefined) return

    this.devMgr.dmLog(
      TraceLevels.LOG_TRACE,
      `locale: ${this.locale}, accDef: ${JSON.stringify(accDef)}, accInfo: ${JSON.stringify(this.accInfo)}`
    )
    if (popupShow) {
      // Popup with scroll menu
      if (accDef.sequenceDef && accDef.sequenceDef.enableScroll) {
        this.scrollGroupList = accDef.sequenceDef.sequence
          .map((sequence) => sequence.scrollGroupId)
          .filter((x, i, a) => a.indexOf(x) == i)
      }
      this.accInfo = Object.assign({ savedGroup: this.accInfo }, accDef)
      if (this.accessibility.enabled) {
        this.accessibility.DeviceEvent = { key: ACC_POPUPS.POPUP_SHOW, value: accDef.sequenceDef }
      }
    } else {
      // popupHide
      if (forward) return
      this.accInfo = this.accInfo.savedGroup
      if (this.accessibility.enabled) {
        this.accessibility.DeviceEvent = { key: ACC_POPUPS.POPUP_HIDE, value: accDef || '' }
      }
    }
  }

  /**
   * play message if accessibility is not defined
   */
  playAccessibilityNotSupported(msg) {
    if (this.accessibility.enabled) {
      if (msg) {
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, msg)
        this.accessibility.play(msg)
      } else {
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager is not supported for: ' + window.location.pathname)
        this.accessibility.play(this.getLanguageText(this.languageDef, 'NotSupportAccessibility'))
      }
    }
  }

  /**
   * set focus of the screen when accessibility is enabled
   */
  setFocus(event) {
    this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager setFocus() currentIndex: ' + this.currentAIndex)
    this.devMgr.dmLog(
      TraceLevels.LOG_EXT_TRACE,
      'AccManager setFocus() currentGroup: ' + JSON.stringify(this.currentGroup)
    )
    if (this.currentAIndex === -1 && isEmpty(this.currentGroup)) {
      this.playAccessibilityNotSupported()
      return
    }

    if (isEmpty(this.currentGroup.sequence[this.currentAIndex])) {
      this.currentAIndex = 0
    }
    let item = this.currentGroup.sequence[this.currentAIndex]
    let element = document.getElementById(item.id)
    if (!element) {
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager setFocus() element: ' + element)
      this.playAccessibilityNotSupported(
        `Accessibility is not supported due to the element with item.id: ${item.id} is not defined.`
      )
      return
    }
    let speechText = ''
    if (item.textId || item.text) {
      // Set focus for button
      if (element.nodeName.toUpperCase() === 'BUTTON' && item.buttonId && event !== EZAKPKeys.NAVENTER) {
        // let inputParams = this.currentGroup.sequence[this.currentAIndex].inputParams
        // let multiParams = this.currentGroup.sequence[this.currentAIndex].multiParams
        if (item.buttonId === this.currentGroup.nextKeyNavId) {
          // wait while DOM load completely. Usually this is for continue/confirm button. (Disable -> Enable)
          sleep(200).then(() => {
            this.readButtonText(element, item)
          })
        } else {
          this.readButtonText(element, item)
        }
        // Set focus for non-button div
      } else {
        /** If the speech text is mixed of 2 languages **/
        if (item.bilingual) {
          let lang0 = this.getLanguageDef(item.bilingual[0])
          let lang1 = this.getLanguageDef(item.bilingual[1])

          if (this.isCUSSRequired) {
            speechText = this.xmlTTSBilingual(
              lang0.languageStr,
              lang0.language[item.textId],
              lang1.languageStr,
              lang1.language[item.textId]
            )
            // For KeypadSimulator
          } else {
            speechText = [
              { text: this.getLanguageText(lang0, item.textId), languageStr: lang0.languageStr },
              { text: this.getLanguageText(lang1, item.textId), languageStr: lang1.languageStr },
            ]
          }
        } else if (item.language) {
          let lang = this.getLanguageDef(item.language)
          speechText = this.getLanguageText(lang, item.textId)
          // Keypad
        } else if (item.keypad) {
          // TODO cannot play help for this, so set speechText value
          speechText = this.getLanguageText(this.languageDef, [item.textId, 'keyInstruction'])
          /** If the speech text has a dynamic text parameter **/
        } else if (item.textParameters) {
          // set speech text
          if (item.text) {
            speechText = applyParameters(
              this.xmlTTSLanguage(this.languageDef.languageStr, item.text),
              item.textParameters
            )
            // set speech text by textId
          } else {
            speechText = applyParameters(this.getLanguageText(this.languageDef, item.textId), item.textParameters)
          }
          /** Regular speech text without dynamic text parameter **/
        } else {
          if (item.text) {
            speechText = this.xmlTTSLanguage(this.languageDef.languageStr, item.text)
            // if (this.isCUSSRequired) {
            //   speechText = this.xmlTTSLanguage(this.languageDef.languageStr, item.text)
            // } else {
            //   speechText = item.text
            // }
          } else {
            speechText = this.getLanguageText(this.languageDef, item.textId) //this.languageDef[item.textId]
          }
        }
        this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, '(AccManager.js) setFocus()' + JSON.stringify(speechText))
        /**
         * TODO: Review checking initialMessageInPlaying is relevant
         */
        this.initialMessageInPlaying = false // set false to ignore this block for now
        if (this.initialMessageInPlaying) {
          this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'speechText is stopped because initial message is in playing')
          this.initialMessageInPlaying = false
        } else {
          if (this.isCUSSRequired || !item.bilingual) {
            this.accessibility.play(speechText)
          } else {
            // For KeypadSimulator
            this.accessibility.playBilingual(speechText)
          }
        }
        this.currentHelp = speechText
      }
      /**
       * no text or textId
       */
    } else {
      console.log('Speech text is not defined for: ', item, this.currentGroup)
      this.accessibility.play('speech text is not defined.', 'en')
    }

    /** element is document.getElementById(item.id) **/
    if (element) {
      element.focus()
    } else {
      document.activeElement.blur()
    }
  }

  /**
   * build the initial message of the application
   */
  buildInitialMessage() {
    const toXml = this.isCUSSRequired
    let language = this.languageDef
    let otherLanguage = null
    let targetLanguage = language.language['NavSelectLanguage']
    let cussHelpIndex = 0
    if (language.languageStr.indexOf('en') > -1) {
      otherLanguage = this.secondLanguage
      // targetLanguage = this.secondLanguage.languageText
      cussHelpIndex = 0
    } else {
      otherLanguage = this.firstLanguage
      // targetLanguage = 'ENGLISH'
      cussHelpIndex = 1
    }
    let enabledSelectLanguage = this.languages.length > 1
    let keypadHelpText = language.language['NavKeypadHelp']
    let deviceHelp = null
    if (this.deviceInfo && this.deviceInfo.Cuss_Accessibility_keypad) {
      deviceHelp = this.deviceInfo.Cuss_Accessibility_keypad.deviceHelp
    }

    if (this.isCUSSRequired && deviceHelp) {
      keypadHelpText = Array.isArray(deviceHelp.deviceDescription.speak)
        ? `<s>${deviceHelp.deviceDescription.speak[cussHelpIndex]}</s>
           <s>${deviceHelp.deviceLocation.speak[cussHelpIndex]}</s>
           <s>${deviceHelp.deviceProfile.speak[cussHelpIndex]}</s>
           <s>${deviceHelp.deviceUsage.speak[cussHelpIndex]}</s>'`
        : `<s>${deviceHelp.deviceDescription.speak}</s>
           <s>${deviceHelp.deviceLocation.speak}</s>
           <s>${deviceHelp.deviceProfile.speak}</s>
           <s>${deviceHelp.deviceUsage.speak}</s>'`
    }

    const conveyorPosition = getConveyorPosition()
    let msg = this.isCUSSRequired
      ? '<?xml version="1.0" encoding="UTF-8"?>' +
        '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" ' +
        'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
        'xsi:schemaLocation="http://www.w3.org/2001/10/synthesis http://www.w3.org/TR/speech-synthesis/synthesis.xsd" ' +
        'xml:lang="' +
        language.languageStr +
        '">' +
        '<p xml:lang="' +
        language.languageStr +
        '">' +
        applyParameters(language.language['Application'], [this.theme, conveyorPosition]) +
        '</p>'
      : applyParameters(language.language['Application'], [this.theme, conveyorPosition])

    if (deviceHelp && Array.isArray(deviceHelp.deviceDescription.speak)) {
      msg += this.isCUSSRequired
        ? `<p xml:lang="${otherLanguage.languageStr}">
                ${
                  enabledSelectLanguage
                    ? applyParameters(otherLanguage.language['SelectLanguage'], [targetLanguage])
                    : ''
                }
              </p>
              <p xml:lang="${language.languageStr}">${keypadHelpText}</p>
              <p xml:lang="${otherLanguage.languageStr}">${otherLanguage.language['NavKeypadHelp']}</p>
            </speak>`
        : `${applyParameters(otherLanguage.language['SelectLanguage'], [targetLanguage])}  ${keypadHelpText} ${
            otherLanguage.language['NavKeypadHelp']
          }`
    } else {
      msg += this.isCUSSRequired
        ? `<p xml:lang="${language.languageStr}">
                ${enabledSelectLanguage ? applyParameters(language.language['SelectLanguage'], [targetLanguage]) : ''} 
              </p>
              <p xml:lang="${language.languageStr}">${keypadHelpText}</p>
            </speak>`
        : `${
            enabledSelectLanguage ? applyParameters(language.language['SelectLanguage'], [targetLanguage]) : ''
          } ${keypadHelpText}`
    }

    console.log('msg: ' + msg)
    return msg
  }

  /**
   * Loaded when application screen is launched (AccEvents.PAGE_LOADED)
   * @param {Object} value - accessibility sequence set from application
   */
  getSequence(value) {
    if (value) {
      this.currentPath = value.pathName
      this.accessibleGroup = value.sequenceDef
      this.numberOfHelpPressed = -1
      this.currentGroup = this.accessibleGroup
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager getSequence() previous path : ' + this.prevPath)
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager getSequence() path : ' + this.currentPath)
      this.devMgr.dmLog(
        TraceLevels.LOG_EXT_TRACE,
        'AccManager getSequence() group : ' + JSON.stringify(this.accessibleGroup)
      )
      /** New Screen **/
      if (this.currentPath !== this.prevPath) {
        this.currentAIndex = value.startIndex
        this.isHelpReady = false
      }
      this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager getSequence() currentAIndex : ' + this.currentAIndex)
      /** Add Device Help & Screen Description **/
      if (!this.isHelpReady) {
        // Device Help
        this.buildHelpGroup(value.pathName, this.languageDef)
        // Screen Description (Screen related help)
        if (this.accessibility.enabled && value.screenDescription) {
          this.helpArray.unshift(this.getLanguageText(this.languageDef, value.screenDescription))
        }
        this.isHelpReady = true
      }
      this.prevPath = value.pathName
      this.setFocus()
    } else {
      this.playAccessibilityNotSupported()
    }
  }

  /**
   * set tts text based on text id
   * @param {Object} langDef
   * @param {string} textId
   * @param {boolean} toXml
   * @param {boolean} silent
   */
  getLanguageText(langDef, textId, silent = false) {
    const toXml = this.isCUSSRequired
    this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, `AccManager getLanguageText() textId: ${textId}`)
    let langText = ''
    if (typeof textId === 'string') {
      textId = [textId]
    }
    textId.map((eachTextId) => {
      if (langDef.language[eachTextId]) {
        langText += langDef.language[eachTextId] + ' '
      } else if (!silent) {
        langText += langDef.language['TranslationUnavailable'] + ': ' + eachTextId
      }
    })
    if (toXml) {
      return this.xmlTTSLanguage(langDef.languageStr, langText)
    }
    return langText
  }

  /**
   * set language definition based on locale
   * @param {string} locale - current locale of application
   */
  getLanguageDef(locale) {
    const accMessage = this.translations[locale] || this.translations['en']
    const currentLanguage = this.languages.find((language) => language.id === locale)
    const languageDef = {
      language: accMessage,
      languageStr: currentLanguage ? currentLanguage.str || currentLanguage.languageCode : 'en-US',
      languageText: currentLanguage ? currentLanguage.text : 'ENGLISH',
    }

    // // if simulator use google voice
    // if (this.accessibility.name === 'KeypadSimulator') {
    //   if (languageDef.languageStr.includes('en')) {
    //     languageDef.languageStr = 'en-US'
    //   } else if (languageDef.languageStr.includes('fr')) {
    //     languageDef.languageStr = 'fr-FR'
    //   } else if (languageDef.languageStr.includes('es')) {
    //     languageDef.languageStr = 'es-ES'
    //   } else if (languageDef.languageStr.includes('pt')) {
    //     languageDef.languageStr = 'pt-BR'
    //   } else if (languageDef.languageStr.includes('pa')) {
    //     languageDef.languageStr = 'hi-IN'
    //   } else if (languageDef.languageStr.includes('uk')) {
    //     languageDef.languageStr = 'ru-RU'
    //   }
    //   this.accessibility.languageStr = languageDef.languageStr
    // }
    if (!this.isCUSSRequired) {
      this.accessibility.languageStr = languageDef.languageStr
    }

    return languageDef
  }

  /**
   * convert text to xml text
   * @param {string} lang - current language
   * @param {*} text - text message to read
   */
  xmlTTSLanguage(lang, text) {
    this.devMgr.dmLog(TraceLevels.LOG_EXT_TRACE, 'AccManager xmlTTSLanguage() lang: ' + lang + ' text: ' + text)
    let xmlText =
      '<?xml version="1.0" encoding="UTF-8"?>' +
      '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" ' +
      'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
      'xsi:schemaLocation="http://www.w3.org/2001/10/synthesis http://www.w3.org/TR/speech-synthesis/synthesis.xsd" ' +
      'xml:lang="' +
      lang +
      '">' +
      text +
      '</speak>'
    return xmlText
  }

  // use both language parameters as languages and texts
  xmlTTSBilingual(lang1, text1, lang2, text2) {
    //lang1 = (lang1 === 'en-EN') ? 'en' : (lang1 === 'fr-FR') ? 'fr' : lang1
    //lang2 = (lang2 === 'en-EN') ? 'en' : (lang2 === 'fr-FR') ? 'fr' : lang2
    console.log('xmlTTSBilingual()', lang1, text1, lang2, text2)

    // let xmlText = '<?xml version="1.0" encoding="windows-1252"?>' +
    // let xmlText = '<?xml version="1.0" encoding="UTF-8"?>' +
    let xmlText =
      '<?xml version="1.0" encoding="UTF-8"?>' +
      '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" ' +
      'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
      'xsi:schemaLocation="http://www.w3.org/2001/10/synthesis http://www.w3.org/TR/speech-synthesis/synthesis.xsd" ' +
      'xml:lang="' +
      lang1 +
      '">' +
      '<p>' +
      text1 +
      '</p>' +
      '<p xml:lang="' +
      lang2 +
      '">' +
      text2 +
      '</p>' +
      '</speak>'
    return xmlText
  }

  handleCountEvent(action, customBtnId) {
    let element = ''
    let buttonId = ''
    if (action === EZAKPKeys.NAVNEXT) {
      buttonId = customBtnId ? customBtnId : 'plusButtonId'
    } else if (action === EZAKPKeys.NAVPREVIOUS) {
      buttonId = customBtnId ? customBtnId : 'minusButtonId'
    }
    element = document.getElementById(this.currentGroup.sequence[this.currentAIndex][buttonId])
    element.click()
  }

  buildHelpGroup(pathName, languageDef) {
    // Add Device help (from application)
    if (this.disablePlatformHelp && this.accessibleGroup.help) {
      let deviceHelp = []
      this.accessibleGroup.help.map((helpItem) => {
        let param = ''
        switch (helpItem) {
          case 'Barcode_Reader':
            param = languageDef.language[this.barcodeScanFace.toUpperCase()]
            break
          case 'Passport_Reader':
            param = languageDef.language[this.passportScanFace.toUpperCase()]
            break
          case 'Card_Reader':
            param = this.paymentCardDirection === 'V' ? 'Vertical' : 'Horizontal'
            break
          default:
        }
        deviceHelp.push(applyParameters(this.getLanguageText(languageDef, helpItem), [param]))
      })
      this.accessibleGroup.help = deviceHelp
    } else {
      // Add Device help (from platform)
      this.addHelp(pathName, this.accessibleGroup, languageDef)
    }
    // additional help
    this.accessibleGroup.help.push(this.getLanguageText(this.languageDef, 'AgentHelp'))

    this.helpArray = this.accessibleGroup.help
  }

  switchLocale(locale) {
    this.locale = locale
    console.log(this.accessibility.name)
    if (this.accessibility.name === 'KeypadSimulator') {
      this.accessibility.locale = locale
      console.log(this.language, this.languageDef)
    }
  }

  /**
   * Move to NextNav div(continue/confirm button)
   */
  handleNavigateToNext() {
    // Get out from subgroup and next to continue/confirm button
    if (this.currentGroup.type !== ACC_POPUPS.POPUP && this.groupArray && this.groupArray.length > 0) {
      const savedGroup = this.groupArray.pop()
      // this.currentGroup = this.getCurrentSequence(this.accessibleGroup)
      this.currentGroup = savedGroup.group
    }
    this.currentAIndex = -1
    if (this.currentGroup && this.currentGroup.sequence) {
      this.currentGroup.sequence.forEach((seq, index) => {
        if (seq.id === this.currentGroup.nextKeyNavId) {
          this.currentAIndex = index
        }
      })
    }

    if (this.currentAIndex !== -1) {
      // give a buffer to load DOM for continue/confirm button status
      if (this.currentGroup.sequence[this.currentAIndex].id === this.currentGroup.nextKeyNavId) {
        sleep(200).then(() => {
          this.setFocus()
        })
      } else {
        this.setFocus()
      }
    }
  }

  getSequenceSpeechText(sequence, type) {
    let speechText = ''
    if (sequence.textId && sequence.text === undefined) {
      if (sequence.textParameters) {
        speechText = applyParameters(this.getLanguageText(this.languageDef, sequence.textId), sequence.textParameters)
      } else {
        speechText = this.getLanguageText(this.languageDef, sequence.textId)
      }
    } else if (sequence.text) {
      // speech text for keypad
      if (type === ACCESSIBILITY_HANDLER.KEYPAD) {
        speechText = applyParameters(this.getLanguageText(this.languageDef, 'KeypadPressed'), [sequence.text])
      } else if (type === ACCESSIBILITY_HANDLER.CHECKBOX) {
        const element = document.getElementById(sequence.id)
        // TODO: set speechText if current div is check box.
        if (element.attributes['aria-checked']) {
          speechText = sequence.text
        } else if (element.children[0].attributes['aria-checked']) {
          // NOTE: Use previous status since DOM is not updated yet.
          if (element.children[0].attributes['aria-checked'].nodeValue === '-1') {
            speechText = applyParameters(this.getLanguageText(this.languageDef, 'Checked'), [sequence.text])
          } else if (element.children[0].attributes['aria-checked'].nodeValue === '1') {
            speechText = applyParameters(this.getLanguageText(this.languageDef, 'UnChecked'), [sequence.text])
          } else {
            speechText = sequence.text
          }
        } else {
          speechText = sequence.text
        }
      } else {
        speechText = applyParameters(sequence.text, sequence.textParameters)
      }
    }
    return speechText
  }

  moveToSubgroup(sequence) {
    this.groupArray.push({
      id: sequence.id,
      group: this.currentGroup,
      index: this.currentAIndex,
    })
    this.currentGroup = sequence.subSequence
    this.currentAIndex = 0
    this.setFocus()
  }

  readButtonText(element, item) {
    let speechText = ''
    // Button is disabled
    /**
     * NOTE: disabled might skip the button.
     * aria-disabled will focus the button and announce that it exists,
     * but that it isn’t enabled yet.
     */
    if (element.attributes['aria-disabled'] && element.attributes['aria-disabled'].nodeValue === 'true') {
      let buttonName = element.innerText
      speechText = applyParameters(this.getLanguageText(this.languageDef, 'ButtonDisabled'), [buttonName])
    } else {
      if (item.textParameters) {
        speechText = applyParameters(this.getLanguageText(this.languageDef, item.textId), item.textParameters)
      } else {
        // language button
        if (item.language) {
          speechText = this.getLanguageText(this.getLanguageDef(item.language), item.textId, this.isCUSSRequired)
        } else {
          if (item.text) {
            speechText = this.xmlTTSLanguage(this.languageDef.languageStr, item.text)

            // if (this.isCUSSRequired) {
            //   speechText = this.xmlTTSLanguage(this.languageDef.languageStr, item.text)
            // } else {
            //   speechText = item.text
            // }
          } else {
            speechText = this.getLanguageText(this.languageDef, item.textId) //this.languageDef[item.textId]
          }
        }
      }
    }
    this.accessibility.play(speechText)
    this.currentHelp = speechText
  }

  /*****************************************************************
   * Helper functions
   *****************************************************************/
  addHelp(screen, group, language) {
    let helpText = ''
    // Application Help
    if (group) {
      let applicationHelp = group.help ? group.help : []
      group['help'] = []
      applicationHelp.map((msg) => {
        helpText = language.language[msg]
        if (helpText) {
          group['help'].push(helpText)
        } else {
          group['help'].push(`message id ${msg} is undefined`)
        }
      })
      if (!group.help) {
        group['help'] = []
      }
      switch (screen) {
        case 'Welcome':
          helpText = this.getDeviceHelp(language, 'AEA_BAGDROP')
          if (helpText !== '') group['help'].push(helpText)
          helpText = this.getDeviceHelp(language, 'BARCODE_READER')
          if (helpText !== '') group['help'].push(helpText)
          break
        case 'PutBagOnBelt':
          helpText = this.getDeviceHelp(language, 'AEA_BAGDROP')
          if (helpText !== '') group['help'].push(helpText)
          break
        case 'scanPassport':
        case 'getTravelDoc':
        case 'getTravelDocs':
          helpText = this.getDeviceHelp(language, 'PASSPORT_READER')
          if (helpText !== '') group['help'].push(helpText)
          break
        case 'takePhoto':
          helpText = this.getDeviceHelp(language, 'FACE_TRACKING')
          if (helpText !== '') group['help'].push(helpText)
          break
        //add card here and help message - CARD_READER
        case 'PaymentCardSwipe':
          helpText = this.getDeviceHelp(language, 'CARD_READER')
          if (helpText !== '') group['help'].push(helpText)
          break
      }
      if (this.isCUSSRequired) {
        helpText = this.getDeviceHelp(language, 'CUSS_ACCESSIBILITY_KEYPAD')
      }

      if (helpText !== '') {
        group['help'].push(helpText)
      }
    }
  }

  getDeviceHelp(language, deviceStr) {
    let firstLanguage = this.languages[0]
    let languageStr = firstLanguage.languageStr ? firstLanguage.languageStr : 'en-US'
    let helpText = ''
    let ttsHelpText = ''
    let languageIndex = 0
    if (language.languageStr === firstLanguage.str) {
      languageIndex = 0
    } else {
      languageIndex = 1
    }
    if (this.deviceInfo && this.deviceInfo[deviceStr]) {
      // Platform provides Multi-language for device help
      if (Array.isArray(this.deviceInfo[deviceStr].deviceHelp.deviceDescription.speak)) {
        helpText = this.deviceInfo[deviceStr].deviceHelp.deviceDescription.speak[languageIndex]
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceLocation.speak[languageIndex]
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceProfile.speak[languageIndex]
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceUsage.speak[languageIndex]
        ttsHelpText = this.xmlTTSLanguage(language.languageStr, helpText)
        // Platform provides ONLY English
      } else {
        helpText = this.deviceInfo[deviceStr].deviceHelp.deviceDescription.speak
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceLocation.speak
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceProfile.speak
        helpText += this.deviceInfo[deviceStr].deviceHelp.deviceUsage.speak
        ttsHelpText = this.xmlTTSLanguage(languageStr, helpText)
      }
    }
    if (this.isCUSSRequired) {
      return ttsHelpText
    }
    return helpText
  }

  getCurrentSequence(group) {
    let level = this.groupArray.length
    let id = ''
    let subGroup = group
    for (let i = 0; i < level; i++) {
      id = this.groupArray[i].id
      subGroup = getSequenceById(id, subGroup)
    }
    if (subGroup) {
      return subGroup
    } else {
      return group
    }
  }

  getCurrentInputId() {
    let inputId = null
    let inputName = this.currentGroup.sequence[this.currentAIndex].id
    switch (inputName) {
      case 'input1':
      case 'first':
        inputId = 0
        break
      case 'input2':
      case 'second':
        inputId = 1
        break
      case 'input3':
      case 'third':
        inputId = 2
        break
      default:
        inputId = 0
    }
    return inputId
  }

  getInputMaxLength() {
    let maxLength = []
    this.currentGroup.sequence.forEach((seq) => {
      if (seq.maxLength) {
        maxLength.push(seq.maxLength)
      }
    })
    return maxLength
  }

  getInputValues() {
    let values = []
    let element
    this.currentGroup.sequence.forEach((seq) => {
      if (seq.isField) {
        element = document.getElementById(seq.id)
        if (element) {
          values.push(element.value)
        }
      }
    })
    return values
  }

  getScrollGroupIndex(sequence, action, startIndex = 0) {
    if (this.scrollGroupIndex < startIndex) {
      this.scrollGroupIndex = startIndex
    }
    if (action === 'INCREASE') {
      this.scrollGroupIndex =
        this.scrollGroupIndex < this.scrollGroupList.length - 1 ? this.scrollGroupIndex + 1 : startIndex
    } else {
      this.scrollGroupIndex =
        this.scrollGroupIndex > startIndex ? this.scrollGroupIndex - 1 : this.scrollGroupList.length - 1
    }
    const letter = this.scrollGroupList[this.scrollGroupIndex]
    return sequence.findIndex((item) => item.scrollGroupId && item.scrollGroupId.toUpperCase() === letter.toUpperCase())
  }

  getKeypadGroupIndex(sequence, action, startIndex = 0) {
    if (this.keypadGroupIndex < startIndex) {
      this.keypadGroupIndex = startIndex
    }
    if (action === 'INCREASE') {
      this.keypadGroupIndex =
        this.keypadGroupIndex < this.keypadGroupList.length - 1 ? this.keypadGroupIndex + 1 : startIndex
    }
    // else if (action === 'DECREASE') {
    //   this.keypadGroupIndex =
    //     this.keypadGroupIndex > startIndex ? this.keypadGroupIndex - 1 : this.keypadGroupList.length - 1
    // }
    const groupName = this.keypadGroupList[this.keypadGroupIndex]
    return sequence.findIndex(
      (item) => item.keypadGroupId && item.keypadGroupId.toLowerCase() === groupName.toLowerCase()
    )
  }

  getAccLocale() {
    return this.accessibility.locale
  }

  // reset currentAIndex by provided index
  resetCurrentAIndex(index) {
    this.currentAIndex = index
  }
}
