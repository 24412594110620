import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import { getDeviceManager, getSBDAppMan, playSound, getBuildAccessibility, history } from 'main'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { TestButtons, EMBTable } from 'components'
import { PageTitle, PageSubTitle, DynamicImage, Spacer } from 'components/styledComponents'
import { BAG_TXN_NOMORE, SBDUpdateAction } from 'constants/Constants'
import useMedia from 'hooks/useMedia'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../footer'
import { populateBagtagInfo, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'

const PutBagOnBelt = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const nextBagQuestion = useSelector((state) => state.localData.nextBagQuestion)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const numberOfProcessedBags = useSelector((state) => state.localData.numberOfProcessedBags)
  const [displayArr, setDisplayArr] = useState([])
  const [accBagStatus, setAccBagStatus] = useState([])
  const [putBagOnBeltState, setPutBagOnBeltState] = useState({
    bagtagStatusArr: [],
    titleText: intl.formatMessage(messages.PutBagOnBelt2)
  })
  const initialIndicator = useRef(true)
  const isLandscape = useCheckLandscape()
  const columnWidth = useMedia(null, ['300px', '200px', '250px'], '250px')
  const subTitleAlign = useMedia(null, ['flex-start', 'flex-start', 'center'], 'center')
  const columns = [
    {
      header: intl.formatMessage(messages.BaggageTag),
      width: columnWidth
    },
    {
      header: intl.formatMessage(messages.Status),
      width: columnWidth
    }
  ]

  let displayFullTable = null

  // handle bottom right action buttons
  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(PutBagOnBelt.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    let bagTagObject = null
    let bagMeasurements = null
    switch (e.currentTarget.id.split('-')[0]) {
      case 'confirmBtn':
        SBDAppManager.doQuit(BAG_TXN_NOMORE, '') // <-- doQuit() redirects to "Closing Remarks" automatically, if "BAG_TXN_NOMORE"
        break
      case 'buttonPutBag':
        dispatch(updateLocalData('appFlow', 2))
        aeaBagDrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=BAGP'
        })
        setTimeout(() => {
          aeaBagDrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=BAGP'
          })
        }, 2000)

        break
      case 'buttonTESTbag2':
        navigate('ConfirmTotalWeight', 21)
        break
      case 'buttonTESTbag3':
        //history.push('ClosingRemarks')
        break
      case 'redirect':
        history.push(e.currentTarget.id.split('-')[1])
        break
      default:
    }
  }

  let testButtons = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonPutBag',
      text: 'Put bag',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonTESTbag2',
      text: 'temp test',
      handler: handleActions,
      group: 0
    },
    {
      id: 'redirect-ConfirmTotalWeight',
      text: 'Confirm TW',
      handler: handleActions,
      group: 1
    }
  ]

  useEffect(() => {
    if (!itineraryInfo) {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '(PutBagOnBelt.js) useEffect[itineraryInfo, numberOfProcessedBags] - itineraryInfo NOT AVAILABLE ...'
      )
    } else {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '(PutBagOnBelt.js) componentDidMount() - this.props.itineraryInfo AVAILABLE !!!'
      )

      let newTitleText =
        numberOfProcessedBags > 1
          ? intl.formatMessage(messages.PutBagOnBelt2)
          : intl.formatMessage(messages.PutBagOnBelt1)
      let bagtagStatus = populateBagtagInfo(itineraryInfo)
      //prepare status table data
      let bagStatusArray = []
      let accBagStatusArray = []
      for (let i = 0; i < bagtagStatus.length; i++) {
        if (bagtagStatus[i].status === 'ACTIVATED') {
          bagStatusArray.push({
            data: [
              <div className={'bagProcessing-activated-text'} key={'id' + i}>
                {bagtagStatus[i].bagtagID}
              </div>,
              <div key={'status' + i} className={'bagProcessing-active-wrapper'}>
                <div className={'bagProcessing-active-icon'} />
                <div className={'bagProcessing-activated-text'}>{intl.formatMessage(messages.Activated)}</div>
              </div>
            ]
          })
        } else {
          bagStatusArray.push({
            data: [
              <div className={'bagProcessing-inactivated-text'} key={'id' + i}>
                {bagtagStatus[i].bagtagID}
              </div>,
              <div key={'status' + i} className={'bagProcessing-active-wrapper'}>
                <div className={'bagProcessing-inactive-icon'} />
                <div className={'bagProcessing-inactivated-text'}>{intl.formatMessage(messages.NotActivated)}</div>
              </div>
            ]
          })
        }
        accBagStatusArray.push({ id: bagtagStatus[i].bagtagID, status: bagtagStatus[i].status === 'ACTIVATED' })
      }

      setDisplayArr(bagStatusArray)
      setAccBagStatus(accBagStatusArray)
      setPutBagOnBeltState((prevState) => {
        return {
          ...prevState,
          ...{ titleText: newTitleText, bagtagStatusArr: bagtagStatus }
        }
      })
    }
  }, [itineraryInfo, numberOfProcessedBags])

  useEffect(() => {
    if (initialIndicator.current) {
      dispatch(updateError(null)) // RESET ERROR
      if (appFlow === 2) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(PutBagOnBelt.js) componentDidMount() - Enable SBD via getSBDAppMan...')
        getSBDAppMan().setSQStart(-1)
        getSBDAppMan().setPutBagStart(new Date().getTime())

        //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

        if (config.callCR == 'Y' && config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - Enable Scanner CR')
          //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
          aeaBagDrop.sendAEA('CR', -10000)
        }

        // check if bag is there - delay for config.minPutBag*1000
        //state.set("PutBagStart", (new Date()).getTime()

        if (config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (PutBagOnBelt.js) componentDidMount() - send SQ')
          aeaBagDrop.sendAEA('SQ', -10000)
        }
      }
      if (appFlow > 2 && appFlow <= 5) {
        navigate('BagProcessing')
      }
      initialIndicator.current = false
    }
  }, [appFlow])

  //if (!config.showOnlyCountOfActivatedBagtags) {
  let displayRow = isLandscape ? 5 : 4
  displayFullTable =
    displayArr.length > 0 ? (
      <EMBTable
        columns={columns}
        scrollbarWidth={36}
        scrollbarGap={20}
        rows={displayArr}
        headerHeight={50}
        rowHeight={30}
        rowFontSize={20}
        headFontSize={20}
        maxDisplayRows={displayRow}
      />
    ) : (
      ''
    )
  //}

  let numOfBagsText =
    locale === 'ar' ? new Intl.NumberFormat('ar-AE').format(numberOfProcessedBags) : numberOfProcessedBags

  const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan_new.gif`}
      //imageName={`${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan_noDuffle.gif`}
      // imageName={`${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {numberOfProcessedBags > 0
          ? intl.formatMessage(messages.PutBagOnBelt2)
          : config.useHeaviestMsg
          ? intl.formatMessage(messages.PutBagOnBelt3)
          : intl.formatMessage(messages.PutBagOnBelt1)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {intl.formatMessage(messages.PutBagOnBeltSubTitle)}
      </PageSubTitle>
      <Spacer height={30} />
    </>
  )

  const header = null //<PageHeader>{intl.formatMessage(messages.PutBagOnBeltHeader)}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={numberOfProcessedBags > 0 && !nextBagQuestion ? true : false}
        confirmAction={handleActions}
        confirmBtnText={intl.formatMessage(messages.buttonNoMoreBags)}
        testData={testButtons}
      />
    </>
  )

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [accBagStatus, locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PutBagOnBelt',
      startIndex: 0,
      isQuitRequired: true,
      isLangRequired: true,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [
              intl.formatMessage(messages.PutBagOnBelt1),
              intl.formatMessage(messages.PutBagOnBeltSubTitle)
            ]
          }
        ]
      }
    }

    if (numberOfProcessedBags > 0) {
      accDef.sequenceDef.sequence[0].textParameters[0] = intl.formatMessage(messages.PutBagOnBelt2)
    } else if (config.useHeaviestMsg) {
      accDef.sequenceDef.sequence[0].textParameters[0] = intl.formatMessage(messages.PutBagOnBelt3)
    }

    if (numberOfProcessedBags > 0 && !nextBagQuestion) {
      accDef.sequenceDef.sequence.push({ id: 'confirmBtn', textId: 'NoMoreBags', buttonId: 'confirmBtn' })
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth, itineraryHeights: ['200px', '120px', '200px'] }
  )

  return <>{UIDisplay}</>
}

export default PutBagOnBelt
