import { SettingActions } from 'constants/Constants'

export function updateSettings(state = '', action) {
  let data = Object.assign({}, state)
  switch (action.type) {
    case SettingActions.UPDATE_SERVICE_DEFINITION:
      data.serviceDefinition = action.serviceDefinition
      break
    case SettingActions.UPDATE_SERVICE_CONFIGURATION:
      data.serviceConfiguration = action.serviceConfiguration
      break
    case SettingActions.UPDATE_FIRST_TRANSITION:
      data.firstTransition = action.firstTransition
      break
    case SettingActions.UPDATE_SERVER_TIME:
      data.serverTimeString = action.serverTimeString
      break
    default:
      return state
  }
  return data
}
